import { type OBUserContext } from 'features/Bento/types/OBContext';

/**
 * This is a basic context that should be used by all machines.
 * Note : getUserContext should be passed at instantiation and never reassigned.
 */
const baseContext = {
  getUserContext: () => {
    throw new Error('NOT_IMPLEMENTED');
  },
} as { getUserContext: () => OBUserContext };

export default baseContext;
