import { type MachineServices } from 'common/bento/types/MachineConfig';
import { apolloClient } from 'common/graphql/clients';

import { FinishProfileSetupDocument } from '../graphql/mutations/finishProfileSetup.gql';
import { type Context, type Model } from './model';

export enum Service {
  SendHasFinishedProfileSetup = 'sendHasFinishedProfileSetup',
}

const services: MachineServices<Model, Service> = {
  sendHasFinishedProfileSetup: async (context: Context) => {
    const { hasFinishedProfileSetup } =
      context.getModuleData().verificationData.viewer.metadata;

    /**
     * In the legacy onboarding there were littles chances to trigger this mutation twice.
     * But now it can be triggered every time you submit a complete and eligible compliance form.
     * To prevent firing multiple times the event Profile_Setup_Finished (see https://github.com/shinetools/graphql/blob/641a5351aa970ad8270cb7d2ddd6e925868575f8/src/api/shine/userMetadata/resolvers.js#L15)
     * we make sure to not call it again if it has been already set to true in the past.
     * This can be removed when we no longer use the event Profile_Setup_Finished.
     */
    if (hasFinishedProfileSetup) {
      return null;
    }

    return apolloClient.mutate({
      mutation: FinishProfileSetupDocument,
      variables: { input: { hasFinishedProfileSetup: true } },
    });
  },
};

export default services;
