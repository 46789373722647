import { type ExtractEventFrom } from 'features/Bento/types/Machine';

import getAffiliateSource from '../libs/getAffiliateSource';
import shouldUserGoThroughInHouseSasuCreation from '../libs/shouldUserGoThroughInHouseSasuCreation';
import { type Context, type Model } from './model';

/**
 * Original list provided here :
 * @see https://linear.app/shine/issue/LED-883/sign-up-affiliation-legaltech-skip-capdep-question
 */
const LEGALTECH_PARTNERS = [
  'captain-contrat',
  'contract-factory',
  'keobiz',
  'dougs',
  'fomalizi',
  'agence-juridique',
];

export enum Guard {
  IsInHouseSasuCreation = 'isInHouseSasuCreation',
}

const guards = {
  hasReferral: (context: Context) => {
    return !!context.referral;
  },
  hasStatutes: (
    context: Context,
    event: ExtractEventFrom<Model, 'SUBMIT_HAS_STATUTES'>,
  ) => event.data,

  isAffiliatedLegaltech: (context: Context) => {
    const affiliateSource = getAffiliateSource(context.affiliate);

    return Boolean(
      affiliateSource && LEGALTECH_PARTNERS.includes(affiliateSource),
    );
  },

  [Guard.IsInHouseSasuCreation]: (
    context: Context,
    event: ExtractEventFrom<Model, 'SUBMIT_COMPANY_CREATION_LEGAL_FORM'>,
  ) => shouldUserGoThroughInHouseSasuCreation(event.data),

  isIndependent: (
    context: Context,
    event: ExtractEventFrom<Model, 'SUBMIT_COMPANY_CREATION_TYPE'>,
  ) => event.data === 'independent',

  isSociety: (
    context: Context,
    event: ExtractEventFrom<Model, 'SUBMIT_COMPANY_CREATION_TYPE'>,
  ) => event.data === 'society',
};

export default guards;
