import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';

import { useStyles } from '../../context';

const CardAction = forwardRef<BoxProps, 'div'>((props, ref) => {
  const styles = useStyles();

  return <Box __css={styles.action} ref={ref} {...props} />;
});

export default CardAction;
