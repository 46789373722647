import {
  Box,
  type BoxProps,
  forwardRef,
  type ThemingProps,
} from '@chakra-ui/react';
import { type ComponentWithAs, useMultiStyleConfig } from '@chakra-ui/system';

import CardAction from './components/Action';
import CardContent, { type CardContentProps } from './components/Content';
import CardDescription from './components/Description';
import CardGroup from './components/Group';
import CardTitle from './components/Title';
import { COMPONENT_NAME, StylesProvider } from './context';

export interface CardParts {
  Action: typeof CardAction;
  Content: typeof CardContent;
  /**
   * Used to render texts or paragraphs inside the Card component, renders as a \<p\> tag by default
   */
  Description: typeof CardDescription;
  Group: typeof CardGroup;
  /**
   * Used to render a heading inside the Card component, renders as a \<p\> tag by default
   */
  Title: typeof CardTitle;
}

export interface CardProps extends BoxProps, ThemingProps<'Card'> {
  isActive?: boolean;
}

const Card = forwardRef<CardProps, 'div'>(
  (
    {
      children,
      className,
      isActive = false,
      size = 'md',
      variant = 'normal',
      ...props
    },
    ref,
  ) => {
    const styles = useMultiStyleConfig(COMPONENT_NAME, {
      isActive,
      size,
      variant,
    });

    /**
     * Card is used in the CardGroup component
     * props.className is used for Intercom (see features/HelpHome)
     */
    const classNames = ['Card', className];

    return (
      <Box
        __css={styles.container}
        ref={ref}
        {...props}
        className={classNames.join(' ')}
      >
        <StylesProvider value={styles}>{children}</StylesProvider>
      </Box>
    );
  },
) as ComponentWithAs<'div', CardProps> & CardParts;

Card.Action = CardAction;
Card.Content = CardContent;
Card.Description = CardDescription;
Card.Group = CardGroup;
Card.Title = CardTitle;

export type { CardContentProps };
export default Card;
