import type * as Types from '__generated__/GQL';
import { VerificationStatus } from 'features/Onboarding/modules/Verification/lib/steps/types';

/**
 * Maps a KYC status to a display status
 */
export const kycToStatus = (kycStatus: Types.KycStatus | null) => {
  switch (kycStatus) {
    case 'NONE':
    case 'MISSING':
      return VerificationStatus.ToDo;
    case 'REFUSED':
      return VerificationStatus.Invalid;
    case 'VALIDATED':
      return VerificationStatus.Validated;
    default:
      return VerificationStatus.Completed;
  }
};
