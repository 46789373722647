import { type FC, useContext } from 'react';
import { type SystemProps } from '@chakra-ui/system';

import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';
import NavigationContext from 'features/Onboarding/components/OBNavigation/NavigationContext';

import OBNavigationDesktop from './OBNavigationDesktop';
import OBNavigationMobile from './OBNavigationMobile';

export interface OBNavigationProps extends SystemProps {
  onPrev?: () => void;
  onClose?: () => void;
  isCollapsed?: boolean;
}

/**
 * Renders the navigation for the onboarding, including the onboarding timeline and user controls.
 */
const OBNavigation: FC<OBNavigationProps> = ({ isCollapsed, ...props }) => {
  const isDesktop = useLayoutSizeQuery(BentoLayoutSize.md);

  const navigationContext = useContext(NavigationContext);

  return isDesktop ? (
    <OBNavigationDesktop navigation={navigationContext} {...props} />
  ) : (
    <OBNavigationMobile
      isCollapsed={isCollapsed}
      navigation={navigationContext}
      {...props}
    />
  );
};

export default OBNavigation;
