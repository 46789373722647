import { useQuery } from '@apollo/client';

import useCompanyContext from 'common/hooks/useCompanyContext';
import { type RoleFeaturesProps } from 'features/TeamOnboarding/TeamOnboardingTutorials/utils';

import { CompanyUserInvitationDocument } from './companyUserInvitation.gql';

const useCompanyUserInvitation = () => {
  const { companyProfileId } = useCompanyContext();

  const { data, ...hook } = useQuery(CompanyUserInvitationDocument, {
    skip: !companyProfileId,
    variables: { companyProfileId: companyProfileId as string },
  });

  const featuresData: Omit<RoleFeaturesProps, 'onTutorialsDone'> = {
    companyName: data?.viewer.company.profile.legalName ?? '',
    firstName: data?.viewer.companyUser.firstName ?? '',
  };

  return {
    data,
    featuresData,
    role: data?.viewer.companyUser.invitation?.role,
    ...hook,
  };
};

export default useCompanyUserInvitation;
