import { forwardRef } from '@chakra-ui/system';

import Button, { type ButtonProps } from 'components/_core/Button';

interface MenuMobileItemProps extends Omit<ButtonProps, 'children'> {
  label: string;
}

const MenuMobileItem = forwardRef<MenuMobileItemProps, 'button'>(
  ({ label, ...rest }, ref) => (
    <Button
      _after={{ display: 'none' }}
      height="auto"
      iconPosition="left"
      padding="space-16"
      ref={ref}
      textAlign="left"
      variant="inline-secondary"
      width="full"
      {...rest}
    >
      {label}
    </Button>
  ),
);

export default MenuMobileItem;
