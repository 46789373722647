import { createContext, useContext } from 'react';
import { type UseRadioGroupReturn } from '@chakra-ui/react';

import { type RadioPositions } from '..';

interface RadioGroupContext extends UseRadioGroupReturn {
  radioPosition?: RadioPositions;
}

const Context = createContext<RadioGroupContext | null>(null);

export const RadioGroupProvider = Context.Provider;

export function useRadioGroup() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useRadioGroup can only be used inside of a RadioGroupProvider',
    );
  }

  return context;
}
