import { type FC } from 'react';
import { Box } from '@chakra-ui/react';

import { OnboardingPhaseWrapper } from 'features/Onboarding/styles';

import OnboardingSidePanel from '../OnboardingSidePanel';
import Page from '../Page';
import { type OnboardingStyleMessageWrapperProps } from './types';

/**
 * A wrapper that can be used outside of the Onboarding feature to show a message within its usual container.
 */
const OnboardingStyleMessageWrapper: FC<OnboardingStyleMessageWrapperProps> = ({
  children,
  user,
}) => (
  <OnboardingPhaseWrapper>
    <Box padding="space-24">
      <OnboardingSidePanel user={user} />
    </Box>
    <Page withNoBackButton>{children}</Page>
  </OnboardingPhaseWrapper>
);

export default OnboardingStyleMessageWrapper;
