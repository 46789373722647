import { type FC } from 'react';

import GuidanceBaseAdvertising from './Base/GuidanceBaseAdvertising';
import MobileAppRatings from './components/MobileAppRatings';
import locales from './locales';

/**
 * Ad-like guidance to promote Shine Start
 */
const GuidanceShineStartAdvertising: FC = () => {
  const loc = locales.shineStartAdvertising;

  return (
    <GuidanceBaseAdvertising title={loc.title}>
      <MobileAppRatings />
    </GuidanceBaseAdvertising>
  );
};

export default GuidanceShineStartAdvertising;
