import { format } from 'date-fns';

import {
  type FinishIdentityVerificationInput,
  type KycReviewDocumentType,
} from '__generated__/GQL';
import config from 'config';
import assertEventType from 'common/bento/lib/assertEventType';
import { type MachineServices } from 'common/bento/types/MachineConfig';
import { apolloClient } from 'common/graphql/clients';
import webView from 'common/webView';
import logger from 'helpers/logger';

import { FinishIdentityVerificationDocument } from '../graphql/mutations/finishIdentityVerification.gql';
import { StartIdentityVerificationDocument } from '../graphql/mutations/startIdentityVerification.gql';
import { VerificationIdUpdateIdentityDocument } from '../graphql/mutations/verificationIdUpdateIdentity.gql';
import { AdminIdentityModuleDataDocument } from '../graphql/queries/adminIdentityModuleData.gql';
import getOnfidoError from '../libs/getOnfidoError';
import { type Model, type NativeIdCheckOutcome } from './model';

export enum Service {
  CompleteNativeIdCheck = 'completeNativeIdCheck',
  UpdateIdentity = 'updateIdentity',
  StartIdentityVerification = 'startIdentityVerification',
  FinishIdentityVerification = 'finishIdentityVerification',
}

const services: MachineServices<Model, Service> = {
  completeNativeIdCheck: async (): Promise<NativeIdCheckOutcome> => {
    webView.postMessage({ action: 'NATIVE_ID_CHECK_REQUESTED' });

    return new Promise((resolve) => {
      webView.listenNativeMessage(async (message) => {
        switch (message.action) {
          case 'NATIVE_ID_CHECK_COMPLETED': {
            await apolloClient.refetchQueries({
              include: [AdminIdentityModuleDataDocument],
            });

            return resolve({ status: 'done' });
          }

          case 'NATIVE_ID_CHECK_CANCELED': {
            return resolve({ status: 'canceled' });
          }

          case 'NATIVE_ID_CHECK_FAILED': {
            const onfidoErrorCode = message.payload;

            if (!onfidoErrorCode) {
              return resolve({ error: null, status: 'error' });
            }

            const onfidoError = getOnfidoError(onfidoErrorCode);

            if (onfidoError === null) {
              logger.error(`Unhandled Onfido error code: ${onfidoErrorCode}`);
            }

            return resolve({ error: onfidoError, status: 'error' });
          }

          default: {
            logger.error(
              `Unhandled message in NativeIdCheck: ${JSON.stringify(message)}`,
            );
            await apolloClient.refetchQueries({
              include: [AdminIdentityModuleDataDocument],
            });

            /**
             * By default we'll try to mark Onfido as done.
             * Refetching the data will show to the user if it was really done, or not.
             */
            return resolve({ status: 'done' });
          }
        }
      });
    });
  },

  finishIdentityVerification: async (
    { getModuleData, idCheckContext },
    event,
  ) => {
    assertEventType(event, ['ONFIDO_SUCCESS', 'xstate.init']);

    if (!idCheckContext?.provider) {
      throw new Error(
        'idCheckContext is required in finishIdentityVerification',
      );
    }

    const { companyUser } = getModuleData().viewer;

    const input: FinishIdentityVerificationInput = {
      companyUserId: companyUser.companyUserId,
      provider: idCheckContext.provider,
    };

    if (event.type === 'ONFIDO_SUCCESS') {
      input.providerDocuments = event.data.map(
        ({ providerDocumentId, type }) => ({
          providerDocumentId,
          type: type as KycReviewDocumentType,
        }),
      );
    }

    const { data } = await apolloClient.mutate({
      awaitRefetchQueries: true,
      mutation: FinishIdentityVerificationDocument,
      refetchQueries: [
        {
          query: AdminIdentityModuleDataDocument,
          variables: {
            companyProfileId: companyUser.companyProfileId,
          },
        },
      ],
      variables: { input },
    });

    return data;
  },

  startIdentityVerification: async ({ getModuleData, idDocumentCase }) => {
    const { data, errors } = await apolloClient.mutate({
      mutation: StartIdentityVerificationDocument,
      variables: {
        input: {
          companyUserId: getModuleData().viewer.companyUser.companyUserId,
          environment: config.identityCheckSdkEnvironment,
          idDocumentCase,
        },
      },
    });

    if (!data) {
      throw new Error(
        errors?.[0]?.message ?? 'startIdentityVerification failed',
      );
    }

    return {
      provider: data.startIdentityVerification.provider,
      token: data.startIdentityVerification.sdkToken,
    };
  },

  updateIdentity: async (_, event) => {
    assertEventType(event, 'SUBMIT');
    const identityData = event.data;

    await apolloClient.mutate({
      mutation: VerificationIdUpdateIdentityDocument,
      variables: {
        input: {
          ...identityData,
          birthdate: format(new Date(identityData.birthdate), 'yyyy-MM-dd'),
        },
      },
    });
  },
};

export default services;
