export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** A scalar that validates UUID v4 values */
  UUIDv4: { input: string; output: string; }
};

export enum Account_Closure_Status {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Locked = 'LOCKED',
  Requested = 'REQUESTED'
}

export type AbortApplicationInput = {
  id: Scalars['ID']['input'];
};

export type AcceptAuthenticationDeviceRequestInput = {
  authenticationDeviceId: Scalars['ID']['input'];
  authenticationDeviceRequestId: Scalars['ID']['input'];
  phone: Scalars['ID']['input'];
};

export type AcceptAuthenticationDeviceRequestPayload = {
  __typename: 'AcceptAuthenticationDeviceRequestPayload';
  authenticationDeviceRequest: AuthenticationDeviceRequest;
};

export type AcceptCardPaymentStrongAuthenticationRequestInput = {
  cardPaymentStrongAuthenticationRequestId: Scalars['ID']['input'];
  deviceToken: Scalars['String']['input'];
};

export type AccountClosableResult = {
  __typename: 'AccountClosableResult';
  ineligibilityReasons: Array<AccountClosureIneligibilityReason>;
  isClosable: Scalars['Boolean']['output'];
};

export type AccountClosure = {
  __typename: 'AccountClosure';
  accountClosureId: Scalars['ID']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  companyUserId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  explanationsPayload: Maybe<Scalars['JSON']['output']>;
  kyxCheckId: Maybe<Scalars['String']['output']>;
  reasons: Array<AccountClosureReason>;
  status: Account_Closure_Status;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type AccountClosureCancelInput = {
  accountClosureId: Scalars['ID']['input'];
};

export type AccountClosureCreationInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  explanationsPayload?: InputMaybe<Scalars['JSON']['input']>;
  hasLeftForCompetition?: InputMaybe<Scalars['Boolean']['input']>;
  reasons: Array<AccountClosureReason>;
};

export enum AccountClosureIneligibilityReason {
  AccountNotEmpty = 'ACCOUNT_NOT_EMPTY',
  CheckDepositCashedIn = 'CHECK_DEPOSIT_CASHED_IN',
  IsNotAccountHolder = 'IS_NOT_ACCOUNT_HOLDER',
  MerchantAccountNotEmpty = 'MERCHANT_ACCOUNT_NOT_EMPTY',
  OngoingProcedure = 'ONGOING_PROCEDURE',
  OpenedCapitalDeposit = 'OPENED_CAPITAL_DEPOSIT',
  PendingTransaction = 'PENDING_TRANSACTION'
}

export enum AccountClosureReason {
  Other = 'OTHER',
  SatisfiedMissingBankingService = 'SATISFIED_MISSING_BANKING_SERVICE',
  SatisfiedMissingFeature = 'SATISFIED_MISSING_FEATURE',
  SatisfiedNoActivityOrTooFewActivityForNow = 'SATISFIED_NO_ACTIVITY_OR_TOO_FEW_ACTIVITY_FOR_NOW',
  SatisfiedOther = 'SATISFIED_OTHER',
  SatisfiedPricingIssue = 'SATISFIED_PRICING_ISSUE',
  SatisfiedTechnicalIssue = 'SATISFIED_TECHNICAL_ISSUE',
  StopActivity = 'STOP_ACTIVITY',
  UnsatisfiedBadBankingServiceExperience = 'UNSATISFIED_BAD_BANKING_SERVICE_EXPERIENCE',
  UnsatisfiedBadSupportExperience = 'UNSATISFIED_BAD_SUPPORT_EXPERIENCE',
  UnsatisfiedMissingBankingService = 'UNSATISFIED_MISSING_BANKING_SERVICE',
  UnsatisfiedMissingFeature = 'UNSATISFIED_MISSING_FEATURE',
  UnsatisfiedOther = 'UNSATISFIED_OTHER',
  UnsatisfiedPricingIssue = 'UNSATISFIED_PRICING_ISSUE',
  UnsatisfiedTechnicalIssue = 'UNSATISFIED_TECHNICAL_ISSUE'
}

export type AccountingExport = {
  __typename: 'AccountingExport';
  accountingExportId: Scalars['ID']['output'];
  accountingExportRecipientId: Scalars['ID']['output'];
  accountingRecurringExportId: Maybe<Scalars['ID']['output']>;
  bankAccountId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  options: Array<Maybe<AccountingExportOption>>;
  startDate: Scalars['String']['output'];
  status: AccountingExportStatus;
  storageBucketName: Maybe<Scalars['String']['output']>;
  storageFileRef: Maybe<Scalars['String']['output']>;
  type: AccountingExportType;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export enum AccountingExportOption {
  BankStatements = 'BANK_STATEMENTS',
  Invoices = 'INVOICES',
  Receipts = 'RECEIPTS',
  Transactions = 'TRANSACTIONS'
}

export type AccountingExportRecipient = {
  __typename: 'AccountingExportRecipient';
  accountingExportRecipientId: Scalars['ID']['output'];
  bankAccountId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type AccountingExportRecipientInput = {
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum AccountingExportStatus {
  Failed = 'FAILED',
  Generated = 'GENERATED',
  Pending = 'PENDING',
  Sent = 'SENT',
  Todo = 'TODO'
}

export enum AccountingExportType {
  OneOff = 'ONE_OFF',
  Recurring = 'RECURRING'
}

export type AccountingRecurringExport = {
  __typename: 'AccountingRecurringExport';
  accountingExportRecipientId: Scalars['ID']['output'];
  accountingRecurringExportId: Scalars['ID']['output'];
  bankAccountId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  frequency: Scalars['String']['output'];
  options: Array<Maybe<AccountingExportOption>>;
  recipient: Maybe<AccountingExportRecipient>;
  status: AccountingRecurringExportStatus;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type AccountingRecurringExportConnection = {
  __typename: 'AccountingRecurringExportConnection';
  edges: Array<AccountingRecurringExportEdge>;
  pageInfo: PageInfo;
};

export type AccountingRecurringExportEdge = {
  __typename: 'AccountingRecurringExportEdge';
  cursor: Scalars['String']['output'];
  node: AccountingRecurringExport;
};

export enum AccountingRecurringExportStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Paused = 'PAUSED'
}

export type ActionRequest = {
  __typename: 'ActionRequest';
  action: Scalars['String']['output'];
  authenticationActionRequestId: Scalars['ID']['output'];
  client: ThirdPartyClient;
  expiresAt: Scalars['String']['output'];
  payload: Scalars['String']['output'];
  recipient: Maybe<BankTransferRecipient>;
  status: ActionRequestStatus;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum ActionRequestStatus {
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type ActivateCreditCardInput = {
  creditCardId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type ActivatePhysicalPaymentCardInput = {
  paymentCardId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type ActivatePhysicalPaymentCardPayload = {
  __typename: 'ActivatePhysicalPaymentCardPayload';
  creditCard: CreditCardInterface;
  pin: Maybe<Scalars['String']['output']>;
};

export type ActivationOnboardingProgress = {
  __typename: 'ActivationOnboardingProgress';
  completed: Scalars['Boolean']['output'];
  done: Array<ActivationOnboardingStep>;
  skipped: Array<ActivationOnboardingStep>;
};

export enum ActivationOnboardingStep {
  FirstCardOrdered = 'FIRST_CARD_ORDERED',
  FirstPayin = 'FIRST_PAYIN',
  StrongAuthenticationEnrolled = 'STRONG_AUTHENTICATION_ENROLLED'
}

export type ActivationWidget = {
  __typename: 'ActivationWidget';
  accountFilled: Scalars['Boolean']['output'];
  enrolledStrongAuthentication: Scalars['Boolean']['output'];
  paymentCardOrdered: Scalars['Boolean']['output'];
};

export type ActivityDistributionAmounts = {
  bicDistributionAmount?: InputMaybe<Scalars['Float']['input']>;
  bncDistributionAmount?: InputMaybe<Scalars['Float']['input']>;
  sellingDistributionAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type AddOrReplaceKybDocumentInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  firebaseBucket?: InputMaybe<Scalars['String']['input']>;
  firebaseRef?: InputMaybe<Scalars['String']['input']>;
  type: KybDocumentType;
  uid?: InputMaybe<Scalars['ID']['input']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetAddition?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AnswerHeardAboutUsInput = {
  value: Scalars['String']['input'];
};

export type AnswerHeardAboutUsPayload = {
  __typename: 'AnswerHeardAboutUsPayload';
  success: Scalars['Boolean']['output'];
};

export type Application = {
  __typename: 'Application';
  applicationId: Scalars['ID']['output'];
  canResetOnboarding: Scalars['Boolean']['output'];
  capitalDeposit: Maybe<CapitalDeposit>;
  companyCreationDocuments: Array<CompanyCreationDocument>;
  companyCreationSASU: Maybe<CompanyCreationSasu>;
  companyProfileId: Scalars['UUIDv4']['output'];
  isCapitalDeposit: Scalars['Boolean']['output'];
  isCompanyCreationSASU: Scalars['Boolean']['output'];
  isCreatedByMandatee: Scalars['Boolean']['output'];
  isMultiKYC: Scalars['Boolean']['output'];
  isShineStart: Scalars['Boolean']['output'];
  kybDocuments: Array<KybDocument>;
  lastKybReview: Maybe<KybReview>;
  legalBeneficiaries: Array<CompanyLegalBeneficiary>;
  metadata: CompanyMetadata;
  onboardingCompanyProfile: OnboardingCompanyProfile;
  onboardingPackages: Array<OnboardingPackage>;
  onboardingPlans: Array<OnboardingPlan>;
  onboardingSasuAddons: Array<SasuAddon>;
  shineStart: Maybe<ShineStart>;
  shouldSeeProAndBusinessPlans: Scalars['Boolean']['output'];
  supposedBeneficiaries: Array<CompanySupposedBeneficiary>;
  type: ApplicationType;
};


export type ApplicationKybDocumentsArgs = {
  type: InputMaybe<KybDocumentType>;
};


export type ApplicationOnboardingPackagesArgs = {
  input: InputMaybe<CompanyOnboardingPackagesInput>;
};


export type ApplicationOnboardingPlansArgs = {
  input: InputMaybe<CompanyOnboardingPlansInput>;
};

export type ApplicationAborted = {
  __typename: 'ApplicationAborted';
  id: Scalars['ID']['output'];
};

export type ApplicationSignedDocumentRetrieved = {
  __typename: 'ApplicationSignedDocumentRetrieved';
  document: CompanyCreationDocument;
  id: Scalars['ID']['output'];
};

export type ApplicationStarted = {
  __typename: 'ApplicationStarted';
  id: Scalars['ID']['output'];
  type: ApplicationType;
  userId: Scalars['ID']['output'];
};

export enum ApplicationType {
  CapitalDeposit = 'CapitalDeposit',
  CompanyCreationSasu = 'CompanyCreationSASU',
  ShineStart = 'ShineStart',
  Standard = 'Standard'
}

export type ApproveBankTransferRequestInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  recipientEmail?: InputMaybe<Scalars['String']['input']>;
  shouldNotifyRecipient: Scalars['Boolean']['input'];
  teamMembersRequestId: Scalars['UUIDv4']['input'];
};

export type ApproveExpenseClaimRequestInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  teamMembersRequestId: Scalars['UUIDv4']['input'];
};

export type ApprovePhysicalPaymentCardOrderRequestInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  isOptionAtmEnabled: Scalars['Boolean']['input'];
  isOptionNfcEnabled: Scalars['Boolean']['input'];
  isOptionOnlineEnabled: Scalars['Boolean']['input'];
  limitAtmMonth: Scalars['Float']['input'];
  limitPaymentWeek: Scalars['Float']['input'];
  teamMembersRequestId: Scalars['UUIDv4']['input'];
};

export type ApproveVirtualBudgetCardOrderRequestInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  deactivateAt?: InputMaybe<Scalars['String']['input']>;
  isOptionNfcEnabled: Scalars['Boolean']['input'];
  isOptionOnlineEnabled: Scalars['Boolean']['input'];
  limitPaymentAll: Scalars['Float']['input'];
  limitPaymentWeek: Scalars['Float']['input'];
  teamMembersRequestId: Scalars['UUIDv4']['input'];
};

export type ApproveVirtualPaymentCardOrderRequestInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  deactivateAt?: InputMaybe<Scalars['String']['input']>;
  isOptionNfcEnabled: Scalars['Boolean']['input'];
  isOptionOnlineEnabled: Scalars['Boolean']['input'];
  limitPaymentWeek: Scalars['Float']['input'];
  teamMembersRequestId: Scalars['UUIDv4']['input'];
};

export type ArchiveQuoteInput = {
  quoteId: Scalars['ID']['input'];
};

export type AskForFreeMonthInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
};

export type AuthenticationClientOutput = {
  __typename: 'AuthenticationClientOutput';
  agreementId: Scalars['String']['output'];
  authenticationClientId: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  hasCertificate: Maybe<Scalars['Boolean']['output']>;
  logoURL: Scalars['String']['output'];
  qsealCertificateFileRef: Maybe<Scalars['String']['output']>;
  qwacCertificateFileRef: Maybe<Scalars['String']['output']>;
  scope: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type AuthenticationDevice = {
  __typename: 'AuthenticationDevice';
  authenticationDeviceId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  lastConnectedAt: Maybe<Scalars['String']['output']>;
  lastConnectionAttemptedAt: Maybe<Scalars['String']['output']>;
  lastConnectionCity: Maybe<Scalars['String']['output']>;
  lastConnectionCountry: Maybe<Scalars['String']['output']>;
  model: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  notificationToken: Maybe<Scalars['String']['output']>;
  phone: Scalars['ID']['output'];
  platform: Maybe<AuthenticationDevicePlatform>;
  request: AuthenticationDeviceRequest;
  status: AuthenticationDeviceStatus;
  token: Scalars['String']['output'];
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};


export type AuthenticationDeviceRequestArgs = {
  authenticationDeviceRequestId: Scalars['ID']['input'];
};

export type AuthenticationDeviceParams = {
  authenticationDeviceId: Scalars['ID']['input'];
  phone: Scalars['ID']['input'];
};

export enum AuthenticationDevicePlatform {
  Android = 'android',
  Ios = 'ios',
  Web = 'web'
}

export type AuthenticationDeviceRequest = {
  __typename: 'AuthenticationDeviceRequest';
  authenticationDeviceId: Scalars['ID']['output'];
  authenticationDeviceRequestId: Scalars['ID']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  expiresAt: Scalars['String']['output'];
  ip: Scalars['String']['output'];
  phone: Scalars['ID']['output'];
  status: AuthenticationDeviceRequestStatus;
  updatedAt: Scalars['String']['output'];
};

export type AuthenticationDeviceRequestConnection = {
  __typename: 'AuthenticationDeviceRequestConnection';
  edges: Array<AuthenticationDeviceRequestEdge>;
  pageInfo: PageInfo;
};

export type AuthenticationDeviceRequestEdge = {
  __typename: 'AuthenticationDeviceRequestEdge';
  cursor: Scalars['String']['output'];
  node: AuthenticationDeviceRequest;
};

export enum AuthenticationDeviceRequestStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Refused = 'REFUSED'
}

export enum AuthenticationDeviceStatus {
  Blocked = 'BLOCKED',
  Trusted = 'TRUSTED',
  Unknown = 'UNKNOWN'
}

export type AutomaticVirtualCardOrderPayload = {
  __typename: 'AutomaticVirtualCardOrderPayload';
  isDefinitiveValue: Scalars['Boolean']['output'];
  isPlanInsufficient: Maybe<Scalars['Boolean']['output']>;
  shouldAutoOrderVirtualCard: Scalars['Boolean']['output'];
};

export type B2BMandateInput = {
  bankAccountId: Scalars['ID']['input'];
  bucketName?: InputMaybe<Scalars['String']['input']>;
  companyUserId?: InputMaybe<Scalars['ID']['input']>;
  creditorIdentifier: Scalars['String']['input'];
  creditorName: Scalars['String']['input'];
  fileRef?: InputMaybe<Scalars['String']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  publicUrl?: InputMaybe<Scalars['String']['input']>;
  uniqueMandateReference: Scalars['String']['input'];
};

export type B2BMandatePayload = {
  __typename: 'B2BMandatePayload';
  directDebitMandate: DirectDebitMandate;
};

export type BalanceWidgetAccount = {
  __typename: 'BalanceWidgetAccount';
  bankAccountId: Scalars['ID']['output'];
  currency: Scalars['String']['output'];
  currentBalance: Scalars['Float']['output'];
  illustrationName: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type BalancesWidget = {
  __typename: 'BalancesWidget';
  bankAccounts: Array<BalanceWidgetAccount>;
  totalBalance: Scalars['Int']['output'];
  totalCurrency: Scalars['String']['output'];
};

export type BankAccount = {
  __typename: 'BankAccount';
  activationOnboardingProgress: ActivationOnboardingProgress;
  bankAccountId: Scalars['ID']['output'];
  bankLocation: Scalars['String']['output'];
  bankZipCode: Scalars['String']['output'];
  bban: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  currentBalance: Scalars['Float']['output'];
  iban: Scalars['String']['output'];
  illustrationName: Maybe<Scalars['String']['output']>;
  lockedCustomMessage: Maybe<Scalars['String']['output']>;
  lockedReason: Maybe<LockedReason>;
  name: Scalars['String']['output'];
  nextBalance: Scalars['Float']['output'];
  /** @deprecated Use Viewer.company.legalName instead */
  ownerName: Scalars['String']['output'];
  status: Maybe<BankAccountStatus>;
  trzWalletId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
};


export type BankAccountActivationOnboardingProgressArgs = {
  companyUserId: Scalars['ID']['input'];
};

export type BankAccountEdge = {
  __typename: 'BankAccountEdge';
  cursor: Scalars['String']['output'];
  node: BankAccount;
};

export enum BankAccountStatementStatus {
  Deleted = 'DELETED',
  Pending = 'PENDING',
  Validated = 'VALIDATED'
}

export type BankAccountStatements = {
  __typename: 'BankAccountStatements';
  accessToken: Maybe<Scalars['String']['output']>;
  bankAccountId: Scalars['ID']['output'];
  bankAccountStatementId: Scalars['ID']['output'];
  endBalance: Scalars['Int']['output'];
  endsAt: Scalars['String']['output'];
  startBalance: Scalars['Int']['output'];
  startsAt: Scalars['String']['output'];
  status: Maybe<BankAccountStatementStatus>;
  uid: Scalars['ID']['output'];
};

export type BankAccountStatementsEdge = {
  __typename: 'BankAccountStatementsEdge';
  cursor: Scalars['String']['output'];
  node: BankAccountStatements;
};

export enum BankAccountStatus {
  Closed = 'CLOSED',
  Locked = 'LOCKED',
  Pending = 'PENDING',
  Validated = 'VALIDATED'
}

export type BankAccountsFilters = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type BankTransfer = {
  __typename: 'BankTransfer';
  amount: Scalars['Float']['output'];
  bankAccountId: Maybe<Scalars['ID']['output']>;
  bankProvider: BankTransferProviders;
  bankProviderTransferId: Scalars['ID']['output'];
  bankTransferId: Scalars['ID']['output'];
  bankTransferRecipientId: Scalars['ID']['output'];
  companyUserId: Scalars['ID']['output'];
  createdAt: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  endRecurringAt: Maybe<Scalars['Float']['output']>;
  failureReasonCode: Maybe<Scalars['String']['output']>;
  isRecurring: Maybe<Scalars['Boolean']['output']>;
  operatorFlag: Maybe<Scalars['String']['output']>;
  operatorNote: Maybe<Scalars['String']['output']>;
  recurrence: Maybe<Scalars['String']['output']>;
  scheduledBankTransferId: Maybe<Scalars['ID']['output']>;
  startAt: Maybe<Scalars['Float']['output']>;
  startRecurringAt: Maybe<Scalars['Float']['output']>;
  status: BankTransferStatus;
  statusAt: Maybe<Scalars['Float']['output']>;
  statusMessage: Maybe<Scalars['String']['output']>;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['Float']['output'];
  userLabel: Maybe<Scalars['String']['output']>;
};

export type BankTransferEdge = {
  __typename: 'BankTransferEdge';
  cursor: Scalars['String']['output'];
  node: BankTransfer;
};

export type BankTransferPayload = {
  __typename: 'BankTransferPayload';
  bankTransfer: Maybe<BankTransfer>;
};

export enum BankTransferProviders {
  Treezor = 'TREEZOR'
}

export type BankTransferRecipient = {
  __typename: 'BankTransferRecipient';
  bankProvider: BankTransferProviders;
  bankProviderTransferRecipientId: Scalars['ID']['output'];
  bankTransferRecipientId: Scalars['ID']['output'];
  createdAt: Scalars['Float']['output'];
  email: Maybe<Scalars['String']['output']>;
  iban: Maybe<Scalars['String']['output']>;
  isTreezorBeneficiary: Maybe<Scalars['Boolean']['output']>;
  isUserBankAccount: Scalars['Boolean']['output'];
  label: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  status: BankTransferRecipientStatus;
  swiftBic: Maybe<Scalars['String']['output']>;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['Float']['output'];
};

export type BankTransferRecipientEdge = {
  __typename: 'BankTransferRecipientEdge';
  cursor: Scalars['String']['output'];
  node: BankTransferRecipient;
};

export type BankTransferRecipientFilterInput = {
  iban: Scalars['String']['input'];
};

export type BankTransferRecipientInput = {
  bankTransferRecipientId?: InputMaybe<Scalars['ID']['input']>;
  iban: Scalars['String']['input'];
  name: Scalars['String']['input'];
  swiftBic: Scalars['String']['input'];
};

export type BankTransferRecipientPayload = {
  __typename: 'BankTransferRecipientPayload';
  bankTransferRecipient: Maybe<BankTransferRecipient>;
};

export enum BankTransferRecipientStatus {
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Validated = 'VALIDATED'
}

export type BankTransferRequest = {
  __typename: 'BankTransferRequest';
  cancelReason: Maybe<TeamMembersRequestCancelReason>;
  cancelledAt: Maybe<Scalars['String']['output']>;
  companyProfileId: Scalars['UUIDv4']['output'];
  completedAt: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  requester: Maybe<TeamMembersRequestRequester>;
  requesterCompanyUserId: Scalars['UUIDv4']['output'];
  requesterPayload: BankTransferRequestRequesterPayload;
  reviewedAt: Maybe<Scalars['String']['output']>;
  reviewer: Maybe<TeamMembersRequestReviewer>;
  reviewerCompanyUserId: Maybe<Scalars['UUIDv4']['output']>;
  reviewerPayload: Maybe<BankTransferRequestReviewerPayload>;
  status: TeamMembersRequestStatus;
  supportingDocumentsUrls: Array<Scalars['String']['output']>;
  teamMembersRequestId: Scalars['UUIDv4']['output'];
  type: TeamMembersRequestType;
  updatedAt: Scalars['String']['output'];
};

export type BankTransferRequestRecipient = {
  __typename: 'BankTransferRequestRecipient';
  iban: Scalars['String']['output'];
  name: Scalars['String']['output'];
  swiftBic: Scalars['String']['output'];
};

export type BankTransferRequestRecipientInput = {
  iban: Scalars['String']['input'];
  name: Scalars['String']['input'];
  swiftBic: Scalars['String']['input'];
};

export type BankTransferRequestRequesterPayload = {
  __typename: 'BankTransferRequestRequesterPayload';
  amount: Scalars['Int']['output'];
  bankAccountId: Scalars['ID']['output'];
  bankTransferRecipient: BankTransferRequestRecipient;
  userLabel: Maybe<Scalars['String']['output']>;
};

export type BankTransferRequestReviewerPayload = {
  __typename: 'BankTransferRequestReviewerPayload';
  bankAccountId: Scalars['ID']['output'];
  recipientEmail: Maybe<Scalars['String']['output']>;
  shouldNotifyRecipient: Scalars['Boolean']['output'];
};

export enum BankTransferStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  ProviderCreationPending = 'PROVIDER_CREATION_PENDING',
  Validated = 'VALIDATED'
}

export type BankingPlanQuotas = {
  __typename: 'BankingPlanQuotas';
  cardQuota: Scalars['Int']['output'];
  cashDepositQuota: Scalars['Int']['output'];
  checkQuota: Scalars['Int']['output'];
  sepaQuota: Scalars['Int']['output'];
  virtualCardQuota: Scalars['Int']['output'];
  withdrawalQuota: Scalars['Int']['output'];
};

export type BeneficiaryListApplicantInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum BicActivityCategory {
  SellingGoods = 'SELLING_GOODS',
  Services = 'SERVICES'
}

export enum BicServiceCategory {
  Artisan = 'ARTISAN',
  Commercial = 'COMMERCIAL'
}

export type BlockAuthenticationDeviceInput = {
  authenticationDeviceId: Scalars['ID']['input'];
  phone: Scalars['ID']['input'];
};

export type BlockAuthenticationDevicePayload = {
  __typename: 'BlockAuthenticationDevicePayload';
  authenticationDevice: AuthenticationDevice;
};

export type BrowserInfo = {
  colorDepth: Scalars['Int']['input'];
  httpUserAgent: Scalars['String']['input'];
  javaEnabled: Scalars['Boolean']['input'];
  javascriptEnabled: Scalars['Boolean']['input'];
  language: Scalars['String']['input'];
  screenHeight: Scalars['Int']['input'];
  screenWidth: Scalars['Int']['input'];
  timezone: Scalars['String']['input'];
};

export type BulkBankTransfer = {
  __typename: 'BulkBankTransfer';
  bulkBankTransferId: Scalars['ID']['output'];
  totalAmount: Scalars['Float']['output'];
};

export type BulkBankTransferError = {
  __typename: 'BulkBankTransferError';
  code: BulkBankTransferParsingError;
  transferIndexes: Maybe<Array<Scalars['Int']['output']>>;
};

export type BulkBankTransferFileParseResult = {
  __typename: 'BulkBankTransferFileParseResult';
  bucketName: Scalars['String']['output'];
  errors: Array<BulkBankTransferError>;
  fileRef: Scalars['ID']['output'];
  nextBalance: Scalars['Float']['output'];
  totalAmount: Scalars['Float']['output'];
  transfers: Array<Transfer>;
};

export type BulkBankTransferFileParseResultInput = {
  bucketName: Scalars['String']['input'];
  companyUserId: Scalars['ID']['input'];
  fileRef: Scalars['String']['input'];
};

export enum BulkBankTransferParsingError {
  BulkBankTransferAmountNotInEuro = 'BULK_BANK_TRANSFER_AMOUNT_NOT_IN_EURO',
  BulkBankTransferAmountTooHigh = 'BULK_BANK_TRANSFER_AMOUNT_TOO_HIGH',
  BulkBankTransferFileFormatNotSupported = 'BULK_BANK_TRANSFER_FILE_FORMAT_NOT_SUPPORTED',
  BulkBankTransferFileNotParsable = 'BULK_BANK_TRANSFER_FILE_NOT_PARSABLE',
  BulkBankTransferIbanNotSepa = 'BULK_BANK_TRANSFER_IBAN_NOT_SEPA',
  BulkBankTransferIbanNotValid = 'BULK_BANK_TRANSFER_IBAN_NOT_VALID',
  BulkBankTransferInsufficientFunds = 'BULK_BANK_TRANSFER_INSUFFICIENT_FUNDS',
  BulkBankTransferLabelHasSpecialChar = 'BULK_BANK_TRANSFER_LABEL_HAS_SPECIAL_CHAR',
  BulkBankTransferRecipientNameDiffers = 'BULK_BANK_TRANSFER_RECIPIENT_NAME_DIFFERS',
  BulkBankTransferTooManyTransfers = 'BULK_BANK_TRANSFER_TOO_MANY_TRANSFERS'
}

export type CanAccessFeature = {
  __typename: 'CanAccessFeature';
  banking: Scalars['Boolean']['output'];
  company: Scalars['Boolean']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  export: Scalars['Boolean']['output'];
  taxForecast: Scalars['Boolean']['output'];
  uid: Scalars['ID']['output'];
};

export type CancelBankTransferInput = {
  transactionId: Scalars['ID']['input'];
};

export type CancelDowngradeInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type CancelInvoiceInput = {
  invoiceId: Scalars['ID']['input'];
};

export type CancelScheduledBankTransferInput = {
  scheduledBankTransferId: Scalars['ID']['input'];
};

export type CancelTeamMembersRequestInput = {
  teamMembersRequestId: Scalars['UUIDv4']['input'];
};

export type CapitalDeposit = {
  __typename: 'CapitalDeposit';
  capitalDepositId: Scalars['ID']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['String']['output'];
  depositAccountBic: Maybe<Scalars['String']['output']>;
  depositAccountIban: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  hasEuropeanBankAccount: Maybe<Scalars['Boolean']['output']>;
  ineligibilityReason: Maybe<Scalars['String']['output']>;
  isConfirmedPartner: Maybe<Scalars['Boolean']['output']>;
  isPaid: Scalars['Boolean']['output'];
  paidUpCapitalPercentage: Maybe<Scalars['Float']['output']>;
  partner: Maybe<Scalars['String']['output']>;
  partnerDetails: Maybe<Scalars['String']['output']>;
  provider: Maybe<CapitalDepositProvider>;
  status: CapitalDepositStatus;
  stepStatus: CapitalDepositStepStatus;
  updatedAt: Scalars['String']['output'];
};

export type CapitalDepositPayload = {
  __typename: 'CapitalDepositPayload';
  capitalDeposit: CapitalDeposit;
};

export enum CapitalDepositProvider {
  Ditto = 'DITTO',
  Ibanfirst = 'IBANFIRST',
  NotaryFourez = 'NOTARY_FOUREZ'
}

export enum CapitalDepositStatus {
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  CertificateOfDepositSent = 'CERTIFICATE_OF_DEPOSIT_SENT',
  CompanyCreated = 'COMPANY_CREATED',
  FormCompleted = 'FORM_COMPLETED',
  FormInvalid = 'FORM_INVALID',
  FundsReceived = 'FUNDS_RECEIVED',
  FundsReleased = 'FUNDS_RELEASED',
  FundsReleaseInitiated = 'FUNDS_RELEASE_INITIATED',
  IbanstartAccountCreated = 'IBANSTART_ACCOUNT_CREATED',
  Initiated = 'INITIATED',
  KybInvalid = 'KYB_INVALID',
  KybPrechecked = 'KYB_PRECHECKED',
  KybSentForPostcheck = 'KYB_SENT_FOR_POSTCHECK',
  KybSentForPrecheck = 'KYB_SENT_FOR_PRECHECK',
  KybSentToTreezor = 'KYB_SENT_TO_TREEZOR',
  KybValidated = 'KYB_VALIDATED',
  KycInvalid = 'KYC_INVALID',
  KycPrechecked = 'KYC_PRECHECKED',
  KycSentForPostcheck = 'KYC_SENT_FOR_POSTCHECK',
  KycSentForPrecheck = 'KYC_SENT_FOR_PRECHECK',
  KycSentToTreezor = 'KYC_SENT_TO_TREEZOR',
  KycValidated = 'KYC_VALIDATED',
  OptedOut = 'OPTED_OUT',
  ProviderAccountCreated = 'PROVIDER_ACCOUNT_CREATED',
  ProviderApplicationCreated = 'PROVIDER_APPLICATION_CREATED',
  Refused = 'REFUSED'
}

export enum CapitalDepositStepStatus {
  Error = 'error',
  None = 'none',
  Pending = 'pending',
  Success = 'success'
}

export type Card = {
  __typename: 'Card';
  actionLocalized: Scalars['String']['output'];
  bodyLocalized: Scalars['String']['output'];
  cardId: Scalars['ID']['output'];
  clientId: Maybe<Scalars['ID']['output']>;
  contractId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Float']['output'];
  detailed: Scalars['Boolean']['output'];
  displayAt: Scalars['Float']['output'];
  done: Scalars['Boolean']['output'];
  entityId: Maybe<Scalars['ID']['output']>;
  entityType: Maybe<EntityType>;
  iconURL: Scalars['String']['output'];
  imgURL: Scalars['String']['output'];
  invoiceId: Maybe<Scalars['ID']['output']>;
  locked: Scalars['Boolean']['output'];
  seen: Scalars['Boolean']['output'];
  titleLocalized: Scalars['String']['output'];
  transactionId: Maybe<Scalars['ID']['output']>;
  type: Scalars['String']['output'];
  typeLocalized: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
};

export type CardDigitizationDataPayload = {
  __typename: 'CardDigitizationDataPayload';
  credential: Maybe<Scalars['String']['output']>;
};

export type CardEdge = {
  __typename: 'CardEdge';
  cursor: Scalars['String']['output'];
  node: Card;
};

export type CardPayload = {
  __typename: 'CardPayload';
  card: Maybe<Card>;
};

export enum CardPaymentStatus {
  Canceled = 'CANCELED',
  None = 'NONE',
  Pending = 'PENDING',
  Validated = 'VALIDATED'
}

export type CardPaymentStrongAuthenticationRequest = {
  __typename: 'CardPaymentStrongAuthenticationRequest';
  authenticationDevice: Maybe<AuthenticationDevice>;
  authenticationRequestedAt: Scalars['String']['output'];
  cardPaymentStrongAuthenticationRequestId: Scalars['ID']['output'];
  category: Scalars['String']['output'];
  creditCard: CreditCardInterface;
  expiresAt: Scalars['String']['output'];
  merchantAppRedirectURL: Maybe<Scalars['String']['output']>;
  merchantName: Scalars['String']['output'];
  providerFinalStatus: Maybe<CardPaymentStrongAuthenticationRequestProviderFinalStatus>;
  secondsLeftBeforeExpiration: Scalars['Int']['output'];
  status: CardPaymentStrongAuthenticationRequestStatus;
  transactionAmount: Scalars['Float']['output'];
  transactionCurrency: Scalars['String']['output'];
};

export enum CardPaymentStrongAuthenticationRequestProviderFinalStatus {
  Error = 'ERROR',
  Success = 'SUCCESS',
  Timeout = 'TIMEOUT'
}

export enum CardPaymentStrongAuthenticationRequestStatus {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Refused = 'REFUSED'
}

export enum CardProductId {
  Budget = 'BUDGET'
}

export type CardSecurityDetails = {
  __typename: 'CardSecurityDetails';
  CVV: Scalars['String']['output'];
  PAN: Scalars['String']['output'];
};

export type CashDepositCompanyMonthlyHistoryInput = {
  companyUserId: Scalars['ID']['input'];
};

export type CashDepositCompanyMonthlyHistoryResult = {
  __typename: 'CashDepositCompanyMonthlyHistoryResult';
  depositsCount: Scalars['Int']['output'];
  freeDepositsCount: Scalars['Int']['output'];
};

export type CashDepositLimitsInput = {
  companyUserId: Scalars['ID']['input'];
};

export type CashDepositLimitsResult = {
  __typename: 'CashDepositLimitsResult';
  freeDepositsCount: Scalars['Int']['output'];
  maximumMonthlyAmount: Scalars['Int']['output'];
  maximumOneTimeAmount: Scalars['Int']['output'];
  minimumOneTimeAmount: Scalars['Int']['output'];
};

export type CashDepositProviderUser = {
  __typename: 'CashDepositProviderUser';
  barcode: Scalars['String']['output'];
  cashDepositProviderUserId: Scalars['ID']['output'];
};

export type CashflowManagementDashboard = {
  __typename: 'CashflowManagementDashboard';
  balance: Array<DashboardTransaction>;
  clients: Maybe<Array<ClientStats>>;
  granularity: Maybe<DashboardGranularity>;
  merchants: Maybe<Array<MerchantStats>>;
  payin: Array<DashboardTransaction>;
  paymentMethod: DashboardPaymentMethod;
  payout: Array<DashboardTransaction>;
  teamMembers: DashboardTeamMembersValues;
  transactionCategoriesMcc: TransactionCategoriesMccValues;
};

export type CashflowManagementDashboardInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  endDate: Scalars['String']['input'];
  previousEndDate?: InputMaybe<Scalars['String']['input']>;
  previousStartDate?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
};

export type CatalogItem = {
  __typename: 'CatalogItem';
  companyProfileId: Scalars['UUIDv4']['output'];
  invoiceCatalogItemId: Scalars['ID']['output'];
  searchKey: Scalars['String']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: TaskType;
  unitPrice: Scalars['Float']['output'];
  vatPercentage: Scalars['Float']['output'];
};

export type CatalogItemEdge = {
  __typename: 'CatalogItemEdge';
  cursor: Scalars['String']['output'];
  node: CatalogItem;
};

export type CatalogItemPayload = {
  __typename: 'CatalogItemPayload';
  catalogItem: CatalogItem;
};

export type CatalogItemsFilters = {
  searchInput?: InputMaybe<Scalars['String']['input']>;
};

export type CatalogItemsPageInfo = {
  __typename: 'CatalogItemsPageInfo';
  endCursor: Maybe<Scalars['ID']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
  totalCountWithoutFilters: Scalars['Int']['output'];
};

export type Challenge = {
  __typename: 'Challenge';
  challengeId: Scalars['UUIDv4']['output'];
};

export enum ChallengeMethod {
  Mda = 'MDA',
  Sms = 'SMS'
}

export enum ChallengeStateName {
  ChallengeApproved = 'CHALLENGE_APPROVED',
  ChallengeCanceled = 'CHALLENGE_CANCELED',
  ChallengeDeclined = 'CHALLENGE_DECLINED',
  ChallengeDeclinedNotInitiator = 'CHALLENGE_DECLINED_NOT_INITIATOR',
  ChallengeExpired = 'CHALLENGE_EXPIRED',
  ChallengeFallbackSent = 'CHALLENGE_FALLBACK_SENT',
  ChallengeInitiated = 'CHALLENGE_INITIATED',
  ChallengeSent = 'CHALLENGE_SENT',
  ChallengeVerificationFailed = 'CHALLENGE_VERIFICATION_FAILED'
}

export type CheckDeposit = {
  __typename: 'CheckDeposit';
  amount: Scalars['Float']['output'];
  checkDepositId: Scalars['ID']['output'];
  checkNumber: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  destinationAddress: CheckDepositDestinationAddress;
  drawerName: Scalars['String']['output'];
  error: Maybe<Scalars['String']['output']>;
  firebaseBucketName: Maybe<Scalars['String']['output']>;
  firebaseFileRef: Maybe<Scalars['String']['output']>;
  fnciInfo: Maybe<FnciInfo>;
  isPhysicalDrawer: Scalars['Boolean']['output'];
  providerType: CheckDepositProviderType;
  refusedReasons: Maybe<Array<CheckDepositRefusedReasons>>;
  rlmcKey: Scalars['String']['output'];
  status: CheckDepositStatus;
  transaction: Maybe<Transaction>;
  transactionId: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type CheckDepositConnection = {
  __typename: 'CheckDepositConnection';
  edges: Array<CheckDepositEdge>;
  pageInfo: PageInfo;
};

export type CheckDepositDestinationAddress = {
  __typename: 'CheckDepositDestinationAddress';
  city: Scalars['String']['output'];
  name: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type CheckDepositEdge = {
  __typename: 'CheckDepositEdge';
  cursor: Scalars['String']['output'];
  node: CheckDeposit;
};

export type CheckDepositMandate = {
  __typename: 'CheckDepositMandate';
  checkDepositMandateId: Scalars['ID']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  companyUserId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  status: CheckDepositMandateStatus;
  statusAt: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum CheckDepositMandateStatus {
  Revoked = 'REVOKED',
  Signed = 'SIGNED'
}

export enum CheckDepositProviderType {
  Tessi = 'TESSI',
  Treezor = 'TREEZOR'
}

export enum CheckDepositRefusedReasons {
  AmountHigherThanSpecified = 'AMOUNT_HIGHER_THAN_SPECIFIED',
  AmountLowerThanSpecified = 'AMOUNT_LOWER_THAN_SPECIFIED',
  AutomaticCheckControlFailed = 'AUTOMATIC_CHECK_CONTROL_FAILED',
  CheckControlFailed = 'CHECK_CONTROL_FAILED',
  CheckDepositAccountAlert = 'CHECK_DEPOSIT_ACCOUNT_ALERT',
  CheckDepositAmountsDoNotMatch = 'CHECK_DEPOSIT_AMOUNTS_DO_NOT_MATCH',
  CheckDepositAmountTooHigh = 'CHECK_DEPOSIT_AMOUNT_TOO_HIGH',
  CheckDepositBeneficiaryControlFailed = 'CHECK_DEPOSIT_BENEFICIARY_CONTROL_FAILED',
  CheckDepositBeneficiaryMismatch = 'CHECK_DEPOSIT_BENEFICIARY_MISMATCH',
  CheckDepositCheckNotReferenced = 'CHECK_DEPOSIT_CHECK_NOT_REFERENCED',
  CheckDepositConfidentialError = 'CHECK_DEPOSIT_CONFIDENTIAL_ERROR',
  CheckDepositFalsifiedCheque = 'CHECK_DEPOSIT_FALSIFIED_CHEQUE',
  CheckDepositFraudulentUsage = 'CHECK_DEPOSIT_FRAUDULENT_USAGE',
  CheckDepositInvalidDate = 'CHECK_DEPOSIT_INVALID_DATE',
  CheckDepositIrregularCheck = 'CHECK_DEPOSIT_IRREGULAR_CHECK',
  CheckDepositMissingAmountNumber = 'CHECK_DEPOSIT_MISSING_AMOUNT_NUMBER',
  CheckDepositMissingAmountText = 'CHECK_DEPOSIT_MISSING_AMOUNT_TEXT',
  CheckDepositMissingDate = 'CHECK_DEPOSIT_MISSING_DATE',
  CheckDepositMissingDebtorName = 'CHECK_DEPOSIT_MISSING_DEBTOR_NAME',
  CheckDepositMissingOrInvalidBeneficiary = 'CHECK_DEPOSIT_MISSING_OR_INVALID_BENEFICIARY',
  CheckDepositMissingSignature = 'CHECK_DEPOSIT_MISSING_SIGNATURE',
  CheckDepositMissingSignatureOrBankAccountNumberOnVerso = 'CHECK_DEPOSIT_MISSING_SIGNATURE_OR_BANK_ACCOUNT_NUMBER_ON_VERSO',
  CheckDepositMonthlyAmountLimit = 'CHECK_DEPOSIT_MONTHLY_AMOUNT_LIMIT',
  CheckDepositPhysicalAmountControlsMismatch = 'CHECK_DEPOSIT_PHYSICAL_AMOUNT_CONTROLS_MISMATCH',
  CheckNotReceived = 'CHECK_NOT_RECEIVED',
  InternalServerErrorFromPartner = 'INTERNAL_SERVER_ERROR_FROM_PARTNER',
  InvalidCmc7 = 'INVALID_CMC7',
  InvalidResponseFromPartner = 'INVALID_RESPONSE_FROM_PARTNER',
  InvalidRlmcKey = 'INVALID_RLMC_KEY',
  PaymentAlreadyTreated = 'PAYMENT_ALREADY_TREATED',
  PaymentFailure = 'PAYMENT_FAILURE',
  RefusedByPartner = 'REFUSED_BY_PARTNER'
}

export enum CheckDepositStatus {
  Canceled = 'CANCELED',
  FundsReleased = 'FUNDS_RELEASED',
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export type CheckFiscalIdUnicityInput = {
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  fiscalId: Scalars['String']['input'];
  type?: InputMaybe<InvoiceType>;
};

export type CheckFiscalIdUnicityPayload = {
  __typename: 'CheckFiscalIdUnicityPayload';
  ok: Scalars['Boolean']['output'];
};

export type Client = {
  __typename: 'Client';
  city: Scalars['String']['output'];
  clientId: Scalars['ID']['output'];
  companyName: Maybe<Scalars['String']['output']>;
  companyProfileId: Scalars['UUIDv4']['output'];
  contacts: Array<ClientContact>;
  country: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  deleted: Scalars['Boolean']['output'];
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  legalRegistrationNumber: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  statusOverview: ClientStatusOverview;
  street: Scalars['String']['output'];
  type: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
  vatId: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type ClientContact = {
  __typename: 'ClientContact';
  clientContactId: Scalars['ID']['output'];
  clientId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type ClientContactPayload = {
  __typename: 'ClientContactPayload';
  clientContact: Maybe<ClientContact>;
};

export type ClientContactWithoutId = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ClientEdge = {
  __typename: 'ClientEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type ClientPayload = {
  __typename: 'ClientPayload';
  client: Maybe<Client>;
};

export type ClientStats = {
  __typename: 'ClientStats';
  clientName: Scalars['String']['output'];
  clientPayinValue: Maybe<Scalars['Float']['output']>;
  clientTransactionsCount: Maybe<Scalars['Int']['output']>;
};

export type ClientStatusOverview = {
  __typename: 'ClientStatusOverview';
  invoices: InvoicesStatus;
  quotes: QuotesStatus;
};

export enum ClientType {
  Organization = 'ORGANIZATION',
  PrivateIndividual = 'PRIVATE_INDIVIDUAL'
}

export type ClientsFilters = {
  searchInput?: InputMaybe<Scalars['String']['input']>;
};

export type Company = {
  __typename: 'Company';
  bankAccount: Maybe<BankAccount>;
  bankAccounts: Array<BankAccount>;
  bankTransferRecipient: BankTransferRecipient;
  bankTransferRecipientWith: Maybe<BankTransferRecipient>;
  bankTransferRecipients: ViewerBankTransfersRecipientsConnection;
  bankTransferRecipientsCount: ViewerTotalCount;
  canAccessCashflowDashboardPlusBeta: Scalars['Boolean']['output'];
  canAccessInternationalTransfer: Scalars['Boolean']['output'];
  canAccessPhoneSupport: Scalars['Boolean']['output'];
  canPostponeLegalRegistrationNumberRequest: Scalars['Boolean']['output'];
  canTransferMoney: Scalars['Boolean']['output'];
  capitalDeposit: Maybe<CapitalDeposit>;
  catalogItems: ViewerCatalogItemsConnection;
  clients: ViewerClientsConnection;
  companyProfileId: Scalars['UUIDv4']['output'];
  creditApplications: Array<CreditApplication>;
  creditCards: Array<CreditCardInterface>;
  currentPlan: PricingPlan;
  currentTaxForecastPeriod: Maybe<CurrentTaxForecastPeriod>;
  disputableTransactions: DisputableTransactionsConnection;
  feeStatements: Array<FeeStatement>;
  invoices: ViewerInvoicesConnection;
  invoicesStats: InvoicesStatus;
  isCapitalDeposit: Scalars['Boolean']['output'];
  isCreatedByMandatee: Scalars['Boolean']['output'];
  isEligibleToCreditApplication: Scalars['Boolean']['output'];
  isLegalRegistrationNumberRequired: Scalars['Boolean']['output'];
  isMultiKYC: Scalars['Boolean']['output'];
  isShineStart: Scalars['Boolean']['output'];
  kybDocuments: Array<KybDocument>;
  lastInvoiceFiscalId: LastInvoiceFiscalId;
  lastKybReview: Maybe<KybReview>;
  lastQuoteFiscalId: LastInvoiceFiscalId;
  legalBeneficiaries: Array<CompanyLegalBeneficiary>;
  merchantAccount: Maybe<MerchantAccount>;
  merchantOnboardingInfo: MerchantOnboardingInfo;
  metadata: CompanyMetadata;
  onboardingPackages: Array<OnboardingPackage>;
  onboardingPlans: Array<OnboardingPlan>;
  onboardingSasuAddons: Array<SasuAddon>;
  paymentCards: PaymentCardsConnection;
  paymentCardsOwners: Array<Scalars['String']['output']>;
  profile: CompanyProfile;
  quotes: ViewerQuotesConnection;
  quotesStats: QuotesStatus;
  settings: CompanySettings;
  shineRecurringAccountingExportSettings: Array<ShineRecurringAccountingExportSettings>;
  shineStart: Maybe<ShineStart>;
  shouldSeeProAndBusinessPlans: Scalars['Boolean']['output'];
  subscriptionPlan: Maybe<SubscriptionPlan>;
  subscriptionPlans: Array<Plan>;
  supposedBeneficiaries: Array<CompanySupposedBeneficiary>;
  /** @deprecated Use Company.currentTaxForecastPeriod instead */
  taxForecast: Maybe<TaxForecast>;
  taxForecastPeriods: TaxForecastPeriodConnection;
  taxForecastProfile: Maybe<TaxForecastProfile>;
  teamMember: TeamMember;
  teamMembers: TeamMemberConnection;
  teamMembersRequests: TeamMembersRequestsConnection;
  transactionsSearch: TransactionsSearchConnection;
  users: Array<CompanyUser>;
};


export type CompanyBankTransferRecipientArgs = {
  bankTransferRecipientId: Scalars['ID']['input'];
};


export type CompanyBankTransferRecipientWithArgs = {
  filter: BankTransferRecipientFilterInput;
};


export type CompanyBankTransferRecipientsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyCatalogItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<CatalogItemsFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyClientsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<ClientsFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyCreditCardsArgs = {
  companyUserId: InputMaybe<Scalars['ID']['input']>;
};


export type CompanyDisputableTransactionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: DisputableTransactionFilters;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInvoicesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<InvoicesFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInvoicesStatsArgs = {
  clientId: InputMaybe<Scalars['ID']['input']>;
};


export type CompanyKybDocumentsArgs = {
  type: InputMaybe<KybDocumentType>;
};


export type CompanyOnboardingPackagesArgs = {
  input: InputMaybe<CompanyOnboardingPackagesInput>;
};


export type CompanyOnboardingPlansArgs = {
  input: InputMaybe<CompanyOnboardingPlansInput>;
};


export type CompanyPaymentCardsArgs = {
  companyUserId: InputMaybe<Scalars['ID']['input']>;
  filters: InputMaybe<GetPaymentCardsFilters>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<SortOrder>;
  sortBy: InputMaybe<PaymentCardSortBy>;
};


export type CompanyQuotesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<QuotesFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyQuotesStatsArgs = {
  clientId: InputMaybe<Scalars['ID']['input']>;
};


export type CompanyTaxForecastPeriodsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPaid: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTeamMemberArgs = {
  companyUserId: Scalars['ID']['input'];
};


export type CompanyTeamMembersArgs = {
  filters: InputMaybe<FiltersTeamMembersInput>;
};


export type CompanyTeamMembersRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<TeamMembersRequestsFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyTransactionsSearchArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: TransactionsFilters;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyActivityInput = {
  classificationCode: Scalars['String']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyAddressInput = {
  city: Scalars['String']['input'];
  street: Scalars['String']['input'];
  streetAddition?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CompanyCreationActivity = {
  __typename: 'CompanyCreationActivity';
  category: Maybe<Scalars['String']['output']>;
  legalName: Scalars['String']['output'];
};

export type CompanyCreationActivityDomain = {
  __typename: 'CompanyCreationActivityDomain';
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CompanyCreationActivityMessage = {
  __typename: 'CompanyCreationActivityMessage';
  condition: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
};

export type CompanyCreationActivityQuestion = {
  __typename: 'CompanyCreationActivityQuestion';
  condition: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  options: Maybe<Array<CompanyCreationActivityQuestionOption>>;
  type: CompanyCreationActivityQuestionType;
};

export type CompanyCreationActivityQuestionOption = {
  __typename: 'CompanyCreationActivityQuestionOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum CompanyCreationActivityQuestionType {
  Boolean = 'boolean',
  MultiSelect = 'multi_select',
  Number = 'number',
  Select = 'select',
  Text = 'text'
}

export type CompanyCreationAvailableActivities = {
  __typename: 'CompanyCreationAvailableActivities';
  activities: Array<CompanyCreationAvailableActivity>;
  domains: Array<CompanyCreationActivityDomain>;
};

export type CompanyCreationAvailableActivity = {
  __typename: 'CompanyCreationAvailableActivity';
  domains: Array<Scalars['String']['output']>;
  hideFromSearch: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  keywords: Scalars['String']['output'];
  messages: Array<CompanyCreationActivityMessage>;
  name: Scalars['String']['output'];
  questions: Array<CompanyCreationActivityQuestion>;
};

export type CompanyCreationDocument = {
  __typename: 'CompanyCreationDocument';
  applicationId: Scalars['ID']['output'];
  file: Maybe<File>;
  id: Scalars['ID']['output'];
  status: CompanyCreationDocumentStatus;
  type: CompanyCreationDocumentType;
};

export enum CompanyCreationDocumentStatus {
  Missing = 'MISSING',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export enum CompanyCreationDocumentType {
  CompanyCertificateOfResidence = 'COMPANY_CERTIFICATE_OF_RESIDENCE',
  NoCriminalConvictionStatement = 'NO_CRIMINAL_CONVICTION_STATEMENT',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  ProofOfIdentityHandWritten = 'PROOF_OF_IDENTITY_HAND_WRITTEN'
}

export type CompanyCreationOptionsInput = {
  hasRequestedToBeHiddenFromPublicRegister: Scalars['Boolean']['input'];
  paymentInFullDischarge: Scalars['Boolean']['input'];
  taxFrequency: TaxFrequency;
};

export type CompanyCreationProvidedActivity = {
  /** JSON serialized object where key is the question ID, and value the answer */
  answers: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type CompanyCreationSasu = {
  __typename: 'CompanyCreationSASU';
  activities: Maybe<Array<CompanyCreationActivity>>;
  companyProfileId: Scalars['UUIDv4']['output'];
  objetSocial: Maybe<Scalars['String']['output']>;
  rawSelectedActivities: Maybe<Array<RawSelectedActivities>>;
  status: Maybe<CompanyCreationStatus>;
};

export type CompanyCreationSasuInfoSet = {
  __typename: 'CompanyCreationSASUInfoSet';
  id: Scalars['ID']['output'];
};

export enum CompanyCreationStatus {
  DossierSentToGuichetUnique = 'DOSSIER_SENT_TO_GUICHET_UNIQUE',
  Other = 'OTHER'
}

export type CompanyInformationInput = {
  address?: InputMaybe<AddressInput>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyInvitation = {
  __typename: 'CompanyInvitation';
  canResendInvite: Scalars['Boolean']['output'];
  companyInvitationId: Scalars['ID']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['String']['output'];
  inviteeEmail: Maybe<Scalars['String']['output']>;
  inviteeFirstName: Maybe<Scalars['String']['output']>;
  inviteeLastName: Maybe<Scalars['String']['output']>;
  inviteeUid: Maybe<Scalars['String']['output']>;
  inviterName: Maybe<Scalars['String']['output']>;
  lastEmailSentAt: Maybe<Scalars['String']['output']>;
  role: CompanyInvitationRole;
  slug: Scalars['String']['output'];
  status: CompanyInvitationStatus;
  updatedAt: Scalars['String']['output'];
};

export type CompanyInvitationPayload = {
  __typename: 'CompanyInvitationPayload';
  companyInvitation: CompanyInvitation;
};

export enum CompanyInvitationRole {
  Accountant = 'ACCOUNTANT',
  AccountHolder = 'ACCOUNT_HOLDER',
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE',
  LegalBeneficiary = 'LEGAL_BENEFICIARY'
}

export enum CompanyInvitationStatus {
  Completed = 'COMPLETED',
  Opened = 'OPENED',
  Pending = 'PENDING',
  Redeemed = 'REDEEMED'
}

export type CompanyLegalBeneficiariesPayload = {
  __typename: 'CompanyLegalBeneficiariesPayload';
  companyLegalBeneficiaries: Array<CompanyLegalBeneficiary>;
};

export type CompanyLegalBeneficiary = {
  __typename: 'CompanyLegalBeneficiary';
  companyLegalBeneficiaryId: Scalars['ID']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  companyUser: Maybe<CompanyUser>;
  createdAt: Scalars['String']['output'];
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Scalars['String']['output']>;
  invitation: Maybe<CompanyInvitation>;
  isCurrentUser: Scalars['Boolean']['output'];
  isDirector: Maybe<Scalars['Boolean']['output']>;
  isMajorShareholder: Maybe<Scalars['Boolean']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  moralEntityName: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  numberOfShares: Maybe<Scalars['Int']['output']>;
  onboardingStatus: CompanyLegalBeneficiaryOnboardingStatus;
  uidOrCompanyProfileId: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export enum CompanyLegalBeneficiaryOnboardingStatus {
  Invalid = 'INVALID',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  NotStarted = 'NOT_STARTED',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export type CompanyMetadata = {
  __typename: 'CompanyMetadata';
  accountStatus: Maybe<CompanyMetadataAccountStatus>;
  companyProfileId: Scalars['UUIDv4']['output'];
  hasAgreedToHokodoPrivacyPolicy: Scalars['Boolean']['output'];
  hasAgreedToHokodoPrivacyPolicyAt: Maybe<Scalars['String']['output']>;
  hasBeenMigratedToEP: Scalars['Boolean']['output'];
  hasBeneficiaryRegisterFetchSucceeded: Maybe<Scalars['Boolean']['output']>;
  hasRegisteredAllBeneficiaries: Scalars['Boolean']['output'];
  isEP: Scalars['Boolean']['output'];
  isKybInReview: Maybe<Scalars['Boolean']['output']>;
  isLocked: Scalars['Boolean']['output'];
  isPayoutLocked: Maybe<Scalars['Boolean']['output']>;
  kybError: Maybe<Scalars['String']['output']>;
  kybErrorLocalized: Maybe<Scalars['String']['output']>;
  kybErrorTag: Maybe<Scalars['String']['output']>;
  kybStatus: CompanyMetadataKybStatus;
  lockType: Maybe<CompanyMetadataLockType>;
  lockedCustomMessage: Maybe<Scalars['String']['output']>;
  lockedReason: Maybe<LockedReason>;
  maxInvoiceCardPayment: Maybe<Scalars['Float']['output']>;
  migratedToEPAt: Maybe<Scalars['String']['output']>;
  reviewStatus: CompanyMetadataReviewStatus;
  reviewStatusAt: Maybe<Scalars['String']['output']>;
  selectedPlanId: Maybe<Scalars['ID']['output']>;
  status: Maybe<CompanyMetadataStatus>;
  uid: Scalars['ID']['output'];
  updatedAt: Maybe<Scalars['String']['output']>;
  willBeOnboardedAs: Maybe<CompanyMetadataWillBeOnboardedAs>;
};

export enum CompanyMetadataAccountStatus {
  Pending = 'PENDING',
  Validated = 'VALIDATED'
}

export enum CompanyMetadataKybStatus {
  None = 'NONE',
  Paused = 'PAUSED',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export enum CompanyMetadataLockType {
  Payout = 'PAYOUT'
}

export type CompanyMetadataPayload = {
  __typename: 'CompanyMetadataPayload';
  companyMetadata: Maybe<CompanyMetadata>;
};

export enum CompanyMetadataReviewStatus {
  Invalid = 'INVALID',
  None = 'NONE',
  Prechecked = 'PRECHECKED',
  Refused = 'REFUSED',
  SentForPostcheck = 'SENT_FOR_POSTCHECK',
  SentForPrecheck = 'SENT_FOR_PRECHECK',
  SentToTreezor = 'SENT_TO_TREEZOR',
  Validated = 'VALIDATED'
}

export enum CompanyMetadataStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Locked = 'LOCKED'
}

export enum CompanyMetadataWillBeOnboardedAs {
  Agent = 'AGENT',
  Ep = 'EP'
}

export type CompanyOnboardingPackagesInput = {
  coupon?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PackageType>;
};

export type CompanyOnboardingPlansInput = {
  variant?: InputMaybe<PlansVariant>;
};

export type CompanyProfile = {
  __typename: 'CompanyProfile';
  activity: Maybe<Scalars['String']['output']>;
  activityDetails: Maybe<Scalars['String']['output']>;
  activityType: Scalars['String']['output'];
  address: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  /** @deprecated Use Company.creditApplications instead */
  creditApplications: Array<CreditApplication>;
  /** @deprecated Use Viewer.company.creditCards instead */
  creditCards: Maybe<Array<Maybe<CreditCardInterface>>>;
  hasAccre: Scalars['Boolean']['output'];
  hasActivityOutsideEU: Maybe<Scalars['Boolean']['output']>;
  hasClientHelpingCountryUnderSanctions: Maybe<Scalars['Boolean']['output']>;
  hasClientInCountryUnderSanctions: Maybe<Scalars['Boolean']['output']>;
  hasClientUnderSanctions: Maybe<Scalars['Boolean']['output']>;
  hasFinishedProfileSetup: Scalars['Boolean']['output'];
  hasPrelevLib: Scalars['Boolean']['output'];
  inseeLegalFormCode: Maybe<Scalars['String']['output']>;
  inseeLegalFormLabel: Maybe<Scalars['String']['output']>;
  isMicro: Scalars['Boolean']['output'];
  /** @deprecated Use Viewer.company.isMultiKYC instead */
  isMultiKYC: Maybe<Scalars['Boolean']['output']>;
  legalAnnualTurnOver: Maybe<Scalars['String']['output']>;
  legalForm: Scalars['String']['output'];
  legalName: Scalars['String']['output'];
  legalNumberOfEmployeeRange: Maybe<Scalars['String']['output']>;
  legalNumberOfShares: Maybe<Scalars['Float']['output']>;
  legalRegistrationNumber: Scalars['String']['output'];
  legalShareCapital: Scalars['Float']['output'];
  legalVATNumber: Scalars['String']['output'];
  /** @deprecated Use Company.metadata instead */
  metadata: Maybe<CompanyMetadata>;
  rcsNumber: Maybe<Scalars['String']['output']>;
  registrationDate: Scalars['String']['output'];
  rmNumber: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  street: Scalars['String']['output'];
  streetAddition: Maybe<Scalars['String']['output']>;
  /** @deprecated Use Company.subscriptionPlan instead */
  subscriptionPlan: Maybe<SubscriptionPlan>;
  taxFrequency: Scalars['String']['output'];
  tradeName: Scalars['String']['output'];
  /** @deprecated Stop using that field. */
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['Float']['output'];
  zip: Scalars['String']['output'];
};

export type CompanyProfileInput = {
  activityDetails?: InputMaybe<Scalars['String']['input']>;
  activityType?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyProfileId: Scalars['UUIDv4']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  hasAccre?: InputMaybe<Scalars['Boolean']['input']>;
  hasPrelevLib?: InputMaybe<Scalars['Boolean']['input']>;
  inseeLegalFormCode?: InputMaybe<Scalars['String']['input']>;
  isMicro?: InputMaybe<Scalars['Boolean']['input']>;
  legalAnnualTurnOver?: InputMaybe<Scalars['String']['input']>;
  legalForm?: InputMaybe<Scalars['String']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNumberOfEmployeeRange?: InputMaybe<Scalars['String']['input']>;
  legalNumberOfShares?: InputMaybe<Scalars['Int']['input']>;
  legalRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  legalShareCapital?: InputMaybe<Scalars['Float']['input']>;
  legalVATNumber?: InputMaybe<Scalars['String']['input']>;
  rcsNumber?: InputMaybe<Scalars['String']['input']>;
  registrationDate?: InputMaybe<Scalars['String']['input']>;
  rmNumber?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetAddition?: InputMaybe<Scalars['String']['input']>;
  taxFrequency?: InputMaybe<Scalars['String']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyProfilePayload = {
  __typename: 'CompanyProfilePayload';
  company: Maybe<CompanyProfile>;
};

export type CompanySettings = {
  __typename: 'CompanySettings';
  companyProfileId: Scalars['UUIDv4']['output'];
  invoicingDefaultCustomLegalMentions: Maybe<Scalars['String']['output']>;
  invoicingDefaultDisplayTradeName: Maybe<Scalars['Boolean']['output']>;
  invoicingDefaultIssuerEmail: Maybe<Scalars['String']['output']>;
  invoicingDefaultLateFeeFloatPercentage: Maybe<Scalars['Float']['output']>;
  invoicingDefaultLateMaxDaysOther: Maybe<Scalars['Int']['output']>;
  invoicingDefaultLateMaxDaysType: Maybe<InvoicingDefaultLateMaxDaysTypeEnum>;
  invoicingDefaultQuoteValidityDays: Maybe<Scalars['Float']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
};

export type CompanySupposedBeneficiary = {
  __typename: 'CompanySupposedBeneficiary';
  firstName: Scalars['String']['output'];
  gender: Maybe<Scalars['String']['output']>;
  isDirector: Maybe<Scalars['Boolean']['output']>;
  isMajorShareholder: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  supposedBeneficiaryId: Scalars['ID']['output'];
};

export type CompanyUser = {
  __typename: 'CompanyUser';
  /** @deprecated auto virtual card order is now implemented on the service side */
  automaticVirtualCardOrder: AutomaticVirtualCardOrderPayload;
  city: Scalars['String']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  companyUserId: Scalars['ID']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  email: Maybe<Scalars['String']['output']>;
  features: Array<CompanyUserFeature>;
  firstName: Maybe<Scalars['String']['output']>;
  hasFilledComplianceFields: Scalars['Boolean']['output'];
  hasFinishedApplicationSetup: Scalars['Boolean']['output'];
  hasOnboardingKycBeenValidated: Scalars['Boolean']['output'];
  hasReview: Scalars['Boolean']['output'];
  hasSubmittedOnboardingKycWithoutPoA: Maybe<Scalars['Boolean']['output']>;
  invitation: Maybe<CompanyInvitation>;
  isAllowedToSkipOnfidoSelfie: Scalars['Boolean']['output'];
  isAllowedToSkipPOA: Scalars['Boolean']['output'];
  isAwaitingAccountValidation: Scalars['Boolean']['output'];
  isBetaTester: Scalars['Boolean']['output'];
  isKycBackReviewInProgress: Scalars['Boolean']['output'];
  isLegalBeneficiary: Scalars['Boolean']['output'];
  isMandatee: Scalars['Boolean']['output'];
  isOnboarded: Scalars['Boolean']['output'];
  isOnboardingKycInProgress: Scalars['Boolean']['output'];
  isPrimaryApplicant: Scalars['Boolean']['output'];
  kycAddressStatus: KycStatus;
  /** @deprecated Use CompanyUser.kycReviewDocuments instead */
  kycDocuments: Array<KycDocument>;
  kycIdProviderError: Maybe<Scalars['String']['output']>;
  kycIdStatus: KycStatus;
  kycReviewDocuments: Array<KycReviewDocument>;
  kycStatus: CompanyUserKycStatus;
  lastKycReview: Maybe<KycReview>;
  lastName: Maybe<Scalars['String']['output']>;
  lockedReason: Maybe<CompanyUserLockedReason>;
  /** @deprecated Decommissioned feature */
  paymentCardOrder: Maybe<PaymentCardOrder>;
  permissions: Scalars['String']['output'];
  qesStatus: QesStatus;
  referralCodes: Array<ReferralCode>;
  referrals: Array<Referral>;
  remainingReferrerAmount: Scalars['Int']['output'];
  reviewStatus: CompanyUserReviewStatus;
  roles: Array<Role>;
  shouldAskForEpCguAgreement: Scalars['Boolean']['output'];
  status: CompanyUserStatus;
  street: Maybe<Scalars['String']['output']>;
  streetAddition: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};


export type CompanyUserKycDocumentsArgs = {
  types: InputMaybe<Array<Scalars['String']['input']>>;
};


export type CompanyUserKycReviewDocumentsArgs = {
  types: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CompanyUserFeature = {
  __typename: 'CompanyUserFeature';
  name: FeatureName;
  where: Maybe<Where>;
};

export enum CompanyUserKycStatus {
  Exempted = 'EXEMPTED',
  Invalid = 'INVALID',
  None = 'NONE',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export enum CompanyUserLockedReason {
  PlanDowngrade = 'PLAN_DOWNGRADE',
  Revoked = 'REVOKED'
}

export enum CompanyUserReviewStatus {
  Escalated = 'ESCALATED',
  Invalid = 'INVALID',
  None = 'NONE',
  Prechecked = 'PRECHECKED',
  Refused = 'REFUSED',
  SentForPostcheck = 'SENT_FOR_POSTCHECK',
  SentForPrecheck = 'SENT_FOR_PRECHECK',
  SentToTreezor = 'SENT_TO_TREEZOR',
  Validated = 'VALIDATED'
}

export enum CompanyUserStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Locked = 'LOCKED'
}

export type ComputeTaxRatesInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  taxForecastPeriodId: Scalars['ID']['input'];
};

export type ComputedTaxForecast = {
  __typename: 'ComputedTaxForecast';
  amount: Scalars['Float']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  complementaryRevenue: Maybe<Scalars['Float']['output']>;
  computedRevenue: Scalars['Float']['output'];
  createdAt: Scalars['Int']['output'];
  isCustomizedEstimation: Maybe<Scalars['Boolean']['output']>;
  rate: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  taxForecastId: Scalars['ID']['output'];
  taxForecastPeriodId: Scalars['ID']['output'];
  taxType: TaxTypeEnum;
  updatedAt: Scalars['Int']['output'];
  vatAmount: Maybe<Scalars['Float']['output']>;
  vatOffset: Maybe<Scalars['Float']['output']>;
  vatRate: Maybe<Scalars['Float']['output']>;
};

export type ConfirmAuthenticationActionRequestInput = {
  authenticationActionRequestId: Scalars['ID']['input'];
  deviceToken: Scalars['String']['input'];
  passcode: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type ConfirmAuthenticationActionRequestOutput = {
  __typename: 'ConfirmAuthenticationActionRequestOutput';
  accessToken: Maybe<Scalars['String']['output']>;
  authenticationActionRequestId: Scalars['ID']['output'];
  deviceToken: Maybe<Scalars['String']['output']>;
  passcode: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
};

export type ConvertQuoteToInvoiceInput = {
  allowCreditCardPayment: Scalars['Boolean']['input'];
  allowIbanPayment: Scalars['Boolean']['input'];
  clientContactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isReminderEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUserCcedOnEmails?: InputMaybe<Scalars['Boolean']['input']>;
  quoteId: Scalars['ID']['input'];
  reminderFrequency?: InputMaybe<ReminderFrequencyEnum>;
  reminderMaxOccurrences?: InputMaybe<Scalars['Int']['input']>;
};

export type Coupon = {
  __typename: 'Coupon';
  amountWithVAT: Scalars['Float']['output'];
  amountWithoutVAT: Scalars['Float']['output'];
  couponId: Scalars['ID']['output'];
  status: CouponStatus;
  trialPeriod: Maybe<Scalars['Int']['output']>;
};

export enum CouponStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Expired = 'EXPIRED'
}

export type CreateAccountingExportInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  endDate: Scalars['String']['input'];
  options: Array<InputMaybe<AccountingExportOption>>;
  recipient: AccountingExportRecipientInput;
  startDate: Scalars['String']['input'];
};

export type CreateAccountingExportPayload = {
  __typename: 'CreateAccountingExportPayload';
  accountingExport: Maybe<AccountingExport>;
};

export type CreateAccountingRecurringExportInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  options: Array<InputMaybe<AccountingExportOption>>;
  recipient: AccountingExportRecipientInput;
};

export type CreateAccountingRecurringExportPayload = {
  __typename: 'CreateAccountingRecurringExportPayload';
  accountingRecurringExport: Maybe<AccountingRecurringExport>;
};

export type CreateBankTransferInput = {
  amount: Scalars['Float']['input'];
  bankAccountId: Scalars['ID']['input'];
  bankTransferRecipientId: Scalars['ID']['input'];
  companyUserId?: InputMaybe<Scalars['ID']['input']>;
  currency: Scalars['String']['input'];
  deviceToken?: InputMaybe<Scalars['String']['input']>;
  isInstant?: InputMaybe<Scalars['Boolean']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  recipientEmail?: InputMaybe<Scalars['String']['input']>;
  shouldNotifyRecipient?: InputMaybe<Scalars['Boolean']['input']>;
  userLabel?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBankTransferRecipientInput = {
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  companyUserId?: InputMaybe<Scalars['ID']['input']>;
  iban: Scalars['String']['input'];
  isUserBankAccount?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  swiftBic: Scalars['String']['input'];
};

export type CreateBulkBankTransferInput = {
  bankAccountId: Scalars['ID']['input'];
  bucketName: Scalars['String']['input'];
  companyUserId: Scalars['ID']['input'];
  fileRef: Scalars['String']['input'];
  transfers: Array<TransferInput>;
};

export type CreateCapitalDepositInput = {
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  isConfirmedPartner?: InputMaybe<Scalars['Boolean']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  partner?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCapitalDepositPayload = {
  __typename: 'CreateCapitalDepositPayload';
  capitalDeposit: CapitalDeposit;
};

export type CreateCatalogItemInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: TaskType;
  unitPrice: Scalars['Float']['input'];
  vatPercentage: Scalars['Float']['input'];
};

export type CreateCheckDepositInput = {
  amount: Scalars['Float']['input'];
  bankAccountId: Scalars['String']['input'];
  checkNumber: Scalars['String']['input'];
  drawerName: Scalars['String']['input'];
  firebaseBucketName: Scalars['String']['input'];
  firebaseFileRef: Scalars['String']['input'];
  isPhysicalDrawer: Scalars['Boolean']['input'];
  rlmcKey: Scalars['String']['input'];
};

export type CreateClientContactInput = {
  clientId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateClientInput = {
  city: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyProfileId: Scalars['UUIDv4']['input'];
  contacts?: InputMaybe<Array<ClientContactWithoutId>>;
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  legalRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ClientType>;
  vatId?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type CreateCreditApplicationInput = {
  amount: Scalars['Float']['input'];
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  duration: Scalars['Int']['input'];
  isEligibleToBonus: Scalars['Boolean']['input'];
  reason: Scalars['String']['input'];
};

export type CreateIntercomConversationInput = {
  category: Scalars['String']['input'];
  contactReason: Scalars['String']['input'];
  message: Scalars['String']['input'];
  subCategory?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInternationalTransferInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  internationalTransferQuoteId: Scalars['UUIDv4']['input'];
  internationalTransferRecipientId: Scalars['ID']['input'];
  lang: Scalars['String']['input'];
  receiptFileRef: Scalars['String']['input'];
  requirements: Scalars['JSON']['input'];
};

export type CreateInternationalTransferQuoteInput = {
  amountFloat: Scalars['Float']['input'];
  companyProfileId: Scalars['String']['input'];
  companyUserId: Scalars['String']['input'];
  currency: Scalars['String']['input'];
};

export type CreateInternationalTransferQuoteResponse = CreateInternationalTransferQuoteResponseError | CreateInternationalTransferQuoteResponseSuccess;

export type CreateInternationalTransferQuoteResponseError = {
  __typename: 'CreateInternationalTransferQuoteResponseError';
  errors: Array<InternationalTransferQuoteProviderError>;
  isSuccess: Scalars['Boolean']['output'];
};

export type CreateInternationalTransferQuoteResponseSuccess = {
  __typename: 'CreateInternationalTransferQuoteResponseSuccess';
  isSuccess: Scalars['Boolean']['output'];
  quote: InternationalTransferQuote;
};

export type CreateInternationalTransferRecipientInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  currency: Scalars['String']['input'];
  internationalTransferQuoteId: Scalars['UUIDv4']['input'];
  lang: Scalars['String']['input'];
  providerRecipientType: Scalars['String']['input'];
  requirements: Scalars['JSON']['input'];
};

export type CreateInternationalTransferRecipientResponse = InternationalTransferProviderErrorResponse | InternationalTransferRecipient;

export type CreateInternationalTransferResponse = InternationalTransferPayout | InternationalTransferProviderErrorResponse;

export type CreateIntraCompanyFundsTransferInput = {
  amount: Scalars['Float']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  fromBankAccountId: Scalars['ID']['input'];
  toBankAccountId: Scalars['ID']['input'];
  userLabel?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvoiceInput = {
  allowCreditCardPayment: Scalars['Boolean']['input'];
  allowIbanPayment: Scalars['Boolean']['input'];
  clientContactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  clientId: Scalars['ID']['input'];
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  currency: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  fiscalId?: InputMaybe<Scalars['String']['input']>;
  isUserCcedOnEmails?: InputMaybe<Scalars['Boolean']['input']>;
  lateFeePercentage: Scalars['Float']['input'];
  lateMaxDays: Scalars['Float']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  particularMentions?: InputMaybe<Array<ParticularMentionInput>>;
  paymentMentions?: InputMaybe<Array<InvoicePaymentMentionInput>>;
  settings?: InputMaybe<InvoiceSettingsInput>;
  startAt: Scalars['Float']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  tasks: Array<InputMaybe<InvoiceTaskInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateKycDocumentFromProviderInput = {
  companyUserId?: InputMaybe<Scalars['ID']['input']>;
  provider: KycProvider;
  providerDocumentId: Scalars['ID']['input'];
  type: DocumentType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateKycDocumentsFromProviderInput = {
  companyUserId?: InputMaybe<Scalars['ID']['input']>;
  documents: Array<InputMaybe<CreateKycDocumentFromProviderInput>>;
  skipCheckValidated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateKycDocumentsFromProviderPayload = {
  __typename: 'CreateKycDocumentsFromProviderPayload';
  kycDocuments: Array<Maybe<KycDocument>>;
};

export type CreateKycProviderUserInput = {
  provider: KycProvider;
};

export type CreateKycProviderUserPayload = {
  __typename: 'CreateKycProviderUserPayload';
  kycProviderUser: Maybe<KycProviderUser>;
};

export type CreateKycReviewDocumentInput = {
  companyUserId?: InputMaybe<Scalars['ID']['input']>;
  firebaseBucketName?: InputMaybe<Scalars['String']['input']>;
  firebaseFileRef?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<KycReviewDocumentStatus>;
  type: KycReviewDocumentType;
  typeLocalized?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMerchantAccountInput = {
  companyProfileId: Scalars['String']['input'];
  tokens: CreateMerchantAccountTokensInput;
};

export type CreateMerchantAccountResponse = {
  __typename: 'CreateMerchantAccountResponse';
  merchantAccount: MerchantAccount;
};

export type CreateMerchantAccountTokensInput = {
  account: Scalars['String']['input'];
  persons: Array<Scalars['String']['input']>;
};

export type CreatePackagePaymentIntentInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  coupon?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
};

export type CreatePhysicalPersonLegalBeneficiaryInput = {
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  inviteeEmail?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  numberOfShares?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePhysicalPersonLegalBeneficiarySoloInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  inviteeEmail?: InputMaybe<Scalars['String']['input']>;
  isDirector?: InputMaybe<Scalars['Boolean']['input']>;
  isFutureAccountHolder?: InputMaybe<Scalars['Boolean']['input']>;
  isMajorShareholder?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  numberOfShares?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateQuoteInput = {
  clientId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  fiscalId?: InputMaybe<Scalars['String']['input']>;
  lateFeePercentage: Scalars['Float']['input'];
  lateMaxDays: Scalars['Float']['input'];
  particularMentions?: InputMaybe<Array<ParticularMentionInput>>;
  paymentMentions?: InputMaybe<Array<QuotePaymentMentionInput>>;
  settings: QuoteSettingsInput;
  startAt: Scalars['Float']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  tasks: Array<InvoiceTaskInput>;
  title: Scalars['String']['input'];
};

export type CreateReceiptInput = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  bucketPath?: InputMaybe<Scalars['String']['input']>;
  companyProfileId: Scalars['UUIDv4']['input'];
  fileRef?: InputMaybe<Scalars['String']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
  transactionId?: InputMaybe<Scalars['ID']['input']>;
  url: Scalars['String']['input'];
};

export type CreateReferralInput = {
  referralCode: Scalars['String']['input'];
  referreePhone: Scalars['String']['input'];
};

export type CreateSasuCreationPaymentIntentInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  packageId: Scalars['ID']['input'];
  paymentMethodId: Scalars['String']['input'];
};

export type CreateScheduledBankTransferInput = {
  amount: Scalars['Float']['input'];
  bankAccountId: Scalars['ID']['input'];
  bankTransferRecipientId: Scalars['ID']['input'];
  companyUserId?: InputMaybe<Scalars['ID']['input']>;
  documentBucket?: InputMaybe<Scalars['String']['input']>;
  documentFileRef?: InputMaybe<Scalars['String']['input']>;
  endDateDeterminedBy?: InputMaybe<ScheduledBankTransferEndDateDeterminedBy>;
  frequency: ScheduledBankTransferFrequency;
  label?: InputMaybe<Scalars['String']['input']>;
  lastDueDate?: InputMaybe<Scalars['String']['input']>;
  nextDueDate: Scalars['String']['input'];
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  recipientEmail?: InputMaybe<Scalars['String']['input']>;
  remainingNumberOfOccurrences?: InputMaybe<Scalars['Int']['input']>;
  shouldNotifyRecipient?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateSecondaryBankAccountInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  illustrationName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateShineOneOffAccountingExportSettingsInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  formats: Array<ShineAccountingExportFormat>;
  fromDate: Scalars['String']['input'];
  includeBankStatements?: InputMaybe<Scalars['Boolean']['input']>;
  includeInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  includePersonalTransactions?: InputMaybe<Scalars['Boolean']['input']>;
  recipients: Array<Scalars['String']['input']>;
  toDate: Scalars['String']['input'];
};

export type CreateShineRecurringAccountingExportSettingsInput = {
  cadence: ShineRecurringAccountingExportSettingsCadence;
  cadenceOffset: Scalars['Int']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  formats: Array<ShineAccountingExportFormat>;
  includeBankStatements?: InputMaybe<Scalars['Boolean']['input']>;
  includeInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  includePersonalTransactions?: InputMaybe<Scalars['Boolean']['input']>;
  recipients: Array<Scalars['String']['input']>;
};

export type CreateShineStartInput = {
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
};

export type CreateShineStartPayload = {
  __typename: 'CreateShineStartPayload';
  shineStart: ShineStart;
};

export type CreateTaxForecastProfileInput = {
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  isSubjectToVat?: InputMaybe<Scalars['Boolean']['input']>;
  revenueBeforeShine?: InputMaybe<Scalars['Float']['input']>;
  subjectToVatSince?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['ID']['input']>;
  vatDefaultRate?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateTeamMemberPayload = {
  __typename: 'CreateTeamMemberPayload';
  companyUser: CompanyUser;
};

export type CreateUserBankDetailsInput = {
  iban: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  swiftBic: Scalars['String']['input'];
};

export type CreateVatAmountInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  receiptId: Scalars['ID']['input'];
  vatAmountItems: Array<CreateVatAmountItemInput>;
};

export type CreateVatAmountItemInput = {
  trueVatAmount: Scalars['Float']['input'];
  trueVatRate: Scalars['Float']['input'];
};

export type CreditApplication = {
  __typename: 'CreditApplication';
  amount: Scalars['Float']['output'];
  applicationFee: Scalars['Float']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['String']['output'];
  creditApplicationId: Scalars['ID']['output'];
  documents: Maybe<Array<Maybe<CreditApplicationDocument>>>;
  duration: Scalars['Int']['output'];
  isEligibleToBonus: Scalars['Boolean']['output'];
  providerAgreementUrl: Scalars['String']['output'];
  providerId: Maybe<Scalars['String']['output']>;
  rate: Scalars['Float']['output'];
  rateBeforeReduction: Scalars['Float']['output'];
  reason: Scalars['String']['output'];
  reasonLocale: Scalars['String']['output'];
  status: CreditApplicationStatuses;
  statusAt: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type CreditApplicationDocument = {
  __typename: 'CreditApplicationDocument';
  bucketName: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  creditApplicationDocumentId: Scalars['ID']['output'];
  fileRef: Scalars['String']['output'];
  publicUrl: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum CreditApplicationStatuses {
  AwaitingAgreementSignature = 'AWAITING_AGREEMENT_SIGNATURE',
  Granted = 'GRANTED',
  OptedOut = 'OPTED_OUT',
  Pending = 'PENDING',
  Refused = 'REFUSED'
}

export type CreditCard = CreditCardInterface & {
  __typename: 'CreditCard';
  actualDeliveryAddress: Maybe<Scalars['String']['output']>;
  alias: Maybe<Scalars['String']['output']>;
  cardDesign: Maybe<Scalars['String']['output']>;
  cardHolder: CompanyUser;
  cardHolderName: Maybe<Scalars['String']['output']>;
  cardProductId: Maybe<CardProductId>;
  cardProductType: PaymentCardProductType;
  companyUserId: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  creditCardId: Scalars['ID']['output'];
  deactivateAt: Maybe<Scalars['String']['output']>;
  deliveryCity: Maybe<Scalars['String']['output']>;
  deliveryStreetFirstLine: Maybe<Scalars['String']['output']>;
  deliveryStreetSecondLine: Maybe<Scalars['String']['output']>;
  deliveryZip: Maybe<Scalars['String']['output']>;
  digitizationData: Maybe<Array<Maybe<DigitizationData>>>;
  expiryDate: Scalars['Float']['output'];
  isDefinitivelyBlocked: Scalars['Boolean']['output'];
  isLive: Scalars['Boolean']['output'];
  isPhysical: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  limitAtmMonth: Scalars['Int']['output'];
  limitAtmWeek: Scalars['Int']['output'];
  limitPaymentAll: Scalars['Float']['output'];
  /** @deprecated Field is not returned by the service */
  limitPaymentMonth: Maybe<Scalars['Int']['output']>;
  limitPaymentWeek: Scalars['Int']['output'];
  maskedPan: Scalars['String']['output'];
  maxLimitAtmMonth: Scalars['Int']['output'];
  /** @deprecated Use maxLimitAtmMonth instead */
  maxLimitAtmWeek: Scalars['Int']['output'];
  maxLimitPaymentWeek: Scalars['Int']['output'];
  optionAtm: Scalars['Boolean']['output'];
  optionForeign: Scalars['Boolean']['output'];
  optionNfc: Scalars['Boolean']['output'];
  optionOnline: Scalars['Boolean']['output'];
  pinTryExceeds: Scalars['Boolean']['output'];
  providerCardId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  shippingDate: Maybe<Scalars['Float']['output']>;
  status: CreditCardStatusCode;
  totalAtmMonth: Scalars['Float']['output'];
  totalAtmWeek: Scalars['Float']['output'];
  totalPaymentAll: Scalars['Float']['output'];
  /** @deprecated Field is not returned by the service */
  totalPaymentMonth: Maybe<Scalars['Float']['output']>;
  totalPaymentWeek: Scalars['Float']['output'];
  trackingNumber: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Float']['output'];
  walletId: Scalars['ID']['output'];
};

export type CreditCardActivationPayload = {
  __typename: 'CreditCardActivationPayload';
  creditCard: CreditCardInterface;
  pin: Scalars['String']['output'];
};

export type CreditCardInterface = {
  actualDeliveryAddress: Maybe<Scalars['String']['output']>;
  alias: Maybe<Scalars['String']['output']>;
  cardDesign: Maybe<Scalars['String']['output']>;
  cardHolder: CompanyUser;
  cardHolderName: Maybe<Scalars['String']['output']>;
  cardProductId: Maybe<CardProductId>;
  cardProductType: PaymentCardProductType;
  companyUserId: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  creditCardId: Scalars['ID']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deactivateAt: Maybe<Scalars['String']['output']>;
  deliveryCity: Maybe<Scalars['String']['output']>;
  deliveryStreetFirstLine: Maybe<Scalars['String']['output']>;
  deliveryStreetSecondLine: Maybe<Scalars['String']['output']>;
  deliveryZip: Maybe<Scalars['String']['output']>;
  digitizationData: Maybe<Array<Maybe<DigitizationData>>>;
  expiryDate: Scalars['Float']['output'];
  isDefinitivelyBlocked: Scalars['Boolean']['output'];
  isLive: Scalars['Boolean']['output'];
  isPhysical: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  limitAtmMonth: Scalars['Int']['output'];
  limitAtmWeek: Scalars['Int']['output'];
  limitPaymentAll: Scalars['Float']['output'];
  /** @deprecated Field is not returned by the service */
  limitPaymentMonth: Maybe<Scalars['Int']['output']>;
  limitPaymentWeek: Scalars['Int']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  maskedPan: Maybe<Scalars['String']['output']>;
  maxLimitAtmMonth: Scalars['Int']['output'];
  /** @deprecated Use maxLimitAtmMonth instead */
  maxLimitAtmWeek: Scalars['Int']['output'];
  maxLimitPaymentWeek: Scalars['Int']['output'];
  optionAtm: Scalars['Boolean']['output'];
  optionForeign: Scalars['Boolean']['output'];
  optionNfc: Scalars['Boolean']['output'];
  optionOnline: Scalars['Boolean']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  pinTryExceeds: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  providerCardId: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  providerName: Maybe<Scalars['String']['output']>;
  shippingDate: Maybe<Scalars['Float']['output']>;
  status: CreditCardStatusCode;
  totalAtmMonth: Scalars['Float']['output'];
  totalAtmWeek: Scalars['Float']['output'];
  totalPaymentAll: Scalars['Float']['output'];
  /** @deprecated Field is not returned by the service */
  totalPaymentMonth: Maybe<Scalars['Float']['output']>;
  totalPaymentWeek: Scalars['Float']['output'];
  updatedAt: Scalars['Float']['output'];
  walletId: Scalars['ID']['output'];
};

export type CreditCardPayload = {
  __typename: 'CreditCardPayload';
  creditCard: CreditCardInterface;
};

export type CreditCardPendingOrder = CreditCardInterface & {
  __typename: 'CreditCardPendingOrder';
  actualDeliveryAddress: Maybe<Scalars['String']['output']>;
  alias: Maybe<Scalars['String']['output']>;
  cardDesign: Maybe<Scalars['String']['output']>;
  cardHolder: CompanyUser;
  cardHolderName: Maybe<Scalars['String']['output']>;
  cardProductId: Maybe<CardProductId>;
  cardProductType: PaymentCardProductType;
  companyUserId: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  creditCardId: Scalars['ID']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deactivateAt: Maybe<Scalars['String']['output']>;
  deliveryCity: Maybe<Scalars['String']['output']>;
  deliveryStreetFirstLine: Maybe<Scalars['String']['output']>;
  deliveryStreetSecondLine: Maybe<Scalars['String']['output']>;
  deliveryZip: Maybe<Scalars['String']['output']>;
  digitizationData: Maybe<Array<Maybe<DigitizationData>>>;
  expiryDate: Scalars['Float']['output'];
  isDefinitivelyBlocked: Scalars['Boolean']['output'];
  isLive: Scalars['Boolean']['output'];
  isPhysical: Scalars['Boolean']['output'];
  isPremium: Scalars['Boolean']['output'];
  limitAtmMonth: Scalars['Int']['output'];
  limitAtmWeek: Scalars['Int']['output'];
  limitPaymentAll: Scalars['Float']['output'];
  /** @deprecated Field is not returned by the service */
  limitPaymentMonth: Maybe<Scalars['Int']['output']>;
  limitPaymentWeek: Scalars['Int']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  maskedPan: Maybe<Scalars['String']['output']>;
  maxLimitAtmMonth: Scalars['Int']['output'];
  /** @deprecated Use maxLimitAtmMonth instead */
  maxLimitAtmWeek: Scalars['Int']['output'];
  maxLimitPaymentWeek: Scalars['Int']['output'];
  optionAtm: Scalars['Boolean']['output'];
  optionForeign: Scalars['Boolean']['output'];
  optionNfc: Scalars['Boolean']['output'];
  optionOnline: Scalars['Boolean']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  pinTryExceeds: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  providerCardId: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  providerName: Maybe<Scalars['String']['output']>;
  shippingDate: Maybe<Scalars['Float']['output']>;
  status: CreditCardStatusCode;
  totalAtmMonth: Scalars['Float']['output'];
  totalAtmWeek: Scalars['Float']['output'];
  totalPaymentAll: Scalars['Float']['output'];
  /** @deprecated Field is not returned by the service */
  totalPaymentMonth: Maybe<Scalars['Float']['output']>;
  totalPaymentWeek: Scalars['Float']['output'];
  updatedAt: Scalars['Float']['output'];
  walletId: Scalars['ID']['output'];
};

export enum CreditCardStatusCode {
  Active = 'ACTIVE',
  Created = 'CREATED',
  Deactivated = 'DEACTIVATED',
  Inactive = 'INACTIVE',
  Locked = 'LOCKED',
  Lost = 'LOST',
  Stolen = 'STOLEN'
}

export type CreditFees = {
  __typename: 'CreditFees';
  applicationFee: Scalars['Float']['output'];
};

export type CreditLimits = {
  __typename: 'CreditLimits';
  maxAmount: Scalars['Float']['output'];
  maxDuration: Scalars['Int']['output'];
  maxMonthlyPayment: Scalars['Float']['output'];
  minAmount: Scalars['Float']['output'];
  minDuration: Scalars['Int']['output'];
};

export type CreditRate = {
  __typename: 'CreditRate';
  bonusRate: Scalars['Float']['output'];
  maxAmount: Scalars['Int']['output'];
  minAmount: Scalars['Int']['output'];
  standardRate: Scalars['Float']['output'];
};

export type CreditRateByDuration = {
  __typename: 'CreditRateByDuration';
  bonusRate: Scalars['Float']['output'];
  lowerBoundMonth: Scalars['Int']['output'];
  standardRate: Scalars['Float']['output'];
  upperBoundMonth: Scalars['Int']['output'];
};

export type CreditReason = {
  __typename: 'CreditReason';
  locale: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type CreditThresholdsForCashflow = {
  __typename: 'CreditThresholdsForCashflow';
  amount: Scalars['Float']['output'];
  duration: Scalars['Int']['output'];
};

export type CurrentTaxForecastPeriod = {
  __typename: 'CurrentTaxForecastPeriod';
  amount: Scalars['Float']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  isCustomizedEstimation: Maybe<Scalars['Boolean']['output']>;
  payBefore: Scalars['Float']['output'];
  periodEndsAt: Scalars['Float']['output'];
  periodStartsAt: Scalars['Float']['output'];
  taxForecastPeriodId: Scalars['ID']['output'];
};

export enum DashboardGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type DashboardPaymentMethod = {
  __typename: 'DashboardPaymentMethod';
  payin: PaymentMethodValues;
  payout: PaymentMethodValues;
};

export type DashboardTeamMembersValues = {
  __typename: 'DashboardTeamMembersValues';
  newValue: Maybe<Array<TeamMembersStats>>;
  oldValue: Maybe<Array<TeamMembersStats>>;
};

export type DashboardTransaction = {
  __typename: 'DashboardTransaction';
  newDate: Maybe<Scalars['String']['output']>;
  newValue: Maybe<Scalars['Float']['output']>;
  oldDate: Maybe<Scalars['String']['output']>;
  oldValue: Maybe<Scalars['Float']['output']>;
};

export type DeleteAccountingRecurringExportInput = {
  accountingRecurringExportId: Scalars['ID']['input'];
};

export type DeleteAccountingRecurringExportPayload = {
  __typename: 'DeleteAccountingRecurringExportPayload';
  accountingRecurringExport: Maybe<AccountingRecurringExport>;
};

export type DeleteBankTransferRecipientInput = {
  bankTransferRecipientId: Scalars['ID']['input'];
};

export type DeleteCatalogItemInput = {
  invoiceCatalogItemId: Scalars['ID']['input'];
};

export type DeleteClientContactInput = {
  clientContactId: Scalars['ID']['input'];
  clientId: Scalars['ID']['input'];
};

export type DeleteClientInput = {
  clientId: Scalars['ID']['input'];
};

export type DeleteCompanyCreationDocumentInput = {
  applicationId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
};

export type DeleteCompanyCreationDocumentPayload = {
  __typename: 'DeleteCompanyCreationDocumentPayload';
  companyCreationDocuments: Array<CompanyCreationDocument>;
};

export type DeleteCompanyInvitationInput = {
  companyInvitationId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type DeleteInvoiceInput = {
  invoiceId: Scalars['ID']['input'];
};

export type DeleteKybDocumentInput = {
  kybDocumentId: Scalars['ID']['input'];
};

export type DeleteKybDocumentPayload = {
  __typename: 'DeleteKybDocumentPayload';
  kybDocument: KybDocument;
};

export type DeleteKycReviewDocumentInput = {
  kycReviewDocumentId: Scalars['ID']['input'];
};

export type DeleteMerchantSandboxAccountInput = {
  merchantAccountId: Scalars['String']['input'];
};

export type DeletePhysicalPersonLegalBeneficiarySoloInput = {
  companyLegalBeneficiaryId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type DeleteQuoteInput = {
  quoteId: Scalars['ID']['input'];
};

export type DeleteReceiptInput = {
  receiptId: Scalars['ID']['input'];
};

export type DeleteShineRecurringAccountingExportSettingsInput = {
  accountingExportSettingsId: Scalars['ID']['input'];
};

export enum DetailsOfCharges {
  Ben = 'BEN',
  Our = 'OUR',
  Sha = 'SHA'
}

export type Device = {
  __typename: 'Device';
  appVersion: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  deviceId: Scalars['UUIDv4']['output'];
  loginCredentialId: Scalars['UUIDv4']['output'];
  modelName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notificationToken: Maybe<Scalars['String']['output']>;
  platform: Scalars['String']['output'];
  platformVersion: Maybe<Scalars['String']['output']>;
  role: DeviceRole;
  uniqueId: Scalars['String']['output'];
};

export type DeviceActivity = {
  __typename: 'DeviceActivity';
  createdAt: Scalars['String']['output'];
  geolocation: Maybe<GeoLocation>;
  name: Scalars['String']['output'];
};

export type DeviceInput = {
  appVersion: Scalars['String']['input'];
  modelName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  platform?: InputMaybe<Scalars['String']['input']>;
  platformVersion?: InputMaybe<Scalars['String']['input']>;
  uniqueId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export enum DeviceRole {
  Mda = 'MDA',
  Trusted = 'TRUSTED',
  Unknown = 'UNKNOWN'
}

export enum DigitizationActivationMethod {
  CardholderToCallAutomatedNumber = 'CARDHOLDER_TO_CALL_AUTOMATED_NUMBER',
  CardholderToCallMannedNumber = 'CARDHOLDER_TO_CALL_MANNED_NUMBER',
  CardholderToUseMobileApp = 'CARDHOLDER_TO_USE_MOBILE_APP',
  CardholderToVisitWebsite = 'CARDHOLDER_TO_VISIT_WEBSITE',
  EmailToCardholderAddress = 'EMAIL_TO_CARDHOLDER_ADDRESS',
  IssuerToCallCardholderNumber = 'ISSUER_TO_CALL_CARDHOLDER_NUMBER',
  TextToCardholderNumber = 'TEXT_TO_CARDHOLDER_NUMBER'
}

export type DigitizationData = {
  __typename: 'DigitizationData';
  activationMethod: DigitizationActivationMethod;
  createdAt: Scalars['Float']['output'];
  deviceName: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  paymentCardDigitizationId: Scalars['ID']['output'];
  paymentCardId: Scalars['ID']['output'];
  tokenUniqueReference: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
  walletProvider: DigitizationWalletProvider;
};

export enum DigitizationWalletProvider {
  Apple = 'APPLE',
  Google = 'GOOGLE'
}

export type DirectDebitMandate = {
  __typename: 'DirectDebitMandate';
  bankAccountId: Scalars['ID']['output'];
  bankTransferRecipientId: Maybe<Scalars['ID']['output']>;
  bucketName: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Float']['output'];
  creditorIban: Maybe<Scalars['String']['output']>;
  creditorIdentifier: Maybe<Scalars['String']['output']>;
  creditorName: Scalars['String']['output'];
  creditorSwiftBic: Maybe<Scalars['String']['output']>;
  debtorIban: Scalars['String']['output'];
  debtorName: Scalars['String']['output'];
  debtorSwiftBic: Scalars['String']['output'];
  directDebitMandateId: Scalars['ID']['output'];
  fileRef: Maybe<Scalars['String']['output']>;
  isOldDebtorIban: Scalars['Boolean']['output'];
  isRecurring: Scalars['Boolean']['output'];
  isRevoked: Scalars['Boolean']['output'];
  label: Maybe<Scalars['String']['output']>;
  publicUrl: Maybe<Scalars['String']['output']>;
  revokedAt: Maybe<Scalars['String']['output']>;
  scheme: DirectDebitMandateScheme;
  type: DirectDebitMandateType;
  uid: Scalars['ID']['output'];
  uniqueMandateReference: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Float']['output'];
};

export type DirectDebitMandateEdge = {
  __typename: 'DirectDebitMandateEdge';
  cursor: Scalars['String']['output'];
  node: DirectDebitMandate;
};

export enum DirectDebitMandateScheme {
  SddB2B = 'SDD_B2B',
  SddCore = 'SDD_CORE'
}

export enum DirectDebitMandateType {
  Payin = 'PAYIN',
  Payout = 'PAYOUT'
}

export type DismissActivationOnboardingCardOrderInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type DismissActivationWidgetInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type DismissActivationWidgetPayload = {
  __typename: 'DismissActivationWidgetPayload';
  success: Scalars['Boolean']['output'];
};

export type DisputableTransaction = {
  __typename: 'DisputableTransaction';
  alreadyDisputedAt: Maybe<Scalars['String']['output']>;
  bankAccountId: Scalars['ID']['output'];
  bankTransferId: Scalars['ID']['output'];
  category: TransactionCategory;
  companyUserId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  feeType: TransactionFeeType;
  initiatorName: Maybe<Scalars['String']['output']>;
  is3DS: Maybe<Scalars['Boolean']['output']>;
  isAlreadyDisputed: Scalars['Boolean']['output'];
  paymentMethod: TransactionPaymentMethod;
  status: TransactionStatus;
  title: Scalars['String']['output'];
  transactionAt: Scalars['Float']['output'];
  transactionId: Scalars['ID']['output'];
  transferPayinSenderName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type DisputableTransactionEdge = {
  __typename: 'DisputableTransactionEdge';
  node: DisputableTransaction;
};

export type DisputableTransactionFilters = {
  companyProfileId: Scalars['UUIDv4']['input'];
  transactionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DisputableTransactionPageInfo = {
  __typename: 'DisputableTransactionPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  nextCursor: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type DisputableTransactionsConnection = {
  __typename: 'DisputableTransactionsConnection';
  edges: Array<DisputableTransactionEdge>;
  pageInfo: DisputableTransactionPageInfo;
};

export type Dispute = {
  __typename: 'Dispute';
  disputeGroupId: Scalars['String']['output'];
  intercomConversationId: Scalars['String']['output'];
};

export type DisputeCreateInput = {
  bankAccountIds: Array<Scalars['String']['input']>;
  companyUserId: Scalars['String']['input'];
  transactionIds: Array<Scalars['String']['input']>;
};

export enum DocumentType {
  BankIdentityStatement = 'BANK_IDENTITY_STATEMENT',
  BusinessLegalStatus = 'BUSINESS_LEGAL_STATUS',
  CompanyRegistration = 'COMPANY_REGISTRATION',
  DriverLicense = 'DRIVER_LICENSE',
  DriverLicenseHost = 'DRIVER_LICENSE_HOST',
  FourthlineGenericIdDocument = 'FOURTHLINE_GENERIC_ID_DOCUMENT',
  FourthlineReport = 'FOURTHLINE_REPORT',
  Generic = 'GENERIC',
  Id = 'ID',
  IdDocumentOtherShot = 'ID_DOCUMENT_OTHER_SHOT',
  IdHost = 'ID_HOST',
  IdRecto = 'ID_RECTO',
  IdRectoHost = 'ID_RECTO_HOST',
  IdVerso = 'ID_VERSO',
  IdVersoHost = 'ID_VERSO_HOST',
  OfficialTaxCertificate = 'OFFICIAL_TAX_CERTIFICATE',
  Passport = 'PASSPORT',
  PassportHost = 'PASSPORT_HOST',
  ProofOfAccommodation = 'PROOF_OF_ACCOMMODATION',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  ProofOfAddressHost = 'PROOF_OF_ADDRESS_HOST',
  ProxyGranting = 'PROXY_GRANTING',
  ResidencePermitAcknowledgement = 'RESIDENCE_PERMIT_ACKNOWLEDGEMENT',
  ResidencePermitRecto = 'RESIDENCE_PERMIT_RECTO',
  ResidencePermitRectoHost = 'RESIDENCE_PERMIT_RECTO_HOST',
  ResidencePermitVerso = 'RESIDENCE_PERMIT_VERSO',
  ResidencePermitVersoHost = 'RESIDENCE_PERMIT_VERSO_HOST',
  ResidencyPermit = 'RESIDENCY_PERMIT',
  Selfie = 'SELFIE',
  SelfieWithId = 'SELFIE_WITH_ID',
  SwornStatement = 'SWORN_STATEMENT',
  Video = 'VIDEO',
  Visa = 'VISA'
}

export type DowngradeInfo = {
  __typename: 'DowngradeInfo';
  downgradeCost: Scalars['Float']['output'];
  nextBillingDate: Scalars['String']['output'];
};

export type DuplicateInvoiceInput = {
  invoiceId: Scalars['ID']['input'];
};

export type DuplicateQuoteInput = {
  quoteId: Scalars['ID']['input'];
};

export enum EmploymentSituation {
  Employee = 'EMPLOYEE',
  FarmingEmployee = 'FARMING_EMPLOYEE',
  None = 'NONE',
  Retired = 'RETIRED',
  Student = 'STUDENT'
}

export type EnrollDeviceInput = {
  certificate: Scalars['String']['input'];
  uniqueId: Scalars['String']['input'];
};

export enum EntityType {
  BankAccount = 'bankAccount',
  CompanyUser = 'companyUser',
  DirectDebitMandate = 'directDebitMandate',
  KyxCheck = 'kyxCheck',
  PaymentCardRenewal = 'paymentCardRenewal'
}

export type ExpenseClaimRequest = {
  __typename: 'ExpenseClaimRequest';
  cancelReason: Maybe<TeamMembersRequestCancelReason>;
  cancelledAt: Maybe<Scalars['String']['output']>;
  companyProfileId: Scalars['UUIDv4']['output'];
  completedAt: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  requester: Maybe<TeamMembersRequestRequester>;
  requesterCompanyUserId: Scalars['UUIDv4']['output'];
  requesterPayload: ExpenseClaimRequestRequesterPayload;
  reviewedAt: Maybe<Scalars['String']['output']>;
  reviewer: Maybe<TeamMembersRequestReviewer>;
  reviewerCompanyUserId: Maybe<Scalars['UUIDv4']['output']>;
  reviewerPayload: Maybe<ExpenseClaimRequestReviewerPayload>;
  status: TeamMembersRequestStatus;
  supportingDocumentsUrls: Array<Scalars['String']['output']>;
  teamMembersRequestId: Scalars['UUIDv4']['output'];
  type: TeamMembersRequestType;
  updatedAt: Scalars['String']['output'];
};

export type ExpenseClaimRequestAmountInput = {
  total: Scalars['Int']['input'];
  vatItems: Array<ExpenseClaimRequestVatItemInput>;
};

export type ExpenseClaimRequestRecipient = {
  __typename: 'ExpenseClaimRequestRecipient';
  iban: Scalars['String']['output'];
  name: Scalars['String']['output'];
  swiftBic: Scalars['String']['output'];
};

export type ExpenseClaimRequestRecipientInput = {
  iban: Scalars['String']['input'];
  name: Scalars['String']['input'];
  swiftBic: Scalars['String']['input'];
};

export type ExpenseClaimRequestRequesterPayload = {
  __typename: 'ExpenseClaimRequestRequesterPayload';
  amount: ExpenseClaimRequestRequesterPayloadAmount;
  bankAccountId: Scalars['ID']['output'];
  bankTransferRecipient: ExpenseClaimRequestRecipient;
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  reseller: Scalars['String']['output'];
};

export type ExpenseClaimRequestRequesterPayloadAmount = {
  __typename: 'ExpenseClaimRequestRequesterPayloadAmount';
  total: Scalars['Int']['output'];
  vatItems: Array<ExpenseClaimRequestVatItem>;
};

export type ExpenseClaimRequestReviewerPayload = {
  __typename: 'ExpenseClaimRequestReviewerPayload';
  bankAccountId: Scalars['ID']['output'];
};

export type ExpenseClaimRequestSupportingDocumentInput = {
  bucketName: Scalars['String']['input'];
  fileRef: Scalars['String']['input'];
};

export type ExpenseClaimRequestVatItem = {
  __typename: 'ExpenseClaimRequestVatItem';
  amount: Scalars['Int']['output'];
  rate: Scalars['Float']['output'];
};

export type ExpenseClaimRequestVatItemInput = {
  amount: Scalars['Int']['input'];
  rate: Scalars['Float']['input'];
};

export type ExternalCompany = {
  __typename: 'ExternalCompany';
  activityType: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  inseeLegalFormCode: Maybe<Scalars['String']['output']>;
  legalForm: Maybe<Scalars['String']['output']>;
  legalName: Maybe<Scalars['String']['output']>;
  legalRegistrationNumber: Scalars['String']['output'];
  legalVATNumber: Maybe<Scalars['String']['output']>;
  nature: Maybe<Scalars['String']['output']>;
  registrationDate: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
  tradeName: Maybe<Scalars['String']['output']>;
  zip: Maybe<Scalars['String']['output']>;
};

export enum FnciColorCode {
  Green = 'GREEN',
  Orange = 'ORANGE',
  Red = 'RED',
  White = 'WHITE'
}

export type FnciInfo = {
  __typename: 'FNCIInfo';
  colorCode: FnciColorCode;
  cpt1: Scalars['Int']['output'];
  cpt2: Scalars['Int']['output'];
  cpt3: Scalars['Int']['output'];
};

export enum Feature {
  AutomaticVirtualCardOrder = 'automaticVirtualCardOrder',
  BulkTransfers = 'bulkTransfers',
  CashDeposit = 'cashDeposit',
  CashflowManagementDashboard = 'cashflowManagementDashboard',
  CheckDeposit = 'checkDeposit',
  InsuranceAssistance = 'insuranceAssistance',
  InvoicingItemsCatalog = 'invoicingItemsCatalog',
  MultiWallet = 'multiWallet',
  PersonalizedLogo = 'personalizedLogo',
  TeamManagement = 'teamManagement'
}

export type FeatureFilter = RoleFilter | UserFilter;

export enum FeatureFilterField {
  Role = 'role',
  User = 'user'
}

export enum FeatureName {
  Accountingexport = 'accountingexport',
  Activationonboarding = 'activationonboarding',
  Bank = 'bank',
  BankInfo = 'bank_info',
  BankTaxforecasts = 'bank_taxforecasts',
  BankTransactions = 'bank_transactions',
  Canny = 'canny',
  Company = 'company',
  CompanyBilling = 'company_billing',
  CompanyBillingCurrentplan = 'company_billing_currentplan',
  CompanyBillingCurrentplanRead = 'company_billing_currentplan_read',
  CompanyClose = 'company_close',
  CompanyOnboarding = 'company_onboarding',
  CompanyPlan = 'company_plan',
  CompanyProfile = 'company_profile',
  CompanyProfileRead = 'company_profile_read',
  CompanyProfileWrite = 'company_profile_write',
  CompanySettings = 'company_settings',
  CompanySettingsRead = 'company_settings_read',
  Credits = 'credits',
  Dashboard = 'dashboard',
  Disputes = 'disputes',
  Insurance = 'insurance',
  Invoicing = 'invoicing',
  Merchant = 'merchant',
  MerchantRead = 'merchant_read',
  Mobile = 'mobile',
  Paymentcards = 'paymentcards',
  PaymentcardsOptions = 'paymentcards_options',
  PaymentcardsOptionsContactless = 'paymentcards_options_contactless',
  PaymentcardsOrder = 'paymentcards_order',
  PaymentcardsPin = 'paymentcards_pin',
  PaymentcardsRead = 'paymentcards_read',
  Payments = 'payments',
  Referral = 'referral',
  Request = 'request',
  RequestBanktransfers = 'request_banktransfers',
  RequestExpenseclaims = 'request_expenseclaims',
  RequestPaymentcards = 'request_paymentcards',
  Team = 'team',
  TeamMembers = 'team_members',
  TeamMembersInvite = 'team_members_invite',
  TeamMembersRead = 'team_members_read',
  TeamMembersRevoke = 'team_members_revoke',
  TeamRequests = 'team_requests',
  Toolbox = 'toolbox',
  User = 'user',
  UserProfile = 'user_profile',
  UserProfileRead = 'user_profile_read',
  UserProfileReadAddress = 'user_profile_read_address',
  UserProfileWrite = 'user_profile_write',
  UserProfileWriteAddress = 'user_profile_write_address'
}

export type FeatureType = {
  __typename: 'FeatureType';
  type: Scalars['String']['output'];
};

export type FeatureTypePaymentCard = {
  __typename: 'FeatureTypePaymentCard';
  type: PaymentCardType;
};

export type FeeStatement = {
  __typename: 'FeeStatement';
  bucketName: Maybe<Scalars['String']['output']>;
  companyProfileId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  feeStatementId: Scalars['String']['output'];
  fileRef: Maybe<Scalars['String']['output']>;
  signedUrl: Scalars['String']['output'];
  status: FeeStatementStatus;
  updatedAt: Scalars['String']['output'];
  year: Scalars['Float']['output'];
};

export enum FeeStatementStatus {
  Deleted = 'DELETED',
  Pending = 'PENDING',
  Validated = 'VALIDATED'
}

export type FetchAndAddRegistrationDocumentInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  legalRegistrationNumber: Scalars['String']['input'];
};

export type File = {
  __typename: 'File';
  url: Scalars['String']['output'];
};

export type FiltersTeamMembersInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type FinishIdentityVerificationInput = {
  companyUserId: Scalars['ID']['input'];
  provider: KycProvider;
  providerDocuments?: InputMaybe<Array<KycProviderDocumentInput>>;
};

export type FinishIdentityVerificationPayload = {
  __typename: 'FinishIdentityVerificationPayload';
  kycReviewDocuments: Array<KycReviewDocument>;
};

export type FinishQesInput = {
  companyUserId: Scalars['ID']['input'];
};

export type FinishQesPayload = {
  __typename: 'FinishQesPayload';
  companyUser: Maybe<CompanyUser>;
};

export type FounderInput = {
  employmentSituation: EmploymentSituation;
  healthInsurancePlan: HealthInsurancePlan;
  maritalInfo: MaritalInfoInput;
  socialSecurityNumber?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateShortLivedTokenInput = {
  deviceToken: Scalars['String']['input'];
  passcode: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']>;
};

export type GeoLocation = {
  __typename: 'GeoLocation';
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  ipAddress: Maybe<Scalars['String']['output']>;
  latLong: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
};

export type GetCardDigitizationDataInput = {
  certificates?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  creditCardId: Scalars['ID']['input'];
  nonce?: InputMaybe<Scalars['String']['input']>;
  nonceSignature?: InputMaybe<Scalars['String']['input']>;
  tokenRequestor: TokenRequestor;
};

export type GetChallengeMethodOutput = {
  __typename: 'GetChallengeMethodOutput';
  deviceId: Maybe<Scalars['UUIDv4']['output']>;
  method: Scalars['String']['output'];
  notificationToken: Maybe<Scalars['String']['output']>;
  uniqueId: Maybe<Scalars['String']['output']>;
};

export type GetDeviceInput = {
  deviceId: Scalars['UUIDv4']['input'];
};

export type GetDeviceListInput = {
  roles?: InputMaybe<Array<InputMaybe<DeviceRole>>>;
};

export type GetDeviceListOutput = {
  __typename: 'GetDeviceListOutput';
  devices: Array<Device>;
};

export type GetDeviceOutput = {
  __typename: 'GetDeviceOutput';
  appVersion: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  deviceId: Scalars['UUIDv4']['output'];
  loginCredentialId: Scalars['UUIDv4']['output'];
  modelName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notificationToken: Maybe<Scalars['String']['output']>;
  platform: Scalars['String']['output'];
  platformVersion: Maybe<Scalars['String']['output']>;
  role: DeviceRole;
  uniqueId: Scalars['String']['output'];
};

export type GetDeviceStateInput = {
  deviceId: Scalars['UUIDv4']['input'];
};

export type GetDeviceStateOutput = {
  __typename: 'GetDeviceStateOutput';
  deviceId: Scalars['UUIDv4']['output'];
  role: DeviceRole;
  state: Scalars['String']['output'];
  statedAt: Scalars['String']['output'];
};

export type GetDeviceWithActivitiesInput = {
  deviceId: Scalars['UUIDv4']['input'];
};

export type GetDeviceWithActivitiesOutput = {
  __typename: 'GetDeviceWithActivitiesOutput';
  activities: Maybe<Array<Maybe<DeviceActivity>>>;
  appVersion: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  deviceId: Scalars['UUIDv4']['output'];
  loginCredentialId: Scalars['UUIDv4']['output'];
  modelName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notificationToken: Maybe<Scalars['String']['output']>;
  platform: Scalars['String']['output'];
  platformVersion: Maybe<Scalars['String']['output']>;
  role: DeviceRole;
};

export type GetMobileDeviceAuthenticatorDetailsOutput = {
  __typename: 'GetMobileDeviceAuthenticatorDetailsOutput';
  activities: Array<Maybe<DeviceActivity>>;
  appVersion: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  deviceId: Scalars['UUIDv4']['output'];
  modelName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  platformVersion: Maybe<Scalars['String']['output']>;
  role: DeviceRole;
  uniqueId: Scalars['String']['output'];
};

export type GetPackagePaymentIntentInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  coupon?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['ID']['input'];
};

export type GetPaymentCardsFilters = {
  cardHolderName?: InputMaybe<Array<Scalars['String']['input']>>;
  cardProductType?: InputMaybe<Array<PaymentCardProductType>>;
  status?: InputMaybe<Array<PaymentCardStatusFilter>>;
};

export type GetSasuCreationPaymentIntentInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  coupon?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['ID']['input'];
};

export type GiveLegalBeneficiaryAccessInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  role: Role;
};

export type GrantAuthenticationClientInput = {
  client_id: Scalars['String']['input'];
  companyProfileId: Scalars['String']['input'];
  redirect_uri: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type GrantAuthenticationClientOutput = {
  __typename: 'GrantAuthenticationClientOutput';
  redirectTo: Scalars['String']['output'];
};

export enum HealthInsurancePlan {
  Agricultural = 'AGRICULTURAL',
  DoesNotKnow = 'DOES_NOT_KNOW',
  General = 'GENERAL',
  Student = 'STUDENT',
  Tns = 'TNS'
}

export type HelpContactCategory = {
  __typename: 'HelpContactCategory';
  categories: Array<HelpContactCategory>;
  label: Scalars['String']['output'];
  links: Array<HelpContactCategoryLink>;
  value: Scalars['String']['output'];
};

export type HelpContactCategoryLink = {
  __typename: 'HelpContactCategoryLink';
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type HelpContactReason = {
  __typename: 'HelpContactReason';
  categories: Array<HelpContactCategory>;
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type HelpLink = {
  __typename: 'HelpLink';
  helpLinkId: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type HelpLinks = {
  __typename: 'HelpLinks';
  generalInformation: HelpLink;
  helpLinksId: Scalars['ID']['output'];
  howToBegin: HelpLink;
  howToUseAccount: HelpLink;
  howToUseCard: HelpLink;
  mostAskedQuestions: Array<HelpLink>;
  shineBlog: HelpLink;
  shineNewsletter: HelpLink;
};

export enum IdDocumentCase {
  AcceptedPassportOrIdCard = 'ACCEPTED_PASSPORT_OR_ID_CARD',
  ExpiredResidencyCard = 'EXPIRED_RESIDENCY_CARD',
  NoDocument = 'NO_DOCUMENT',
  ValidResidencyCard = 'VALID_RESIDENCY_CARD',
  Visa = 'VISA'
}

export type InitSensitiveOperationInput = {
  actionData: Scalars['JSON']['input'];
  actionType: SensitiveOperationType;
};

export type InitSensitiveOperationPayload = {
  __typename: 'InitSensitiveOperationPayload';
  sensitiveOperation: SentitiveOperation;
};

export type InitiateCashDepositInput = {
  amount: Scalars['Float']['input'];
  companyUserId: Scalars['ID']['input'];
};

export type InitiateCashDepositResult = {
  __typename: 'InitiateCashDepositResult';
  cashDepositUser: CashDepositProviderUser;
};

export type Insurance = {
  __typename: 'Insurance';
  legalProtectionInfos: LegalProtectionInfos;
};

export type Insurances = {
  __typename: 'Insurances';
  coverage: InsurancesCoverage;
  coverageFrom2024: InsurancesCoverage2024;
  perks: InsurancesPerks;
};

export type InsurancesCoverage = {
  __typename: 'InsurancesCoverage';
  accidentCoverage: Scalars['Boolean']['output'];
  computerBreakage: Scalars['Boolean']['output'];
  debtRecovery: Scalars['Boolean']['output'];
  legalAssistance: Scalars['Boolean']['output'];
  mobileScreen: Scalars['Boolean']['output'];
  warrantyExtension: Scalars['Boolean']['output'];
};

export type InsurancesCoverage2024 = {
  __typename: 'InsurancesCoverage2024';
  computerBreakage: InsurancesCoverage2024Item;
  debtRecovery: InsurancesCoverage2024Item;
  healthCostsAbroad: InsurancesCoverage2024Item;
  hospitalization: InsurancesCoverage2024Item;
  legalAssistance: InsurancesCoverage2024Item;
  mobileScreen: InsurancesCoverage2024Item;
  paymentFraud: InsurancesCoverage2024Item;
  phishing: InsurancesCoverage2024Item;
  purchaseProtection: InsurancesCoverage2024Item;
  rentalVehicle: InsurancesCoverage2024Item;
  transportDelay: InsurancesCoverage2024Item;
};

export type InsurancesCoverage2024Item = {
  __typename: 'InsurancesCoverage2024Item';
  isCovered: Scalars['Boolean']['output'];
  isCoveredFromPlan: ProviderPlanId;
};

export type InsurancesPerks = {
  __typename: 'InsurancesPerks';
  mobileFranchiseBuybackAmount: Scalars['Int']['output'];
};

export type InterbankFee = {
  __typename: 'InterbankFee';
  amount: Scalars['String']['output'];
  currency: Scalars['String']['output'];
};

export type InternationalTransferCurrency = {
  __typename: 'InternationalTransferCurrency';
  code: Scalars['String']['output'];
  isoCountryCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  supportsDecimals: Scalars['Boolean']['output'];
  symbol: Scalars['String']['output'];
};

export type InternationalTransferFee = {
  __typename: 'InternationalTransferFee';
  /** @deprecated Use amountFloatVatIncl and amountFloatVatExcl instead */
  amountFloat: Scalars['Float']['output'];
  amountFloatVatExcl: Scalars['Float']['output'];
  amountFloatVatIncl: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type InternationalTransferPayout = {
  __typename: 'InternationalTransferPayout';
  exchangeRate: Scalars['Float']['output'];
  fee: InternationalTransferFee;
  internationalTransferPayoutId: Scalars['UUIDv4']['output'];
  sourceAmountFloat: Scalars['Float']['output'];
  sourceCurrency: Scalars['String']['output'];
  targetAmountFloat: Scalars['Float']['output'];
  targetCurrency: Scalars['String']['output'];
};

export type InternationalTransferProviderError = {
  __typename: 'InternationalTransferProviderError';
  code: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  path: Maybe<Scalars['String']['output']>;
};

export type InternationalTransferProviderErrorResponse = {
  __typename: 'InternationalTransferProviderErrorResponse';
  errors: Array<InternationalTransferProviderError>;
};

export type InternationalTransferQuote = {
  __typename: 'InternationalTransferQuote';
  createdAt: Scalars['String']['output'];
  expireAt: Scalars['String']['output'];
  fee: InternationalTransferFee;
  formattedEstimatedDeliveryAt: Maybe<Scalars['String']['output']>;
  internationalTransferQuoteId: Scalars['UUIDv4']['output'];
  rate: Scalars['Float']['output'];
  source: InternationalTransferSource;
  target: InternationalTransferTarget;
};

export type InternationalTransferQuoteProviderError = {
  __typename: 'InternationalTransferQuoteProviderError';
  /** @deprecated This field should not be used */
  arguments: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  code: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  path: Maybe<Scalars['String']['output']>;
};

export type InternationalTransferRecipient = {
  __typename: 'InternationalTransferRecipient';
  accountSummary: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  internationalTransferRecipientId: Scalars['ID']['output'];
  isSelectable: Scalars['Boolean']['output'];
};

export type InternationalTransferRecipientNetworkRequirementsInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  internationalTransferQuoteId: Scalars['UUIDv4']['input'];
  lang: Scalars['String']['input'];
  requirements?: InputMaybe<Scalars['JSON']['input']>;
};

export type InternationalTransferRecipientRequirements = {
  __typename: 'InternationalTransferRecipientRequirements';
  fields: Array<InternationalTransferRequirementsFieldGroup>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  usageInfo: Maybe<Scalars['String']['output']>;
};

export type InternationalTransferRecipientsInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId?: InputMaybe<Scalars['ID']['input']>;
  currency: Scalars['String']['input'];
  internationalTransferQuoteId: Scalars['UUIDv4']['input'];
  lang: Scalars['String']['input'];
};

export type InternationalTransferRequirement = {
  __typename: 'InternationalTransferRequirement';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type InternationalTransferRequirements = {
  __typename: 'InternationalTransferRequirements';
  fields: Array<InternationalTransferRequirementsFieldGroup>;
  type: Scalars['String']['output'];
};

export type InternationalTransferRequirementsField = {
  __typename: 'InternationalTransferRequirementsField';
  displayFormat: Maybe<Scalars['String']['output']>;
  example: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  maxLength: Maybe<Scalars['Int']['output']>;
  minLength: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  refreshRequirementsOnChange: Scalars['Boolean']['output'];
  required: Scalars['Boolean']['output'];
  type: InternationalTransferRequirementsFieldType;
  validationAsync: Maybe<InternationalTransferRequirementsFieldValidationAsync>;
  validationRegexp: Maybe<Scalars['String']['output']>;
  valuesAllowed: Maybe<Array<InternationalTransferRequirementsFieldValueAllowed>>;
};

export type InternationalTransferRequirementsFieldGroup = {
  __typename: 'InternationalTransferRequirementsFieldGroup';
  group: Array<InternationalTransferRequirementsField>;
  name: Scalars['String']['output'];
};

export enum InternationalTransferRequirementsFieldType {
  Date = 'date',
  Radio = 'radio',
  Select = 'select',
  Text = 'text'
}

export type InternationalTransferRequirementsFieldValidationAsync = {
  __typename: 'InternationalTransferRequirementsFieldValidationAsync';
  params: Maybe<Array<InternationalTransferRequirementsFieldValidationAsyncParam>>;
  url: Scalars['String']['output'];
};

export type InternationalTransferRequirementsFieldValidationAsyncParam = {
  __typename: 'InternationalTransferRequirementsFieldValidationAsyncParam';
  key: Scalars['String']['output'];
  parameterName: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type InternationalTransferRequirementsFieldValueAllowed = {
  __typename: 'InternationalTransferRequirementsFieldValueAllowed';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InternationalTransferRequirementsInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  internationalTransferQuoteId: Scalars['UUIDv4']['input'];
  internationalTransferRecipientId: Scalars['ID']['input'];
  lang: Scalars['String']['input'];
  requirements?: InputMaybe<Scalars['JSON']['input']>;
};

export type InternationalTransferSource = {
  __typename: 'InternationalTransferSource';
  amountFloat: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type InternationalTransferTarget = {
  __typename: 'InternationalTransferTarget';
  amountFloat: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type IntraCompanyFundsTransferPayload = {
  __typename: 'IntraCompanyFundsTransferPayload';
  amount: Scalars['Float']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  companyUserId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  fromBankAccountId: Scalars['ID']['output'];
  intraCompanyFundsTransferId: Scalars['ID']['output'];
  status: IntraCompanyFundsTransferStatus;
  toBankAccountId: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
  userLabel: Maybe<Scalars['String']['output']>;
};

export enum IntraCompanyFundsTransferStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  ExecutedByProvider = 'EXECUTED_BY_PROVIDER',
  PendingProvider = 'PENDING_PROVIDER',
  RejectedByProvider = 'REJECTED_BY_PROVIDER'
}

export type InviteTeamMemberInput = {
  companyProfileId: Scalars['String']['input'];
  inviteeEmail: Scalars['String']['input'];
  inviteeFirstName: Scalars['String']['input'];
  inviteeLastName: Scalars['String']['input'];
  role: InviteTeamMemberRoleInput;
};

export enum InviteTeamMemberRoleInput {
  Accountant = 'ACCOUNTANT',
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE'
}

export type Invoice = {
  __typename: 'Invoice';
  allowCreditCardPayment: Scalars['Boolean']['output'];
  allowIbanPayment: Scalars['Boolean']['output'];
  cardPaymentStatus: CardPaymentStatus;
  client: InvoiceClient;
  clientContactIds: Array<Scalars['ID']['output']>;
  clientId: Scalars['ID']['output'];
  company: InvoiceCompany;
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  deleted: Maybe<Scalars['Boolean']['output']>;
  fiscalId: Scalars['String']['output'];
  invoiceId: Scalars['ID']['output'];
  isDraft: Scalars['Boolean']['output'];
  isUserCcedOnEmails: Scalars['Boolean']['output'];
  lateFeePercentage: Scalars['Float']['output'];
  lateMaxDays: Scalars['Float']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  note: Scalars['String']['output'];
  paidAt: Scalars['Float']['output'];
  particularMentions: Array<ParticularMentionLocale>;
  paymentIntent: Maybe<PaymentIntent>;
  paymentMentions: Array<InvoicePaymentMentionLocale>;
  pdf: Maybe<InvoicingPdf>;
  publicPaymentId: Scalars['String']['output'];
  publicPaymentUrl: Scalars['String']['output'];
  settings: InvoiceSettings;
  startAt: Scalars['Float']['output'];
  status: InvoiceStatus;
  statusAt: Scalars['String']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  tasks: Array<InvoiceTask>;
  title: Scalars['String']['output'];
  totalAmountAfterTaxes: Scalars['Float']['output'];
  totalAmountBeforeTaxes: Scalars['Float']['output'];
  totalVatAmount: Scalars['Float']['output'];
  type: InvoiceType;
  uid: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
  user: InvoiceUser;
  wallet: Maybe<Wallet>;
};

export type InvoiceClient = {
  __typename: 'InvoiceClient';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  invoiceClientId: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  legalRegistrationNumber: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  street: Scalars['String']['output'];
  type: Maybe<Scalars['String']['output']>;
  vatId: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type InvoiceCompany = {
  __typename: 'InvoiceCompany';
  activityType: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  hasAccre: Scalars['Boolean']['output'];
  hasPrelevLib: Scalars['Boolean']['output'];
  invoiceCompanyId: Scalars['ID']['output'];
  isMicro: Scalars['Boolean']['output'];
  legalForm: Scalars['String']['output'];
  legalName: Scalars['String']['output'];
  legalRegistrationNumber: Scalars['String']['output'];
  legalShareCapital: Maybe<Scalars['Float']['output']>;
  legalVATNumber: Scalars['String']['output'];
  nature: Maybe<Scalars['String']['output']>;
  rcsNumber: Maybe<Scalars['String']['output']>;
  registrationDate: Maybe<Scalars['String']['output']>;
  rmNumber: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  street: Scalars['String']['output'];
  taxFrequency: Scalars['String']['output'];
  tradeName: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
  zip: Scalars['String']['output'];
};

export type InvoiceEdge = {
  __typename: 'InvoiceEdge';
  cursor: Scalars['String']['output'];
  node: Invoice;
};

export type InvoicePayload = {
  __typename: 'InvoicePayload';
  invoice: Invoice;
};

export enum InvoicePaymentMention {
  InvoiceB2CLatePayment = 'INVOICE_B2C_LATE_PAYMENT',
  InvoiceLatePayment = 'INVOICE_LATE_PAYMENT'
}

export type InvoicePaymentMentionInput = {
  type: InvoicePaymentMention;
};

export type InvoicePaymentMentionLocale = {
  __typename: 'InvoicePaymentMentionLocale';
  type: InvoicePaymentMention;
  valueEn: Scalars['String']['output'];
  valueFr: Scalars['String']['output'];
};

export enum InvoiceSendingMethod {
  CopiedOnlineLink = 'COPIED_ONLINE_LINK',
  Downloaded = 'DOWNLOADED',
  None = 'NONE'
}

export type InvoiceSettings = {
  __typename: 'InvoiceSettings';
  clientId: Maybe<Scalars['String']['output']>;
  companyProfileId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  displayTradeName: Maybe<Scalars['Boolean']['output']>;
  invoiceId: Maybe<Scalars['String']['output']>;
  invoiceSettingsId: Scalars['String']['output'];
  isReminderEnabled: Scalars['Boolean']['output'];
  issuerEmail: Maybe<Scalars['String']['output']>;
  legalMentions: Array<Scalars['String']['output']>;
  quoteValidityDays: Maybe<Scalars['Int']['output']>;
  reminderFrequency: Maybe<ReminderFrequencyEnum>;
  reminderLanguage: Maybe<Scalars['String']['output']>;
  reminderMaxOccurrences: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type InvoiceSettingsInput = {
  displayTradeName?: InputMaybe<Scalars['Boolean']['input']>;
  isReminderEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  issuerEmail?: InputMaybe<Scalars['String']['input']>;
  legalMentions?: InputMaybe<Array<Scalars['String']['input']>>;
  reminderFrequency?: InputMaybe<ReminderFrequencyEnum>;
  reminderLanguage?: InputMaybe<Scalars['String']['input']>;
  reminderMaxOccurrences?: InputMaybe<Scalars['Int']['input']>;
};

export enum InvoiceStatus {
  Canceled = 'CANCELED',
  MarkedAsPaid = 'MARKED_AS_PAID',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Pending = 'PENDING',
  Sent = 'SENT'
}

export type InvoiceTask = {
  __typename: 'InvoiceTask';
  invoiceCatalogItemId: Maybe<Scalars['ID']['output']>;
  invoiceTaskId: Scalars['ID']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  taskOrder: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  totalAmountBeforeTaxes: Scalars['Float']['output'];
  totalVatAmount: Scalars['Float']['output'];
  type: Maybe<TaskType>;
  unitPrice: Scalars['Float']['output'];
  units: Scalars['Float']['output'];
  vatPercentage: Scalars['Float']['output'];
};

export type InvoiceTaskInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  invoiceCatalogItemId?: InputMaybe<Scalars['ID']['input']>;
  invoiceTaskId?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  taskOrder?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TaskType>;
  unitPrice: Scalars['Float']['input'];
  units: Scalars['Float']['input'];
  vatPercentage: Scalars['Float']['input'];
};

export enum InvoiceType {
  Invoice = 'INVOICE',
  Quote = 'QUOTE'
}

export type InvoiceUser = {
  __typename: 'InvoiceUser';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  invoiceUserId: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  street: Scalars['String']['output'];
  vatId: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type InvoicesFilters = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InvoiceStatus>>;
};

export type InvoicesStatus = {
  __typename: 'InvoicesStatus';
  canceled: InvoicesStatusMetrics;
  overdue: InvoicesStatusMetrics;
  paid: InvoicesStatusMetrics;
  pending: InvoicesStatusMetrics;
  sent: InvoicesStatusMetrics;
};

export type InvoicesStatusMetrics = {
  __typename: 'InvoicesStatusMetrics';
  amount: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
};

export type Invoicing = {
  __typename: 'Invoicing';
  canAttachLogo: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export enum InvoicingDefaultLateMaxDaysTypeEnum {
  Fifteen = 'FIFTEEN',
  FortyFiveEndOfMonth = 'FORTY_FIVE_END_OF_MONTH',
  Fourteen = 'FOURTEEN',
  Other = 'OTHER',
  Seven = 'SEVEN',
  Thirty = 'THIRTY',
  TwentyOne = 'TWENTY_ONE',
  Zero = 'ZERO'
}

export type InvoicingPdf = {
  __typename: 'InvoicingPdf';
  enUrl: Scalars['String']['output'];
  frUrl: Scalars['String']['output'];
};

export type InvoicingSpecialMentions = {
  __typename: 'InvoicingSpecialMentions';
  invoicePaymentMentions: Array<InvoicePaymentMentionLocale>;
  particularMentions: Array<ParticularMentionLocale>;
  quotePaymentMentions: Array<QuotePaymentMentionLocale>;
};

export type KybDocument = {
  __typename: 'KybDocument';
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['String']['output']>;
  errorLocalized: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  firebaseBucketName: Maybe<Scalars['String']['output']>;
  firebaseFileRef: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  kybDocumentId: Scalars['ID']['output'];
  status: KybDocumentStatus;
  trzDocumentId: Maybe<Scalars['String']['output']>;
  type: KybDocumentType;
  uid: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type KybDocumentPayload = {
  __typename: 'KybDocumentPayload';
  kybDocument: KybDocument;
};

export enum KybDocumentStatus {
  Missing = 'MISSING',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export enum KybDocumentType {
  BeneficiaryRegister = 'BENEFICIARY_REGISTER',
  BeneficiaryRegisterXml = 'BENEFICIARY_REGISTER_XML',
  CertificateOfDeposit = 'CERTIFICATE_OF_DEPOSIT',
  CertificateOfDepositCapitalIncrease = 'CERTIFICATE_OF_DEPOSIT_CAPITAL_INCREASE',
  CompanyRegistrationOfficialPaper = 'COMPANY_REGISTRATION_OFFICIAL_PAPER',
  DraftStatutes = 'DRAFT_STATUTES',
  Generic = 'GENERIC',
  InpiNotice = 'INPI_NOTICE',
  Kbis = 'KBIS',
  KbisMoralBeneficiary = 'KBIS_MORAL_BENEFICIARY',
  Mandate = 'MANDATE',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  ProofOfFunds = 'PROOF_OF_FUNDS',
  Statutes = 'STATUTES',
  StatutesMoralBeneficiary = 'STATUTES_MORAL_BENEFICIARY'
}

export type KybReview = {
  __typename: 'KybReview';
  companyProfileId: Scalars['UUIDv4']['output'];
  completedAt: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  kybReviewId: Scalars['ID']['output'];
  refusalTag: Maybe<RefusalTag>;
  startedAt: Maybe<Scalars['String']['output']>;
  status: Maybe<KybReviewStatus>;
  updatedAt: Maybe<Scalars['String']['output']>;
};

export enum KybReviewStatus {
  Invalid = 'INVALID',
  Prechecked = 'PRECHECKED',
  Refused = 'REFUSED',
  SentForPostcheck = 'SENT_FOR_POSTCHECK',
  SentForPrecheck = 'SENT_FOR_PRECHECK',
  SentToTreezor = 'SENT_TO_TREEZOR',
  Validated = 'VALIDATED'
}

export type KycDocument = {
  __typename: 'KycDocument';
  error: Scalars['String']['output'];
  errorLocalized: Maybe<Scalars['String']['output']>;
  /** @deprecated Use kycReviewDocumentId instead */
  kycDocumentId: Scalars['ID']['output'];
  kycReviewDocumentId: Scalars['ID']['output'];
  status: KycReviewDocumentStatus;
  type: KycReviewDocumentType;
  updatedAt: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export enum KycDocumentStatus {
  Missing = 'MISSING',
  None = 'NONE',
  Pending = 'PENDING',
  Prevalidated = 'PREVALIDATED',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export type KycInformationPayload = {
  __typename: 'KycInformationPayload';
  profile: Maybe<UserProfile>;
};

export enum KycProvider {
  Fourthline = 'FOURTHLINE',
  Onfido = 'ONFIDO',
  Treezor = 'TREEZOR'
}

export type KycProviderDocumentInput = {
  providerDocumentId: Scalars['ID']['input'];
  type: KycReviewDocumentType;
};

export type KycProviderUser = {
  __typename: 'KycProviderUser';
  createdAt: Scalars['String']['output'];
  kycProviderUserId: Scalars['ID']['output'];
  provider: Scalars['String']['output'];
  providerUserId: Scalars['ID']['output'];
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type KycReview = {
  __typename: 'KycReview';
  completedAt: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  kycReviewDocumentIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  kycReviewId: Scalars['ID']['output'];
  refusalTag: Maybe<RefusalTag>;
  startedAt: Maybe<Scalars['String']['output']>;
  status: Maybe<KycReviewStatus>;
  uid: Scalars['ID']['output'];
  updatedAt: Maybe<Scalars['String']['output']>;
};

export type KycReviewDocument = {
  __typename: 'KycReviewDocument';
  createdAt: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['String']['output']>;
  /** @deprecated Use errors instead */
  error: Scalars['String']['output'];
  errorLocalized: Maybe<Scalars['String']['output']>;
  errors: Maybe<Array<KycReviewDocumentError>>;
  firebaseBucketName: Maybe<Scalars['String']['output']>;
  firebaseFileRef: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  /** @deprecated Use kycReviewDocumentId instead */
  kycDocumentId: Scalars['ID']['output'];
  kycReviewDocumentId: Scalars['ID']['output'];
  onfidoDocumentId: Maybe<Scalars['String']['output']>;
  status: KycReviewDocumentStatus;
  trzDocumentId: Maybe<Scalars['String']['output']>;
  type: KycReviewDocumentType;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type KycReviewDocumentDeletePayload = {
  __typename: 'KycReviewDocumentDeletePayload';
  kycReviewDocument: KycReviewDocument;
};

export enum KycReviewDocumentError {
  BlackAndWhite = 'BLACK_AND_WHITE',
  Blurry = 'BLURRY',
  Brightness = 'BRIGHTNESS',
  Cropped = 'CROPPED',
  Expired = 'EXPIRED',
  Falsified = 'FALSIFIED',
  IdDocumentSecurityInvisible = 'ID_DOCUMENT_SECURITY_INVISIBLE',
  IdDocumentTooBright = 'ID_DOCUMENT_TOO_BRIGHT',
  IdDocumentTooDark = 'ID_DOCUMENT_TOO_DARK',
  IdIncoherent = 'ID_INCOHERENT',
  Incomplete = 'INCOMPLETE',
  JddIncoherent = 'JDD_INCOHERENT',
  JddIncoherentNom = 'JDD_INCOHERENT_NOM',
  KybDraftStatutesActivityUnclear = 'KYB_DRAFT_STATUTES_ACTIVITY_UNCLEAR',
  KybDraftStatutesEditAccountHolder = 'KYB_DRAFT_STATUTES_EDIT_ACCOUNT_HOLDER',
  KybDraftStatutesInconsistentAddress = 'KYB_DRAFT_STATUTES_INCONSISTENT_ADDRESS',
  KybDraftStatutesInconsistentLegalName = 'KYB_DRAFT_STATUTES_INCONSISTENT_LEGAL_NAME',
  KybDraftStatutesInconsistentName = 'KYB_DRAFT_STATUTES_INCONSISTENT_NAME',
  KybDraftStatutesInconsistentShareCapital = 'KYB_DRAFT_STATUTES_INCONSISTENT_SHARE_CAPITAL',
  KybDraftStatutesLegalNameIncludesName = 'KYB_DRAFT_STATUTES_LEGAL_NAME_INCLUDES_NAME',
  KybDraftStatutesMissingAddress = 'KYB_DRAFT_STATUTES_MISSING_ADDRESS',
  KybDraftStatutesMissingAllocationOfShares = 'KYB_DRAFT_STATUTES_MISSING_ALLOCATION_OF_SHARES',
  KybDraftStatutesMissingAppointmentDeed = 'KYB_DRAFT_STATUTES_MISSING_APPOINTMENT_DEED',
  KybDraftStatutesMissingBirthDate = 'KYB_DRAFT_STATUTES_MISSING_BIRTH_DATE',
  KybDraftStatutesMissingMentionAppendix = 'KYB_DRAFT_STATUTES_MISSING_MENTION_APPENDIX',
  KybDraftStatutesMissingMentionApports = 'KYB_DRAFT_STATUTES_MISSING_MENTION_APPORTS',
  KybDraftStatutesMissingShareholders = 'KYB_DRAFT_STATUTES_MISSING_SHAREHOLDERS',
  KybDraftStatutesMissingShares = 'KYB_DRAFT_STATUTES_MISSING_SHARES',
  KybDraftStatutesNotAccepted = 'KYB_DRAFT_STATUTES_NOT_ACCEPTED',
  KybDraftStatutesNotAuthenticated = 'KYB_DRAFT_STATUTES_NOT_AUTHENTICATED',
  KybDraftStatutesNotComplete = 'KYB_DRAFT_STATUTES_NOT_COMPLETE',
  KybDraftStatutesOtherMentions = 'KYB_DRAFT_STATUTES_OTHER_MENTIONS',
  KybDraftStatutesOtherMentionsAppendix = 'KYB_DRAFT_STATUTES_OTHER_MENTIONS_APPENDIX',
  KybDraftStatutesShareholderInconsistentAddress = 'KYB_DRAFT_STATUTES_SHAREHOLDER_INCONSISTENT_ADDRESS',
  KybDraftStatutesShareholderInconsistentBirthdate = 'KYB_DRAFT_STATUTES_SHAREHOLDER_INCONSISTENT_BIRTHDATE',
  KybDraftStatutesSharesInconsistent = 'KYB_DRAFT_STATUTES_SHARES_INCONSISTENT',
  KybDraftStatutesUnclearActivity = 'KYB_DRAFT_STATUTES_UNCLEAR_ACTIVITY',
  KybDraftStatutesVariableCapital = 'KYB_DRAFT_STATUTES_VARIABLE_CAPITAL',
  KybDraftStatutesVariableCapitalInconsistent = 'KYB_DRAFT_STATUTES_VARIABLE_CAPITAL_INCONSISTENT',
  KybInconsistentAddress = 'KYB_INCONSISTENT_ADDRESS',
  KybInconsistentName = 'KYB_INCONSISTENT_NAME',
  KybInconsistentSiren = 'KYB_INCONSISTENT_SIREN',
  KybInconsistentSiret = 'KYB_INCONSISTENT_SIRET',
  KybInpiInconsistentLegalForm = 'KYB_INPI_INCONSISTENT_LEGAL_FORM',
  KybKbisExpired = 'KYB_KBIS_EXPIRED',
  KybKbisInconsistentSiret = 'KYB_KBIS_INCONSISTENT_SIRET',
  KybMandateInconsistentCompany = 'KYB_MANDATE_INCONSISTENT_COMPANY',
  KybMandateInconsistentContractor = 'KYB_MANDATE_INCONSISTENT_CONTRACTOR',
  KybMandateInconsistentMandatee = 'KYB_MANDATE_INCONSISTENT_MANDATEE',
  KybMandateMissingMentionPower = 'KYB_MANDATE_MISSING_MENTION_POWER',
  KybNotUpToDate = 'KYB_NOT_UP_TO_DATE',
  KybRegistrationDocNotSupported = 'KYB_REGISTRATION_DOC_NOT_SUPPORTED',
  KybStatutesBeneficiaryMissing = 'KYB_STATUTES_BENEFICIARY_MISSING',
  KybStatutesDocNotSupported = 'KYB_STATUTES_DOC_NOT_SUPPORTED',
  KybStatutesIncomplete = 'KYB_STATUTES_INCOMPLETE',
  KybStatutesNotAuthenticated = 'KYB_STATUTES_NOT_AUTHENTICATED',
  KybStatutesNotComplete = 'KYB_STATUTES_NOT_COMPLETE',
  KybStatutesNotUpToDate = 'KYB_STATUTES_NOT_UP_TO_DATE',
  KybStatutesRemoveBeneficiary = 'KYB_STATUTES_REMOVE_BENEFICIARY',
  KybStatutesSignatureMissing = 'KYB_STATUTES_SIGNATURE_MISSING',
  KybStatutesSubscribersListMissing = 'KYB_STATUTES_SUBSCRIBERS_LIST_MISSING',
  KybStatutesWrongAccountHolder = 'KYB_STATUTES_WRONG_ACCOUNT_HOLDER',
  KybUnclearActivity = 'KYB_UNCLEAR_ACTIVITY',
  KycBirthPlaceInconsistent = 'KYC_BIRTH_PLACE_INCONSISTENT',
  KycDrivingLicenceNotSupported = 'KYC_DRIVING_LICENCE_NOT_SUPPORTED',
  KycHostInconsistentName = 'KYC_HOST_INCONSISTENT_NAME',
  KycIdAssistedDocumentPhoto = 'KYC_ID_ASSISTED_DOCUMENT_PHOTO',
  KycIdAssistedSelfie = 'KYC_ID_ASSISTED_SELFIE',
  KycIdAssistedVideo = 'KYC_ID_ASSISTED_VIDEO',
  KycIdDateOfBirthDoesNotMatch = 'KYC_ID_DATE_OF_BIRTH_DOES_NOT_MATCH',
  KycIdDateOfBirthDoesNotMatchPreviousId = 'KYC_ID_DATE_OF_BIRTH_DOES_NOT_MATCH_PREVIOUS_ID',
  KycIdDocumentConditionInsufficient = 'KYC_ID_DOCUMENT_CONDITION_INSUFFICIENT',
  KycIdErrorOtherDocument = 'KYC_ID_ERROR_OTHER_DOCUMENT',
  KycIdErrorOtherSelfie = 'KYC_ID_ERROR_OTHER_SELFIE',
  KycIdErrorOtherVideo = 'KYC_ID_ERROR_OTHER_VIDEO',
  KycIdExpired = 'KYC_ID_EXPIRED',
  KycIdExpiryDateDoesNotMatch = 'KYC_ID_EXPIRY_DATE_DOES_NOT_MATCH',
  KycIdGdprBreach = 'KYC_ID_GDPR_BREACH',
  KycIdGdprBreachSelfie = 'KYC_ID_GDPR_BREACH_SELFIE',
  KycIdGdprBreachVideo = 'KYC_ID_GDPR_BREACH_VIDEO',
  KycIdInadequateSelfie = 'KYC_ID_INADEQUATE_SELFIE',
  KycIdInadequateVideo = 'KYC_ID_INADEQUATE_VIDEO',
  KycIdInconsistentName = 'KYC_ID_INCONSISTENT_NAME',
  KycIdInconsistentSelfie = 'KYC_ID_INCONSISTENT_SELFIE',
  KycIdInconsistentSelfieFaceNotVisible = 'KYC_ID_INCONSISTENT_SELFIE_FACE_NOT_VISIBLE',
  KycIdInconsistentSelfieNotSamePerson = 'KYC_ID_INCONSISTENT_SELFIE_NOT_SAME_PERSON',
  KycIdInconsistentSelfieTooBlurry = 'KYC_ID_INCONSISTENT_SELFIE_TOO_BLURRY',
  KycIdInconsistentSelfieTooBright = 'KYC_ID_INCONSISTENT_SELFIE_TOO_BRIGHT',
  KycIdInconsistentSelfieTooDark = 'KYC_ID_INCONSISTENT_SELFIE_TOO_DARK',
  KycIdInconsistentVideoTooBlurry = 'KYC_ID_INCONSISTENT_VIDEO_TOO_BLURRY',
  KycIdInconsistentVideoTooBright = 'KYC_ID_INCONSISTENT_VIDEO_TOO_BRIGHT',
  KycIdInconsistentVideoTooDark = 'KYC_ID_INCONSISTENT_VIDEO_TOO_DARK',
  KycIdInverted = 'KYC_ID_INVERTED',
  KycIdIssueDateDoesNotMatch = 'KYC_ID_ISSUE_DATE_DOES_NOT_MATCH',
  KycIdKycGenderDoesNotMatch = 'KYC_ID_KYC_GENDER_DOES_NOT_MATCH',
  KycIdKycInvalidIdDocument = 'KYC_ID_KYC_INVALID_ID_DOCUMENT',
  KycIdLivenessFeature = 'KYC_ID_LIVENESS_FEATURE',
  KycIdNameDoesNotMatchPreviousId = 'KYC_ID_NAME_DOES_NOT_MATCH_PREVIOUS_ID',
  KycIdNameFieldsDoesNotMatch = 'KYC_ID_NAME_FIELDS_DOES_NOT_MATCH',
  KycIdNationalityDoesNotMatch = 'KYC_ID_NATIONALITY_DOES_NOT_MATCH',
  KycIdNumberDoesNotMatch = 'KYC_ID_NUMBER_DOES_NOT_MATCH',
  KycIdPhotoBlocked = 'KYC_ID_PHOTO_BLOCKED',
  KycIdPlaceOfBirthDoesNotMatch = 'KYC_ID_PLACE_OF_BIRTH_DOES_NOT_MATCH',
  KycIdPlaceOfBirthDoesNotMatchPreviousId = 'KYC_ID_PLACE_OF_BIRTH_DOES_NOT_MATCH_PREVIOUS_ID',
  KycIdPlasticCover = 'KYC_ID_PLASTIC_COVER',
  KycIdRectoVersoMissing = 'KYC_ID_RECTO_VERSO_MISSING',
  KycMissingSelfie = 'KYC_MISSING_SELFIE',
  KycPassportHostPageMissing = 'KYC_PASSPORT_HOST_PAGE_MISSING',
  KycPassportNonEuropean = 'KYC_PASSPORT_NON_EUROPEAN',
  KycPassportSecondPageMissing = 'KYC_PASSPORT_SECOND_PAGE_MISSING',
  KycPoa_4GBox = 'KYC_POA_4G_BOX',
  KycPoaCompanyName = 'KYC_POA_COMPANY_NAME',
  KycPoaEndedInvoice = 'KYC_POA_ENDED_INVOICE',
  KycPoaExpired = 'KYC_POA_EXPIRED',
  KycPoaFirstnameMissing = 'KYC_POA_FIRSTNAME_MISSING',
  KycPoaInconsistentAddress = 'KYC_POA_INCONSISTENT_ADDRESS',
  KycPoaInconsistentName = 'KYC_POA_INCONSISTENT_NAME',
  KycPoaMobile = 'KYC_POA_MOBILE',
  KycPoaNameSlightlyDifferent = 'KYC_POA_NAME_SLIGHTLY_DIFFERENT',
  KycPoaNotSupported = 'KYC_POA_NOT_SUPPORTED',
  KycPoaNoControlPoint = 'KYC_POA_NO_CONTROL_POINT',
  KycPoaPaymentSchedule = 'KYC_POA_PAYMENT_SCHEDULE',
  KycPoaPlaceOfConsumptionAddress = 'KYC_POA_PLACE_OF_CONSUMPTION_ADDRESS',
  KycPoaPrinted = 'KYC_POA_PRINTED',
  KycPoaScreenshot = 'KYC_POA_SCREENSHOT',
  KycPoaUnpaidInvoice = 'KYC_POA_UNPAID_INVOICE',
  KycProofOfAccommodationEsignature = 'KYC_PROOF_OF_ACCOMMODATION_ESIGNATURE',
  KycProofOfAccommodationExpired = 'KYC_PROOF_OF_ACCOMMODATION_EXPIRED',
  KycProofOfAccommodationHostBirthdateMismatch = 'KYC_PROOF_OF_ACCOMMODATION_HOST_BIRTHDATE_MISMATCH',
  KycProofOfAccommodationInconsistentAddress = 'KYC_PROOF_OF_ACCOMMODATION_INCONSISTENT_ADDRESS',
  KycProofOfAccommodationInconsistentSignature = 'KYC_PROOF_OF_ACCOMMODATION_INCONSISTENT_SIGNATURE',
  KycProofOfAccommodationNameSlightlyDifferent = 'KYC_PROOF_OF_ACCOMMODATION_NAME_SLIGHTLY_DIFFERENT',
  KycProofOfAccommodationSignatureMissing = 'KYC_PROOF_OF_ACCOMMODATION_SIGNATURE_MISSING',
  KycProofOfAccommodationUserBirthdateMismatch = 'KYC_PROOF_OF_ACCOMMODATION_USER_BIRTHDATE_MISMATCH',
  KycResidencePermitHostMissing = 'KYC_RESIDENCE_PERMIT_HOST_MISSING',
  KycResidencePermitMissing = 'KYC_RESIDENCE_PERMIT_MISSING',
  KycShineStartPoaMentionMicroMissing = 'KYC_SHINE_START_POA_MENTION_MICRO_MISSING',
  KycShineStartPoaNotSupported = 'KYC_SHINE_START_POA_NOT_SUPPORTED',
  KycShineStartResidencePermitHostInconsistentAddress = 'KYC_SHINE_START_RESIDENCE_PERMIT_HOST_INCONSISTENT_ADDRESS',
  KycShineStartResidencePermitInconsistentAddress = 'KYC_SHINE_START_RESIDENCE_PERMIT_INCONSISTENT_ADDRESS',
  Missing = 'MISSING',
  NeedAnother = 'NEED_ANOTHER',
  NotAuthenticated = 'NOT_AUTHENTICATED',
  NoDate = 'NO_DATE',
  NoSignature = 'NO_SIGNATURE',
  OutOfBounds = 'OUT_OF_BOUNDS',
  Photocopy = 'PHOTOCOPY',
  Scan = 'SCAN',
  Screen = 'SCREEN',
  Screenshot = 'SCREENSHOT',
  TypeNotSupported = 'TYPE_NOT_SUPPORTED',
  Unreadable = 'UNREADABLE',
  UploadError = 'UPLOAD_ERROR'
}

export type KycReviewDocumentFilters = {
  type: Scalars['String']['input'];
};

export type KycReviewDocumentPayload = {
  __typename: 'KycReviewDocumentPayload';
  kycReviewDocument: KycReviewDocument;
};

export enum KycReviewDocumentStatus {
  Missing = 'MISSING',
  Pending = 'PENDING',
  Prevalidated = 'PREVALIDATED',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export enum KycReviewDocumentType {
  BankIdentityStatement = 'BANK_IDENTITY_STATEMENT',
  BusinessLegalStatus = 'BUSINESS_LEGAL_STATUS',
  CompanyRegistration = 'COMPANY_REGISTRATION',
  DriverLicense = 'DRIVER_LICENSE',
  DriverLicenseHost = 'DRIVER_LICENSE_HOST',
  FourthlineGenericIdDocument = 'FOURTHLINE_GENERIC_ID_DOCUMENT',
  FourthlineReport = 'FOURTHLINE_REPORT',
  Generic = 'GENERIC',
  Id = 'ID',
  IdDocumentOtherShot = 'ID_DOCUMENT_OTHER_SHOT',
  IdHost = 'ID_HOST',
  IdRecto = 'ID_RECTO',
  IdRectoHost = 'ID_RECTO_HOST',
  IdVerso = 'ID_VERSO',
  IdVersoHost = 'ID_VERSO_HOST',
  OfficialTaxCertificate = 'OFFICIAL_TAX_CERTIFICATE',
  Passport = 'PASSPORT',
  PassportHost = 'PASSPORT_HOST',
  ProofOfAccommodation = 'PROOF_OF_ACCOMMODATION',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  ProofOfAddressHost = 'PROOF_OF_ADDRESS_HOST',
  ProxyGranting = 'PROXY_GRANTING',
  ResidencePermitAcknowledgement = 'RESIDENCE_PERMIT_ACKNOWLEDGEMENT',
  ResidencePermitRecto = 'RESIDENCE_PERMIT_RECTO',
  ResidencePermitRectoHost = 'RESIDENCE_PERMIT_RECTO_HOST',
  ResidencePermitVerso = 'RESIDENCE_PERMIT_VERSO',
  ResidencePermitVersoHost = 'RESIDENCE_PERMIT_VERSO_HOST',
  ResidencyPermit = 'RESIDENCY_PERMIT',
  Selfie = 'SELFIE',
  SelfieWithId = 'SELFIE_WITH_ID',
  SwornStatement = 'SWORN_STATEMENT',
  Video = 'VIDEO',
  Visa = 'VISA'
}

export type KycReviewDocumentsFilters = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  types: Array<Scalars['String']['input']>;
};

export enum KycReviewStatus {
  Invalid = 'INVALID',
  Prechecked = 'PRECHECKED',
  Refused = 'REFUSED',
  SentForPostcheck = 'SENT_FOR_POSTCHECK',
  SentForPrecheck = 'SENT_FOR_PRECHECK',
  SentToTreezor = 'SENT_TO_TREEZOR',
  Validated = 'VALIDATED'
}

export enum KycStatus {
  Missing = 'MISSING',
  None = 'NONE',
  Pending = 'PENDING',
  Prevalidated = 'PREVALIDATED',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export type KycUnderReviewWidget = {
  __typename: 'KycUnderReviewWidget';
  isCompanyRegistrationSentByEmail: Scalars['Boolean']['output'];
  isPOASentByEmail: Scalars['Boolean']['output'];
  isShineStart: Scalars['Boolean']['output'];
  isStatutesSentByEmail: Scalars['Boolean']['output'];
  shineStartStatus: Maybe<ShineStartStatus>;
};

export type KyxCheck = {
  __typename: 'KyxCheck';
  createdAt: Scalars['String']['output'];
  kyxCheckId: Scalars['ID']['output'];
  paymentCardRenewal: Maybe<PaymentCardRenewal>;
  reviewStatus: KyxCheckReviewStatus;
  status: KyxCheckStatus;
  type: KyxCheckType;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
  userInputPayload: Maybe<Scalars['JSON']['output']>;
};

export enum KyxCheckReviewStatus {
  Invalid = 'INVALID',
  None = 'NONE',
  Pending = 'PENDING',
  Prechecked = 'PRECHECKED',
  Refused = 'REFUSED',
  SentForPostcheck = 'SENT_FOR_POSTCHECK',
  SentForPrecheck = 'SENT_FOR_PRECHECK',
  SentToTreezor = 'SENT_TO_TREEZOR',
  Validated = 'VALIDATED'
}

export enum KyxCheckStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED'
}

export enum KyxCheckType {
  AddressConfirmationForCardRenewal = 'ADDRESS_CONFIRMATION_FOR_CARD_RENEWAL',
  IdentityCheckForClosure = 'IDENTITY_CHECK_FOR_CLOSURE',
  PersonalAddressChange = 'PERSONAL_ADDRESS_CHANGE'
}

export type KyxCheckUserInput = {
  kyxCheckId: Scalars['ID']['input'];
  userInput: Scalars['JSON']['input'];
};

export type KyxChecksFilters = {
  type?: InputMaybe<KyxCheckType>;
};

export type LastInvoiceFiscalId = {
  __typename: 'LastInvoiceFiscalId';
  last: Maybe<Scalars['String']['output']>;
  next: Scalars['String']['output'];
};

export type LegalProtectionInfos = {
  __typename: 'LegalProtectionInfos';
  identifierCode: Maybe<Scalars['String']['output']>;
  legalExpertPhoneNumber: Scalars['String']['output'];
};

export type LinkToInvoiceInput = {
  invoiceId: Scalars['ID']['input'];
  transactionId: Scalars['ID']['input'];
};

export enum LockedReason {
  AccountClosureRequest = 'ACCOUNT_CLOSURE_REQUEST',
  AppUpdateRequired = 'APP_UPDATE_REQUIRED',
  CompanyAnomaly = 'COMPANY_ANOMALY',
  CompanySituationUpdate = 'COMPANY_SITUATION_UPDATE',
  Custom = 'CUSTOM',
  FraudInvestigation = 'FRAUD_INVESTIGATION',
  KycRefused = 'KYC_REFUSED',
  MissingInformation = 'MISSING_INFORMATION',
  NegativeBalanceBankAccount = 'NEGATIVE_BALANCE_BANK_ACCOUNT',
  TakeoverAttempt = 'TAKEOVER_ATTEMPT',
  UndeliveredCard = 'UNDELIVERED_CARD'
}

export type MaritalInfoInput = {
  isUnderCommunityOfGoods?: InputMaybe<Scalars['Boolean']['input']>;
  status: MaritalStatus;
};

export enum MaritalStatus {
  Cohabitation = 'COHABITATION',
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Pacsed = 'PACSED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type MarkBeneficiaryListAsCompleteInput = {
  applicants?: InputMaybe<Array<InputMaybe<BeneficiaryListApplicantInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MarkCardsAsSeenInput = {
  cardIds: Array<Scalars['ID']['input']>;
};

export type MarkCardsAsSeenPayload = {
  __typename: 'MarkCardsAsSeenPayload';
  cards: Array<Card>;
};

export type MarkInvoiceAsPaidInput = {
  invoiceId: Scalars['ID']['input'];
};

export type MarkInvoiceAsSentInput = {
  fiscalId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
  method?: InputMaybe<InvoiceSendingMethod>;
};

export type MarkQuoteAsAcceptedInput = {
  quoteId: Scalars['ID']['input'];
};

export type MarkQuoteAsSentInput = {
  fiscalId?: InputMaybe<Scalars['String']['input']>;
  method: InvoiceSendingMethod;
  quoteId: Scalars['ID']['input'];
};

export type MerchantAccount = {
  __typename: 'MerchantAccount';
  balance: MerchantBalance;
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['String']['output'];
  merchantAccountId: Scalars['UUIDv4']['output'];
  onboardingStatus: MerchantOnboardingStatus;
  providerAccountId: Scalars['ID']['output'];
  providerSession: MerchantProviderSession;
  providerType: MerchantProviderType;
  transactions: MerchantTransactionsConnection;
  updatedAt: Scalars['String']['output'];
};

export type MerchantBalance = {
  __typename: 'MerchantBalance';
  available: Scalars['Int']['output'];
  currency: MerchantSupportedCurrency;
  pending: Scalars['Int']['output'];
};

export type MerchantOnboardingAccountCompanyInfo = {
  __typename: 'MerchantOnboardingAccountCompanyInfo';
  business_type: Scalars['String']['output'];
  company: MerchantOnboardingCompanyInfo;
};

export type MerchantOnboardingAccountIndividualInfo = {
  __typename: 'MerchantOnboardingAccountIndividualInfo';
  business_type: Scalars['String']['output'];
  individual: MerchantOnboardingIndividualInfo;
};

export type MerchantOnboardingAccountInfo = MerchantOnboardingAccountCompanyInfo | MerchantOnboardingAccountIndividualInfo;

export type MerchantOnboardingCompanyInfo = {
  __typename: 'MerchantOnboardingCompanyInfo';
  address: MerchantOnboardingInfoAddress;
  name: Scalars['String']['output'];
  tax_id: Scalars['String']['output'];
};

export type MerchantOnboardingIndividualInfo = {
  __typename: 'MerchantOnboardingIndividualInfo';
  address: MerchantOnboardingInfoAddress;
  dob: MerchantOnboardingInfoDateOfBirth;
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type MerchantOnboardingInfo = {
  __typename: 'MerchantOnboardingInfo';
  account: MerchantOnboardingAccountInfo;
  companyProfileId: Scalars['UUIDv4']['output'];
  persons: Array<MerchantOnboardingPersonInfo>;
};

export type MerchantOnboardingInfoAddress = {
  __typename: 'MerchantOnboardingInfoAddress';
  city: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  postal_code: Scalars['String']['output'];
};

export type MerchantOnboardingInfoDateOfBirth = {
  __typename: 'MerchantOnboardingInfoDateOfBirth';
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type MerchantOnboardingPersonInfo = {
  __typename: 'MerchantOnboardingPersonInfo';
  address: MerchantOnboardingInfoAddress;
  dob: MerchantOnboardingInfoDateOfBirth;
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  relationship: MerchantOnboardingPersonInfoRelationship;
};

export type MerchantOnboardingPersonInfoRelationship = {
  __typename: 'MerchantOnboardingPersonInfoRelationship';
  executive: Scalars['Boolean']['output'];
  owner: Scalars['Boolean']['output'];
  representative: Scalars['Boolean']['output'];
};

export enum MerchantOnboardingStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type MerchantProviderSession = {
  __typename: 'MerchantProviderSession';
  clientSecret: Scalars['String']['output'];
  expiresAt: Scalars['Int']['output'];
  type: MerchantProviderSessionType;
};

export enum MerchantProviderSessionType {
  Dashboard = 'DASHBOARD',
  Onboarding = 'ONBOARDING'
}

export enum MerchantProviderType {
  Stripe = 'STRIPE'
}

export type MerchantStats = {
  __typename: 'MerchantStats';
  merchantCategory: Scalars['String']['output'];
  merchantName: Scalars['String']['output'];
  merchantPayoutValue: Scalars['Float']['output'];
  merchantTransactionsCount: Scalars['Int']['output'];
};

export enum MerchantSupportedCurrency {
  Eur = 'EUR'
}

export type MerchantTransaction = {
  __typename: 'MerchantTransaction';
  amount: Scalars['Int']['output'];
  currency: MerchantSupportedCurrency;
  fee: Maybe<MerchantTransactionFee>;
  merchantAccountId: Scalars['UUIDv4']['output'];
  paymentMethod: MerchantTransactionPaymentMethod;
  paymentMethodDetails: MerchantTransactionPaymentMethodDetails;
  providerType: ProviderType;
  reference: Maybe<MerchantTransactionReference>;
  transactionAt: Scalars['String']['output'];
  transactionId: Scalars['ID']['output'];
  type: MerchantTransactionType;
};

export type MerchantTransactionEdge = {
  __typename: 'MerchantTransactionEdge';
  cursor: Scalars['ID']['output'];
  node: MerchantTransaction;
};

export type MerchantTransactionFee = {
  __typename: 'MerchantTransactionFee';
  amount: Scalars['Int']['output'];
};

export enum MerchantTransactionPaymentMethod {
  BankTransfer = 'BANK_TRANSFER',
  Card = 'CARD'
}

export type MerchantTransactionPaymentMethodDetails = {
  __typename: 'MerchantTransactionPaymentMethodDetails';
  card: Maybe<MerchantTransactionPaymentMethodDetailsCard>;
};

export type MerchantTransactionPaymentMethodDetailsCard = {
  __typename: 'MerchantTransactionPaymentMethodDetailsCard';
  brand: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  last4: Maybe<Scalars['String']['output']>;
};

export type MerchantTransactionReference = MerchantTransactionReferenceInvoice;

export type MerchantTransactionReferenceInvoice = {
  __typename: 'MerchantTransactionReferenceInvoice';
  invoiceId: Scalars['UUIDv4']['output'];
};

export enum MerchantTransactionType {
  Payin = 'PAYIN',
  Payout = 'PAYOUT'
}

export type MerchantTransactionsConnection = {
  __typename: 'MerchantTransactionsConnection';
  edges: Array<MerchantTransactionEdge>;
  pageInfo: MerchantTransactionsPageInfo;
};

export type MerchantTransactionsPageInfo = {
  __typename: 'MerchantTransactionsPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  nextCursor: Maybe<Scalars['String']['output']>;
};

export type MetadataPayload = {
  __typename: 'MetadataPayload';
  companyMetadata: Maybe<CompanyMetadata>;
  metadata: Maybe<UserMetadata>;
};

export type MobileHomeWidgets = {
  __typename: 'MobileHomeWidgets';
  activation: Maybe<ActivationWidget>;
  balances: Maybe<BalancesWidget>;
  paymentActions: Maybe<PaymentActionsWidget>;
  transactions: Maybe<TransactionsWidget>;
  underReview: Maybe<UnderReviewWidget>;
};

export type Mutation = {
  __typename: 'Mutation';
  abortApplication: Scalars['Boolean']['output'];
  acceptAuthenticationDeviceRequest: AcceptAuthenticationDeviceRequestPayload;
  acceptCardPaymentStrongAuthenticationRequest: CardPaymentStrongAuthenticationRequest;
  /** @deprecated Use activatePhysicalPaymentCard instead */
  activateCreditCard: CreditCardActivationPayload;
  activatePhysicalPaymentCard: ActivatePhysicalPaymentCardPayload;
  addOrReplaceKybDocument: KybDocumentPayload;
  agreeToOutstandingAgreement: OutstandingAgreementPayload;
  answerHeardAboutUs: AnswerHeardAboutUsPayload;
  approveBankTransferRequest: BankTransferRequest;
  approveExpenseClaimRequest: ExpenseClaimRequest;
  approvePhysicalPaymentCardOrderRequest: PhysicalPaymentCardOrderRequest;
  approveVirtualBudgetCardOrderRequest: VirtualBudgetCardOrderRequest;
  approveVirtualPaymentCardOrderRequest: VirtualPaymentCardOrderRequest;
  archiveQuote: QuotePayload;
  askForFreeMonth: Scalars['Boolean']['output'];
  assignAgreementDocument: OutstandingAgreementPayload;
  blockAuthenticationDevice: BlockAuthenticationDevicePayload;
  cancelAccountClosure: AccountClosure;
  cancelBankTransfer: TransactionPayload;
  cancelDowngrade: UpgradeDowngradePayload;
  cancelInvoice: InvoicePayload;
  cancelScheduledBankTransfer: Scalars['Boolean']['output'];
  cancelTeamMembersRequest: TeamMembersRequest;
  computeTaxRates: Array<ComputedTaxForecast>;
  confirmAuthenticationActionRequest: ConfirmAuthenticationActionRequestOutput;
  convertQuoteToInvoice: InvoicePayload;
  createAccountClosure: AccountClosure;
  createAccountingExport: CreateAccountingExportPayload;
  createAccountingRecurringExport: CreateAccountingRecurringExportPayload;
  createBankTransfer: BankTransferPayload;
  createBankTransferRecipient: BankTransferRecipientPayload;
  createBankTransferRecipientWithSca: BankTransferRecipientPayload;
  createBulkBankTransfer: BulkBankTransfer;
  createCapitalDepositAndRelatedEntities: CreateCapitalDepositPayload;
  createCatalogItem: CatalogItemPayload;
  createClient: ClientPayload;
  createClientContact: ClientContactPayload;
  createCreditApplication: CreditApplication;
  createDispute: Dispute;
  createIntercomConversation: Scalars['Boolean']['output'];
  createInternationalTransfer: CreateInternationalTransferResponse;
  createInternationalTransferQuote: CreateInternationalTransferQuoteResponse;
  createInternationalTransferRecipient: CreateInternationalTransferRecipientResponse;
  createIntraCompanyFundsTransfer: IntraCompanyFundsTransferPayload;
  createInvoice: InvoicePayload;
  /** @deprecated Use Mutation.createKycReviewDocumentsFromProvider instead */
  createKycDocumentsFromProvider: CreateKycDocumentsFromProviderPayload;
  /** @deprecated Must not be called directly! Use Mutation.startIdentityVerification instead */
  createKycProviderUser: CreateKycProviderUserPayload;
  createKycReviewDocument: KycReviewDocumentPayload;
  createMerchantAccount: CreateMerchantAccountResponse;
  /** @deprecated Use Mutation.getSasuCreationPaymentIntent instead */
  createPackagePaymentIntent: PaymentIntent;
  createPhysicalPersonLegalBeneficiary: CompanyLegalBeneficiary;
  createQuote: QuotePayload;
  createReceipt: ReceiptPayload;
  createReferral: Referral;
  /** @deprecated Use Mutation.getPackagePaymentIntent instead */
  createSasuCreationPaymentIntent: PaymentIntent;
  createScheduledBankTransfer: ScheduledBankTransferPayload;
  createSecondaryBankAccount: BankAccount;
  createShineOneOffAccountingExportSettings: ShineOneOffAccountingExportSettings;
  createShineRecurringAccountingExportSettings: ShineRecurringAccountingExportSettings;
  createShineStartAndRelatedEntities: CreateShineStartPayload;
  createTaxForecastProfile: TaxForecastProfile;
  createTeamMemberReview: CreateTeamMemberReviewPayload;
  createUserBankDetails: UserBankDetails;
  createVatAmount: VatAmount;
  deleteAccountingRecurringExport: DeleteAccountingRecurringExportPayload;
  deleteBankTransferRecipient: Scalars['Boolean']['output'];
  deleteCatalogItem: CatalogItemPayload;
  deleteClient: ClientPayload;
  deleteClientContact: ClientContactPayload;
  deleteCompanyCreationDocument: DeleteCompanyCreationDocumentPayload;
  deleteCompanyInvitation: CompanyInvitation;
  deleteInvoice: InvoicePayload;
  deleteKybDocument: DeleteKybDocumentPayload;
  deleteKycReviewDocument: KycReviewDocumentDeletePayload;
  deleteMerchantSandboxAccount: Scalars['Boolean']['output'];
  deletePhysicalPersonLegalBeneficiary: Scalars['Boolean']['output'];
  deleteQuote: QuotePayload;
  deleteReceipt: ReceiptPayload;
  deleteSecondaryBankAccount: BankAccount;
  deleteShineRecurringAccountingExportSettings: ShineRecurringAccountingExportSettings;
  dismissActivationOnboardingCardOrder: Scalars['Boolean']['output'];
  dismissActivationWidget: DismissActivationWidgetPayload;
  duplicateInvoice: InvoicePayload;
  duplicateQuote: QuotePayload;
  enrollDevice: Maybe<Device>;
  fetchAndAddRegistrationDocument: Maybe<KybDocumentPayload>;
  finishIdentityVerification: FinishIdentityVerificationPayload;
  finishQes: FinishQesPayload;
  /** @deprecated Use Mutation.startIdentityVerification instead */
  generateOnfidoToken: OnfidoToken;
  generateShortLivedToken: ShortLivedTokenPayload;
  getCardDigitizationData: CardDigitizationDataPayload;
  getPackagePaymentIntent: PaymentIntent;
  getSasuCreationPaymentIntent: PaymentIntent;
  giveLegalBeneficiaryAccess: CompanyUser;
  grantAuthenticationClient: GrantAuthenticationClientOutput;
  initSensitiveOperation: InitSensitiveOperationPayload;
  inviteTeamMember: CompanyInvitation;
  linkToInvoice: TransactionPayload;
  lockCompanyUser: CompanyUser;
  /** @deprecated Use registerCompanyBeneficiaries instead */
  markBeneficiaryListAsComplete: Scalars['Boolean']['output'];
  markCardsAsSeen: MarkCardsAsSeenPayload;
  markInvoiceAsPaid: InvoicePayload;
  markInvoiceAsSent: InvoicePayload;
  markQuoteAsAccepted: QuotePayload;
  markQuoteAsSent: QuotePayload;
  nudge: ReferralPayload;
  orderPhysicalPaymentCard: OrderPhysicalPaymentCardPayload;
  /** @deprecated Use OrderVirtualPermanentPaymentCard or OrderVirtualTemporaryPaymentCard instead */
  orderVirtualPaymentCard: CreditCardPayload;
  orderVirtualPermanentPaymentCard: CreditCardPayload;
  orderVirtualTemporaryPaymentCard: CreditCardPayload;
  payInvoice: PayInvoicePayload;
  payPackage: PayPackagePayload;
  paySasuCreation: PayPackagePayload;
  preregisterCheck: CheckDeposit;
  provideCompanyCreationActivitySelection: ProvideCompanyCreationActivitySelectionPayload;
  provideCompanyCreationDocument: ProvideCompanyCreationDocumentPayload;
  provideCompanyFinancialInfo: ProvideCompanyFinancialInfoPayload;
  provideCompanyLegalInfo: ProvideCompanyLegalInfoPayload;
  provideExistingCompanyInformation: ProvideExistingCompanyInformationPayload;
  redeemCompanyInvitation: Maybe<Scalars['Boolean']['output']>;
  refuseAuthenticationDeviceRequest: RefuseAuthenticationDeviceRequestPayload;
  refuseCardPaymentStrongAuthenticationRequest: CardPaymentStrongAuthenticationRequest;
  registerCheck: CheckDeposit;
  registerCompanyBeneficiaries: RegisterCompanyBeneficiariesPayload;
  rejectAuthenticationActionRequest: RejectAuthenticationActionRequestOutput;
  rejectTeamMembersRequest: TeamMembersRequest;
  remindCardLater: CardPayload;
  resetStandardOnboarding: ResetStandardOnboardingPayload;
  revokeDirectDebitMandate: B2BMandatePayload;
  revokePhone: RevokePhoneOutput;
  saveCompanyCreationObjetSocial: SaveCompanyCreationObjetSocialPayload;
  selectCompanySubscriptionPlan: CompanyMetadataPayload;
  selectInternationalTransferRecipient: SelectInternationalTransferRecipientResponse;
  /** @deprecated Use registerCompanyBeneficiaries instead */
  sendAllCompanyInvitations: Scalars['Boolean']['output'];
  sendCompanyInvitation: CompanyInvitationPayload;
  sendDummyInvoice: InvoicePayload;
  sendDummyQuote: QuotePayload;
  sendInvoice: InvoicePayload;
  sendQuote: QuotePayload;
  sendShineAppDownloadSms: Scalars['Boolean']['output'];
  sendTestInvoiceReminder: Scalars['Boolean']['output'];
  setActivationOnboardingCompleted: Scalars['Boolean']['output'];
  setApplicantBasicIdentityInformation: SetApplicantBasicIdentityInformationPayload;
  setApplicantBusinessRole: SetApplicantBusinessRolePayload;
  setApplicantFiscalSituation: SetApplicantFiscalSituationPayload;
  setApplicantIdentityInformation: SetApplicantIdentityInformationPayload;
  setApplicantPersonalAddress: SetApplicantPersonalAddressPayload;
  setApplicantTaxResidencies: SetApplicantTaxResidenciesPayload;
  setCapitalDepositCapitalInfo: SetCapitalDepositCapitalInfoPayload;
  setCapitalDepositCompanyInfo: SetCapitalDepositCompanyInfoPayload;
  setCapitalDepositHasEuropeanBankAccount: SetCapitalDepositHasEuropeanBankAccountPayload;
  setCapitalDepositPartner: SetCapitalDepositPartnerPayload;
  setCompanyCreationCapitalInfo: SetCompanyCreationCapitalInfoPayload;
  setPrimaryApplicantNumberOfShares: SetPrimaryApplicantNumberOfSharesPayload;
  setTaxForecastPeriodAmountPaid: TaxForecastPeriod;
  setTaxForecastPeriodUnpaid: TaxForecastPeriod;
  setUserParentsInformation: SetUserParentsInformationPayload;
  signCheckDepositMandate: CheckDepositMandate;
  startApplication: StartApplicationPayload;
  startCompanyCreationDocumentSignature: StartCompanyCreationDocumentSignaturePayload;
  startIdentityVerification: StartIdentityVerificationPayload;
  startNewOnboarding: CompanyProfilePayload;
  startQes: StartQesPayload;
  submitBankTransferRequest: BankTransferRequest;
  submitBeneficiaryApplication: SubmitBeneficiaryApplicationPayload;
  submitCapitalDeposit: SubmitCapitalDepositPayload;
  submitCapitalDepositKybDocuments: SubmitCapitalDepositKybDocumentsPayload;
  submitCapitalDepositMandatee: SubmitCapitalDepositMandateePayload;
  submitCapitalDepositQuestions: CapitalDeposit;
  submitExpenseClaimRequest: ExpenseClaimRequest;
  submitKycApplication: MetadataPayload;
  submitKyxCheck: Maybe<Scalars['Boolean']['output']>;
  submitMandatedCapitalDepositForAccountHolder: SubmitMandatedCapitalDepositForAccountHolderPayload;
  submitPhysicalPaymentCardOrderRequest: PhysicalPaymentCardOrderRequest;
  submitShineStart: SubmitShineStartPayload;
  submitShineStartQuestions: SubmitShineStartQuestionsPayload;
  submitVirtualBudgetCardOrderRequest: VirtualBudgetCardOrderRequest;
  submitVirtualPaymentCardOrderRequest: VirtualPaymentCardOrderRequest;
  switchToPlan: UpgradeDowngradePayload;
  toggleCardDone: CardPayload;
  toggleTransactionHidden: TransactionPayload;
  trackSegmentEvent: Scalars['Boolean']['output'];
  transitionTeamMemberToRole: TeamMemberAppliedTransitionSummary;
  unblockAuthenticationDevice: UnblockAuthenticationDevicePayload;
  unblockCreditCardPin: CreditCardPayload;
  unlinkFromInvoice: TransactionPayload;
  unlockCompanyUser: CompanyUser;
  unrevokeDirectDebitMandate: B2BMandatePayload;
  updateBankTransferRecipient: BankTransferRecipientPayload;
  updateCapitalDeposit: SubmitCapitalDepositPayload;
  updateCatalogItem: CatalogItemPayload;
  updateClient: ClientPayload;
  updateClientContact: ClientContactPayload;
  updateCompanyInvitationEmailAddress: CompanyInvitationPayload;
  updateCompanyLogo: CompanySettings;
  updateCompanyProfile: CompanyProfilePayload;
  updateCreditCardAlias: CreditCardPayload;
  updateCreditCardDeactivateAt: CreditCardPayload;
  updateCreditCardLimits: CreditCardPayload;
  updateCreditCardOptions: CreditCardPayload;
  updateCreditCardPin: CreditCardPayload;
  updateCreditCardStatus: CreditCardPayload;
  updateInvoice: InvoicePayload;
  updateInvoicePaymentMethods: InvoicePayload;
  updateInvoicePresets: CompanySettings;
  updateKycInformation: KycInformationPayload;
  updateKyxCheckUserInput: KyxCheck;
  updateNotificationToken: UpdateAuthenticationDeviceNotificationTokenPayload;
  updateOnboardingCompanyAddress: UpdateOnboardingCompanyAddressPayload;
  updateOnboardingCompanyProfile: UpdateOnboardingCompanyProfilePayload;
  updateOnboardingCompanySanctionsDeclaration: UpdateOnboardingCompanySanctionsDeclarationPayload;
  updatePaymentCardRenewal: PaymentCardRenewal;
  updatePhysicalPersonLegalBeneficiary: CompanyLegalBeneficiary;
  updateQuote: QuotePayload;
  updateSecondaryBankAccount: BankAccount;
  updateShineRecurringAccountingExportSettings: ShineRecurringAccountingExportSettings;
  updateShineStartDocument: ShineStartDocument;
  updateTaxData: UpdateTaxDataResponse;
  updateTaxForecastProfile: TaxForecastProfile;
  updateTaxResidency: UpdateTaxResidencyPayload;
  updateTransactionNote: TransactionPayload;
  updateUserBankDetails: UserBankDetails;
  updateUserComplianceInfo: UpdateUserComplianceInfoPayload;
  updateUserMetadata: UserMetadataPayload;
  updateUserPersonalInfo: UserProfile;
  updateUserProfile: UserProfilePayload;
  updateUserSettings: UserSettingsPayload;
  updateVatAmount: VatAmount;
  uploadB2BDirectDebitMandate: B2BMandatePayload;
  verifyChallenge: VerifyChallengeOutput;
  verifyReferralCode: VerifyReferralCodePayload;
  verifySensitiveOperation: VerifySensitiveOperationPayload;
};


export type MutationAbortApplicationArgs = {
  input: AbortApplicationInput;
};


export type MutationAcceptAuthenticationDeviceRequestArgs = {
  input: AcceptAuthenticationDeviceRequestInput;
};


export type MutationAcceptCardPaymentStrongAuthenticationRequestArgs = {
  input: AcceptCardPaymentStrongAuthenticationRequestInput;
};


export type MutationActivateCreditCardArgs = {
  input: ActivateCreditCardInput;
};


export type MutationActivatePhysicalPaymentCardArgs = {
  input: ActivatePhysicalPaymentCardInput;
};


export type MutationAddOrReplaceKybDocumentArgs = {
  input: AddOrReplaceKybDocumentInput;
};


export type MutationAgreeToOutstandingAgreementArgs = {
  input: OutstandingAgreementInput;
};


export type MutationAnswerHeardAboutUsArgs = {
  input: AnswerHeardAboutUsInput;
};


export type MutationApproveBankTransferRequestArgs = {
  input: ApproveBankTransferRequestInput;
};


export type MutationApproveExpenseClaimRequestArgs = {
  input: ApproveExpenseClaimRequestInput;
};


export type MutationApprovePhysicalPaymentCardOrderRequestArgs = {
  input: ApprovePhysicalPaymentCardOrderRequestInput;
};


export type MutationApproveVirtualBudgetCardOrderRequestArgs = {
  input: ApproveVirtualBudgetCardOrderRequestInput;
};


export type MutationApproveVirtualPaymentCardOrderRequestArgs = {
  input: ApproveVirtualPaymentCardOrderRequestInput;
};


export type MutationArchiveQuoteArgs = {
  input: ArchiveQuoteInput;
};


export type MutationAskForFreeMonthArgs = {
  input: AskForFreeMonthInput;
};


export type MutationAssignAgreementDocumentArgs = {
  input: OutstandingAgreementInput;
};


export type MutationBlockAuthenticationDeviceArgs = {
  input: BlockAuthenticationDeviceInput;
};


export type MutationCancelAccountClosureArgs = {
  input: AccountClosureCancelInput;
};


export type MutationCancelBankTransferArgs = {
  input: CancelBankTransferInput;
};


export type MutationCancelDowngradeArgs = {
  input: InputMaybe<CancelDowngradeInput>;
};


export type MutationCancelInvoiceArgs = {
  input: CancelInvoiceInput;
};


export type MutationCancelScheduledBankTransferArgs = {
  input: CancelScheduledBankTransferInput;
};


export type MutationCancelTeamMembersRequestArgs = {
  input: CancelTeamMembersRequestInput;
};


export type MutationComputeTaxRatesArgs = {
  input: ComputeTaxRatesInput;
};


export type MutationConfirmAuthenticationActionRequestArgs = {
  input: ConfirmAuthenticationActionRequestInput;
};


export type MutationConvertQuoteToInvoiceArgs = {
  input: ConvertQuoteToInvoiceInput;
};


export type MutationCreateAccountClosureArgs = {
  input: AccountClosureCreationInput;
};


export type MutationCreateAccountingExportArgs = {
  input: CreateAccountingExportInput;
};


export type MutationCreateAccountingRecurringExportArgs = {
  input: CreateAccountingRecurringExportInput;
};


export type MutationCreateBankTransferArgs = {
  input: CreateBankTransferInput;
};


export type MutationCreateBankTransferRecipientArgs = {
  input: CreateBankTransferRecipientInput;
};


export type MutationCreateBankTransferRecipientWithScaArgs = {
  input: CreateBankTransferRecipientInput;
};


export type MutationCreateBulkBankTransferArgs = {
  input: CreateBulkBankTransferInput;
};


export type MutationCreateCapitalDepositAndRelatedEntitiesArgs = {
  input: CreateCapitalDepositInput;
};


export type MutationCreateCatalogItemArgs = {
  input: CreateCatalogItemInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateClientContactArgs = {
  input: CreateClientContactInput;
};


export type MutationCreateCreditApplicationArgs = {
  input: CreateCreditApplicationInput;
};


export type MutationCreateDisputeArgs = {
  input: DisputeCreateInput;
};


export type MutationCreateIntercomConversationArgs = {
  input: CreateIntercomConversationInput;
};


export type MutationCreateInternationalTransferArgs = {
  input: CreateInternationalTransferInput;
};


export type MutationCreateInternationalTransferQuoteArgs = {
  input: CreateInternationalTransferQuoteInput;
  lang: Scalars['String']['input'];
};


export type MutationCreateInternationalTransferRecipientArgs = {
  input: CreateInternationalTransferRecipientInput;
};


export type MutationCreateIntraCompanyFundsTransferArgs = {
  input: CreateIntraCompanyFundsTransferInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateKycDocumentsFromProviderArgs = {
  input: CreateKycDocumentsFromProviderInput;
};


export type MutationCreateKycProviderUserArgs = {
  input: CreateKycProviderUserInput;
};


export type MutationCreateKycReviewDocumentArgs = {
  input: CreateKycReviewDocumentInput;
};


export type MutationCreateMerchantAccountArgs = {
  input: CreateMerchantAccountInput;
};


export type MutationCreatePackagePaymentIntentArgs = {
  input: CreatePackagePaymentIntentInput;
};


export type MutationCreatePhysicalPersonLegalBeneficiaryArgs = {
  input: CreatePhysicalPersonLegalBeneficiarySoloInput;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};


export type MutationCreateReceiptArgs = {
  input: CreateReceiptInput;
};


export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};


export type MutationCreateSasuCreationPaymentIntentArgs = {
  input: CreateSasuCreationPaymentIntentInput;
};


export type MutationCreateScheduledBankTransferArgs = {
  input: CreateScheduledBankTransferInput;
};


export type MutationCreateSecondaryBankAccountArgs = {
  input: CreateSecondaryBankAccountInput;
};


export type MutationCreateShineOneOffAccountingExportSettingsArgs = {
  input: CreateShineOneOffAccountingExportSettingsInput;
};


export type MutationCreateShineRecurringAccountingExportSettingsArgs = {
  input: CreateShineRecurringAccountingExportSettingsInput;
};


export type MutationCreateShineStartAndRelatedEntitiesArgs = {
  input: CreateShineStartInput;
};


export type MutationCreateTaxForecastProfileArgs = {
  input: CreateTaxForecastProfileInput;
};


export type MutationCreateTeamMemberReviewArgs = {
  input: CreateTeamMemberReviewInput;
};


export type MutationCreateUserBankDetailsArgs = {
  input: CreateUserBankDetailsInput;
};


export type MutationCreateVatAmountArgs = {
  input: CreateVatAmountInput;
};


export type MutationDeleteAccountingRecurringExportArgs = {
  input: DeleteAccountingRecurringExportInput;
};


export type MutationDeleteBankTransferRecipientArgs = {
  input: DeleteBankTransferRecipientInput;
};


export type MutationDeleteCatalogItemArgs = {
  input: DeleteCatalogItemInput;
};


export type MutationDeleteClientArgs = {
  input: DeleteClientInput;
};


export type MutationDeleteClientContactArgs = {
  input: DeleteClientContactInput;
};


export type MutationDeleteCompanyCreationDocumentArgs = {
  input: DeleteCompanyCreationDocumentInput;
};


export type MutationDeleteCompanyInvitationArgs = {
  input: DeleteCompanyInvitationInput;
};


export type MutationDeleteInvoiceArgs = {
  input: DeleteInvoiceInput;
};


export type MutationDeleteKybDocumentArgs = {
  input: DeleteKybDocumentInput;
};


export type MutationDeleteKycReviewDocumentArgs = {
  input: DeleteKycReviewDocumentInput;
};


export type MutationDeleteMerchantSandboxAccountArgs = {
  input: DeleteMerchantSandboxAccountInput;
};


export type MutationDeletePhysicalPersonLegalBeneficiaryArgs = {
  input: DeletePhysicalPersonLegalBeneficiarySoloInput;
};


export type MutationDeleteQuoteArgs = {
  input: DeleteQuoteInput;
};


export type MutationDeleteReceiptArgs = {
  input: DeleteReceiptInput;
};


export type MutationDeleteSecondaryBankAccountArgs = {
  bankAccountId: Scalars['ID']['input'];
};


export type MutationDeleteShineRecurringAccountingExportSettingsArgs = {
  input: DeleteShineRecurringAccountingExportSettingsInput;
};


export type MutationDismissActivationOnboardingCardOrderArgs = {
  input: DismissActivationOnboardingCardOrderInput;
};


export type MutationDismissActivationWidgetArgs = {
  input: DismissActivationWidgetInput;
};


export type MutationDuplicateInvoiceArgs = {
  input: InputMaybe<DuplicateInvoiceInput>;
};


export type MutationDuplicateQuoteArgs = {
  input: DuplicateQuoteInput;
};


export type MutationEnrollDeviceArgs = {
  input: EnrollDeviceInput;
};


export type MutationFetchAndAddRegistrationDocumentArgs = {
  input: FetchAndAddRegistrationDocumentInput;
};


export type MutationFinishIdentityVerificationArgs = {
  input: FinishIdentityVerificationInput;
};


export type MutationFinishQesArgs = {
  input: FinishQesInput;
};


export type MutationGenerateOnfidoTokenArgs = {
  mobileEnvironment: InputMaybe<OnfidoEnvironment>;
};


export type MutationGenerateShortLivedTokenArgs = {
  input: GenerateShortLivedTokenInput;
};


export type MutationGetCardDigitizationDataArgs = {
  input: GetCardDigitizationDataInput;
};


export type MutationGetPackagePaymentIntentArgs = {
  input: GetPackagePaymentIntentInput;
};


export type MutationGetSasuCreationPaymentIntentArgs = {
  input: GetSasuCreationPaymentIntentInput;
};


export type MutationGiveLegalBeneficiaryAccessArgs = {
  input: GiveLegalBeneficiaryAccessInput;
};


export type MutationGrantAuthenticationClientArgs = {
  input: GrantAuthenticationClientInput;
};


export type MutationInitSensitiveOperationArgs = {
  input: InitSensitiveOperationInput;
};


export type MutationInviteTeamMemberArgs = {
  input: InviteTeamMemberInput;
};


export type MutationLinkToInvoiceArgs = {
  input: LinkToInvoiceInput;
};


export type MutationLockCompanyUserArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
};


export type MutationMarkBeneficiaryListAsCompleteArgs = {
  input: MarkBeneficiaryListAsCompleteInput;
};


export type MutationMarkCardsAsSeenArgs = {
  input: MarkCardsAsSeenInput;
};


export type MutationMarkInvoiceAsPaidArgs = {
  input: MarkInvoiceAsPaidInput;
};


export type MutationMarkInvoiceAsSentArgs = {
  input: MarkInvoiceAsSentInput;
};


export type MutationMarkQuoteAsAcceptedArgs = {
  input: MarkQuoteAsAcceptedInput;
};


export type MutationMarkQuoteAsSentArgs = {
  input: MarkQuoteAsSentInput;
};


export type MutationNudgeArgs = {
  input: NudgeInput;
};


export type MutationOrderPhysicalPaymentCardArgs = {
  input: OrderPhysicalPaymentCardInput;
};


export type MutationOrderVirtualPaymentCardArgs = {
  input: OrderVirtualPaymentCardInput;
};


export type MutationOrderVirtualPermanentPaymentCardArgs = {
  input: OrderVirtualPermanentPaymentCardInput;
};


export type MutationOrderVirtualTemporaryPaymentCardArgs = {
  input: OrderVirtualTemporaryPaymentCardInput;
};


export type MutationPayInvoiceArgs = {
  input: PayInvoiceInput;
};


export type MutationPayPackageArgs = {
  input: PayPackageInput;
};


export type MutationPaySasuCreationArgs = {
  input: PaySasuCreationInput;
};


export type MutationPreregisterCheckArgs = {
  input: PreregisterCheckInput;
};


export type MutationProvideCompanyCreationActivitySelectionArgs = {
  input: ProvideCompanyCreationActivitySelectionInput;
};


export type MutationProvideCompanyCreationDocumentArgs = {
  input: ProvideCompanyCreationDocumentInput;
};


export type MutationProvideCompanyFinancialInfoArgs = {
  input: ProvideCompanyFinancialInfoInput;
};


export type MutationProvideCompanyLegalInfoArgs = {
  input: ProvideCompanyLegalInfoInput;
};


export type MutationProvideExistingCompanyInformationArgs = {
  input: ProvideExistingCompanyInformationInput;
};


export type MutationRedeemCompanyInvitationArgs = {
  input: RedeemCompanyInvitationInput;
};


export type MutationRefuseAuthenticationDeviceRequestArgs = {
  input: RefuseAuthenticationDeviceRequestInput;
};


export type MutationRefuseCardPaymentStrongAuthenticationRequestArgs = {
  input: RefuseCardPaymentStrongAuthenticationRequestInput;
};


export type MutationRegisterCheckArgs = {
  input: RegisterCheckInput;
};


export type MutationRegisterCompanyBeneficiariesArgs = {
  input: RegisterCompanyBeneficiariesInput;
};


export type MutationRejectAuthenticationActionRequestArgs = {
  input: RejectAuthenticationActionRequestInput;
};


export type MutationRejectTeamMembersRequestArgs = {
  input: RejectTeamMembersRequestInput;
};


export type MutationRemindCardLaterArgs = {
  input: RemindCardLaterInput;
};


export type MutationResetStandardOnboardingArgs = {
  input: ResetStandardOnboardingInput;
};


export type MutationRevokeDirectDebitMandateArgs = {
  input: RevokeDirectDebitMandateInput;
};


export type MutationRevokePhoneArgs = {
  input: RevokePhoneInput;
};


export type MutationSaveCompanyCreationObjetSocialArgs = {
  input: SaveCompanyCreationObjetSocialInput;
};


export type MutationSelectCompanySubscriptionPlanArgs = {
  input: SelectCompanySubscriptionPlanInput;
};


export type MutationSelectInternationalTransferRecipientArgs = {
  input: SelectInternationalTransferRecipientInput;
};


export type MutationSendAllCompanyInvitationsArgs = {
  input: SendAllCompanyInvitationsInput;
};


export type MutationSendCompanyInvitationArgs = {
  input: SendCompanyInvitationInput;
};


export type MutationSendDummyInvoiceArgs = {
  input: SendDummyInvoiceInput;
};


export type MutationSendDummyQuoteArgs = {
  input: SendDummyQuoteInput;
};


export type MutationSendInvoiceArgs = {
  input: SendInvoiceInput;
};


export type MutationSendQuoteArgs = {
  input: SendQuoteInput;
};


export type MutationSendShineAppDownloadSmsArgs = {
  input: SendShineAppDownloadSmsInput;
};


export type MutationSendTestInvoiceReminderArgs = {
  input: SendTestInvoiceReminderInput;
};


export type MutationSetActivationOnboardingCompletedArgs = {
  input: SetActivationOnboardingCompletedInput;
};


export type MutationSetApplicantBasicIdentityInformationArgs = {
  input: SetApplicantBasicIdentityInformationInput;
};


export type MutationSetApplicantBusinessRoleArgs = {
  input: SetApplicantBusinessRoleInput;
};


export type MutationSetApplicantFiscalSituationArgs = {
  input: SetApplicantFiscalSituationInput;
};


export type MutationSetApplicantIdentityInformationArgs = {
  input: SetApplicantIdentityInformationInput;
};


export type MutationSetApplicantPersonalAddressArgs = {
  input: SetApplicantPersonalAddressInput;
};


export type MutationSetApplicantTaxResidenciesArgs = {
  input: SetApplicantTaxResidenciesInput;
};


export type MutationSetCapitalDepositCapitalInfoArgs = {
  input: SetCapitalDepositCapitalInfoInput;
};


export type MutationSetCapitalDepositCompanyInfoArgs = {
  input: SetCapitalDepositCompanyInfoInput;
};


export type MutationSetCapitalDepositHasEuropeanBankAccountArgs = {
  input: SetCapitalDepositHasEuropeanBankAccountInput;
};


export type MutationSetCapitalDepositPartnerArgs = {
  input: SetCapitalDepositPartnerInput;
};


export type MutationSetCompanyCreationCapitalInfoArgs = {
  input: SetCompanyCreationCapitalInfoInput;
};


export type MutationSetPrimaryApplicantNumberOfSharesArgs = {
  input: SetPrimaryApplicantNumberOfSharesInput;
};


export type MutationSetTaxForecastPeriodAmountPaidArgs = {
  input: SetTaxForecastPeriodAmountPaidInput;
};


export type MutationSetTaxForecastPeriodUnpaidArgs = {
  input: SetTaxForecastPeriodUnpaidInput;
};


export type MutationSetUserParentsInformationArgs = {
  input: SetUserParentsInformationInput;
};


export type MutationSignCheckDepositMandateArgs = {
  input: SignCheckDepositMandateInput;
};


export type MutationStartApplicationArgs = {
  input: StartApplicationInput;
};


export type MutationStartCompanyCreationDocumentSignatureArgs = {
  input: StartCompanyCreationDocumentSignatureInput;
};


export type MutationStartIdentityVerificationArgs = {
  input: StartIdentityVerificationInput;
};


export type MutationStartQesArgs = {
  input: StartQesInput;
};


export type MutationSubmitBankTransferRequestArgs = {
  input: SubmitBankTransferRequestInput;
};


export type MutationSubmitBeneficiaryApplicationArgs = {
  input: SubmitBeneficiaryApplicationInput;
};


export type MutationSubmitCapitalDepositArgs = {
  capitalDepositId: Scalars['ID']['input'];
};


export type MutationSubmitCapitalDepositKybDocumentsArgs = {
  input: SubmitCapitalDepositKybDocumentsInput;
};


export type MutationSubmitCapitalDepositMandateeArgs = {
  input: SubmitCapitalDepositMandateeInput;
};


export type MutationSubmitCapitalDepositQuestionsArgs = {
  capitalDepositId: Scalars['ID']['input'];
};


export type MutationSubmitExpenseClaimRequestArgs = {
  input: SubmitExpenseClaimRequestInput;
};


export type MutationSubmitKycApplicationArgs = {
  input: SubmitKycApplicationInput;
};


export type MutationSubmitKyxCheckArgs = {
  input: SubmitKyxCheckInput;
};


export type MutationSubmitMandatedCapitalDepositForAccountHolderArgs = {
  input: SubmitMandatedCapitalDepositForAccountHolderInput;
};


export type MutationSubmitPhysicalPaymentCardOrderRequestArgs = {
  input: SubmitPhysicalPaymentCardOrderRequestInput;
};


export type MutationSubmitShineStartArgs = {
  shineStartId: Scalars['String']['input'];
};


export type MutationSubmitShineStartQuestionsArgs = {
  input: ShineStartQuestionsInput;
};


export type MutationSubmitVirtualBudgetCardOrderRequestArgs = {
  input: SubmitVirtualBudgetCardOrderRequestInput;
};


export type MutationSubmitVirtualPaymentCardOrderRequestArgs = {
  input: SubmitVirtualPaymentCardOrderRequestInput;
};


export type MutationSwitchToPlanArgs = {
  input: SwitchToPlanInput;
};


export type MutationToggleCardDoneArgs = {
  input: ToggleCardDoneInput;
};


export type MutationToggleTransactionHiddenArgs = {
  input: ToggleTransactionHiddenInput;
};


export type MutationTrackSegmentEventArgs = {
  input: TrackSegmentEventInput;
};


export type MutationTransitionTeamMemberToRoleArgs = {
  input: TeamMemberTransitionInput;
};


export type MutationUnblockAuthenticationDeviceArgs = {
  input: UnblockAuthenticationDeviceInput;
};


export type MutationUnblockCreditCardPinArgs = {
  input: UnblockCreditCardPinInput;
};


export type MutationUnlinkFromInvoiceArgs = {
  input: UnlinkFromInvoiceInput;
};


export type MutationUnlockCompanyUserArgs = {
  input: UnlockCompanyUserInput;
};


export type MutationUnrevokeDirectDebitMandateArgs = {
  input: UnrevokeDirectDebitMandateInput;
};


export type MutationUpdateBankTransferRecipientArgs = {
  input: UpdateBankTransferRecipientInput;
};


export type MutationUpdateCapitalDepositArgs = {
  input: UpdateCapitalDepositInput;
};


export type MutationUpdateCatalogItemArgs = {
  input: UpdateCatalogItemInput;
};


export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};


export type MutationUpdateClientContactArgs = {
  input: UpdateClientContactInput;
};


export type MutationUpdateCompanyInvitationEmailAddressArgs = {
  input: UpdateCompanyInvitationEmailInput;
};


export type MutationUpdateCompanyLogoArgs = {
  input: UpdateCompanyLogoInput;
};


export type MutationUpdateCompanyProfileArgs = {
  input: CompanyProfileInput;
};


export type MutationUpdateCreditCardAliasArgs = {
  input: UpdateCreditCardAliasInput;
};


export type MutationUpdateCreditCardDeactivateAtArgs = {
  input: UpdateCreditCardDeactivateAtInput;
};


export type MutationUpdateCreditCardLimitsArgs = {
  input: UpdateCreditCardLimitsInput;
};


export type MutationUpdateCreditCardOptionsArgs = {
  input: UpdateCreditCardOptionsInput;
};


export type MutationUpdateCreditCardPinArgs = {
  input: UpdateCreditCardPinInput;
};


export type MutationUpdateCreditCardStatusArgs = {
  input: UpdateCreditCardStatusInput;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationUpdateInvoicePaymentMethodsArgs = {
  input: UpdateInvoicePaymentMethodsInput;
};


export type MutationUpdateInvoicePresetsArgs = {
  input: UpdateInvoicePresetsInput;
};


export type MutationUpdateKycInformationArgs = {
  input: UserProfileInput;
};


export type MutationUpdateKyxCheckUserInputArgs = {
  input: KyxCheckUserInput;
};


export type MutationUpdateNotificationTokenArgs = {
  input: UpdateNotificationTokenInput;
};


export type MutationUpdateOnboardingCompanyAddressArgs = {
  input: UpdateOnboardingCompanyAddressInput;
};


export type MutationUpdateOnboardingCompanyProfileArgs = {
  input: UpdateOnboardingCompanyProfileInput;
};


export type MutationUpdateOnboardingCompanySanctionsDeclarationArgs = {
  input: UpdateOnboardingCompanySanctionsDeclarationInput;
};


export type MutationUpdatePaymentCardRenewalArgs = {
  input: PaymentCardRenewalUpdateInput;
};


export type MutationUpdatePhysicalPersonLegalBeneficiaryArgs = {
  input: UpdatePhysicalPersonLegalBeneficiarySoloInput;
};


export type MutationUpdateQuoteArgs = {
  input: UpdateQuoteInput;
};


export type MutationUpdateSecondaryBankAccountArgs = {
  input: UpdateSecondaryBankAccountInput;
};


export type MutationUpdateShineRecurringAccountingExportSettingsArgs = {
  input: UpdateShineRecurringAccountingExportSettingsInput;
};


export type MutationUpdateShineStartDocumentArgs = {
  input: UpdateShineStartDocumentInput;
};


export type MutationUpdateTaxDataArgs = {
  input: UpdateTaxDataInput;
};


export type MutationUpdateTaxForecastProfileArgs = {
  input: UpdateTaxForecastProfileInput;
};


export type MutationUpdateTaxResidencyArgs = {
  input: UpdateTaxResidencyInput;
};


export type MutationUpdateTransactionNoteArgs = {
  input: UpdateTransactionNoteInput;
};


export type MutationUpdateUserBankDetailsArgs = {
  input: UpdateUserBankDetailsInput;
};


export type MutationUpdateUserComplianceInfoArgs = {
  input: UpdateUserComplianceInfoInput;
};


export type MutationUpdateUserMetadataArgs = {
  input: UserMetadataInput;
};


export type MutationUpdateUserPersonalInfoArgs = {
  input: UpdateUserPersonalInfoInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UserProfileInput;
};


export type MutationUpdateUserSettingsArgs = {
  input: UserSettingsInput;
};


export type MutationUpdateVatAmountArgs = {
  input: UpdateVatAmountInput;
};


export type MutationUploadB2BDirectDebitMandateArgs = {
  input: B2BMandateInput;
};


export type MutationVerifyChallengeArgs = {
  input: VerifyChallengeInput;
};


export type MutationVerifyReferralCodeArgs = {
  input: VerifyReferralCodeInput;
};


export type MutationVerifySensitiveOperationArgs = {
  input: VerifySensitiveOperationInput;
};

export type NudgeInput = {
  message: Scalars['String']['input'];
  referralId: Scalars['ID']['input'];
};

export type OAuth2Payload = {
  __typename: 'OAuth2Payload';
  access_token: Scalars['String']['output'];
  expires_in: Scalars['Int']['output'];
  id_token: Scalars['String']['output'];
  refresh_token: Maybe<Scalars['String']['output']>;
  scope: Scalars['String']['output'];
  token_type: Scalars['String']['output'];
};

export type OnBoardingProfileInput = {
  profile?: InputMaybe<UserProfileInput>;
};

export type OnBoardingProfilePayload = {
  __typename: 'OnBoardingProfilePayload';
  metadata: Maybe<UserMetadata>;
  profile: Maybe<UserProfile>;
  token: Maybe<Scalars['String']['output']>;
  /** @deprecated Should not be used anymore */
  tokens: Maybe<OAuth2Payload>;
  walletId: Maybe<Scalars['String']['output']>;
};

export type OnboardingCompanyProfile = {
  __typename: 'OnboardingCompanyProfile';
  activity: Maybe<Scalars['String']['output']>;
  activityDetails: Maybe<Scalars['String']['output']>;
  activityType: Scalars['String']['output'];
  applicationId: Scalars['ID']['output'];
  city: Scalars['String']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  country: Scalars['String']['output'];
  hasActivityOutsideEU: Maybe<Scalars['Boolean']['output']>;
  hasClientHelpingCountryUnderSanctions: Maybe<Scalars['Boolean']['output']>;
  hasClientInCountryUnderSanctions: Maybe<Scalars['Boolean']['output']>;
  hasClientUnderSanctions: Maybe<Scalars['Boolean']['output']>;
  hasFinishedProfileSetup: Scalars['Boolean']['output'];
  inseeLegalFormCode: Maybe<Scalars['String']['output']>;
  isComplianceFilled: Scalars['Boolean']['output'];
  isEligibleToOnboard: Scalars['Boolean']['output'];
  isMicro: Scalars['Boolean']['output'];
  legalAnnualTurnOver: Maybe<Scalars['String']['output']>;
  legalForm: Scalars['String']['output'];
  legalName: Scalars['String']['output'];
  legalNumberOfEmployeeRange: Maybe<Scalars['String']['output']>;
  legalNumberOfShares: Maybe<Scalars['Float']['output']>;
  legalRegistrationNumber: Scalars['String']['output'];
  legalShareCapital: Scalars['Float']['output'];
  onboardingCompanyProfileId: Maybe<Scalars['ID']['output']>;
  registrationDate: Scalars['String']['output'];
  street: Scalars['String']['output'];
  streetAddition: Maybe<Scalars['String']['output']>;
  tradeName: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type OnboardingHotline = {
  __typename: 'OnboardingHotline';
  availability: Scalars['String']['output'];
  calendarLink: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  isAvailable: Scalars['Boolean']['output'];
  phoneNumber: Scalars['String']['output'];
};

export enum OnboardingHotlineType {
  CapitalDeposit = 'CAPITAL_DEPOSIT',
  Default = 'DEFAULT',
  Registration = 'REGISTRATION',
  ShineStart = 'SHINE_START',
  Standard = 'STANDARD'
}

export type OnboardingPackage = {
  __typename: 'OnboardingPackage';
  basePrice: Scalars['Int']['output'];
  basePriceWithoutVAT: Scalars['Int']['output'];
  baseTrialPeriod: Scalars['Int']['output'];
  coupon: Maybe<Scalars['String']['output']>;
  discountPrice: Scalars['Int']['output'];
  discountPriceWithoutVAT: Scalars['Int']['output'];
  plan: OnboardingPackagePlan;
  promotionalOffer: Maybe<PromotionalOffer>;
  providerPackageId: Scalars['String']['output'];
  trialPeriod: Scalars['Int']['output'];
  trialPeriodFromOffer: Scalars['Int']['output'];
  trialPeriodFromReferral: Scalars['Int']['output'];
  type: PackageType;
};

export type OnboardingPackagePlan = {
  __typename: 'OnboardingPackagePlan';
  accounting: FeatureType;
  brandColor: Scalars['String']['output'];
  brandName: Scalars['String']['output'];
  customerSupport: FeatureType;
  features: Array<Scalars['String']['output']>;
  fees: PricingPlanFees;
  invoicing: Invoicing;
  limits: PricingPlanLimits;
  paymentCard: FeatureType;
  price: Scalars['Int']['output'];
  priceWithoutVAT: Scalars['Int']['output'];
  providerPlanId: ProviderPlanId;
};

export type OnboardingPlan = {
  __typename: 'OnboardingPlan';
  accounting: FeatureType;
  baseTrialPeriod: Scalars['Int']['output'];
  brandColor: Scalars['String']['output'];
  brandName: Scalars['String']['output'];
  customerSupport: FeatureType;
  features: Array<Scalars['String']['output']>;
  fees: PricingPlanFees;
  invoicing: Invoicing;
  limits: PricingPlanLimits;
  paymentCard: FeatureType;
  price: Scalars['Int']['output'];
  priceWithoutVAT: Scalars['Int']['output'];
  promotionalOffer: Maybe<PromotionalOffer>;
  providerPlanId: ProviderPlanId;
  trialPeriod: Scalars['Int']['output'];
  trialPeriodFromOffer: Scalars['Int']['output'];
  trialPeriodFromReferral: Scalars['Int']['output'];
};

export type OnboardingTimelineWidget = {
  __typename: 'OnboardingTimelineWidget';
  dummy: Maybe<Scalars['String']['output']>;
};

export type OnboardingUser = {
  __typename: 'OnboardingUser';
  firstParent: Maybe<ParentInformation>;
  hasAnsweredHowDidYouHearAboutUs: Scalars['Boolean']['output'];
  haveParentsSameBirthLastName: Maybe<Scalars['Boolean']['output']>;
  onboardingUserId: Scalars['ID']['output'];
  secondParent: Maybe<ParentInformation>;
};

export enum OnfidoEnvironment {
  Development = 'development',
  Distribution = 'distribution',
  Staging = 'staging'
}

export type OnfidoToken = {
  __typename: 'OnfidoToken';
  token: Scalars['String']['output'];
};

export type OrderPhysicalPaymentCardInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  pin?: InputMaybe<Scalars['String']['input']>;
  shouldSendToCompanyAddress?: InputMaybe<Scalars['Boolean']['input']>;
  useNewPhysicalCardOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /**  Deprecated : This field is not used anymore: https://linear.app/shine/issue/CPA-1941/remove-enablednewphysicalorder-feature-flag  */
  useNewVirtualCardOrder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderPhysicalPaymentCardPayload = {
  __typename: 'OrderPhysicalPaymentCardPayload';
  autoOrderedVirtualCardId: Maybe<Scalars['ID']['output']>;
  creditCard: CreditCardInterface;
};

export type OrderVirtualPaymentCardInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  bankAccountId: Scalars['ID']['input'];
  clientOrderIdentifier?: InputMaybe<Scalars['String']['input']>;
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  deactivateAt?: InputMaybe<Scalars['String']['input']>;
  /**  Deprecated : This field is not used anymore: https://linear.app/shine/issue/CPA-1941/remove-enablednewphysicalorder-feature-flag  */
  useNewVirtualCardOrder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderVirtualPermanentPaymentCardInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  bankAccountId: Scalars['ID']['input'];
  clientOrderIdentifier?: InputMaybe<Scalars['String']['input']>;
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  /**  Deprecated : This field is not used anymore: https://linear.app/shine/issue/CPA-1941/remove-enablednewphysicalorder-feature-flag  */
  useNewVirtualCardOrder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderVirtualTemporaryPaymentCardInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  bankAccountId: Scalars['ID']['input'];
  clientOrderIdentifier?: InputMaybe<Scalars['String']['input']>;
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  deactivateAt: Scalars['String']['input'];
  /**  Deprecated : This field is not used anymore: https://linear.app/shine/issue/CPA-1941/remove-enablednewphysicalorder-feature-flag  */
  useNewVirtualCardOrder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OutstandingAgreement = {
  __typename: 'OutstandingAgreement';
  agreementId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  pdfDocumentUrl: Maybe<Scalars['String']['output']>;
  status: OutstandingAgreementStatus;
  type: OutstandingAgreementType;
  updatedAt: Scalars['String']['output'];
};

export type OutstandingAgreementInput = {
  agreementId: Scalars['ID']['input'];
};

export type OutstandingAgreementPayload = {
  __typename: 'OutstandingAgreementPayload';
  outstandingAgreement: Maybe<OutstandingAgreement>;
};

export enum OutstandingAgreementStatus {
  AwaitingSignature = 'AWAITING_SIGNATURE',
  Complete = 'COMPLETE',
  NoAssignedDocument = 'NO_ASSIGNED_DOCUMENT'
}

export enum OutstandingAgreementType {
  Cgu = 'CGU',
  NotaryFourez = 'NOTARY_FOUREZ'
}

export type Package = {
  __typename: 'Package';
  packageId: Scalars['ID']['output'];
  priceWithVAT: Scalars['Float']['output'];
  priceWithVATInMinorCurrencyUnit: Scalars['Int']['output'];
  priceWithoutCouponWithVAT: Maybe<Scalars['Float']['output']>;
  priceWithoutCouponWithoutVAT: Maybe<Scalars['Float']['output']>;
  priceWithoutDiscountWithVAT: Scalars['Float']['output'];
  priceWithoutDiscountWithoutVAT: Scalars['Float']['output'];
  priceWithoutVAT: Scalars['Float']['output'];
};

export enum PackageType {
  CapitalDeposit = 'CAPITAL_DEPOSIT',
  ShineStart = 'SHINE_START'
}

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Maybe<Scalars['String']['output']>;
};

export type ParentInformation = {
  __typename: 'ParentInformation';
  birthLastName: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  isUnknown: Scalars['Boolean']['output'];
  maritalName: Maybe<Scalars['String']['output']>;
};

export type ParentInformationInput = {
  birthLastName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isUnknown: Scalars['Boolean']['input'];
  maritalName?: InputMaybe<Scalars['String']['input']>;
};

export enum ParticularMention {
  VatDoesNotApply = 'VAT_DOES_NOT_APPLY',
  VatExemption = 'VAT_EXEMPTION',
  VatReverseCharge = 'VAT_REVERSE_CHARGE'
}

export type ParticularMentionInput = {
  type: ParticularMention;
};

export type ParticularMentionLocale = {
  __typename: 'ParticularMentionLocale';
  type: ParticularMention;
  valueEn: Scalars['String']['output'];
  valueFr: Scalars['String']['output'];
};

export type PayInvoiceInput = {
  browserInfo: BrowserInfo;
  invoiceId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type PayInvoicePayload = {
  __typename: 'PayInvoicePayload';
  forwardUrl: Maybe<Scalars['String']['output']>;
};

export type PayPackageInput = {
  cardToken?: InputMaybe<Scalars['String']['input']>;
  companyProfileId: Scalars['UUIDv4']['input'];
  coupon?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['ID']['input'];
  paymentIntentId?: InputMaybe<Scalars['String']['input']>;
};

export type PayPackagePayload = {
  __typename: 'PayPackagePayload';
  capitalDeposit: Maybe<CapitalDeposit>;
  shineStart: Maybe<ShineStart>;
};

export type PaySasuCreationInput = {
  cardToken?: InputMaybe<Scalars['String']['input']>;
  companyProfileId: Scalars['UUIDv4']['input'];
  coupon?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['ID']['input'];
  paymentIntentId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentActionsWidget = {
  __typename: 'PaymentActionsWidget';
  dummy: Maybe<Scalars['String']['output']>;
};

export type PaymentCard = PaymentCardInterface & {
  __typename: 'PaymentCard';
  activatedAt: Maybe<Scalars['String']['output']>;
  actualDeliveryAddress: Maybe<Scalars['String']['output']>;
  alias: Maybe<Scalars['String']['output']>;
  bankAccountId: Scalars['ID']['output'];
  cardDesign: Maybe<Scalars['String']['output']>;
  cardProductId: Maybe<CardProductId>;
  countryCode: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  currencyCode: Maybe<Scalars['String']['output']>;
  deactivateAt: Maybe<Scalars['String']['output']>;
  deactivatedAt: Maybe<Scalars['String']['output']>;
  deliveryCity: Maybe<Scalars['String']['output']>;
  deliveryCountry: Maybe<Scalars['String']['output']>;
  deliveryFirstName: Maybe<Scalars['String']['output']>;
  deliveryLastName: Maybe<Scalars['String']['output']>;
  deliveryStreetFirstLine: Maybe<Scalars['String']['output']>;
  deliveryStreetSecondLine: Maybe<Scalars['String']['output']>;
  deliveryZip: Maybe<Scalars['String']['output']>;
  embossedName: Scalars['String']['output'];
  expiresAt: Maybe<Scalars['String']['output']>;
  isDefinitivelyBlocked: Scalars['Boolean']['output'];
  isHidden: Maybe<Scalars['Boolean']['output']>;
  isOptionAtmEnabled: Scalars['Boolean']['output'];
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  isPhysical: Scalars['Boolean']['output'];
  isPinTryExceeded: Scalars['Boolean']['output'];
  issuedAt: Maybe<Scalars['String']['output']>;
  limitAtmDay: Scalars['Float']['output'];
  limitAtmMonth: Scalars['Float']['output'];
  limitAtmWeek: Scalars['Float']['output'];
  limitPaymentAll: Scalars['Float']['output'];
  limitPaymentWeek: Scalars['Float']['output'];
  maskedPan: Scalars['String']['output'];
  paymentCardId: Scalars['ID']['output'];
  pin: Maybe<Scalars['String']['output']>;
  providerCardId: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  shippingDate: Maybe<Scalars['Float']['output']>;
  status: PaymentCardStatus;
  totalAtmDay: Scalars['Float']['output'];
  totalAtmMonth: Scalars['Float']['output'];
  totalAtmWeek: Scalars['Float']['output'];
  totalPaymentAll: Scalars['Float']['output'];
  totalPaymentWeek: Scalars['Float']['output'];
  trackingNumber: Maybe<Scalars['String']['output']>;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type PaymentCardInterface = {
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  activatedAt: Maybe<Scalars['String']['output']>;
  alias: Maybe<Scalars['String']['output']>;
  bankAccountId: Scalars['ID']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  cardDesign: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  cardProductId: Maybe<CardProductId>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  countryCode: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  currencyCode: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deactivateAt: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deactivatedAt: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryCity: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryCountry: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryFirstName: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryLastName: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryStreetFirstLine: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryStreetSecondLine: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryZip: Maybe<Scalars['String']['output']>;
  embossedName: Scalars['String']['output'];
  expiresAt: Maybe<Scalars['String']['output']>;
  isDefinitivelyBlocked: Scalars['Boolean']['output'];
  isHidden: Maybe<Scalars['Boolean']['output']>;
  isOptionAtmEnabled: Scalars['Boolean']['output'];
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  isPhysical: Scalars['Boolean']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  isPinTryExceeded: Maybe<Scalars['Boolean']['output']>;
  issuedAt: Maybe<Scalars['String']['output']>;
  limitAtmDay: Scalars['Float']['output'];
  limitAtmMonth: Scalars['Float']['output'];
  limitAtmWeek: Scalars['Float']['output'];
  limitPaymentAll: Scalars['Float']['output'];
  limitPaymentWeek: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  maskedPan: Maybe<Scalars['String']['output']>;
  paymentCardId: Scalars['ID']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  pin: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  providerCardId: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  providerName: Maybe<Scalars['String']['output']>;
  status: PaymentCardStatus;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  totalAtmDay: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  totalAtmMonth: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  totalAtmWeek: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  totalPaymentAll: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  totalPaymentWeek: Scalars['Float']['output'];
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type PaymentCardOrder = {
  __typename: 'PaymentCardOrder';
  createdAt: Scalars['String']['output'];
  isOrdered: Scalars['Boolean']['output'];
  paymentCardOrderId: Scalars['ID']['output'];
  pin: Scalars['String']['output'];
  shouldSendToCompanyAddress: Scalars['Boolean']['output'];
  /** @deprecated Should not be used anymore */
  uid: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type PaymentCardPayload = {
  __typename: 'PaymentCardPayload';
  paymentCard: Maybe<PaymentCardInterface>;
};

export type PaymentCardPendingOrder = PaymentCardInterface & {
  __typename: 'PaymentCardPendingOrder';
  activatedAt: Maybe<Scalars['String']['output']>;
  alias: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  bankAccountId: Scalars['ID']['output'];
  cardDesign: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  cardProductId: Maybe<CardProductId>;
  countryCode: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  createdAt: Scalars['String']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  currencyCode: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deactivateAt: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deactivatedAt: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryCity: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryCountry: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryFirstName: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryLastName: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryStreetFirstLine: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  deliveryStreetSecondLine: Maybe<Scalars['String']['output']>;
  deliveryZip: Maybe<Scalars['String']['output']>;
  embossedName: Scalars['String']['output'];
  expiresAt: Maybe<Scalars['String']['output']>;
  isDefinitivelyBlocked: Scalars['Boolean']['output'];
  isHidden: Maybe<Scalars['Boolean']['output']>;
  isOptionAtmEnabled: Scalars['Boolean']['output'];
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  isPhysical: Scalars['Boolean']['output'];
  isPinTryExceeded: Maybe<Scalars['Boolean']['output']>;
  issuedAt: Maybe<Scalars['String']['output']>;
  limitAtmDay: Scalars['Float']['output'];
  limitAtmMonth: Scalars['Float']['output'];
  limitAtmWeek: Scalars['Float']['output'];
  limitPaymentAll: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  limitPaymentWeek: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  maskedPan: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  paymentCardId: Scalars['ID']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  pin: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  providerCardId: Maybe<Scalars['String']['output']>;
  providerName: Maybe<Scalars['String']['output']>;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  status: PaymentCardStatus;
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  totalAtmDay: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  totalAtmMonth: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  totalAtmWeek: Scalars['Float']['output'];
  /** @deprecated See: https://linear.app/shine/issue/CPA-1641/as-a-graphql-client-i-want-the-payment-card-interface-to-be-minimal */
  totalPaymentAll: Scalars['Float']['output'];
  totalPaymentWeek: Scalars['Float']['output'];
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export enum PaymentCardProductType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL',
  VirtualBudget = 'VIRTUAL_BUDGET',
  VirtualTemporary = 'VIRTUAL_TEMPORARY'
}

export type PaymentCardRenewal = {
  __typename: 'PaymentCardRenewal';
  createdAt: Maybe<Scalars['String']['output']>;
  kyxCheckId: Maybe<Scalars['String']['output']>;
  nextPaymentCardId: Maybe<Scalars['String']['output']>;
  paymentCardRenewalId: Scalars['ID']['output'];
  previousPaymentCardId: Maybe<Scalars['String']['output']>;
  shouldSendToCompanyAddress: Maybe<Scalars['Boolean']['output']>;
  status: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['String']['output']>;
};

export type PaymentCardRenewalUpdateInput = {
  paymentCardRenewalId: Scalars['ID']['input'];
  shouldSendToCompanyAddress?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum PaymentCardSortBy {
  CreatedAt = 'CREATED_AT',
  LimitPayment = 'LIMIT_PAYMENT',
  OwnerName = 'OWNER_NAME',
  Status = 'STATUS'
}

export enum PaymentCardStatus {
  Active = 'ACTIVE',
  Created = 'CREATED',
  Deactivated = 'DEACTIVATED',
  Inactive = 'INACTIVE',
  Locked = 'LOCKED',
  Lost = 'LOST',
  Stolen = 'STOLEN'
}

export enum PaymentCardStatusFilter {
  Active = 'ACTIVE',
  Created = 'CREATED',
  Deactivated = 'DEACTIVATED',
  Inactive = 'INACTIVE',
  Locked = 'LOCKED'
}

export enum PaymentCardType {
  BasicDebitCard = 'basicDebitCard',
  BusinessWorldDebitCard = 'businessWorldDebitCard'
}

export type PaymentCardsConnection = {
  __typename: 'PaymentCardsConnection';
  edges: Array<PaymentCardsConnectionEdge>;
  pageInfo: PaymentCardsPageInfo;
};

export type PaymentCardsConnectionEdge = {
  __typename: 'PaymentCardsConnectionEdge';
  node: CreditCardInterface;
};

export type PaymentCardsPageInfo = {
  __typename: 'PaymentCardsPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  nextOffset: Maybe<Scalars['Int']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type PaymentIntent = {
  __typename: 'PaymentIntent';
  clientSecret: Scalars['String']['output'];
  paymentIntentId: Maybe<Scalars['ID']['output']>;
  providerType: Maybe<ProviderType>;
  readyForCapture: Maybe<Scalars['Boolean']['output']>;
  setupIntentId: Maybe<Scalars['ID']['output']>;
  status: PaymentIntentStatus;
};

export enum PaymentIntentStatus {
  NotPayable = 'NOT_PAYABLE',
  Payable = 'PAYABLE'
}

export type PaymentMethodStat = {
  __typename: 'PaymentMethodStat';
  card: Maybe<Scalars['Float']['output']>;
  cashDeposit: Maybe<Scalars['Float']['output']>;
  check: Maybe<Scalars['Float']['output']>;
  directDebit: Maybe<Scalars['Float']['output']>;
  instantTransfer: Maybe<Scalars['Float']['output']>;
  internationalTransfer: Maybe<Scalars['Float']['output']>;
  intraCompanyFundsTransfer: Maybe<Scalars['Float']['output']>;
  swiftTransfer: Maybe<Scalars['Float']['output']>;
  transfer: Maybe<Scalars['Float']['output']>;
  walletTransfer: Maybe<Scalars['Float']['output']>;
};

export type PaymentMethodValues = {
  __typename: 'PaymentMethodValues';
  newValue: PaymentMethodStat;
  oldValue: PaymentMethodStat;
};

export type PhoneSupportLink = {
  __typename: 'PhoneSupportLink';
  link: Scalars['String']['output'];
};

export type PhysicalCardInitialLimits = {
  __typename: 'PhysicalCardInitialLimits';
  defaultLimitAtmMonth: Scalars['Int']['output'];
  defaultLimitPaymentWeek: Scalars['Int']['output'];
  isOptionAtmEnabled: Scalars['Boolean']['output'];
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  maxLimitAtmMonth: Scalars['Int']['output'];
  maxLimitPaymentWeek: Scalars['Int']['output'];
};

export type PhysicalPaymentCardOrderRequest = {
  __typename: 'PhysicalPaymentCardOrderRequest';
  cancelReason: Maybe<TeamMembersRequestCancelReason>;
  cancelledAt: Maybe<Scalars['String']['output']>;
  companyProfileId: Scalars['UUIDv4']['output'];
  completedAt: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  requester: Maybe<TeamMembersRequestRequester>;
  requesterCompanyUserId: Scalars['UUIDv4']['output'];
  requesterPayload: PhysicalPaymentCardOrderRequestRequesterPayload;
  reviewedAt: Maybe<Scalars['String']['output']>;
  reviewer: Maybe<TeamMembersRequestReviewer>;
  reviewerCompanyUserId: Maybe<Scalars['UUIDv4']['output']>;
  reviewerPayload: Maybe<PhysicalPaymentCardOrderRequestReviewerPayload>;
  status: TeamMembersRequestStatus;
  teamMembersRequestId: Scalars['UUIDv4']['output'];
  type: TeamMembersRequestType;
  updatedAt: Scalars['String']['output'];
};

export type PhysicalPaymentCardOrderRequestRequesterPayload = {
  __typename: 'PhysicalPaymentCardOrderRequestRequesterPayload';
  bankAccountId: Scalars['ID']['output'];
};

export type PhysicalPaymentCardOrderRequestReviewerPayload = {
  __typename: 'PhysicalPaymentCardOrderRequestReviewerPayload';
  bankAccountId: Scalars['ID']['output'];
  isOptionAtmEnabled: Scalars['Boolean']['output'];
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  limitAtmMonth: Scalars['Float']['output'];
  limitPaymentWeek: Scalars['Float']['output'];
};

export type Plan = {
  __typename: 'Plan';
  chargebeePlanId: Maybe<Scalars['String']['output']>;
  coupon: Maybe<Coupon>;
  package: Maybe<Package>;
  planId: Scalars['ID']['output'];
  priceWithVAT: Scalars['Float']['output'];
  priceWithoutVAT: Scalars['Float']['output'];
  promotionalOffer: Maybe<PromotionalOffer>;
  trialPeriod: Maybe<Scalars['Int']['output']>;
  trialPeriodUnit: Maybe<PlanTrialPeriodUnit>;
  trialPeriodWithoutDiscount: Maybe<Scalars['Int']['output']>;
};

export enum PlanTrialPeriodUnit {
  Day = 'DAY',
  Month = 'MONTH'
}

export enum PlansVariant {
  Solo = 'SOLO',
  Team = 'TEAM'
}

export type PreregisterCheckInput = {
  amount: Scalars['Float']['input'];
  bankAccountId: Scalars['String']['input'];
  cmc7: Scalars['String']['input'];
  companyProfileId: Scalars['String']['input'];
  companyUserId: Scalars['String']['input'];
  drawerName: Scalars['String']['input'];
  firebaseBucketName: Scalars['String']['input'];
  firebaseFileRef: Scalars['String']['input'];
  issuedAt: Scalars['String']['input'];
  rlmc: Scalars['String']['input'];
};

export type PricingPlan = {
  __typename: 'PricingPlan';
  accounting: FeatureType;
  basePrice: Scalars['Int']['output'];
  brandColor: Scalars['String']['output'];
  brandName: Scalars['String']['output'];
  customerSupport: FeatureType;
  downgradablePlanIds: Array<ProviderPlanId>;
  downgradablePlans: Array<PricingPlan>;
  features: Array<Feature>;
  fees: PricingPlanFees;
  insurances: Insurances;
  invoicing: Invoicing;
  limits: PricingPlanLimits;
  paymentCard: FeatureTypePaymentCard;
  plansWithHigherLimit: Array<PricingPlan>;
  price: Scalars['Int']['output'];
  priceWithoutVAT: Scalars['Int']['output'];
  providerPlanId: ProviderPlanId;
  upgradablePlanIds: Array<ProviderPlanId>;
  upgradablePlans: Array<PricingPlan>;
  upgradablePlansWithFeatureAvailable: Array<PricingPlan>;
};


export type PricingPlanPlansWithHigherLimitArgs = {
  limitName: Scalars['String']['input'];
};


export type PricingPlanUpgradablePlansWithFeatureAvailableArgs = {
  feature: Feature;
};

export type PricingPlanFee = {
  __typename: 'PricingPlanFee';
  fixed: Scalars['Int']['output'];
  minimum: Maybe<Scalars['Float']['output']>;
  variable: Maybe<Scalars['Float']['output']>;
};

export type PricingPlanFees = {
  __typename: 'PricingPlanFees';
  accountIrregularUse: PricingPlanFee;
  additionalCheckDeposit: PricingPlanFee;
  additionalPhysicalPaymentCard: PricingPlanFee;
  additionalPhysicalPaymentCardMonth: PricingPlanFee;
  additionalSepaTransfer: PricingPlanFee;
  additionalVirtualCardMonth: PricingPlanFee;
  additionalWithdrawalEuro: PricingPlanFee;
  additionalWithdrawalInter: PricingPlanFee;
  additionalWithdrawalNonEuro: PricingPlanFee;
  balanceLetterConfirmation: PricingPlanFee;
  bankTransferCertificate: PricingPlanFee;
  cardPayinEuro: PricingPlanFee;
  cardPayinInter: PricingPlanFee;
  cardPayinNonEuro: PricingPlanFee;
  cardPaymentDispute: PricingPlanFee;
  cardPayoutEuro: PricingPlanFee;
  cardPayoutInter: PricingPlanFee;
  cardPayoutNonEuro: PricingPlanFee;
  cashDeposit: PricingPlanFee;
  checkDepositInvalid: PricingPlanFee;
  checkDepositRefused: PricingPlanFee;
  downgradeCost: PricingPlanFee;
  extraSeatMonth: PricingPlanFee;
  internationalTransferPayout: PricingPlanFee;
  otherCertificate: PricingPlanFee;
  swiftTransferPayin: PricingPlanFee;
};

export type PricingPlanLimit = {
  __typename: 'PricingPlanLimit';
  isUnlimited: Scalars['Boolean']['output'];
  value: Scalars['Int']['output'];
};

export type PricingPlanLimits = {
  __typename: 'PricingPlanLimits';
  activeBankAccounts: PricingPlanLimit;
  cashDepositMaxAmountPerDeposit: PricingPlanLimit;
  cashDepositMaxAmountPerMonth: PricingPlanLimit;
  cashDepositMonth: PricingPlanLimit;
  checkDepositMonth: PricingPlanLimit;
  companyUserAccess: PricingPlanLimit;
  physicalPaymentCard: PricingPlanLimit;
  sepaTransferMonth: PricingPlanLimit;
  transfersPerBulk: PricingPlanLimit;
  virtualPaymentCardMonth: PricingPlanLimit;
  withdrawalMonth: PricingPlanLimit;
};

export type PrismicRichText = {
  __typename: 'PrismicRichText';
  spans: Maybe<Array<PrismicRichTextSpan>>;
  text: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type PrismicRichTextSpan = {
  __typename: 'PrismicRichTextSpan';
  data: Maybe<PrismicRichTextSpanData>;
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type PrismicRichTextSpanData = {
  __typename: 'PrismicRichTextSpanData';
  label: Maybe<Scalars['String']['output']>;
  link_type: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type PromotionalOffer = {
  __typename: 'PromotionalOffer';
  affiliateLogoSource: Maybe<Scalars['String']['output']>;
  affiliateName: Scalars['String']['output'];
  promotionalOfferId: Scalars['String']['output'];
};

export type ProvideCompanyCreationActivitySelectionInput = {
  activities: Array<CompanyCreationProvidedActivity>;
  applicationId: Scalars['ID']['input'];
};

export type ProvideCompanyCreationActivitySelectionPayload = {
  __typename: 'ProvideCompanyCreationActivitySelectionPayload';
  application: Application;
};

export type ProvideCompanyCreationDocumentInput = {
  applicationId: Scalars['ID']['input'];
  fileBucketName: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
  type: CompanyCreationDocumentType;
};

export type ProvideCompanyCreationDocumentPayload = {
  __typename: 'ProvideCompanyCreationDocumentPayload';
  companyCreationDocuments: Array<CompanyCreationDocument>;
};

export type ProvideCompanyFinancialInfoInput = {
  id: Scalars['ID']['input'];
  legalAnnualTurnOver: Scalars['String']['input'];
  legalNumberOfEmployeeRange: Scalars['String']['input'];
  legalShareCapital?: InputMaybe<Scalars['Int']['input']>;
};

export type ProvideCompanyFinancialInfoPayload = {
  __typename: 'ProvideCompanyFinancialInfoPayload';
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export type ProvideCompanyLegalInfoInput = {
  activity: CompanyActivityInput;
  address: CompanyAddressInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  inseeLegalFormCode: Scalars['String']['input'];
  isMicro?: InputMaybe<Scalars['Boolean']['input']>;
  legalName: Scalars['String']['input'];
  legalRegistrationNumber: Scalars['String']['input'];
  registrationDate: Scalars['String']['input'];
  tradeName?: InputMaybe<Scalars['String']['input']>;
};

export type ProvideCompanyLegalInfoPayload = {
  __typename: 'ProvideCompanyLegalInfoPayload';
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export type ProvideExistingCompanyInformationInput = {
  activityDetails?: InputMaybe<Scalars['String']['input']>;
  activityType: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  country: Scalars['String']['input'];
  inseeLegalFormCode?: InputMaybe<Scalars['String']['input']>;
  isMicro: Scalars['Boolean']['input'];
  legalAnnualTurnOver?: InputMaybe<Scalars['String']['input']>;
  legalForm?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  legalNumberOfEmployeeRange: Scalars['String']['input'];
  legalRegistrationNumber: Scalars['String']['input'];
  legalShareCapital: Scalars['Float']['input'];
  registrationDate: Scalars['String']['input'];
  street: Scalars['String']['input'];
  streetAddition?: InputMaybe<Scalars['String']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type ProvideExistingCompanyInformationPayload = {
  __typename: 'ProvideExistingCompanyInformationPayload';
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export enum ProviderPlanId {
  Basic = 'basic',
  Business = 'business',
  MoralPersonBasic = 'moral_person_basic',
  MoralPersonBusiness = 'moral_person_business',
  MoralPersonPremium = 'moral_person_premium',
  PhysicalPersonBasic = 'physical_person_basic',
  PhysicalPersonPremium = 'physical_person_premium',
  Plus = 'plus',
  Pro = 'pro'
}

export enum ProviderType {
  Hipay = 'HIPAY',
  Stripe = 'STRIPE'
}

export enum QesStatus {
  AwaitingValidation = 'AWAITING_VALIDATION',
  Ineligible = 'INELIGIBLE',
  Invalid = 'INVALID',
  NotNeeded = 'NOT_NEEDED',
  Signed = 'SIGNED',
  Todo = 'TODO'
}

export type Query = {
  __typename: 'Query';
  accountClosure: Maybe<AccountClosure>;
  actionRequest: ActionRequest;
  authenticationDeviceRequest: AuthenticationDeviceRequest;
  bankAccount: BankAccount;
  bankTransfer: BankTransfer;
  /** @deprecated Use Company.bankTransferRecipient instead */
  bankTransferRecipient: BankTransferRecipient;
  bankTransferRequest: BankTransferRequest;
  bankingPlanQuotas: BankingPlanQuotas;
  basicCardMonthlyFee: Package;
  bulkBankTransferFileParseResult: BulkBankTransferFileParseResult;
  canAddCardToGPay: Scalars['Boolean']['output'];
  canSendMoneyInstantly: Scalars['Boolean']['output'];
  canUseNewDeviceManagement: Scalars['Boolean']['output'];
  canUseSecurityWithStrongAuthentication: Scalars['Boolean']['output'];
  card: Card;
  cardPaymentStrongAuthenticationRequests: Array<CardPaymentStrongAuthenticationRequest>;
  cardSecurityDetails: CardSecurityDetails;
  cashDepositCompanyMonthlyHistory: CashDepositCompanyMonthlyHistoryResult;
  cashDepositLimits: CashDepositLimitsResult;
  cashflowManagementDashboard: Maybe<CashflowManagementDashboard>;
  checkDepositMandate: Maybe<CheckDepositMandate>;
  client: Client;
  companyCreationAvailableActivities: CompanyCreationAvailableActivities;
  companyCreationObjetSocialTemplate: Scalars['String']['output'];
  /** @deprecated Use Company.legalBeneficiaries instead */
  companyLegalBeneficiaries: Array<CompanyLegalBeneficiary>;
  coupon: Coupon;
  creditCardTotals: CreditCardInterface;
  creditFees: CreditFees;
  creditLimits: CreditLimits;
  /** @deprecated No longer supported and will be removed in app version 1.208.0 */
  creditRates: Array<CreditRate>;
  creditRatesByDuration: Array<CreditRateByDuration>;
  creditReasons: Array<CreditReason>;
  creditThresholdsForCashflow: CreditThresholdsForCashflow;
  directDebitMandate: DirectDebitMandate;
  expenseClaimRequest: ExpenseClaimRequest;
  externalCompanyLookup: Array<ExternalCompany>;
  getAuthenticationClient: AuthenticationClientOutput;
  getChallengeMethod: GetChallengeMethodOutput;
  getDevice: GetDeviceOutput;
  getDeviceList: GetDeviceListOutput;
  getDeviceState: GetDeviceStateOutput;
  getDeviceWithActivities: GetDeviceWithActivitiesOutput;
  getMobileDeviceAuthenticatorDetails: Maybe<GetMobileDeviceAuthenticatorDetailsOutput>;
  helpContactTree: Array<HelpContactReason>;
  helpLinks: HelpLinks;
  initiateCashDeposit: InitiateCashDepositResult;
  insurance: Insurance;
  internationalTransferCurrencies: Array<InternationalTransferCurrency>;
  internationalTransferRecipientNetworkRequirements: Array<InternationalTransferRecipientRequirements>;
  internationalTransferRecipients: Array<InternationalTransferRecipient>;
  internationalTransferRequirements: Array<InternationalTransferRequirements>;
  invoice: Invoice;
  invoiceMinimumLateFee: Scalars['Float']['output'];
  invoicePdf: InvoicingPdf;
  invoiceSearch: Array<Invoice>;
  invoiceSuggestions: Array<Invoice>;
  invoicingSpecialMentions: InvoicingSpecialMentions;
  isAccountClosable: AccountClosableResult;
  kycReviewDocument: Maybe<KycReviewDocument>;
  kycReviewDocuments: Array<KycReviewDocument>;
  kyxCheck: KyxCheck;
  kyxChecks: Array<KyxCheck>;
  newCardPrice: Package;
  newPremiumCardPrice: Package;
  newVirtualCardPrice: Package;
  /** Onboarding hotline contact information */
  onboardingHotline: Maybe<OnboardingHotline>;
  physicalCardInitialLimits: PhysicalCardInitialLimits;
  plan: Plan;
  planWithAddon: Plan;
  plansWithAddon: Array<Plan>;
  premiumCardMonthlyFee: Package;
  pricingPlan: PricingPlan;
  quote: Quote;
  quotePdf: InvoicingPdf;
  receipt: Receipt;
  /** Get the recipient bank details from an IBAN */
  recipientBankDetails: RecipientBankDetails;
  shineRecurringAccountingExportSetting: ShineRecurringAccountingExportSettings;
  shineStartDocument: ShineStartDocument;
  shouldAskSanctionsDeclarationQuestions: Scalars['Boolean']['output'];
  subscriptionInvoice: SubscriptionInvoice;
  taxForecastPeriod: TaxForecastPeriod;
  toolboxCollection: Maybe<ToolboxCollection>;
  toolboxCollections: ToolboxCollectionConnection;
  toolboxCollectionsCount: ViewerTotalCount;
  toolboxHighlightedOffers: ToolboxOfferConnection;
  toolboxIntegration: Maybe<ToolboxIntegration>;
  toolboxIntegrations: ToolboxIntegrationConnection;
  toolboxIntegrationsCount: ViewerTotalCount;
  toolboxOffer: Maybe<ToolboxOffer>;
  toolboxOffers: ToolboxOfferConnection;
  toolboxOffersCount: ViewerTotalCount;
  toolboxTags: Array<ToolboxTag>;
  transaction: Transaction;
  transactionCategoriesMcc: Array<TransactionCategoryMcc>;
  transactionsSum: Scalars['Float']['output'];
  viewer: Viewer;
  /** @deprecated Use virtualPermanentCardInitialLimits or virtualTemporaryCardInitialLimits instead */
  virtualCardInitialLimits: VirtualCardInitialLimits;
  virtualCardQuotas: SubscriptionQuota;
  virtualPermanentCardInitialLimits: VirtualPermanentCardInitialLimits;
  virtualTemporaryCardInitialLimits: VirtualTemporaryCardInitialLimits;
};


export type QueryAccountClosureArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type QueryActionRequestArgs = {
  authenticationActionRequestId: Scalars['ID']['input'];
};


export type QueryAuthenticationDeviceRequestArgs = {
  authenticationDeviceId: Scalars['ID']['input'];
  authenticationDeviceRequestId: Scalars['ID']['input'];
  phone: Scalars['ID']['input'];
};


export type QueryBankAccountArgs = {
  bankAccountId: Scalars['ID']['input'];
};


export type QueryBankTransferArgs = {
  bankTransferId: Scalars['ID']['input'];
};


export type QueryBankTransferRecipientArgs = {
  bankTransferRecipientId: Scalars['ID']['input'];
};


export type QueryBankTransferRequestArgs = {
  teamMembersRequestId: Scalars['UUIDv4']['input'];
};


export type QueryBankingPlanQuotasArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type QueryBasicCardMonthlyFeeArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type QueryBulkBankTransferFileParseResultArgs = {
  input: BulkBankTransferFileParseResultInput;
};


export type QueryCanSendMoneyInstantlyArgs = {
  amount: Scalars['Float']['input'];
  bankAccountId: Scalars['ID']['input'];
  deviceToken: Scalars['String']['input'];
  recipientBic: Scalars['String']['input'];
  recipientIban: Scalars['String']['input'];
};


export type QueryCardArgs = {
  cardId: Scalars['ID']['input'];
};


export type QueryCardSecurityDetailsArgs = {
  creditCardId: Scalars['ID']['input'];
};


export type QueryCashDepositCompanyMonthlyHistoryArgs = {
  input: CashDepositCompanyMonthlyHistoryInput;
};


export type QueryCashDepositLimitsArgs = {
  input: CashDepositLimitsInput;
};


export type QueryCashflowManagementDashboardArgs = {
  dashboardInput: CashflowManagementDashboardInput;
};


export type QueryCheckDepositMandateArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type QueryClientArgs = {
  clientId: Scalars['ID']['input'];
};


export type QueryCompanyCreationAvailableActivitiesArgs = {
  applicationId: Scalars['ID']['input'];
};


export type QueryCompanyCreationObjetSocialTemplateArgs = {
  applicationId: Scalars['UUIDv4']['input'];
};


export type QueryCompanyLegalBeneficiariesArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type QueryCouponArgs = {
  addonId: InputMaybe<Scalars['String']['input']>;
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  couponId: Scalars['ID']['input'];
};


export type QueryCreditCardTotalsArgs = {
  creditCardId: Scalars['ID']['input'];
};


export type QueryCreditFeesArgs = {
  amount: InputMaybe<Scalars['Float']['input']>;
  duration: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCreditLimitsArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type QueryCreditRatesByDurationArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type QueryDirectDebitMandateArgs = {
  directDebitMandateId: Scalars['ID']['input'];
};


export type QueryExpenseClaimRequestArgs = {
  teamMembersRequestId: Scalars['UUIDv4']['input'];
};


export type QueryExternalCompanyLookupArgs = {
  isOwnCompanyLookup: InputMaybe<Scalars['Boolean']['input']>;
  q: Scalars['String']['input'];
};


export type QueryGetAuthenticationClientArgs = {
  clientId: Scalars['ID']['input'];
};


export type QueryGetDeviceArgs = {
  input: GetDeviceInput;
};


export type QueryGetDeviceListArgs = {
  input: GetDeviceListInput;
};


export type QueryGetDeviceStateArgs = {
  input: GetDeviceStateInput;
};


export type QueryGetDeviceWithActivitiesArgs = {
  input: GetDeviceWithActivitiesInput;
};


export type QueryInitiateCashDepositArgs = {
  input: InitiateCashDepositInput;
};


export type QueryInternationalTransferCurrenciesArgs = {
  lang: Scalars['String']['input'];
};


export type QueryInternationalTransferRecipientNetworkRequirementsArgs = {
  input: InternationalTransferRecipientNetworkRequirementsInput;
};


export type QueryInternationalTransferRecipientsArgs = {
  input: InternationalTransferRecipientsInput;
};


export type QueryInternationalTransferRequirementsArgs = {
  input: InternationalTransferRequirementsInput;
};


export type QueryInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type QueryInvoicePdfArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type QueryInvoiceSearchArgs = {
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  searchInput: Scalars['String']['input'];
  status: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryInvoiceSuggestionsArgs = {
  transactionId: Scalars['ID']['input'];
};


export type QueryIsAccountClosableArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type QueryKycReviewDocumentArgs = {
  filters: KycReviewDocumentFilters;
};


export type QueryKycReviewDocumentsArgs = {
  filters: KycReviewDocumentsFilters;
};


export type QueryKyxCheckArgs = {
  kyxCheckId: Scalars['ID']['input'];
};


export type QueryKyxChecksArgs = {
  filters: KyxChecksFilters;
};


export type QueryNewCardPriceArgs = {
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
};


export type QueryNewPremiumCardPriceArgs = {
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  forceFeePayment?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNewVirtualCardPriceArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
  forceFeePayment?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOnboardingHotlineArgs = {
  onboardingHotlineType: InputMaybe<OnboardingHotlineType>;
};


export type QueryPhysicalCardInitialLimitsArgs = {
  paymentCardType: PaymentCardType;
};


export type QueryPlanArgs = {
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  planId: Scalars['ID']['input'];
};


export type QueryPlanWithAddonArgs = {
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  couponId: InputMaybe<Scalars['ID']['input']>;
  planId: Scalars['ID']['input'];
};


export type QueryPlansWithAddonArgs = {
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
};


export type QueryPremiumCardMonthlyFeeArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
  forceFeePayment?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPricingPlanArgs = {
  providerPlanId: ProviderPlanId;
};


export type QueryQuoteArgs = {
  quoteId: Scalars['ID']['input'];
};


export type QueryQuotePdfArgs = {
  quoteId: Scalars['ID']['input'];
};


export type QueryReceiptArgs = {
  receiptId: Scalars['ID']['input'];
};


export type QueryRecipientBankDetailsArgs = {
  iban: Scalars['String']['input'];
};


export type QueryShineRecurringAccountingExportSettingArgs = {
  accountingExportSettingsId: Scalars['ID']['input'];
};


export type QueryShineStartDocumentArgs = {
  shineStartDocumentId: Scalars['ID']['input'];
  shineStartId: Scalars['ID']['input'];
};


export type QueryShouldAskSanctionsDeclarationQuestionsArgs = {
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
};


export type QuerySubscriptionInvoiceArgs = {
  subscriptionId: Scalars['ID']['input'];
  subscriptionInvoiceId: Scalars['ID']['input'];
};


export type QueryTaxForecastPeriodArgs = {
  taxForecastPeriodId: Scalars['ID']['input'];
};


export type QueryToolboxCollectionArgs = {
  toolboxCollectionId: Scalars['ID']['input'];
};


export type QueryToolboxCollectionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QueryToolboxIntegrationArgs = {
  toolboxIntegrationId: Scalars['ID']['input'];
};


export type QueryToolboxIntegrationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<ToolboxIntegrationsFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QueryToolboxIntegrationsCountArgs = {
  filters: InputMaybe<ToolboxIntegrationsCountFilters>;
};


export type QueryToolboxOfferArgs = {
  toolboxOfferId: Scalars['ID']['input'];
};


export type QueryToolboxOffersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<ToolboxOffersFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type QueryToolboxOffersCountArgs = {
  filters: InputMaybe<ToolboxOffersCountFilters>;
};


export type QueryToolboxTagsArgs = {
  type: InputMaybe<ToolboxOfferType>;
};


export type QueryTransactionArgs = {
  transactionId: Scalars['ID']['input'];
};


export type QueryTransactionsSumArgs = {
  filters: TransactionsSumFilters;
};


export type QueryVirtualCardQuotasArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};

export enum QuotaType {
  Cards = 'CARDS',
  CashDeposits = 'CASH_DEPOSITS',
  CheckDeposits = 'CHECK_DEPOSITS',
  Transfers = 'TRANSFERS',
  VirtualCards = 'VIRTUAL_CARDS',
  Withdrawal = 'WITHDRAWAL'
}

export type Quote = {
  __typename: 'Quote';
  client: InvoiceClient;
  clientContactIds: Array<Scalars['ID']['output']>;
  clientId: Scalars['ID']['output'];
  company: InvoiceCompany;
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  fiscalId: Scalars['String']['output'];
  generatedInvoiceId: Maybe<Scalars['ID']['output']>;
  isUserCcedOnEmails: Scalars['Boolean']['output'];
  lateFeePercentage: Scalars['Float']['output'];
  lateMaxDays: Scalars['Float']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  particularMentions: Array<ParticularMentionLocale>;
  paymentMentions: Array<QuotePaymentMentionLocale>;
  pdf: Maybe<InvoicingPdf>;
  quoteId: Scalars['ID']['output'];
  settings: InvoiceSettings;
  startAt: Scalars['Float']['output'];
  status: QuoteStatus;
  statusAt: Scalars['String']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  tasks: Array<InvoiceTask>;
  title: Scalars['String']['output'];
  totalAmountAfterTaxes: Scalars['Float']['output'];
  totalAmountBeforeTaxes: Scalars['Float']['output'];
  totalVatAmount: Scalars['Float']['output'];
  type: InvoiceType;
  updatedAt: Scalars['Float']['output'];
  user: InvoiceUser;
};

export type QuoteEdge = {
  __typename: 'QuoteEdge';
  cursor: Scalars['String']['output'];
  node: Quote;
};

export type QuotePayload = {
  __typename: 'QuotePayload';
  quote: Quote;
};

export enum QuotePaymentMention {
  QuoteB2CLatePayment = 'QUOTE_B2C_LATE_PAYMENT',
  QuoteLatePayment = 'QUOTE_LATE_PAYMENT'
}

export type QuotePaymentMentionInput = {
  type: QuotePaymentMention;
};

export type QuotePaymentMentionLocale = {
  __typename: 'QuotePaymentMentionLocale';
  type: QuotePaymentMention;
  valueEn: Scalars['String']['output'];
  valueFr: Scalars['String']['output'];
};

export type QuoteSettingsInput = {
  displayTradeName?: InputMaybe<Scalars['Boolean']['input']>;
  issuerEmail?: InputMaybe<Scalars['String']['input']>;
  legalMentions?: InputMaybe<Array<Scalars['String']['input']>>;
  quoteValidityDays?: InputMaybe<Scalars['Int']['input']>;
};

export enum QuoteStatus {
  Accepted = 'ACCEPTED',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
  Sent = 'SENT'
}

export type QuotesFilters = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<QuoteStatus>>;
};

export type QuotesStatus = {
  __typename: 'QuotesStatus';
  accepted: InvoicesStatusMetrics;
  archived: InvoicesStatusMetrics;
  pending: InvoicesStatusMetrics;
  sent: InvoicesStatusMetrics;
};

export type RawSelectedActivities = {
  __typename: 'RawSelectedActivities';
  answers: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
};

export type Receipt = {
  __typename: 'Receipt';
  category: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  processing: Scalars['Boolean']['output'];
  receiptId: Scalars['ID']['output'];
  sellerName: Scalars['String']['output'];
  total: Scalars['Float']['output'];
  transactionAt: Scalars['Float']['output'];
  transactionId: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['Float']['output'];
  url: Scalars['String']['output'];
  vatAmount: Maybe<VatAmount>;
};

export type ReceiptPayload = {
  __typename: 'ReceiptPayload';
  receipt: Maybe<Receipt>;
};

export type Recipient = {
  __typename: 'Recipient';
  bankTransferRecipientId: Maybe<Scalars['ID']['output']>;
  iban: Scalars['String']['output'];
  name: Scalars['String']['output'];
  swiftBic: Scalars['String']['output'];
};

export type RecipientBankDetails = {
  __typename: 'RecipientBankDetails';
  bic: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type RedeemCompanyInvitationInput = {
  slug: Scalars['String']['input'];
};

export type Referral = {
  __typename: 'Referral';
  createdAt: Scalars['String']['output'];
  displayedStatus: ReferralDisplayedStatus;
  nudgeMessage: Maybe<Scalars['String']['output']>;
  nudgedAt: Maybe<Scalars['String']['output']>;
  referralCodeId: Scalars['ID']['output'];
  referralId: Scalars['ID']['output'];
  referreePhone: Scalars['String']['output'];
  referreeRewardAppliedAt: Maybe<Scalars['String']['output']>;
  referreeUid: Maybe<Scalars['ID']['output']>;
  referreeUser: Maybe<ReferreeUser>;
  referrerCompanyUserId: Scalars['ID']['output'];
  referrerRewardAppliedAt: Maybe<Scalars['String']['output']>;
  referrerRewardProviderTransactionId: Maybe<Scalars['ID']['output']>;
  status: ReferralStatus;
  updatedAt: Scalars['String']['output'];
};

export type ReferralCode = {
  __typename: 'ReferralCode';
  createdAt: Scalars['String']['output'];
  referralCode: Scalars['String']['output'];
  referralCodeId: Scalars['ID']['output'];
  referrerUid: Scalars['ID']['output'];
};

export enum ReferralDisplayedStatus {
  AccountOpened = 'ACCOUNT_OPENED',
  MaxReferrerRewardsReached = 'MAX_REFERRER_REWARDS_REACHED',
  NoTransaction = 'NO_TRANSACTION',
  ReferrerRewarded = 'REFERRER_REWARDED',
  Sleeping = 'SLEEPING',
  UserCreated = 'USER_CREATED'
}

export type ReferralPayload = {
  __typename: 'ReferralPayload';
  referral: Maybe<Referral>;
};

export enum ReferralStatus {
  AccountOpened = 'ACCOUNT_OPENED',
  MaxReferrerRewardsReached = 'MAX_REFERRER_REWARDS_REACHED',
  NoTransaction = 'NO_TRANSACTION',
  Pending = 'PENDING',
  ReferrerRewarded = 'REFERRER_REWARDED',
  Sleeping = 'SLEEPING',
  UserCreated = 'USER_CREATED'
}

export type ReferreeUser = {
  __typename: 'ReferreeUser';
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  uid: Maybe<Scalars['ID']['output']>;
};

export type Referrer = {
  __typename: 'Referrer';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  referrerId: Scalars['ID']['output'];
};

export enum RefusalTag {
  ActivityNotSupported = 'ACTIVITY_NOT_SUPPORTED',
  CompanyClosed = 'COMPANY_CLOSED',
  CompanyHasAMoralPersonAsShareholder = 'COMPANY_HAS_A_MORAL_PERSON_AS_SHAREHOLDER',
  CompanyHasMoreThanOneShareholder = 'COMPANY_HAS_MORE_THAN_ONE_SHAREHOLDER',
  CompanyNotOwnedByUser = 'COMPANY_NOT_OWNED_BY_USER',
  CompanySituationNotSupported = 'COMPANY_SITUATION_NOT_SUPPORTED',
  Custom = 'CUSTOM',
  ForgedDocument = 'FORGED_DOCUMENT',
  IdentityTheft = 'IDENTITY_THEFT',
  LegalFormNotSupported = 'LEGAL_FORM_NOT_SUPPORTED',
  LocationNotSupported = 'LOCATION_NOT_SUPPORTED',
  NoActiveEstablishment = 'NO_ACTIVE_ESTABLISHMENT',
  SuspiciousBehaviour = 'SUSPICIOUS_BEHAVIOUR',
  SuspiciousChangeOfExecutives = 'SUSPICIOUS_CHANGE_OF_EXECUTIVES',
  SuspiciousRecentLiquidations = 'SUSPICIOUS_RECENT_LIQUIDATIONS',
  UserNotAllowedToWorkInFrance = 'USER_NOT_ALLOWED_TO_WORK_IN_FRANCE',
  UserTaxResidencyCountryNotSupported = 'USER_TAX_RESIDENCY_COUNTRY_NOT_SUPPORTED'
}

export type RefuseAuthenticationDeviceRequestInput = {
  authenticationDeviceId: Scalars['ID']['input'];
  authenticationDeviceRequestId: Scalars['ID']['input'];
  phone: Scalars['ID']['input'];
};

export type RefuseAuthenticationDeviceRequestPayload = {
  __typename: 'RefuseAuthenticationDeviceRequestPayload';
  authenticationDeviceRequest: AuthenticationDeviceRequest;
};

export type RefuseCardPaymentStrongAuthenticationRequestInput = {
  cardPaymentStrongAuthenticationRequestId: Scalars['ID']['input'];
  deviceToken: Scalars['String']['input'];
};

export type RegisterCheckInput = {
  checkDepositId: Scalars['String']['input'];
};

export type RegisterCompanyBeneficiariesInput = {
  applicants?: InputMaybe<Array<InputMaybe<BeneficiaryListApplicantInput>>>;
  id: Scalars['ID']['input'];
  sendInvitations: Scalars['Boolean']['input'];
};

export type RegisterCompanyBeneficiariesPayload = {
  __typename: 'RegisterCompanyBeneficiariesPayload';
  application: Application;
};

export type RejectAuthenticationActionRequestInput = {
  authenticationActionRequestId: Scalars['ID']['input'];
  deviceToken: Scalars['String']['input'];
  phone: Scalars['ID']['input'];
};

export type RejectAuthenticationActionRequestOutput = {
  __typename: 'RejectAuthenticationActionRequestOutput';
  accessToken: Maybe<Scalars['String']['output']>;
  authenticationActionRequestId: Scalars['ID']['output'];
  deviceToken: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
};

export type RejectTeamMembersRequestInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  teamMembersRequestId: Scalars['UUIDv4']['input'];
};

export type RemindCardLaterInput = {
  cardId: Scalars['ID']['input'];
  displayAt: Scalars['Float']['input'];
};

export enum ReminderFrequencyEnum {
  Once = 'ONCE',
  Weekly = 'WEEKLY'
}

export type RequestPhysicalCreditCardInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  bankAccountId: Scalars['ID']['input'];
  companyUserId?: InputMaybe<Scalars['ID']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
  shouldSendToCompanyAddress?: InputMaybe<Scalars['Boolean']['input']>;
  walletId?: InputMaybe<Scalars['String']['input']>;
};

export type RequestVirtualCreditCardInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  bankAccountId: Scalars['ID']['input'];
  companyUserId: Scalars['ID']['input'];
  deactivateAt?: InputMaybe<Scalars['String']['input']>;
};

export type ResetStandardOnboardingInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type ResetStandardOnboardingPayload = {
  __typename: 'ResetStandardOnboardingPayload';
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export type RevokeDirectDebitMandateInput = {
  directDebitMandateId: Scalars['ID']['input'];
};

export type RevokePhoneInput = {
  deviceToken: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type RevokePhoneOutput = {
  __typename: 'RevokePhoneOutput';
  ok: Scalars['Boolean']['output'];
};

export enum Role {
  Accountant = 'ACCOUNTANT',
  AccountHolder = 'ACCOUNT_HOLDER',
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE',
  Invitee = 'INVITEE',
  Mandatee = 'MANDATEE'
}

export type RoleFilter = {
  __typename: 'RoleFilter';
  field: FeatureFilterField;
  values: Array<Role>;
};

export type SasuAddon = {
  __typename: 'SasuAddon';
  basePrice: Scalars['Int']['output'];
  basePriceWithoutVAT: Scalars['Int']['output'];
  discountPrice: Scalars['Int']['output'];
  discountPriceWithoutVAT: Scalars['Int']['output'];
  providerAddonId: Scalars['String']['output'];
};

export type SaveCompanyCreationObjetSocialInput = {
  applicationId: Scalars['ID']['input'];
  objetSocial: Scalars['String']['input'];
};

export type SaveCompanyCreationObjetSocialPayload = {
  __typename: 'SaveCompanyCreationObjetSocialPayload';
  companyCreation: CompanyCreationSasu;
};

export type ScheduledBankTransfer = {
  __typename: 'ScheduledBankTransfer';
  amount: Scalars['Float']['output'];
  bankAccountId: Scalars['ID']['output'];
  bankTransferRecipient: BankTransferRecipient;
  bankTransferRecipientId: Scalars['ID']['output'];
  canceledAt: Maybe<Scalars['String']['output']>;
  companyUserId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  /** @deprecated Use receiptUrl instead */
  documentBucket: Maybe<Scalars['String']['output']>;
  /** @deprecated Use receiptUrl instead */
  documentFileRef: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['String']['output']>;
  endDateDeterminedBy: Maybe<ScheduledBankTransferEndDateDeterminedBy>;
  frequency: Scalars['String']['output'];
  isCanceled: Scalars['Boolean']['output'];
  label: Maybe<Scalars['String']['output']>;
  lastOccurrenceDate: Maybe<Scalars['String']['output']>;
  nextOccurrenceDate: Maybe<Scalars['String']['output']>;
  receiptUrl: Maybe<Scalars['String']['output']>;
  remainingNumberOfOccurrences: Maybe<Scalars['Int']['output']>;
  scheduledBankTransferId: Scalars['ID']['output'];
  status: ScheduledBankTransferStatus;
  transactions: Array<Transaction>;
  type: ScheduledBankTransferType;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ScheduledBankTransferEdge = {
  __typename: 'ScheduledBankTransferEdge';
  cursor: Scalars['String']['output'];
  node: ScheduledBankTransfer;
};

export enum ScheduledBankTransferEndDateDeterminedBy {
  ExplicitDate = 'EXPLICIT_DATE',
  NumberOfOccurrences = 'NUMBER_OF_OCCURRENCES'
}

export enum ScheduledBankTransferFrequency {
  EveryMonth = 'EVERY_MONTH',
  EveryWeek = 'EVERY_WEEK',
  EveryYear = 'EVERY_YEAR',
  Once = 'ONCE'
}

export type ScheduledBankTransferPayload = {
  __typename: 'ScheduledBankTransferPayload';
  scheduledBankTransfer: Maybe<ScheduledBankTransfer>;
};

export enum ScheduledBankTransferStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Done = 'DONE'
}

export enum ScheduledBankTransferType {
  OneOff = 'ONE_OFF',
  Recurring = 'RECURRING'
}

export enum SdkEnvironment {
  Development = 'development',
  Production = 'production',
  Staging = 'staging'
}

export type SelectCompanySubscriptionPlanInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  selectedPlanId: Scalars['ID']['input'];
};

export type SelectInternationalTransferRecipientInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
  internationalTransferQuoteId: Scalars['UUIDv4']['input'];
  internationalTransferRecipientId: Scalars['ID']['input'];
  lang: Scalars['String']['input'];
};

export type SelectInternationalTransferRecipientResponse = {
  __typename: 'SelectInternationalTransferRecipientResponse';
  requireUpdate: Scalars['Boolean']['output'];
};

export type SendCompanyInvitationInput = {
  companyInvitationId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type SendDummyInvoiceInput = {
  invoiceId: Scalars['ID']['input'];
};

export type SendDummyQuoteInput = {
  quoteId: Scalars['ID']['input'];
};

export type SendInvoiceInput = {
  clientContactIds: Array<Scalars['ID']['input']>;
  fiscalId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
  isUserCcedOnEmails: Scalars['Boolean']['input'];
};

export type SendQuoteInput = {
  clientContactIds: Array<Scalars['ID']['input']>;
  fiscalId?: InputMaybe<Scalars['String']['input']>;
  isUserCcedOnEmails: Scalars['Boolean']['input'];
  quoteId: Scalars['ID']['input'];
};

export type SendShineAppDownloadSmsInput = {
  phone: Scalars['String']['input'];
};

export type SendTestInvoiceReminderInput = {
  invoiceId: Scalars['ID']['input'];
};

export enum SensitiveOperationType {
  CreateBeneficiary = 'CREATE_BENEFICIARY',
  CreateBulkTransferBeneficiaries = 'CREATE_BULK_TRANSFER_BENEFICIARIES',
  CreateVirtualCard = 'CREATE_VIRTUAL_CARD',
  DisplayCardInformation = 'DISPLAY_CARD_INFORMATION'
}

export type SentitiveOperation = {
  __typename: 'SentitiveOperation';
  sensitiveOperationId: Scalars['ID']['output'];
};

export type SetActivationOnboardingCompletedInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type SetApplicantBasicIdentityInformationInput = {
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
};

export type SetApplicantBasicIdentityInformationPayload = {
  __typename: 'SetApplicantBasicIdentityInformationPayload';
  userProfile: UserProfile;
};

export type SetApplicantBusinessRoleInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  id: Scalars['ID']['input'];
  isDirector: Scalars['Boolean']['input'];
  isMajorShareholder: Scalars['Boolean']['input'];
};

export type SetApplicantBusinessRolePayload = {
  __typename: 'SetApplicantBusinessRolePayload';
  companyLegalBeneficiary: CompanyLegalBeneficiary;
};

export type SetApplicantFiscalSituationInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  incomeRange: Scalars['String']['input'];
  isSpecifiedUSPerson: Scalars['Boolean']['input'];
  personalAssets: Scalars['String']['input'];
};

export type SetApplicantFiscalSituationPayload = {
  __typename: 'SetApplicantFiscalSituationPayload';
  userProfile: UserProfile;
};

export type SetApplicantIdentityInformationInput = {
  birthCity: Scalars['String']['input'];
  birthCountry: Scalars['String']['input'];
  birthDepartment?: InputMaybe<Scalars['String']['input']>;
  birthdate: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  nationality: Scalars['String']['input'];
};

export type SetApplicantIdentityInformationPayload = {
  __typename: 'SetApplicantIdentityInformationPayload';
  userProfile: UserProfile;
};

export type SetApplicantPersonalAddressInput = {
  city: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  street: Scalars['String']['input'];
  streetAddition?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type SetApplicantPersonalAddressPayload = {
  __typename: 'SetApplicantPersonalAddressPayload';
  userProfile: UserProfile;
};

export type SetApplicantTaxResidenciesInput = {
  applicationId: Scalars['String']['input'];
  taxResidencies: Array<TaxResidencyInput>;
};

export type SetApplicantTaxResidenciesPayload = {
  __typename: 'SetApplicantTaxResidenciesPayload';
  taxResidencies: Array<TaxResidency>;
  userProfile: UserProfile;
};

export type SetCapitalDepositCapitalInfoInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  legalNumberOfShares: Scalars['Float']['input'];
  legalShareCapital: Scalars['Float']['input'];
  paidUpCapitalPercentage: Scalars['Float']['input'];
};

export type SetCapitalDepositCapitalInfoPayload = {
  __typename: 'SetCapitalDepositCapitalInfoPayload';
  capitalDeposit: CapitalDeposit;
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export type SetCapitalDepositCompanyInfoInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  inseeLegalFormCode: Scalars['String']['input'];
  legalName: Scalars['String']['input'];
};

export type SetCapitalDepositCompanyInfoPayload = {
  __typename: 'SetCapitalDepositCompanyInfoPayload';
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export type SetCapitalDepositHasEuropeanBankAccountInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  hasEuropeanBankAccount: Scalars['Boolean']['input'];
};

export type SetCapitalDepositHasEuropeanBankAccountPayload = {
  __typename: 'SetCapitalDepositHasEuropeanBankAccountPayload';
  capitalDeposit: CapitalDeposit;
};

export type SetCapitalDepositPartnerInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  partner: Scalars['String']['input'];
  partnerDetails?: InputMaybe<Scalars['String']['input']>;
};

export type SetCapitalDepositPartnerPayload = {
  __typename: 'SetCapitalDepositPartnerPayload';
  capitalDeposit: CapitalDeposit;
};

export type SetCompanyCreationCapitalInfoInput = {
  applicationId: Scalars['ID']['input'];
  legalNumberOfShares: Scalars['Int']['input'];
  legalShareCapital: Scalars['Int']['input'];
};

export type SetCompanyCreationCapitalInfoPayload = {
  __typename: 'SetCompanyCreationCapitalInfoPayload';
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export type SetPrimaryApplicantNumberOfSharesInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  numberOfShares: Scalars['Int']['input'];
};

export type SetPrimaryApplicantNumberOfSharesPayload = {
  __typename: 'SetPrimaryApplicantNumberOfSharesPayload';
  companyProfileId: Scalars['UUIDv4']['output'];
};

export type SetTaxForecastPeriodAmountPaidInput = {
  amountPaid: Scalars['Float']['input'];
  taxForecastPeriodId: Scalars['ID']['input'];
};

export type SetTaxForecastPeriodUnpaidInput = {
  taxForecastPeriodId: Scalars['ID']['input'];
};

export type SetUserParentsInformationInput = {
  firstParent: ParentInformationInput;
  haveParentsSameBirthLastName: Scalars['Boolean']['input'];
  secondParent: ParentInformationInput;
};

export type SetUserParentsInformationPayload = {
  __typename: 'SetUserParentsInformationPayload';
  onboardingUser: OnboardingUser;
};

export enum ShineAccountingExportFormat {
  Csv = 'CSV',
  Ofx = 'OFX',
  Qif = 'QIF'
}

export type ShineAccountingExportSettingsReceipient = {
  __typename: 'ShineAccountingExportSettingsReceipient';
  accountingExportRecipientId: Scalars['UUIDv4']['output'];
  accountingExportSettingsId: Scalars['UUIDv4']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  email: Scalars['String']['output'];
};

export type ShineOneOffAccountingExportSettings = {
  __typename: 'ShineOneOffAccountingExportSettings';
  accountingExportSettingsId: Scalars['ID']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['String']['output']>;
  recipients: Array<ShineAccountingExportSettingsReceipient>;
  settings: ShineOneOffAccountingExportSettingsSettings;
  status: ShineOneOffAccountingExportSettingsStatus;
  type: ShineOneOffAccountingExportSettingsType;
  updatedAt: Scalars['String']['output'];
};

export type ShineOneOffAccountingExportSettingsSettings = {
  __typename: 'ShineOneOffAccountingExportSettingsSettings';
  formats: Array<ShineAccountingExportFormat>;
  fromDate: Scalars['String']['output'];
  includeBankStatements: Scalars['Boolean']['output'];
  includeInvoices: Scalars['Boolean']['output'];
  includePersonalTransactions: Scalars['Boolean']['output'];
  toDate: Scalars['String']['output'];
};

export enum ShineOneOffAccountingExportSettingsStatus {
  Enabled = 'ENABLED'
}

export enum ShineOneOffAccountingExportSettingsType {
  ShineOneOff = 'SHINE_ONE_OFF'
}

export type ShineRecurringAccountingExportSettings = {
  __typename: 'ShineRecurringAccountingExportSettings';
  accountingExportSettingsId: Scalars['ID']['output'];
  cadence: ShineRecurringAccountingExportSettingsCadence;
  cadenceOffset: Scalars['Int']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  createdAt: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['String']['output']>;
  nextExportAt: Scalars['String']['output'];
  recipients: Array<ShineAccountingExportSettingsReceipient>;
  settings: ShineRecurringAccountingExportSettingsSettings;
  status: ShineRecurringAccountingExportSettingsStatus;
  type: ShineRecurringAccountingExportSettingsType;
  updatedAt: Scalars['String']['output'];
};

export enum ShineRecurringAccountingExportSettingsCadence {
  Monthly = 'MONTHLY'
}

export type ShineRecurringAccountingExportSettingsCadenceSettingsInput = {
  offset: Scalars['Int']['input'];
  period: ShineRecurringAccountingExportSettingsCadence;
};

export type ShineRecurringAccountingExportSettingsSettings = {
  __typename: 'ShineRecurringAccountingExportSettingsSettings';
  formats: Array<ShineAccountingExportFormat>;
  includeBankStatements: Scalars['Boolean']['output'];
  includeInvoices: Scalars['Boolean']['output'];
  includePersonalTransactions: Scalars['Boolean']['output'];
};

export enum ShineRecurringAccountingExportSettingsStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export enum ShineRecurringAccountingExportSettingsType {
  ShineRecurring = 'SHINE_RECURRING'
}

export type ShineStart = {
  __typename: 'ShineStart';
  activities: Maybe<Array<CompanyCreationActivity>>;
  activity: Maybe<Scalars['String']['output']>;
  activityCategory: Maybe<Scalars['String']['output']>;
  activityLabel: Maybe<Scalars['String']['output']>;
  activityStartDate: Maybe<Scalars['String']['output']>;
  applicationReferenceNumber: Maybe<Scalars['String']['output']>;
  applicationSubmittedAt: Maybe<Scalars['String']['output']>;
  assignedOperator: Maybe<Scalars['String']['output']>;
  companyProfileId: Maybe<Scalars['UUIDv4']['output']>;
  companyRegisteredAt: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  department: Maybe<Scalars['String']['output']>;
  documents: Array<ShineStartDocument>;
  domiciliaryName: Maybe<Scalars['String']['output']>;
  domiciliarySiret: Maybe<Scalars['String']['output']>;
  eligibleToAcre: Maybe<Scalars['String']['output']>;
  employmentSituation: Maybe<EmploymentSituation>;
  error: Maybe<Scalars['String']['output']>;
  errorLocalized: Maybe<Scalars['String']['output']>;
  externalApplicationReference: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  form: Maybe<ShineStartForm>;
  formCompletedAt: Maybe<Scalars['String']['output']>;
  formerEIActivity: Maybe<Scalars['String']['output']>;
  formerEIActivityCode: Maybe<Scalars['String']['output']>;
  formerEIClosureDate: Maybe<Scalars['String']['output']>;
  formerEILegalRegistrationNumber: Maybe<Scalars['String']['output']>;
  formerEIStartDate: Maybe<Scalars['String']['output']>;
  guichetEntrepriseRecordNumber: Maybe<Scalars['String']['output']>;
  hasAgreedShareInformation: Maybe<Scalars['Boolean']['output']>;
  hasFormerEI: Maybe<Scalars['Boolean']['output']>;
  isPaid: Maybe<Scalars['Boolean']['output']>;
  isRegistrationDelayed: Maybe<Scalars['Boolean']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  maritalStatus: Maybe<MaritalStatus>;
  partner: Maybe<Scalars['String']['output']>;
  rawSelectedActivities: Maybe<Array<RawSelectedActivities>>;
  regulationMessage: Maybe<Scalars['String']['output']>;
  secondaryActivity: Maybe<Scalars['String']['output']>;
  sentToStateAt: Maybe<Scalars['String']['output']>;
  shineStartId: Scalars['ID']['output'];
  shouldBeHidden: Maybe<Scalars['Boolean']['output']>;
  status: ShineStartStatus;
  stepStatus: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ShineStartDocument = {
  __typename: 'ShineStartDocument';
  createdAt: Scalars['String']['output'];
  error: Maybe<ShineStartDocumentError>;
  errorLocalized: Maybe<Scalars['String']['output']>;
  firebaseBucket: Maybe<Scalars['String']['output']>;
  firebaseRef: Maybe<Scalars['String']['output']>;
  shineStartDocumentId: Scalars['ID']['output'];
  shineStartId: Scalars['ID']['output'];
  status: ShineStartDocumentStatus;
  template: Maybe<ShineStartDocumentTemplate>;
  type: ShineStartDocumentType;
  typeLocalized: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  uploadedAt: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export enum ShineStartDocumentError {
  AssemblySignature = 'ASSEMBLY_SIGNATURE',
  BirthBoroughMissing = 'BIRTH_BOROUGH_MISSING',
  BirthPlaceMissing = 'BIRTH_PLACE_MISSING',
  BirthZipMissing = 'BIRTH_ZIP_MISSING',
  BlackAndWhite = 'BLACK_AND_WHITE',
  Blurry = 'BLURRY',
  CityMention = 'CITY_MENTION',
  ContractCommLeaseInvalid = 'CONTRACT_COMM_LEASE_INVALID',
  ContractDomInvalid = 'CONTRACT_DOM_INVALID',
  ContractIncomplete = 'CONTRACT_INCOMPLETE',
  Cropped = 'CROPPED',
  DeclarantChecked = 'DECLARANT_CHECKED',
  DiplomaBox = 'DIPLOMA_BOX',
  DiplomaInvalid = 'DIPLOMA_INVALID',
  DrivingLicenseNotSupported = 'DRIVING_LICENSE_NOT_SUPPORTED',
  DrivingLicenseRectoInvalid = 'DRIVING_LICENSE_RECTO_INVALID',
  DrivingLicenseVersoInvalid = 'DRIVING_LICENSE_VERSO_INVALID',
  EmptyDocument = 'EMPTY_DOCUMENT',
  Expired = 'EXPIRED',
  ExpiredId = 'EXPIRED_ID',
  Falsified = 'FALSIFIED',
  Generic = 'GENERIC',
  HandwrittenMention = 'HANDWRITTEN_MENTION',
  HandwrittenSignature = 'HANDWRITTEN_SIGNATURE',
  IdInvalid = 'ID_INVALID',
  IdRectoOwnerInvalid = 'ID_RECTO_OWNER_INVALID',
  IdVersoOwnerInvalid = 'ID_VERSO_OWNER_INVALID',
  InconsistentActivity = 'INCONSISTENT_ACTIVITY',
  InconsistentDate = 'INCONSISTENT_DATE',
  InconsistentMaritalStatus = 'INCONSISTENT_MARITAL_STATUS',
  InconsistentResidencePermitDate = 'INCONSISTENT_RESIDENCE_PERMIT_DATE',
  IncorrectAddress = 'INCORRECT_ADDRESS',
  IncorrectBirthCity = 'INCORRECT_BIRTH_CITY',
  IncorrectFirstForename = 'INCORRECT_FIRST_FORENAME',
  IncorrectFirstName = 'INCORRECT_FIRST_NAME',
  IncorrectLastName = 'INCORRECT_LAST_NAME',
  IncorrectName = 'INCORRECT_NAME',
  IncorrectOwnerFirstName = 'INCORRECT_OWNER_FIRST_NAME',
  IncorrectOwnerLastName = 'INCORRECT_OWNER_LAST_NAME',
  InterleaveBox = 'INTERLEAVE_BOX',
  InvalidDate = 'INVALID_DATE',
  JqpaInvalid = 'JQPA_INVALID',
  MarriageInvalid = 'MARRIAGE_INVALID',
  Missing = 'MISSING',
  MissingBikeKeyword = 'MISSING_BIKE_KEYWORD',
  MissingCity = 'MISSING_CITY',
  MissingDate = 'MISSING_DATE',
  MissingId = 'MISSING_ID',
  MissingMaritalStatus = 'MISSING_MARITAL_STATUS',
  MissingMention = 'MISSING_MENTION',
  MissingOwnerAddress = 'MISSING_OWNER_ADDRESS',
  MissingParentFirstName = 'MISSING_PARENT_FIRST_NAME',
  MissingPropertySeparation = 'MISSING_PROPERTY_SEPARATION',
  MissingSignature = 'MISSING_SIGNATURE',
  MissingTitle = 'MISSING_TITLE',
  MotherSameName = 'MOTHER_SAME_NAME',
  NeedAnother = 'NEED_ANOTHER',
  NoDate = 'NO_DATE',
  NoSignature = 'NO_SIGNATURE',
  OneBoxChecked = 'ONE_BOX_CHECKED',
  OneOrTwoBoxChecked = 'ONE_OR_TWO_BOX_CHECKED',
  ParentsMaritalStatus = 'PARENTS_MARITAL_STATUS',
  ParentsSameLine = 'PARENTS_SAME_LINE',
  PhotoMontage = 'PHOTO_MONTAGE',
  ProofOfRequestAddressChangeInvalid = 'PROOF_OF_REQUEST_ADDRESS_CHANGE_INVALID',
  ProofProvisionOwnerInvalid = 'PROOF_PROVISION_OWNER_INVALID',
  ProofRadiationInvalid = 'PROOF_RADIATION_INVALID',
  PropertyTaxIncomplete = 'PROPERTY_TAX_INCOMPLETE',
  ReadAndApprovedMissing = 'READ_AND_APPROVED_MISSING',
  Screenshot = 'SCREENSHOT',
  SignatureDate = 'SIGNATURE_DATE',
  SignatureDoesNotMatch = 'SIGNATURE_DOES_NOT_MATCH',
  TitleMissing = 'TITLE_MISSING',
  TypeNotSupported = 'TYPE_NOT_SUPPORTED',
  UnknownParent = 'UNKNOWN_PARENT',
  Unreadable = 'UNREADABLE',
  UploadError = 'UPLOAD_ERROR',
  VitaleCardInvalid = 'VITALE_CARD_INVALID',
  VtcCardRectoInvalid = 'VTC_CARD_RECTO_INVALID',
  VtcCardVersoInvalid = 'VTC_CARD_VERSO_INVALID',
  WrongBirthDate = 'WRONG_BIRTH_DATE',
  WrongId = 'WRONG_ID',
  WrongMaritalStatus = 'WRONG_MARITAL_STATUS'
}

export enum ShineStartDocumentStatus {
  Missing = 'MISSING',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Validated = 'VALIDATED'
}

export type ShineStartDocumentTemplate = {
  __typename: 'ShineStartDocumentTemplate';
  canBeFilled: Maybe<Scalars['Boolean']['output']>;
  url: Scalars['String']['output'];
};

export enum ShineStartDocumentType {
  Accre = 'ACCRE',
  AuthorizationCommunicateThirdParties = 'AUTHORIZATION_COMMUNICATE_THIRD_PARTIES',
  BikeDeliveryStatement = 'BIKE_DELIVERY_STATEMENT',
  CelibacyStatement = 'CELIBACY_STATEMENT',
  CertificateDeliveryInformationSpouse = 'CERTIFICATE_DELIVERY_INFORMATION_SPOUSE',
  CommercialLease = 'COMMERCIAL_LEASE',
  CompanyCertificateOfResidence = 'COMPANY_CERTIFICATE_OF_RESIDENCE',
  CompendiumOfAssent = 'COMPENDIUM_OF_ASSENT',
  DiplomaOrProofOfExperience = 'DIPLOMA_OR_PROOF_OF_EXPERIENCE',
  DrivingLicenseRecto = 'DRIVING_LICENSE_RECTO',
  DrivingLicenseVerso = 'DRIVING_LICENSE_VERSO',
  DronePilotCertificate = 'DRONE_PILOT_CERTIFICATE',
  EcsrLicenseTitle = 'ECSR_LICENSE_TITLE',
  Generic = 'GENERIC',
  IdRectoOwner = 'ID_RECTO_OWNER',
  IdVersoOwner = 'ID_VERSO_OWNER',
  JpqaForm = 'JPQA_FORM',
  NoCriminalConvictionStatement = 'NO_CRIMINAL_CONVICTION_STATEMENT',
  P0 = 'P0',
  PacsOrMarriageContractFamilyBook = 'PACS_OR_MARRIAGE_CONTRACT_FAMILY_BOOK',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  ProofOfIdentityHandWritten = 'PROOF_OF_IDENTITY_HAND_WRITTEN',
  ProofOfRadiation = 'PROOF_OF_RADIATION',
  ProofOfRequestAddressChange = 'PROOF_OF_REQUEST_ADDRESS_CHANGE',
  ProofProvisionOwner = 'PROOF_PROVISION_OWNER',
  ProofRegistrationTransportationRental = 'PROOF_REGISTRATION_TRANSPORTATION_RENTAL',
  PropertyTaxRecto = 'PROPERTY_TAX_RECTO',
  PropertyTaxVerso = 'PROPERTY_TAX_VERSO',
  RequestForNonAssistance = 'REQUEST_FOR_NON_ASSISTANCE',
  VitaleCard = 'VITALE_CARD',
  VtcCardRecto = 'VTC_CARD_RECTO',
  VtcCardVerso = 'VTC_CARD_VERSO'
}

export type ShineStartForm = {
  __typename: 'ShineStartForm';
  activite: Maybe<Scalars['String']['output']>;
  autreBenefAssurance: Maybe<Scalars['Boolean']['output']>;
  communauteBien: Maybe<Scalars['String']['output']>;
  companyMultipleActivity: Maybe<Scalars['Boolean']['output']>;
  conjointCouvert: Maybe<Scalars['Boolean']['output']>;
  hasPreviousEI: Maybe<Scalars['Boolean']['output']>;
  hideFromSirenRegister: Maybe<Scalars['Boolean']['output']>;
  job: Maybe<Scalars['String']['output']>;
  marriedPacs: Maybe<Scalars['Boolean']['output']>;
  numSecu: Maybe<Scalars['Float']['output']>;
  partner: Maybe<Scalars['String']['output']>;
  previousEISiret: Maybe<Scalars['String']['output']>;
  regimeActuel: Maybe<Scalars['String']['output']>;
};

export type ShineStartFormCompleted = {
  __typename: 'ShineStartFormCompleted';
  id: Scalars['ID']['output'];
  status: ShineStartFormCompletedStatus;
};

export enum ShineStartFormCompletedStatus {
  FormCompleted = 'FORM_COMPLETED',
  Rejected = 'REJECTED'
}

export type ShineStartInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type ShineStartQuestionsInput = {
  applicationId: Scalars['ID']['input'];
  company?: InputMaybe<CompanyInformationInput>;
  companyCreationOptions: CompanyCreationOptionsInput;
  founder: FounderInput;
  hasFormerEI: Scalars['Boolean']['input'];
};

export enum ShineStartStatus {
  ApplicationInvalid = 'APPLICATION_INVALID',
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  ApplicationValidated = 'APPLICATION_VALIDATED',
  CompanyRegistered = 'COMPANY_REGISTERED',
  FormCompleted = 'FORM_COMPLETED',
  OptedOut = 'OPTED_OUT',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  SentToState = 'SENT_TO_STATE'
}

export type ShortLivedTokenPayload = {
  __typename: 'ShortLivedTokenPayload';
  access_token: Scalars['String']['output'];
  expires_in: Scalars['Int']['output'];
  scopes: Array<Scalars['String']['output']>;
  token_type: Scalars['String']['output'];
};

export type SignCheckDepositMandateInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StandardHotline = {
  __typename: 'StandardHotline';
  label: Scalars['String']['output'];
  language: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  sublabel: Scalars['String']['output'];
};

export type StartApplicationInput = {
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  type: ApplicationType;
};

export type StartApplicationPayload = {
  __typename: 'StartApplicationPayload';
  companyProfileId: Scalars['UUIDv4']['output'];
};

export type StartCompanyCreationDocumentSignatureInput = {
  applicationId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
};

export type StartCompanyCreationDocumentSignaturePayload = {
  __typename: 'StartCompanyCreationDocumentSignaturePayload';
  signatureLink: Scalars['String']['output'];
};

export type StartIdentityVerificationInput = {
  companyUserId: Scalars['ID']['input'];
  environment?: InputMaybe<SdkEnvironment>;
  idDocumentCase?: InputMaybe<IdDocumentCase>;
};

export type StartIdentityVerificationPayload = {
  __typename: 'StartIdentityVerificationPayload';
  provider: KycProvider;
  sdkToken: Scalars['String']['output'];
};

export type StartQesInput = {
  companyUserId: Scalars['ID']['input'];
};

export type StartQesPayload = {
  __typename: 'StartQesPayload';
  provider: KycProvider;
  sdkToken: Scalars['String']['output'];
};

export type SubmitBankTransferRequestInput = {
  amount: Scalars['Int']['input'];
  bankAccountId: Scalars['ID']['input'];
  bankTransferRecipient: BankTransferRequestRecipientInput;
  companyProfileId: Scalars['UUIDv4']['input'];
  supportingDocument: TeamMembersRequestSupportingDocumentInput;
  userLabel?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitBeneficiaryApplicationInput = {
  companyUserId: Scalars['ID']['input'];
};

export type SubmitBeneficiaryApplicationPayload = {
  __typename: 'SubmitBeneficiaryApplicationPayload';
  companyUser: CompanyUser;
};

export type SubmitCapitalDepositKybDocumentsInput = {
  capitalDepositId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type SubmitCapitalDepositKybDocumentsPayload = {
  __typename: 'SubmitCapitalDepositKybDocumentsPayload';
  capitalDeposit: CapitalDeposit;
};

export type SubmitCapitalDepositMandateeInput = {
  companyProfileId: Scalars['String']['input'];
};

export type SubmitCapitalDepositMandateePayload = {
  __typename: 'SubmitCapitalDepositMandateePayload';
  capitalDeposit: CapitalDeposit;
};

export type SubmitCapitalDepositPayload = {
  __typename: 'SubmitCapitalDepositPayload';
  capitalDeposit: CapitalDeposit;
  metadata: UserMetadata;
};

export type SubmitExpenseClaimRequestInput = {
  amount: ExpenseClaimRequestAmountInput;
  bankAccountId: Scalars['ID']['input'];
  bankTransferRecipient: ExpenseClaimRequestRecipientInput;
  companyProfileId: Scalars['UUIDv4']['input'];
  description: Scalars['String']['input'];
  reseller: Scalars['String']['input'];
  supportingDocument: TeamMembersRequestSupportingDocumentInput;
};

export type SubmitKycApplicationInput = {
  companyUserId: Scalars['ID']['input'];
};

export type SubmitKyxCheckInput = {
  clearUserInput?: InputMaybe<Scalars['Boolean']['input']>;
  kyxCheckId: Scalars['ID']['input'];
  skipReview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubmitMandatedCapitalDepositForAccountHolderInput = {
  companyProfileId: Scalars['String']['input'];
};

export type SubmitMandatedCapitalDepositForAccountHolderPayload = {
  __typename: 'SubmitMandatedCapitalDepositForAccountHolderPayload';
  companyUser: CompanyUser;
};

export type SubmitPhysicalPaymentCardOrderRequestInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  pin: Scalars['String']['input'];
};

export type SubmitShineStartPayload = {
  __typename: 'SubmitShineStartPayload';
  shineStart: ShineStart;
};

export type SubmitShineStartQuestionsPayload = {
  __typename: 'SubmitShineStartQuestionsPayload';
  success: Scalars['Boolean']['output'];
};

export type SubmitVirtualBudgetCardOrderRequestInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  description: Scalars['String']['input'];
  limitPaymentAll: Scalars['Float']['input'];
};

export type SubmitVirtualPaymentCardOrderRequestInput = {
  bankAccountId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type Subscription = {
  __typename: 'Subscription';
  applicationAborted: ApplicationAborted;
  applicationSignedDocumentRetrieved: ApplicationSignedDocumentRetrieved;
  applicationStarted: ApplicationStarted;
  companyCreationSASUInfoSet: CompanyCreationSasuInfoSet;
  creditCardEnabled: CreditCardInterface;
  newAuthenticationDeviceRequest: AuthenticationDeviceRequest;
  newCard: Card;
  newOrUpdatedCardPaymentStrongAuthenticationRequest: CardPaymentStrongAuthenticationRequest;
  paymentCardUpdated: CreditCardInterface;
  shineStartFormCompleted: ShineStartFormCompleted;
  strongAuthenticationChallengeResult: VerifyChallengeOutput;
  teamMembersRequestsCreated: TeamMembersRequest;
  teamMembersRequestsUpdated: TeamMembersRequest;
  updatedActionRequest: ActionRequest;
  updatedAuthenticationDeviceRequest: AuthenticationDeviceRequest;
  updatedBankAccount: BankAccount;
  updatedCapitalDeposit: CapitalDeposit;
  updatedCompany: CompanyProfile;
  updatedCompanyMetadata: CompanyMetadata;
  updatedCompanyUser: CompanyUser;
  updatedShineStart: ShineStart;
  updatedShineStartDocument: ShineStartDocument;
  updatedTransaction: Transaction;
  updatedUserMetadata: UserMetadata;
  /** @deprecated auto virtual card order is now implemented on the service side */
  virtualCardAutomaticallyOrdered: VirtualCardAutomaticallyOrderedPayload;
};


export type SubscriptionCreditCardEnabledArgs = {
  creditCardId: Scalars['ID']['input'];
};


export type SubscriptionNewAuthenticationDeviceRequestArgs = {
  phone: Scalars['ID']['input'];
};


export type SubscriptionPaymentCardUpdatedArgs = {
  companyUserId: Scalars['ID']['input'];
};


export type SubscriptionStrongAuthenticationChallengeResultArgs = {
  challengeId: Scalars['UUIDv4']['input'];
};


export type SubscriptionTeamMembersRequestsCreatedArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type SubscriptionTeamMembersRequestsUpdatedArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type SubscriptionUpdatedAuthenticationDeviceRequestArgs = {
  deviceToken: Scalars['ID']['input'];
  phone: Scalars['ID']['input'];
};


export type SubscriptionUpdatedCompanyMetadataArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type SubscriptionUpdatedCompanyUserArgs = {
  companyUserId: Scalars['ID']['input'];
};


export type SubscriptionUpdatedTransactionArgs = {
  companyUserId: InputMaybe<Scalars['UUIDv4']['input']>;
};


export type SubscriptionVirtualCardAutomaticallyOrderedArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};

export type SubscriptionFees = {
  __typename: 'SubscriptionFees';
  allTimeDue: Scalars['Int']['output'];
  startDate: Scalars['String']['output'];
  status: SubscriptionFeesStatus;
  total: Scalars['Int']['output'];
};

export enum SubscriptionFeesStatus {
  Due = 'DUE',
  Paid = 'PAID'
}

export type SubscriptionInvoice = {
  __typename: 'SubscriptionInvoice';
  dueDateAt: Scalars['String']['output'];
  invoiceAt: Scalars['String']['output'];
  isPlanInvoiceType: Scalars['Boolean']['output'];
  paidAt: Maybe<Scalars['String']['output']>;
  providerInvoiceId: Scalars['ID']['output'];
  status: SubscriptionInvoiceStatus;
  subscriptionInvoiceId: Scalars['ID']['output'];
  totalAmountWithoutVAT: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SubscriptionInvoiceEdge = {
  __typename: 'SubscriptionInvoiceEdge';
  cursor: Scalars['String']['output'];
  node: SubscriptionInvoice;
};

export enum SubscriptionInvoiceStatus {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  PaymentDue = 'PAYMENT_DUE',
  Posted = 'POSTED'
}

export type SubscriptionInvoicesConnection = {
  __typename: 'SubscriptionInvoicesConnection';
  edges: Array<SubscriptionInvoiceEdge>;
  pageInfo: PageInfo;
};

export type SubscriptionOverdue = {
  __typename: 'SubscriptionOverdue';
  endDate: Scalars['String']['output'];
  operationFeesAmount: Scalars['Int']['output'];
  startDate: Scalars['String']['output'];
  subscriptionAmount: Scalars['Int']['output'];
  totalWithVAT: Scalars['Int']['output'];
  totalWithoutVAT: Scalars['Int']['output'];
};

export type SubscriptionPlan = {
  __typename: 'SubscriptionPlan';
  addons: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  companyProfileId: Scalars['UUIDv4']['output'];
  computedPlanAmount: Scalars['Int']['output'];
  coupons: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt: Scalars['String']['output'];
  /** @deprecated Use Company.currentPlan instead */
  currentPlan: PricingPlan;
  /** @deprecated Use downgradePlanInfo instead. */
  downgradeBasicInfo: Maybe<DowngradeInfo>;
  /** @deprecated Use downgradeBasicInfo instead. */
  downgradeInfo: Maybe<DowngradeInfo>;
  downgradePlanInfo: Maybe<DowngradeInfo>;
  /** @deprecated Use downgradePlanInfo instead. */
  downgradePremiumInfo: Maybe<DowngradeInfo>;
  hasScheduledChanges: Maybe<Scalars['Boolean']['output']>;
  invoices: Maybe<SubscriptionInvoicesConnection>;
  isBusiness: Maybe<Scalars['Boolean']['output']>;
  isEntreprise: Maybe<Scalars['Boolean']['output']>;
  isLateOnPayment: Maybe<Scalars['Boolean']['output']>;
  isPremium: Maybe<Scalars['Boolean']['output']>;
  nextBillingAt: Maybe<Scalars['String']['output']>;
  operationFees: Maybe<SubscriptionFees>;
  overdue: Maybe<SubscriptionOverdue>;
  planUnitPrice: Scalars['Int']['output'];
  providerCustomerId: Scalars['ID']['output'];
  providerPlanId: Scalars['ID']['output'];
  providerSubscriptionId: Scalars['ID']['output'];
  quotas: SubscriptionQuotas;
  scheduledDowngrade: Maybe<Scalars['String']['output']>;
  startedAt: Scalars['String']['output'];
  status: Maybe<SubscriptionPlanStatus>;
  subscriptionId: Scalars['ID']['output'];
  trialPeriodLength: Scalars['Float']['output'];
  unpaidInvoicesCount: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
  upgradeBusinessInfo: Maybe<UpgradeInfo>;
  /** @deprecated Use upgradePlanInfo instead. */
  upgradeInfo: Maybe<UpgradeInfo>;
  upgradePlanInfo: Maybe<UpgradeInfo>;
  /** @deprecated Use upgradePlanInfo instead. */
  upgradePremiumInfo: Maybe<UpgradeInfo>;
};


export type SubscriptionPlanDowngradePlanInfoArgs = {
  providerPlanId: ProviderPlanId;
};


export type SubscriptionPlanInvoicesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type SubscriptionPlanUpgradePlanInfoArgs = {
  providerPlanId: ProviderPlanId;
};

export enum SubscriptionPlanStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  NonRenewing = 'NON_RENEWING',
  Paused = 'PAUSED'
}

export type SubscriptionQuota = {
  __typename: 'SubscriptionQuota';
  limit: Scalars['Int']['output'];
  type: QuotaType;
  used: Scalars['Int']['output'];
};

export type SubscriptionQuotaItem = {
  __typename: 'SubscriptionQuotaItem';
  isExceeded: Scalars['Boolean']['output'];
  isUnlimited: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  used: Scalars['Int']['output'];
};

export type SubscriptionQuotas = {
  __typename: 'SubscriptionQuotas';
  activeBankAccountsQuota: SubscriptionQuotaItem;
  businessWorldDebitCardQuota: SubscriptionQuotaItem;
  cashDepositMonthQuota: SubscriptionQuotaItem;
  checkDepositMonthQuota: SubscriptionQuotaItem;
  companyProfileId: Scalars['UUIDv4']['output'];
  endDate: Scalars['String']['output'];
  providerPlanId: Scalars['ID']['output'];
  sepaTransferMonthQuota: SubscriptionQuotaItem;
  startDate: Scalars['String']['output'];
  teamMemberInvitationsQuota: SubscriptionQuotaItem;
  virtualPaymentCardMonthQuota: SubscriptionQuotaItem;
  withdrawalMonthQuota: SubscriptionQuotaItem;
};

export type SwiftTransferPayin = {
  __typename: 'SwiftTransferPayin';
  createdAt: Scalars['String']['output'];
  emitter: SwiftTransferPayinEmitter;
  id: Scalars['ID']['output'];
  ordered: SwiftTransferPayinOrdered;
};

export type SwiftTransferPayinEmitter = {
  __typename: 'SwiftTransferPayinEmitter';
  account: Maybe<Scalars['String']['output']>;
  bic: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
};

export type SwiftTransferPayinOrdered = {
  __typename: 'SwiftTransferPayinOrdered';
  amount: Scalars['Float']['output'];
  computedFeesAmount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  detailsOfCharges: DetailsOfCharges;
  exchangeRate: Scalars['Float']['output'];
  initialAmount: Scalars['Float']['output'];
  initialCurrency: Scalars['String']['output'];
  interbankFees: Array<InterbankFee>;
  label: Maybe<Scalars['String']['output']>;
};

export type SwitchToPlanInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  providerPlanId: ProviderPlanId;
};

export enum TaskType {
  Product = 'PRODUCT',
  Service = 'SERVICE'
}

export type TaxForecast = {
  __typename: 'TaxForecast';
  amount: Scalars['Float']['output'];
  companyProfileId: Scalars['UUIDv4']['output'];
  payBefore: Scalars['Float']['output'];
  periodEndsAt: Scalars['Float']['output'];
  periodStartsAt: Scalars['Float']['output'];
  taxForecastPeriodId: Scalars['ID']['output'];
};

export type TaxForecastLine = {
  __typename: 'TaxForecastLine';
  amount: Scalars['Float']['output'];
  complementaryRevenue: Maybe<Scalars['Float']['output']>;
  computedRevenue: Scalars['Float']['output'];
  rate: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  taxForecastLineId: Scalars['ID']['output'];
  taxType: Maybe<TaxTypeEnum>;
  vatAmount: Maybe<Scalars['Float']['output']>;
  vatOffset: Maybe<Scalars['Float']['output']>;
  vatRate: Maybe<Scalars['Float']['output']>;
};

export type TaxForecastPeriod = {
  __typename: 'TaxForecastPeriod';
  amount: Scalars['Float']['output'];
  amountPaid: Maybe<Scalars['Float']['output']>;
  bicDistributionAmount: Maybe<Scalars['Float']['output']>;
  bncDistributionAmount: Maybe<Scalars['Float']['output']>;
  companyProfileId: Scalars['UUIDv4']['output'];
  complementaryRevenue: Maybe<Scalars['Float']['output']>;
  computedRevenue: Scalars['Float']['output'];
  computedTaxForecasts: Array<ComputedTaxForecast>;
  isCustomizedEstimation: Maybe<Scalars['Boolean']['output']>;
  isPaid: Scalars['Boolean']['output'];
  payBefore: Scalars['Float']['output'];
  periodEndsAt: Scalars['Float']['output'];
  periodStartsAt: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  sellingDistributionAmount: Maybe<Scalars['Float']['output']>;
  taxForecastPeriodId: Scalars['ID']['output'];
  /** @deprecated Use TaxForecastPeriod.computedTaxForecasts instead */
  taxForecasts: Maybe<Array<Maybe<TaxForecastLine>>>;
  vatAmount: Maybe<Scalars['Float']['output']>;
  vatOffset: Maybe<Scalars['Float']['output']>;
  vatRate: Maybe<Scalars['Float']['output']>;
};

export type TaxForecastPeriodConnection = {
  __typename: 'TaxForecastPeriodConnection';
  edges: Array<TaxForecastPeriodEdge>;
  pageInfo: PageInfo;
};

export type TaxForecastPeriodData = {
  activityDistributionAmounts?: InputMaybe<ActivityDistributionAmounts>;
  complementaryRevenue?: InputMaybe<Scalars['Float']['input']>;
  taxForecastPeriodId?: InputMaybe<Scalars['ID']['input']>;
  vatAmount?: InputMaybe<Scalars['Float']['input']>;
  vatOffset?: InputMaybe<Scalars['Float']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};

export type TaxForecastPeriodEdge = {
  __typename: 'TaxForecastPeriodEdge';
  cursor: Scalars['String']['output'];
  node: TaxForecastPeriod;
};

export type TaxForecastProfile = {
  __typename: 'TaxForecastProfile';
  bicActivityCategory: Maybe<BicActivityCategory>;
  bicServiceCategory: Maybe<BicServiceCategory>;
  companyProfileId: Scalars['UUIDv4']['output'];
  customTaxRate: Maybe<Scalars['Float']['output']>;
  familyAdditionalRevenue: Maybe<Scalars['Float']['output']>;
  familySituation: Maybe<TaxForecastProfileFamilySituation>;
  hasCustomizedEstimation: Scalars['Boolean']['output'];
  isAloneWithChild: Maybe<Scalars['Boolean']['output']>;
  isReglementedLiberal: Maybe<Scalars['Boolean']['output']>;
  isSubjectToVat: Scalars['Boolean']['output'];
  mainActivityType: Maybe<TaxForecastProfileMainActivityType>;
  numberOfChildren: Maybe<Scalars['Int']['output']>;
  revenueBeforeShine: Scalars['Float']['output'];
  subActivity: Maybe<TaxForecastProfileSubActivityType>;
  subjectToVatSince: Maybe<Scalars['String']['output']>;
  taxCalculationMode: Maybe<TaxForecastProfileTaxCalculationMode>;
  taxForecastProfileId: Scalars['ID']['output'];
  vatDefaultRate: Scalars['Float']['output'];
};

export type TaxForecastProfileData = {
  customTaxRate?: InputMaybe<Scalars['Float']['input']>;
  familyAdditionalRevenue?: InputMaybe<Scalars['Float']['input']>;
  familySituation?: InputMaybe<TaxForecastProfileFamilySituation>;
  isAloneWithChild?: InputMaybe<Scalars['Boolean']['input']>;
  isReglementedLiberal?: InputMaybe<Scalars['Boolean']['input']>;
  isSubjectToVat?: InputMaybe<Scalars['Boolean']['input']>;
  mainActivityType?: InputMaybe<TaxForecastProfileMainActivityType>;
  numberOfChildren?: InputMaybe<Scalars['Int']['input']>;
  subActivity?: InputMaybe<TaxForecastProfileSubActivityType>;
  taxCalculationMode?: InputMaybe<TaxForecastProfileTaxCalculationMode>;
  vatDefaultRate?: InputMaybe<Scalars['Float']['input']>;
};

export enum TaxForecastProfileFamilySituation {
  Couple = 'COUPLE',
  Single = 'SINGLE',
  Widow = 'WIDOW'
}

export enum TaxForecastProfileMainActivityType {
  Commercial = 'COMMERCIAL',
  Craft = 'CRAFT',
  Liberal = 'LIBERAL'
}

export enum TaxForecastProfileSubActivityType {
  Selling = 'SELLING',
  Service = 'SERVICE'
}

export enum TaxForecastProfileTaxCalculationMode {
  Custom = 'CUSTOM',
  Neutral = 'NEUTRAL',
  Standard = 'STANDARD'
}

export enum TaxFrequency {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type TaxResidency = {
  __typename: 'TaxResidency';
  country: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  fiscalId: Scalars['String']['output'];
  taxResidencyId: Scalars['ID']['output'];
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TaxResidencyInput = {
  country: Scalars['String']['input'];
  fiscalId?: InputMaybe<Scalars['String']['input']>;
};

export enum TaxTypeEnum {
  Cfp = 'CFP',
  FraisChambre = 'FRAIS_CHAMBRE',
  PrelevLib = 'PRELEV_LIB',
  Rsi = 'RSI'
}

export type TeamMember = {
  __typename: 'TeamMember';
  companyUserOrInvitationId: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  hasOngoingInvitation: Scalars['Boolean']['output'];
  isCompanyUser: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  ongoingInvitationId: Maybe<Scalars['ID']['output']>;
  ongoingInvitationRole: Maybe<TeamMemberRole>;
  paymentCardsCount: Scalars['Int']['output'];
  roles: Maybe<Array<TeamMemberRole>>;
  status: TeamMemberStatus;
  transitions: Array<TeamMemberTransition>;
};

export type TeamMemberAppliedTransitionSummary = {
  __typename: 'TeamMemberAppliedTransitionSummary';
  companyUserId: Scalars['ID']['output'];
  invitationId: Maybe<Scalars['ID']['output']>;
  isInvitation: Scalars['Boolean']['output'];
  role: TeamMemberTransitionRole;
};

export type TeamMemberConnection = {
  __typename: 'TeamMemberConnection';
  edges: Array<TeamMemberConnectionEdge>;
  pageInfo: Maybe<PageInfo>;
};

export type TeamMemberConnectionEdge = {
  __typename: 'TeamMemberConnectionEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: TeamMember;
};

export enum TeamMemberRole {
  Accountant = 'ACCOUNTANT',
  AccountHolder = 'ACCOUNT_HOLDER',
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE',
  None = 'NONE'
}

export enum TeamMemberStatus {
  Active = 'ACTIVE',
  InvitationSent = 'INVITATION_SENT',
  Locked = 'LOCKED',
  ValidationOngoing = 'VALIDATION_ONGOING'
}

export type TeamMemberTransition = {
  __typename: 'TeamMemberTransition';
  disabledReason: Maybe<TeamMemberTransitionDisabledReason>;
  isDisabled: Scalars['Boolean']['output'];
  isLegalBeneficiarySpecialRole: Scalars['Boolean']['output'];
  requiringInvitation: TeamMemberTransitionInvitationState;
  role: TeamMemberTransitionRole;
};

export enum TeamMemberTransitionDisabledReason {
  AlreadyHasRole = 'ALREADY_HAS_ROLE',
  BeneficiaryOnlyTransition = 'BENEFICIARY_ONLY_TRANSITION',
  CannotSelfEdit = 'CANNOT_SELF_EDIT',
  KycNotValidated = 'KYC_NOT_VALIDATED',
  PendingInvitation = 'PENDING_INVITATION',
  PermissionTooLow = 'PERMISSION_TOO_LOW'
}

export type TeamMemberTransitionInput = {
  companyUserId: Scalars['ID']['input'];
  role: TeamMemberTransitionRole;
};

export enum TeamMemberTransitionInvitationState {
  No = 'NO',
  Unknown = 'UNKNOWN',
  Yes = 'YES'
}

export enum TeamMemberTransitionRole {
  Accountant = 'ACCOUNTANT',
  AccountHolder = 'ACCOUNT_HOLDER',
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE',
  None = 'NONE'
}

export type TeamMembersRequest = BankTransferRequest | ExpenseClaimRequest | PhysicalPaymentCardOrderRequest | VirtualBudgetCardOrderRequest | VirtualPaymentCardOrderRequest;

export enum TeamMembersRequestCancelReason {
  ByRequester = 'BY_REQUESTER',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  RequesterRevoked = 'REQUESTER_REVOKED'
}

export type TeamMembersRequestRequester = {
  __typename: 'TeamMembersRequestRequester';
  companyUserId: Scalars['UUIDv4']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['ID']['output'];
};

export type TeamMembersRequestReviewer = {
  __typename: 'TeamMembersRequestReviewer';
  companyUserId: Scalars['UUIDv4']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['ID']['output'];
};

export enum TeamMembersRequestStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Rejected = 'REJECTED'
}

export type TeamMembersRequestSupportingDocumentInput = {
  bucketName: Scalars['String']['input'];
  fileRef: Scalars['String']['input'];
};

export enum TeamMembersRequestType {
  BankTransfer = 'BANK_TRANSFER',
  ExpenseClaim = 'EXPENSE_CLAIM',
  PhysicalPaymentCardOrder = 'PHYSICAL_PAYMENT_CARD_ORDER',
  VirtualBudgetCardOrder = 'VIRTUAL_BUDGET_CARD_ORDER',
  VirtualPaymentCardOrder = 'VIRTUAL_PAYMENT_CARD_ORDER'
}

export type TeamMembersRequestsConnection = {
  __typename: 'TeamMembersRequestsConnection';
  edges: Array<TeamMembersRequestsConnectionEdge>;
  pageInfo: TeamMembersRequestsPageInfo;
};

export type TeamMembersRequestsConnectionEdge = {
  __typename: 'TeamMembersRequestsConnectionEdge';
  node: TeamMembersRequest;
};

export type TeamMembersRequestsFilters = {
  /**
   * Returns only TeamMembersRequests in status "APPROVED", "PROCESSED", "COMPLETED", "CANCELED" or "REJECTED"
   * (more efficient than querying by each statuses).
   */
  onlyReviewed?: InputMaybe<Scalars['Boolean']['input']>;
  requesterCompanyUserId?: InputMaybe<Scalars['UUIDv4']['input']>;
  status?: InputMaybe<Array<TeamMembersRequestStatus>>;
  type?: InputMaybe<Array<TeamMembersRequestType>>;
};

export type TeamMembersRequestsPageInfo = {
  __typename: 'TeamMembersRequestsPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  nextCursor: Maybe<Scalars['ID']['output']>;
  totalCount: Scalars['Int']['output'];
  totalCountInCompany: Scalars['Int']['output'];
};

export type TeamMembersStats = {
  __typename: 'TeamMembersStats';
  teamMemberCompanyUserId: Scalars['String']['output'];
  teamMemberName: Scalars['String']['output'];
  teamMemberPayoutValue: Scalars['Float']['output'];
  teamMemberTransactionsCount: Scalars['Int']['output'];
};

export type ThirdPartyClient = {
  __typename: 'ThirdPartyClient';
  authenticationClientId: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  logoURL: Scalars['String']['output'];
};

export type ToggleCardDoneInput = {
  cardId: Scalars['ID']['input'];
  done: Scalars['Boolean']['input'];
};

export type ToggleTransactionHiddenInput = {
  isPersonal: Scalars['Boolean']['input'];
  transactionId: Scalars['ID']['input'];
};

export enum TokenRequestor {
  Apple = 'APPLE',
  Google = 'GOOGLE'
}

export type ToolboxCollection = {
  __typename: 'ToolboxCollection';
  cardBg: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  offers: Array<ToolboxOffer>;
  title: Maybe<Scalars['String']['output']>;
  toolboxCollectionId: Scalars['ID']['output'];
};

export type ToolboxCollectionConnection = {
  __typename: 'ToolboxCollectionConnection';
  edges: Array<ToolboxCollectionConnectionEdge>;
  pageInfo: PageInfo;
};

export type ToolboxCollectionConnectionEdge = {
  __typename: 'ToolboxCollectionConnectionEdge';
  cursor: Scalars['String']['output'];
  node: ToolboxCollection;
};

export type ToolboxIntegration = {
  __typename: 'ToolboxIntegration';
  content: Maybe<Array<ToolboxOfferContent>>;
  description: Maybe<Scalars['String']['output']>;
  link: Maybe<Scalars['String']['output']>;
  partner: Maybe<ToolboxPartner>;
  perk: Maybe<Array<PrismicRichText>>;
  similarIntegrations: Maybe<Array<ToolboxIntegration>>;
  tagsIds: Array<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  toolboxIntegrationId: Scalars['ID']['output'];
};

export type ToolboxIntegrationConnection = {
  __typename: 'ToolboxIntegrationConnection';
  edges: Array<ToolboxIntegrationConnectionEdge>;
  pageInfo: PageInfo;
};

export type ToolboxIntegrationConnectionEdge = {
  __typename: 'ToolboxIntegrationConnectionEdge';
  cursor: Scalars['String']['output'];
  node: ToolboxIntegration;
};

export type ToolboxIntegrationsCountFilters = {
  tagsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ToolboxIntegrationsFilters = {
  tagsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ToolboxOffer = {
  __typename: 'ToolboxOffer';
  content: Maybe<Array<ToolboxOfferContent>>;
  cover: Maybe<Scalars['String']['output']>;
  link: Maybe<Scalars['String']['output']>;
  partner: Maybe<ToolboxPartner>;
  perk: Maybe<Array<PrismicRichText>>;
  perkBadge: Maybe<Scalars['String']['output']>;
  similarOffers: Maybe<Array<ToolboxOffer>>;
  tagsIds: Array<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  toolboxOfferId: Scalars['ID']['output'];
};

export type ToolboxOfferConnection = {
  __typename: 'ToolboxOfferConnection';
  edges: Array<ToolboxOfferConnectionEdge>;
  pageInfo: PageInfo;
};

export type ToolboxOfferConnectionEdge = {
  __typename: 'ToolboxOfferConnectionEdge';
  cursor: Scalars['String']['output'];
  node: ToolboxOffer;
};

export type ToolboxOfferContent = {
  __typename: 'ToolboxOfferContent';
  content: Maybe<Array<PrismicRichText>>;
  title: Maybe<Array<PrismicRichText>>;
};

export enum ToolboxOfferType {
  Integration = 'integration',
  Offer = 'offer'
}

export type ToolboxOffersCountFilters = {
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  tagsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ToolboxOffersFilters = {
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  tagsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ToolboxPartner = {
  __typename: 'ToolboxPartner';
  catchline: Maybe<Scalars['String']['output']>;
  description: Maybe<Array<PrismicRichText>>;
  link: Maybe<Scalars['String']['output']>;
  logo: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  toolboxPartnerId: Scalars['ID']['output'];
};

export type ToolboxTag = {
  __typename: 'ToolboxTag';
  icon: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  toolboxTagId: Scalars['ID']['output'];
};

export type TrackSegmentEventInput = {
  companyProfileId?: InputMaybe<Scalars['String']['input']>;
  event: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['JSON']['input']>;
};

export type Transaction = {
  __typename: 'Transaction';
  bankAccountId: Scalars['ID']['output'];
  bankTransfer: Maybe<BankTransfer>;
  bankTransferId: Scalars['ID']['output'];
  bankTransferStatus: Maybe<TransactionBankTransferStatus>;
  cardPaymentMethod: Maybe<TransactionCardPaymentMethod>;
  category: TransactionCategory;
  categoryIds: Maybe<Array<Scalars['ID']['output']>>;
  companyUserId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Float']['output'];
  creditCard: Maybe<CreditCardInterface>;
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  directDebitMandate: Maybe<DirectDebitMandate>;
  directDebitMandateId: Scalars['ID']['output'];
  enrichment: Maybe<TransactionEnrichment>;
  expectedReleaseDate: Maybe<Scalars['String']['output']>;
  fee: Scalars['Float']['output'];
  feeType: TransactionFeeType;
  foreignEntityId: Maybe<Scalars['String']['output']>;
  foreignEntityType: Maybe<Scalars['String']['output']>;
  hasHelpQuestions: Maybe<Scalars['Boolean']['output']>;
  hasReceipts: Scalars['Boolean']['output'];
  helpQuestions: Maybe<Array<Maybe<TransactionHelpQuestion>>>;
  initiatorName: Maybe<Scalars['String']['output']>;
  internationalTransferPayout: Maybe<InternationalTransferPayout>;
  /** @deprecated Use Transaction.invoices instead. */
  invoiceId: Maybe<Scalars['ID']['output']>;
  invoices: Array<TransactionLinkedInvoice>;
  isCancelable: Maybe<Scalars['Boolean']['output']>;
  isHidden: Maybe<Scalars['Boolean']['output']>;
  isOnAgentWallet: Scalars['Boolean']['output'];
  isPersonal: Scalars['Boolean']['output'];
  isRefund: Scalars['Boolean']['output'];
  note: Maybe<Scalars['String']['output']>;
  payinVirtualStatus: Maybe<Scalars['String']['output']>;
  paymentMethod: TransactionPaymentMethod;
  providerCardDigitalizationExternalId: Maybe<Scalars['String']['output']>;
  providerTransferTag: Maybe<Scalars['String']['output']>;
  /** @deprecated Use Transaction.receipts instead */
  receipt: Maybe<Receipt>;
  receipts: Maybe<Array<Receipt>>;
  remainingAuthorized: Maybe<Scalars['Float']['output']>;
  status: TransactionStatus;
  subBankAccountId: Maybe<Scalars['ID']['output']>;
  swiftTransferPayin: Maybe<SwiftTransferPayin>;
  title: Scalars['String']['output'];
  transactionAt: Scalars['Float']['output'];
  transactionId: Scalars['ID']['output'];
  transactionSettledAt: Maybe<Scalars['Float']['output']>;
  transferPayinSenderName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export enum TransactionBankTransferStatus {
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  SentToSepaNetwork = 'SENT_TO_SEPA_NETWORK',
  SuspendedTms = 'SUSPENDED_TMS',
  Validated = 'VALIDATED'
}

export enum TransactionCardPaymentMethod {
  Contactless = 'CONTACTLESS',
  DirectSettlement = 'DIRECT_SETTLEMENT',
  Magnetic = 'MAGNETIC',
  Online = 'ONLINE',
  Other = 'OTHER',
  Pin = 'PIN',
  Recurring = 'RECURRING',
  Refund = 'REFUND'
}

export type TransactionCategoriesMccStats = {
  __typename: 'TransactionCategoriesMccStats';
  count: Scalars['Int']['output'];
  name: TransactionCategoryMccName;
  transactionCategoryIds: Maybe<Array<Scalars['String']['output']>>;
  value: Scalars['Float']['output'];
};

export type TransactionCategoriesMccValues = {
  __typename: 'TransactionCategoriesMccValues';
  newValue: Maybe<Array<TransactionCategoriesMccStats>>;
  oldValue: Maybe<Array<TransactionCategoriesMccStats>>;
};

export enum TransactionCategory {
  AlcoolTobacco = 'ALCOOL_TOBACCO',
  Banking = 'BANKING',
  Betting = 'BETTING',
  Cash = 'CASH',
  Contributions = 'CONTRIBUTIONS',
  Default = 'DEFAULT',
  Equipment = 'EQUIPMENT',
  Food = 'FOOD',
  Hotel = 'HOTEL',
  Legal = 'LEGAL',
  Loan = 'LOAN',
  Maintenance = 'MAINTENANCE',
  Misc = 'MISC',
  Personal = 'PERSONAL',
  Rent = 'RENT',
  Services = 'SERVICES',
  Tax = 'TAX',
  Telecom = 'TELECOM',
  Transportation = 'TRANSPORTATION',
  Utilities = 'UTILITIES',
  Vehicle = 'VEHICLE'
}

export type TransactionCategoryMcc = {
  __typename: 'TransactionCategoryMcc';
  createdAt: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['String']['output']>;
  icon: Maybe<Scalars['String']['output']>;
  iconColor: Maybe<Scalars['String']['output']>;
  mccFrom: Scalars['Int']['output'];
  mccTo: Scalars['Int']['output'];
  name: TransactionCategoryMccName;
  transactionCategoryId: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TransactionCategoryMccName = {
  __typename: 'TransactionCategoryMccName';
  en: Scalars['String']['output'];
  fr: Scalars['String']['output'];
};

export type TransactionEdge = {
  __typename: 'TransactionEdge';
  cursor: Scalars['String']['output'];
  node: Transaction;
};

export type TransactionEnrichment = {
  __typename: 'TransactionEnrichment';
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  logoFileBucket: Maybe<Scalars['String']['output']>;
  logoFileRef: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  parentCity: Maybe<Scalars['String']['output']>;
  parentCountry: Maybe<Scalars['String']['output']>;
  parentLogoFileBucket: Maybe<Scalars['String']['output']>;
  parentLogoFileRef: Maybe<Scalars['String']['output']>;
  parentName: Maybe<Scalars['String']['output']>;
  parentStreet: Maybe<Scalars['String']['output']>;
  parentTransactionEntityId: Maybe<Scalars['ID']['output']>;
  parentWebsite: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
  transactionEntityId: Maybe<Scalars['ID']['output']>;
  transactionEntityMappingId: Maybe<Scalars['ID']['output']>;
  transactionId: Scalars['ID']['output'];
  website: Maybe<Scalars['String']['output']>;
};

export type TransactionExport = {
  __typename: 'TransactionExport';
  url: Scalars['String']['output'];
};

export enum TransactionFeeType {
  AccountIrregularUse = 'ACCOUNT_IRREGULAR_USE',
  AdditionalTransfer = 'ADDITIONAL_TRANSFER',
  CardAtmEuro = 'CARD_ATM_EURO',
  CardAtmNonEuro = 'CARD_ATM_NON_EURO',
  CardPayinEuro = 'CARD_PAYIN_EURO',
  CardPayinNonEu = 'CARD_PAYIN_NON_EU',
  CardPayinNonEuro = 'CARD_PAYIN_NON_EURO',
  CardPaymentDispute = 'CARD_PAYMENT_DISPUTE',
  CardPaymentIrregularity = 'CARD_PAYMENT_IRREGULARITY',
  CardPayoutNonEu = 'CARD_PAYOUT_NON_EU',
  CardPayoutNonEuro = 'CARD_PAYOUT_NON_EURO',
  CardReplacementBasic = 'CARD_REPLACEMENT_BASIC',
  CardReplacementPremium = 'CARD_REPLACEMENT_PREMIUM',
  CashDeposit = 'CASH_DEPOSIT',
  Check = 'CHECK',
  CheckInvalid = 'CHECK_INVALID',
  CheckRefused = 'CHECK_REFUSED',
  DirectDebitCancellation = 'DIRECT_DEBIT_CANCELLATION',
  Free = 'FREE',
  InternationalTransferPayout = 'INTERNATIONAL_TRANSFER_PAYOUT',
  SwiftTransferPayin = 'SWIFT_TRANSFER_PAYIN'
}

export type TransactionHelpQuestion = {
  __typename: 'TransactionHelpQuestion';
  answer: Scalars['String']['output'];
  helpLabel: Maybe<Scalars['String']['output']>;
  helpLink: Maybe<Scalars['String']['output']>;
  prefilledMessage: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
};

export type TransactionLinkedInvoice = {
  __typename: 'TransactionLinkedInvoice';
  fiscalId: Scalars['String']['output'];
  invoiceId: Scalars['ID']['output'];
};

export type TransactionPayload = {
  __typename: 'TransactionPayload';
  transaction: Maybe<Transaction>;
};

export enum TransactionPaymentMethod {
  Card = 'CARD',
  CashDeposit = 'CASH_DEPOSIT',
  Check = 'CHECK',
  DirectDebit = 'DIRECT_DEBIT',
  InstantTransfer = 'INSTANT_TRANSFER',
  InternationalTransfer = 'INTERNATIONAL_TRANSFER',
  IntraCompanyFundsTransfer = 'INTRA_COMPANY_FUNDS_TRANSFER',
  SwiftTransfer = 'SWIFT_TRANSFER',
  Transfer = 'TRANSFER',
  WalletTransfer = 'WALLET_TRANSFER'
}

export type TransactionSearchEdge = {
  __typename: 'TransactionSearchEdge';
  node: Transaction;
};

export enum TransactionStatus {
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Validated = 'VALIDATED'
}

export enum TransactionType {
  Payin = 'payin',
  Payout = 'payout'
}

export type TransactionWidgetEntry = {
  __typename: 'TransactionWidgetEntry';
  bankAccountId: Scalars['ID']['output'];
  category: TransactionCategory;
  categoryIds: Maybe<Array<Scalars['ID']['output']>>;
  companyUserId: Maybe<Scalars['ID']['output']>;
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  feeType: TransactionFeeType;
  foreignEntityId: Maybe<Scalars['ID']['output']>;
  foreignEntityType: Maybe<Scalars['String']['output']>;
  initiatorName: Maybe<Scalars['String']['output']>;
  isRefund: Scalars['Boolean']['output'];
  logoUri: Maybe<Scalars['String']['output']>;
  paymentMethod: TransactionPaymentMethod;
  status: TransactionStatus;
  title: Scalars['String']['output'];
  transactionAt: Scalars['Float']['output'];
  transactionId: Scalars['ID']['output'];
  transferPayinSenderName: Scalars['String']['output'];
  type: TransactionType;
  value: Scalars['Float']['output'];
};

export type TransactionsFilters = {
  afterDate?: InputMaybe<Scalars['String']['input']>;
  bankAccountId?: InputMaybe<Scalars['ID']['input']>;
  beforeDate?: InputMaybe<Scalars['String']['input']>;
  companyProfileId?: InputMaybe<Scalars['UUIDv4']['input']>;
  companyUserId?: InputMaybe<Array<Scalars['ID']['input']>>;
  hasReceipts?: InputMaybe<Scalars['Boolean']['input']>;
  isPersonal?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<Array<TransactionPaymentMethod>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<TransactionStatus>>;
  type?: InputMaybe<Array<TransactionType>>;
  valueGreaterThan?: InputMaybe<Scalars['Float']['input']>;
  valueLesserThan?: InputMaybe<Scalars['Float']['input']>;
};

export type TransactionsSearchConnection = {
  __typename: 'TransactionsSearchConnection';
  edges: Array<TransactionSearchEdge>;
  pageInfo: TransactionsSearchPageInfo;
};

export type TransactionsSearchPageInfo = {
  __typename: 'TransactionsSearchPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  nextCursor: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type TransactionsSumFilters = {
  bankAccountId: Scalars['ID']['input'];
  category?: InputMaybe<Array<TransactionCategory>>;
  isPersonal?: InputMaybe<Scalars['Boolean']['input']>;
  isRefund?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<Array<TransactionPaymentMethod>>;
  status?: InputMaybe<TransactionStatus>;
  transactionAfter?: InputMaybe<Scalars['Float']['input']>;
  transactionBefore?: InputMaybe<Scalars['Float']['input']>;
  type: TransactionType;
};

export type TransactionsWidget = {
  __typename: 'TransactionsWidget';
  entries: Array<TransactionWidgetEntry>;
};

export type Transfer = {
  __typename: 'Transfer';
  amount: Scalars['Float']['output'];
  bankTransferRecipient: Recipient;
  currency: Scalars['String']['output'];
  errors: Array<BulkBankTransferError>;
  userLabel: Maybe<Scalars['String']['output']>;
};

export type TransferInput = {
  amount: Scalars['Float']['input'];
  bankTransferRecipient: BankTransferRecipientInput;
  currency: Scalars['String']['input'];
  userLabel?: InputMaybe<Scalars['String']['input']>;
};

export type UnblockAuthenticationDeviceInput = {
  authenticationDeviceId: Scalars['ID']['input'];
  phone: Scalars['ID']['input'];
};

export type UnblockAuthenticationDevicePayload = {
  __typename: 'UnblockAuthenticationDevicePayload';
  authenticationDevice: AuthenticationDevice;
};

export type UnblockCreditCardPinInput = {
  creditCardId: Scalars['ID']['input'];
};

export type UnderReviewWidget = KycUnderReviewWidget | OnboardingTimelineWidget;

export enum UnderReviewWidgetType {
  KycUnderReview = 'KycUnderReview',
  OnboardingTimeline = 'OnboardingTimeline'
}

export type UnlinkFromInvoiceInput = {
  invoiceId: Scalars['ID']['input'];
  transactionId: Scalars['ID']['input'];
};

export type UnlockCompanyUserInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: Scalars['ID']['input'];
};

export type UnrevokeDirectDebitMandateInput = {
  directDebitMandateId: Scalars['ID']['input'];
};

export type UpdateAuthenticationDeviceNotificationTokenPayload = {
  __typename: 'UpdateAuthenticationDeviceNotificationTokenPayload';
  authenticationDevice: AuthenticationDevice;
};

export type UpdateBankTransferRecipientInput = {
  bankTransferRecipientId: Scalars['ID']['input'];
  isUserBankAccount?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCapitalDepositInput = {
  capitalDepositId: Scalars['ID']['input'];
  hasEuropeanBankAccount?: InputMaybe<Scalars['Boolean']['input']>;
  paidUpCapitalPercentage?: InputMaybe<Scalars['Float']['input']>;
  partner?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCatalogItemInput = {
  invoiceCatalogItemId: Scalars['ID']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TaskType>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  vatPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateClientContactInput = {
  clientContactId: Scalars['ID']['input'];
  clientId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClientInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['ID']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  legalRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ClientType>;
  vatId?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyInvitationEmailInput = {
  companyInvitationId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  inviteeEmail: Scalars['String']['input'];
};

export type UpdateCompanyLogoInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  logoBucketName?: InputMaybe<Scalars['String']['input']>;
  logoFileRef?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCreditCardAliasInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  creditCardId: Scalars['ID']['input'];
};

export type UpdateCreditCardDeactivateAtInput = {
  creditCardId: Scalars['ID']['input'];
  deactivateAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCreditCardLimitsInput = {
  creditCardId: Scalars['ID']['input'];
  limitAtmMonth?: InputMaybe<Scalars['Int']['input']>;
  limitAtmWeek?: InputMaybe<Scalars['Int']['input']>;
  limitPaymentWeek?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateCreditCardOptionsInput = {
  creditCardId: Scalars['ID']['input'];
  optionAtm?: InputMaybe<Scalars['Boolean']['input']>;
  optionForeign?: InputMaybe<Scalars['Boolean']['input']>;
  optionNfc?: InputMaybe<Scalars['Boolean']['input']>;
  optionOnline?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCreditCardPinInput = {
  creditCardId: Scalars['ID']['input'];
  pin: Scalars['String']['input'];
};

export type UpdateCreditCardStatusInput = {
  creditCardId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type UpdateInvoiceInput = {
  allowCreditCardPayment?: InputMaybe<Scalars['Boolean']['input']>;
  allowIbanPayment?: InputMaybe<Scalars['Boolean']['input']>;
  clientContactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  clientId?: InputMaybe<Scalars['ID']['input']>;
  createdTasks?: InputMaybe<Array<InvoiceTaskInput>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  deletedTaskIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  fiscalId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
  isUserCcedOnEmails?: InputMaybe<Scalars['Boolean']['input']>;
  lateFeePercentage?: InputMaybe<Scalars['Float']['input']>;
  lateMaxDays?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  particularMentions?: InputMaybe<Array<ParticularMentionInput>>;
  paymentMentions?: InputMaybe<Array<InvoicePaymentMentionInput>>;
  settings?: InputMaybe<InvoiceSettingsInput>;
  startAt?: InputMaybe<Scalars['Float']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedTasks?: InputMaybe<Array<UpdateInvoiceTaskInput>>;
};

export type UpdateInvoicePaymentMethodsInput = {
  allowCreditCardPayment?: InputMaybe<Scalars['Boolean']['input']>;
  allowIbanPayment?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceId: Scalars['ID']['input'];
};

export type UpdateInvoicePresetsInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  invoicingDefaultCustomLegalMentions?: InputMaybe<Scalars['String']['input']>;
  invoicingDefaultDisplayTradeName?: InputMaybe<Scalars['Boolean']['input']>;
  invoicingDefaultIssuerEmail?: InputMaybe<Scalars['String']['input']>;
  invoicingDefaultLateFeeFloatPercentage?: InputMaybe<Scalars['Float']['input']>;
  invoicingDefaultLateMaxDaysOther?: InputMaybe<Scalars['Int']['input']>;
  invoicingDefaultLateMaxDaysType?: InputMaybe<InvoicingDefaultLateMaxDaysTypeEnum>;
  invoicingDefaultQuoteValidityDays?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateInvoiceTaskInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  invoiceCatalogItemId?: InputMaybe<Scalars['ID']['input']>;
  invoiceTaskId: Scalars['ID']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  taskOrder?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TaskType>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  units?: InputMaybe<Scalars['Float']['input']>;
  vatPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateNotificationTokenInput = {
  deviceToken: Scalars['String']['input'];
  notificationToken: Scalars['String']['input'];
};

export type UpdateOnboardingCompanyAddressInput = {
  city: Scalars['String']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  street: Scalars['String']['input'];
  streetAddition?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type UpdateOnboardingCompanyAddressPayload = {
  __typename: 'UpdateOnboardingCompanyAddressPayload';
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export type UpdateOnboardingCompanyProfileInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  inseeLegalFormCode?: InputMaybe<Scalars['String']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNumberOfShares?: InputMaybe<Scalars['Int']['input']>;
  legalShareCapital?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOnboardingCompanyProfilePayload = {
  __typename: 'UpdateOnboardingCompanyProfilePayload';
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export type UpdateOnboardingCompanySanctionsDeclarationInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  hasActivityOutsideEU: Scalars['Boolean']['input'];
  hasClientHelpingCountryUnderSanctions?: InputMaybe<Scalars['Boolean']['input']>;
  hasClientInCountryUnderSanctions?: InputMaybe<Scalars['Boolean']['input']>;
  hasClientUnderSanctions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOnboardingCompanySanctionsDeclarationPayload = {
  __typename: 'UpdateOnboardingCompanySanctionsDeclarationPayload';
  onboardingCompanyProfile: OnboardingCompanyProfile;
};

export type UpdatePhysicalPersonLegalBeneficiarySoloInput = {
  companyLegalBeneficiaryId: Scalars['ID']['input'];
  companyProfileId: Scalars['UUIDv4']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  inviteeEmail?: InputMaybe<Scalars['String']['input']>;
  isDirector?: InputMaybe<Scalars['Boolean']['input']>;
  isFutureAccountHolder?: InputMaybe<Scalars['Boolean']['input']>;
  isMajorShareholder?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  numberOfShares?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateQuoteInput = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  createdTasks?: InputMaybe<Array<InvoiceTaskInput>>;
  deletedTaskIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  fiscalId?: InputMaybe<Scalars['String']['input']>;
  lateFeePercentage?: InputMaybe<Scalars['Float']['input']>;
  lateMaxDays?: InputMaybe<Scalars['Float']['input']>;
  particularMentions?: InputMaybe<Array<ParticularMentionInput>>;
  paymentMentions?: InputMaybe<Array<QuotePaymentMentionInput>>;
  quoteId: Scalars['ID']['input'];
  settings?: InputMaybe<QuoteSettingsInput>;
  startAt?: InputMaybe<Scalars['Float']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedTasks?: InputMaybe<Array<UpdateInvoiceTaskInput>>;
};

export type UpdateSecondaryBankAccountInput = {
  bankAccountId: Scalars['ID']['input'];
  illustrationName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShineRecurringAccountingExportSettingsInput = {
  accountingExportSettingsId: Scalars['ID']['input'];
  cadenceSettings?: InputMaybe<ShineRecurringAccountingExportSettingsCadenceSettingsInput>;
  formats?: InputMaybe<Array<ShineAccountingExportFormat>>;
  includeBankStatements?: InputMaybe<Scalars['Boolean']['input']>;
  includeInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  includePersonalTransactions?: InputMaybe<Scalars['Boolean']['input']>;
  recipients?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateShineStartDocumentInput = {
  error?: InputMaybe<ShineStartDocumentError>;
  errorLocalized?: InputMaybe<Scalars['String']['input']>;
  firebaseBucket?: InputMaybe<Scalars['String']['input']>;
  firebaseRef?: InputMaybe<Scalars['String']['input']>;
  shineStartDocumentId: Scalars['ID']['input'];
  shineStartId: Scalars['ID']['input'];
  status?: InputMaybe<ShineStartDocumentStatus>;
  type?: InputMaybe<ShineStartDocumentType>;
  typeLocalized?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaxDataInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
  taxForecastPeriodData?: InputMaybe<TaxForecastPeriodData>;
  taxForecastProfileData?: InputMaybe<TaxForecastProfileData>;
  taxFrequency: Scalars['String']['input'];
};

export type UpdateTaxDataResponse = {
  __typename: 'UpdateTaxDataResponse';
  currentTaxForecastPeriod: TaxForecastPeriod;
  taxForecastProfile: TaxForecastProfile;
};

export type UpdateTaxForecastProfileInput = {
  isSubjectToVat?: InputMaybe<Scalars['Boolean']['input']>;
  revenueBeforeShine?: InputMaybe<Scalars['Float']['input']>;
  subjectToVatSince?: InputMaybe<Scalars['String']['input']>;
  taxForecastProfileId: Scalars['ID']['input'];
  vatDefaultRate?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTaxResidencyInput = {
  country: Scalars['String']['input'];
  fiscalId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaxResidencyPayload = {
  __typename: 'UpdateTaxResidencyPayload';
  taxResidency: Maybe<TaxResidency>;
  userProfile: UserProfile;
};

export type UpdateTransactionNoteInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  transactionId: Scalars['ID']['input'];
};

export type UpdateUserBankDetailsInput = {
  bankDetailsId: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  swiftBic: Scalars['String']['input'];
};

export type UpdateUserComplianceInfoInput = {
  hasForeignFiscalResidence?: InputMaybe<Scalars['Boolean']['input']>;
  incomeRange?: InputMaybe<Scalars['String']['input']>;
  isSpecifiedUSPerson?: InputMaybe<Scalars['Boolean']['input']>;
  personalAssets?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserComplianceInfoPayload = {
  __typename: 'UpdateUserComplianceInfoPayload';
  profile: Maybe<UserProfile>;
};

export type UpdateUserPersonalInfoInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  companyProfileId: Scalars['UUIDv4']['input'];
  firstName: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  nationality?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVatAmountInput = {
  vatAmountId: Scalars['ID']['input'];
  vatAmountItems: Array<UpdateVatAmountItemInput>;
};

export type UpdateVatAmountItemInput = {
  trueVatAmount: Scalars['Float']['input'];
  trueVatRate: Scalars['Float']['input'];
  vatAmountItemId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpgradeDowngradePayload = {
  __typename: 'UpgradeDowngradePayload';
  companyMetadata: Maybe<CompanyMetadata>;
  companyProfileId: Scalars['UUIDv4']['output'];
  metadata: Maybe<UserMetadata>;
  subscriptionPlan: Maybe<SubscriptionPlan>;
};

export type UpgradeInfo = {
  __typename: 'UpgradeInfo';
  addonUpgradeCost: Scalars['Float']['output'];
  nextBillingDate: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  upgradeCost: Scalars['Float']['output'];
};

export type UserBankDetails = {
  __typename: 'UserBankDetails';
  bankDetailsId: Scalars['ID']['output'];
  iban: Scalars['String']['output'];
  recipientName: Scalars['String']['output'];
  swiftBic: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type UserFilter = {
  __typename: 'UserFilter';
  field: FeatureFilterField;
  values: Array<UserFilterValues>;
};

export enum UserFilterValues {
  Self = 'self'
}

export type UserMetadata = {
  __typename: 'UserMetadata';
  appLang: Maybe<Scalars['String']['output']>;
  confidenceNote: Maybe<Scalars['String']['output']>;
  confidenceStatus: Maybe<Scalars['String']['output']>;
  confidenceUpdatedAt: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['Float']['output']>;
  deviceToken: Maybe<Scalars['String']['output']>;
  hasAgreedToTermsOfService: Maybe<Scalars['Boolean']['output']>;
  hasFinishedKYCDocSetup: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Not compatible with multi account */
  hasFinishedProfileSetup: Maybe<Scalars['Boolean']['output']>;
  hasRequestedCallbackFromExperts: Maybe<Scalars['Boolean']['output']>;
  hasSelectedPremium: Scalars['Boolean']['output'];
  isAccountClosed: Scalars['Boolean']['output'];
  isKycInProgress: Maybe<Scalars['Boolean']['output']>;
  isKycValidated: Maybe<Scalars['Boolean']['output']>;
  isLocked: Maybe<Scalars['Boolean']['output']>;
  isMandatee: Scalars['Boolean']['output'];
  isShineEmployeeAccount: Maybe<Scalars['Boolean']['output']>;
  kycError: Maybe<Scalars['String']['output']>;
  kycErrorLocalized: Maybe<Scalars['String']['output']>;
  kycErrorTag: Maybe<Scalars['String']['output']>;
  kycValidatedAt: Maybe<Scalars['Float']['output']>;
  lockedCustomMessage: Maybe<Scalars['String']['output']>;
  lockedReason: Maybe<LockedReason>;
  notificationStatus: Maybe<Scalars['String']['output']>;
  signupAccountType: Maybe<Scalars['String']['output']>;
  status: Maybe<UserMetadataStatus>;
  uid: Scalars['ID']['output'];
  updatedAt: Maybe<Scalars['Float']['output']>;
};

export type UserMetadataInput = {
  appLang?: InputMaybe<Scalars['String']['input']>;
  deviceToken?: InputMaybe<Scalars['String']['input']>;
  hasAgreedToTermsOfService?: InputMaybe<Scalars['Boolean']['input']>;
  hasFinishedProfileSetup?: InputMaybe<Scalars['Boolean']['input']>;
  hasRequestedCallbackFromExperts?: InputMaybe<Scalars['Boolean']['input']>;
  notificationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type UserMetadataPayload = {
  __typename: 'UserMetadataPayload';
  metadata: Maybe<UserMetadata>;
};

export enum UserMetadataStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Locked = 'LOCKED'
}

export type UserProfile = {
  __typename: 'UserProfile';
  bankDetails: Maybe<UserBankDetails>;
  birthCity: Maybe<Scalars['String']['output']>;
  birthCountry: Maybe<Scalars['String']['output']>;
  birthDepartment: Maybe<Scalars['String']['output']>;
  birthdate: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['Float']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  hasFinishedProfileSetup: Scalars['Boolean']['output'];
  hasForeignFiscalResidence: Maybe<Scalars['Boolean']['output']>;
  incomeRange: Maybe<Scalars['String']['output']>;
  isAddressFilled: Scalars['Boolean']['output'];
  isComplianceFilled: Scalars['Boolean']['output'];
  isEligibleToOnboard: Scalars['Boolean']['output'];
  isSpecifiedUSPerson: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  nationality: Maybe<Scalars['String']['output']>;
  personalAssets: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  street: Maybe<Scalars['String']['output']>;
  streetAddition: Maybe<Scalars['String']['output']>;
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['Float']['output'];
  zip: Maybe<Scalars['String']['output']>;
};

export type UserProfileInput = {
  birthCity?: InputMaybe<Scalars['String']['input']>;
  birthCountry?: InputMaybe<Scalars['String']['input']>;
  birthDepartment?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  hasForeignFiscalResidence?: InputMaybe<Scalars['Boolean']['input']>;
  incomeRange?: InputMaybe<Scalars['String']['input']>;
  isSpecifiedUSPerson?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  personalAssets?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetAddition?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UserProfilePayload = {
  __typename: 'UserProfilePayload';
  profile: Maybe<UserProfile>;
};

export type UserSettings = {
  __typename: 'UserSettings';
  allowContractNotification: Maybe<Scalars['Boolean']['output']>;
  allowIncomingDirectDebitNotification: Maybe<Scalars['Boolean']['output']>;
  allowInvoiceNotification: Maybe<Scalars['Boolean']['output']>;
  allowJobPropositionNotification: Maybe<Scalars['Boolean']['output']>;
  allowMissingReceiptNotification: Maybe<Scalars['Boolean']['output']>;
  allowNewSEPABeneficiaryEmailNotification: Maybe<Scalars['Boolean']['output']>;
  allowPartnersNotification: Maybe<Scalars['Boolean']['output']>;
  allowPayinNotification: Maybe<Scalars['Boolean']['output']>;
  allowPayoutNotification: Maybe<Scalars['Boolean']['output']>;
  allowTaxNotification: Maybe<Scalars['Boolean']['output']>;
  allowTransferPayoutEmailNotification: Maybe<Scalars['Boolean']['output']>;
  canAccessNewAccountingExport: Scalars['Boolean']['output'];
  defaultCurrency: Maybe<Scalars['String']['output']>;
  defaultVatPercentage: Maybe<Scalars['Float']['output']>;
  enableTaxForecastFeatures: Maybe<Scalars['Boolean']['output']>;
  uid: Scalars['ID']['output'];
};

export type UserSettingsInput = {
  allowContractNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowIncomingDirectDebitNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowInvoiceNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowJobPropositionNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowMissingReceiptNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowNewSEPABeneficiaryEmailNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowPartnersNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowPayinNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowPayoutNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowTaxNotification?: InputMaybe<Scalars['Boolean']['input']>;
  allowTransferPayoutEmailNotification?: InputMaybe<Scalars['Boolean']['input']>;
  enableTaxForecastFeatures?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSettingsPayload = {
  __typename: 'UserSettingsPayload';
  settings: Maybe<UserSettings>;
};

export type VatAmount = {
  __typename: 'VatAmount';
  createdAt: Scalars['String']['output'];
  hasBeenSetManually: Scalars['Boolean']['output'];
  receiptId: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
  vatAmountId: Scalars['ID']['output'];
  vatAmountItems: Array<VatAmountItem>;
};

export type VatAmountItem = {
  __typename: 'VatAmountItem';
  createdAt: Scalars['String']['output'];
  predictedVatAmount: Maybe<Scalars['Float']['output']>;
  predictedVatRate: Maybe<Scalars['Float']['output']>;
  trueVatAmount: Maybe<Scalars['Float']['output']>;
  trueVatRate: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['String']['output'];
  vatAmountId: Scalars['ID']['output'];
  vatAmountItemId: Scalars['ID']['output'];
};

export type VerifyChallengeInput = {
  challengeId: Scalars['UUIDv4']['input'];
  name: Scalars['String']['input'];
  signedData?: InputMaybe<Scalars['String']['input']>;
  verificationCode?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyChallengeOutput = {
  __typename: 'VerifyChallengeOutput';
  challengeId: Scalars['UUIDv4']['output'];
  method: Scalars['String']['output'];
  name: Scalars['String']['output'];
  secureSessionToken: Maybe<Scalars['String']['output']>;
};

export type VerifyReferralCodeInput = {
  referralCode: Scalars['String']['input'];
};

export type VerifyReferralCodePayload = {
  __typename: 'VerifyReferralCodePayload';
  firstName: Scalars['String']['output'];
};

export type VerifySensitiveOperationInput = {
  sensitiveOperationId: Scalars['ID']['input'];
  verificationCode: Scalars['String']['input'];
};

export type VerifySensitiveOperationPayload = {
  __typename: 'VerifySensitiveOperationPayload';
  token: Scalars['String']['output'];
};

export type Viewer = {
  __typename: 'Viewer';
  accountingRecurringExport: AccountingRecurringExport;
  accountingRecurringExports: AccountingRecurringExportConnection;
  actionRequests: Array<ActionRequest>;
  /** The onboarding counterpart resolver of Viewer.companyTmp */
  application: Application;
  applications: Array<Application>;
  authenticationDevice: AuthenticationDevice;
  authenticationDeviceRequests: AuthenticationDeviceRequestConnection;
  authenticationDevices: Array<AuthenticationDevice>;
  /** @deprecated Use Viewer.company.bankAccount instead */
  bankAccount: BankAccount;
  bankAccountStatements: ViewerBankAccountStatementsConnection;
  bankAccounts: ViewerBankAccountsConnection;
  bankAccountsIds: Array<Scalars['ID']['output']>;
  canAccessFeature: CanAccessFeature;
  cards: ViewerCardsConnection;
  checkDeposit: CheckDeposit;
  checkDeposits: CheckDepositConnection;
  checkDepositsCount: ViewerTotalCount;
  companies: Array<Company>;
  /** @deprecated Use Viewer.companies instead */
  companiesTmp: Array<Company>;
  company: Company;
  /** @deprecated Use Viewer.company instead */
  companyTmp: Company;
  companyUser: CompanyUser;
  companyUsers: Array<CompanyUser>;
  creditCards: Array<CreditCardInterface>;
  directDebitMandates: ViewerDirectDebitMandatesConnection;
  directDebitMandatesCount: ViewerTotalCount;
  isNewOnboardingAllowed: Scalars['Boolean']['output'];
  /** @deprecated Must not be called directly! Use Mutation.startIdentityVerification instead */
  kycProviderUser: Maybe<KycProviderUser>;
  lastKycReview: Maybe<KycReview>;
  metadata: UserMetadata;
  mobileHomeWidgets: MobileHomeWidgets;
  /** Onboarding hotline contact information */
  onboardingHotline: Maybe<OnboardingHotline>;
  onboardingUser: OnboardingUser;
  outstandingAgreements: Array<OutstandingAgreement>;
  /** @deprecated Decommissioned feature */
  paymentCardOrder: Maybe<PaymentCardOrder>;
  /** Phone Support Link for Help Section */
  phoneSupportLink: Maybe<PhoneSupportLink>;
  profile: UserProfile;
  referrer: Maybe<Referrer>;
  scheduledBankTransfer: ScheduledBankTransfer;
  scheduledBankTransfers: ViewerScheduledBankTransfersConnection;
  scheduledBankTransfersCount: ViewerTotalCount;
  settings: UserSettings;
  /** Standard hotline contact information */
  standardHotline: Maybe<Array<Maybe<StandardHotline>>>;
  taxResidencies: Array<TaxResidency>;
  taxResidency: Maybe<TaxResidency>;
  /** @deprecated Use Company.transactionsSearch instead */
  transactions: ViewerTransactionsConnection;
  /** @deprecated Should not be used anymore */
  transactionsCount: ViewerTotalCount;
  uid: Scalars['ID']['output'];
  /** A set of values related to the canary release of the Unified Onboarding project. */
  webViewOnboarding: WebViewOnboarding;
};


export type ViewerAccountingRecurringExportArgs = {
  accountingRecurringExportId: Scalars['ID']['input'];
};


export type ViewerAccountingRecurringExportsArgs = {
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  status: InputMaybe<AccountingRecurringExportStatus>;
};


export type ViewerApplicationArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type ViewerAuthenticationDeviceArgs = {
  authenticationDeviceId: Scalars['ID']['input'];
  phone: Scalars['ID']['input'];
};


export type ViewerAuthenticationDeviceRequestsArgs = {
  after: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<AuthenticationDeviceRequestStatus>;
};


export type ViewerAuthenticationDevicesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<AuthenticationDeviceStatus>;
};


export type ViewerBankAccountArgs = {
  bankAccountId: Scalars['ID']['input'];
};


export type ViewerBankAccountStatementsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  bankAccountId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
};


export type ViewerBankAccountsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerBankAccountsIdsArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type ViewerCanAccessFeatureArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type ViewerCardsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<ViewerCardsFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type ViewerCheckDepositArgs = {
  checkDepositId: Scalars['ID']['input'];
};


export type ViewerCheckDepositsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  bankAccountId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerCheckDepositsCountArgs = {
  bankAccountId: Scalars['ID']['input'];
};


export type ViewerCompanyArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type ViewerCompanyTmpArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type ViewerCompanyUserArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
};


export type ViewerCreditCardsArgs = {
  companyUserId: InputMaybe<Scalars['ID']['input']>;
  digitizationWalletProvider: InputMaybe<DigitizationWalletProvider>;
  includeDigitizationData: InputMaybe<Scalars['Boolean']['input']>;
};


export type ViewerDirectDebitMandatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  bankAccountId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isRevoked: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  scheme: InputMaybe<DirectDebitMandateScheme>;
};


export type ViewerDirectDebitMandatesCountArgs = {
  bankAccountId: InputMaybe<Scalars['ID']['input']>;
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  isRevoked: InputMaybe<Scalars['Boolean']['input']>;
};


export type ViewerKycProviderUserArgs = {
  provider: KycProvider;
};


export type ViewerMobileHomeWidgetsArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
  companyUserId: InputMaybe<Scalars['ID']['input']>;
  secondaryBankAccountsLimit?: InputMaybe<Scalars['Int']['input']>;
  transactionsLimit?: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerOutstandingAgreementsArgs = {
  companyProfileId: Scalars['UUIDv4']['input'];
  type: InputMaybe<Scalars['String']['input']>;
};


export type ViewerScheduledBankTransferArgs = {
  scheduledBankTransferId: Scalars['ID']['input'];
};


export type ViewerScheduledBankTransfersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  bankAccountId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};


export type ViewerScheduledBankTransfersCountArgs = {
  bankAccountId: InputMaybe<Scalars['ID']['input']>;
  companyProfileId: InputMaybe<Scalars['UUIDv4']['input']>;
  isActive: InputMaybe<Scalars['Boolean']['input']>;
};


export type ViewerTransactionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  bankAccountId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyUserId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  hasReceipts: InputMaybe<Scalars['Boolean']['input']>;
  isPersonal: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  q: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type ViewerTransactionsCountArgs = {
  bankAccountId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyUserId: InputMaybe<Scalars['ID']['input']>;
  hasReceipts: InputMaybe<Scalars['Boolean']['input']>;
  isPersonal: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ViewerBankAccountStatementsConnection = {
  __typename: 'ViewerBankAccountStatementsConnection';
  edges: Array<BankAccountStatementsEdge>;
  pageInfo: PageInfo;
};

export type ViewerBankAccountsConnection = {
  __typename: 'ViewerBankAccountsConnection';
  edges: Array<BankAccountEdge>;
  pageInfo: PageInfo;
};

export type ViewerBankTransfersConnection = {
  __typename: 'ViewerBankTransfersConnection';
  edges: Array<BankTransferEdge>;
  pageInfo: PageInfo;
};

export type ViewerBankTransfersRecipientsConnection = {
  __typename: 'ViewerBankTransfersRecipientsConnection';
  edges: Array<BankTransferRecipientEdge>;
  pageInfo: PageInfo;
};

export type ViewerCardsConnection = {
  __typename: 'ViewerCardsConnection';
  edges: Array<CardEdge>;
  pageInfo: PageInfo;
};

export type ViewerCardsFilters = {
  entityId?: InputMaybe<Scalars['ID']['input']>;
  entityType?: InputMaybe<EntityType>;
  includeSeen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ViewerCatalogItemsConnection = {
  __typename: 'ViewerCatalogItemsConnection';
  edges: Array<CatalogItemEdge>;
  pageInfo: CatalogItemsPageInfo;
};

export type ViewerClientsConnection = {
  __typename: 'ViewerClientsConnection';
  edges: Array<ClientEdge>;
  pageInfo: PageInfo;
};

export type ViewerDirectDebitMandatesConnection = {
  __typename: 'ViewerDirectDebitMandatesConnection';
  edges: Array<DirectDebitMandateEdge>;
  pageInfo: PageInfo;
};

export type ViewerInvoicesConnection = {
  __typename: 'ViewerInvoicesConnection';
  edges: Array<InvoiceEdge>;
  pageInfo: PageInfo;
};

export type ViewerQuotesConnection = {
  __typename: 'ViewerQuotesConnection';
  edges: Array<QuoteEdge>;
  pageInfo: PageInfo;
};

export type ViewerScheduledBankTransfersConnection = {
  __typename: 'ViewerScheduledBankTransfersConnection';
  edges: Array<ScheduledBankTransferEdge>;
  pageInfo: PageInfo;
};

export type ViewerTotalCount = {
  __typename: 'ViewerTotalCount';
  totalCount: Scalars['Int']['output'];
};

export type ViewerTransactionsConnection = {
  __typename: 'ViewerTransactionsConnection';
  edges: Array<TransactionEdge>;
  pageInfo: PageInfo;
};

export type VirtualBudgetCardOrderRequest = {
  __typename: 'VirtualBudgetCardOrderRequest';
  cancelReason: Maybe<TeamMembersRequestCancelReason>;
  cancelledAt: Maybe<Scalars['String']['output']>;
  companyProfileId: Scalars['UUIDv4']['output'];
  completedAt: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  requester: Maybe<TeamMembersRequestRequester>;
  requesterCompanyUserId: Scalars['UUIDv4']['output'];
  requesterPayload: VirtualBudgetCardOrderRequestRequesterPayload;
  reviewedAt: Maybe<Scalars['String']['output']>;
  reviewer: Maybe<TeamMembersRequestReviewer>;
  reviewerCompanyUserId: Maybe<Scalars['UUIDv4']['output']>;
  reviewerPayload: Maybe<VirtualBudgetCardOrderRequestReviewerPayload>;
  status: TeamMembersRequestStatus;
  teamMembersRequestId: Scalars['UUIDv4']['output'];
  type: TeamMembersRequestType;
  updatedAt: Scalars['String']['output'];
};

export type VirtualBudgetCardOrderRequestRequesterPayload = {
  __typename: 'VirtualBudgetCardOrderRequestRequesterPayload';
  bankAccountId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  limitPaymentAll: Scalars['Float']['output'];
};

export type VirtualBudgetCardOrderRequestReviewerPayload = {
  __typename: 'VirtualBudgetCardOrderRequestReviewerPayload';
  bankAccountId: Scalars['ID']['output'];
  deactivateAt: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  limitPaymentAll: Scalars['Float']['output'];
  limitPaymentWeek: Scalars['Float']['output'];
};

export type VirtualCardAutomaticallyOrderedPayload = {
  __typename: 'VirtualCardAutomaticallyOrderedPayload';
  companyProfileId: Scalars['UUIDv4']['output'];
  paymentCardId: Scalars['ID']['output'];
  uid: Scalars['ID']['output'];
};

export type VirtualCardInitialLimits = {
  __typename: 'VirtualCardInitialLimits';
  defaultLimitPaymentWeek: Scalars['Int']['output'];
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  maxLimitPaymentWeek: Scalars['Int']['output'];
};

export type VirtualPaymentCardOrderRequest = {
  __typename: 'VirtualPaymentCardOrderRequest';
  cancelReason: Maybe<TeamMembersRequestCancelReason>;
  cancelledAt: Maybe<Scalars['String']['output']>;
  companyProfileId: Scalars['UUIDv4']['output'];
  completedAt: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  requester: Maybe<TeamMembersRequestRequester>;
  requesterCompanyUserId: Scalars['UUIDv4']['output'];
  requesterPayload: VirtualPaymentCardOrderRequestRequesterPayload;
  reviewedAt: Maybe<Scalars['String']['output']>;
  reviewer: Maybe<TeamMembersRequestReviewer>;
  reviewerCompanyUserId: Maybe<Scalars['UUIDv4']['output']>;
  reviewerPayload: Maybe<VirtualPaymentCardOrderRequestReviewerPayload>;
  status: TeamMembersRequestStatus;
  teamMembersRequestId: Scalars['UUIDv4']['output'];
  type: TeamMembersRequestType;
  updatedAt: Scalars['String']['output'];
};

export type VirtualPaymentCardOrderRequestRequesterPayload = {
  __typename: 'VirtualPaymentCardOrderRequestRequesterPayload';
  bankAccountId: Scalars['ID']['output'];
};

export type VirtualPaymentCardOrderRequestReviewerPayload = {
  __typename: 'VirtualPaymentCardOrderRequestReviewerPayload';
  bankAccountId: Scalars['ID']['output'];
  deactivateAt: Maybe<Scalars['String']['output']>;
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  limitPaymentWeek: Scalars['Float']['output'];
};

export type VirtualPermanentCardInitialLimits = {
  __typename: 'VirtualPermanentCardInitialLimits';
  defaultLimitPaymentWeek: Scalars['Int']['output'];
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  maxLimitPaymentWeek: Scalars['Int']['output'];
};

export type VirtualTemporaryCardInitialLimits = {
  __typename: 'VirtualTemporaryCardInitialLimits';
  defaultLimitPaymentWeek: Scalars['Int']['output'];
  isOptionNfcEnabled: Scalars['Boolean']['output'];
  isOptionOnlineEnabled: Scalars['Boolean']['output'];
  maxLimitPaymentWeek: Scalars['Int']['output'];
};

export type Wallet = {
  __typename: 'Wallet';
  bankLocation: Scalars['String']['output'];
  bankZipCode: Scalars['String']['output'];
  bban: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  isEP: Scalars['Boolean']['output'];
  ribUrl: Scalars['String']['output'];
};

export type WebViewOnboarding = {
  __typename: 'WebViewOnboarding';
  /** Whether this user is currently opted in the Capital Deposit unified onboarding release */
  canAccessCapitalDeposit: Scalars['Boolean']['output'];
  /** Whether this user is currently opted in the Shine Start unified onboarding release */
  canAccessShineStart: Scalars['Boolean']['output'];
  /** Whether this user is currently opted in the Standard unified onboarding release */
  canAccessStandard: Scalars['Boolean']['output'];
  /**
   * Whether this user is currently opted in the experiment where we do NOT reveal the shine app
   * before the onboarding is considered as done.
   */
  shouldSkipAppLayoutExperiment: Scalars['Boolean']['output'];
};

export type Where = {
  __typename: 'Where';
  not: Maybe<Array<Maybe<FeatureFilter>>>;
  or: Maybe<Array<Maybe<FeatureFilter>>>;
};

export type CreateTeamMemberReviewInput = {
  companyUserId: Scalars['ID']['input'];
};

export type CreateTeamMemberReviewPayload = {
  __typename: 'createTeamMemberReviewPayload';
  companyUser: CompanyUser;
};

export type SendAllCompanyInvitationsInput = {
  companyProfileId: Scalars['UUIDv4']['input'];
};
