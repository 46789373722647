import { type TextProps, Typography } from '@shinetools/sunshine-universal';

import { type FCWithChildren } from 'common/types';

import { formatText } from './utils';

interface RichTextProps extends TextProps {
  renderStrong?: FCWithChildren;
}

const RichText: FCWithChildren<RichTextProps> = ({
  children,
  renderStrong = (p) => <Typography.Text bold {...p} />,
  ...props
}) => (
  <Typography.Text {...props}>
    {formatText(children, { renderStrong })}
  </Typography.Text>
);

export default RichText;
