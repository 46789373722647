import { type FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Divider, Flex, Image } from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';

import hourglass from 'assets/images/hourglass.svg';
import successCheckConfettis from 'assets/images/successCheckConfettis.svg';
import useQueryParams from 'common/hooks/useQueryParams';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import IconCircle from 'components/_core/IconCircle';
import SunshineTag from 'components/_core/SunshineTag';
import Text from 'components/_core/Text';
import ErrorView from 'components/ErrorView';
import Loader from 'components/Loader';
import NewDeviceLoginLayout from 'components/Login/LoginForm/components/NewDeviceLoginLayout';
import { getIconName } from 'helpers/auth/devices';

import type * as Types from '../../__generated__/GQL';
import { getDeviceInformation } from '../../helpers/device';
import { approveDevice } from './helpers';
import locales from './locales';

interface TokenPayload {
  authenticationDeviceRequestId: string;
  connectionAttemptDate: string;
  connectionCity: string;
  connectionCountry: string;
  deviceModel: string;
  deviceName: string;
  devicePlatform: Types.AuthenticationDevicePlatform;
  deviceToken: string;
}

interface Token {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  payload: TokenPayload;
}

const EmailFallbackTrustedDeviceManagement: FC = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const token = queryParams.get('token') || '';

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasHasUnknownError, setHasUnknownError] = useState(false);
  const [decodedToken, setDecodedToken] = useState<TokenPayload | null>(null);

  const approve = useCallback(async () => {
    try {
      const { payload } = jwtDecode<Token>(token);
      setDecodedToken(payload);
      await approveDevice(token);
    } catch (err) {
      const { message = '' } = err as Error;

      if (/Invalid token/.test(message) || /Unauthorized/.test(message)) {
        setHasError(true);
      } else {
        setHasUnknownError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    approve();
  }, [approve]);

  const { token: deviceToken } = getDeviceInformation();

  if (hasHasUnknownError) {
    return <ErrorView />;
  }

  const deviceTokenFromJWT = decodedToken?.deviceToken;

  if (hasError) {
    return (
      <NewDeviceLoginLayout>
        <Image src={hourglass} width="180px" />
        <Heading marginBottom="space-16" marginTop="space-24" size="lg">
          {locales.errorTitle}
        </Heading>
        <Text whiteSpace="pre-line">{locales.errorDescription}</Text>
        {deviceToken === deviceTokenFromJWT ? (
          <>
            <Divider
              display={{ base: 'none', md: 'block' }}
              marginY="space-24"
              width="113%"
            />
            <Flex
              alignItems="center"
              justifyContent="center"
              marginTop={{ base: 'space-24', md: 0 }}
              width="100%"
            >
              <Button onClick={() => history.replace('/')} variant="secondary">
                {locales.close}
              </Button>
            </Flex>
          </>
        ) : null}
      </NewDeviceLoginLayout>
    );
  }

  if (isLoading || !decodedToken) {
    return (
      <NewDeviceLoginLayout>
        <Loader />
      </NewDeviceLoginLayout>
    );
  }

  const { deviceModel, deviceName, devicePlatform } = decodedToken;
  const iconName = getIconName({ platform: devicePlatform });

  return (
    <NewDeviceLoginLayout>
      <Image src={successCheckConfettis} width="180px" />
      <Heading marginBottom="space-16" marginTop="space-24" size="lg">
        {locales.successTitle}
      </Heading>
      <Text whiteSpace="pre-line">{locales.successDescription}</Text>
      <Divider
        display={{ base: 'none', md: 'block' }}
        marginY="space-32"
        width="113%"
      />
      <Flex
        alignItems="center"
        marginTop={{ base: 'space-32', md: 0 }}
        width="100%"
      >
        <IconCircle
          backgroundColor="ctaBlue.3"
          color="ctaBlue.-1"
          marginRight="space-16"
          name={iconName}
          size="40px"
        />

        <Flex alignItems="start" flex={1} justifyContent="space-between">
          <Flex alignItems="flex-start" flexDirection="column">
            <Text fontWeight="weight-500" variant="primary">
              {deviceName}
            </Text>
            <Text variant="primary">{deviceModel}</Text>
          </Flex>
          <SunshineTag colorScheme="green" marginLeft="space-8" size="sm">
            {locales.new}
          </SunshineTag>
        </Flex>
      </Flex>
      {deviceToken === deviceTokenFromJWT ? (
        <>
          <Divider marginTop="space-32" width="113%" />
          <Button marginTop="space-24" onClick={() => history.replace('/')}>
            {locales.accessMyAccount}
          </Button>
        </>
      ) : null}
    </NewDeviceLoginLayout>
  );
};

export default EmailFallbackTrustedDeviceManagement;
