import { type FC } from 'react';
import { Typography } from '@shinetools/sunshine-universal';

import Button from 'components/_core/Button';
import * as Form from 'components/_core/form';
import RichText from 'components/_core/RichText';
import PasscodeInput from 'components/PasscodeInput';
import { type ClearAllDigitsFn } from 'components/PasscodeInput/types';
import Row from 'components/Row';

import BackButton from '../components/BackButton';
import { type LoginType } from '../types';
import locales from './locales';
import * as S from './styles';
import { type ComponentProps, type PasscodeType } from './types';

const getTitle = (
  passcodeType: PasscodeType,
  loginType: LoginType,
  isEnteringSecondTime: boolean,
): string => {
  if (passcodeType === 'verificationCode') {
    return locales.titleVerificationCode;
  }
  if (loginType !== 'sign_in' && isEnteringSecondTime === false) {
    return locales.titleCreatePasscode;
  }
  if (loginType !== 'sign_in' && isEnteringSecondTime === true) {
    return locales.titleCreateRetypePasscode;
  }
  return locales.titlePasscode;
};

const getOptionalDescription = (
  passcodeType: PasscodeType,
  loginType: LoginType,
): React.ReactNode => {
  if (passcodeType === 'verificationCode') {
    return <S.Description>{locales.descriptionVerificationCode}</S.Description>;
  }
  if (loginType === 'sign_up' || loginType === 'passcode_reset') {
    return <S.Description>{locales.descriptionCreatePasscode}</S.Description>;
  }
  return (
    <RichText marginBottom="$space.16">{locales.descriptionPasscode}</RichText>
  );
};

// TODO(matt): Wrap this in a <form> element
const Passcode: FC<ComponentProps> = ({
  errorMessage,
  isEnteringSecondTime,
  isValid,
  loading,
  loginType,
  onBack,
  onForgotPasscode,
  onPressNext,
  passcodeType,
  setCode,
}) => {
  const title = getTitle(passcodeType, loginType, isEnteringSecondTime);

  return (
    <S.Container>
      <BackButton onClick={onBack} />

      <Typography.Header marginBottom={8} size="regular">
        {title}
      </Typography.Header>

      {getOptionalDescription(passcodeType, loginType)}

      <Form.Field error={errorMessage} marginBottom="space-32">
        <PasscodeInput
          hidden={passcodeType !== 'verificationCode'}
          length={passcodeType === 'verificationCode' ? 6 : 4}
          onSubmit={(newCode: string, clearAllDigits: ClearAllDigitsFn): void =>
            setCode(newCode, clearAllDigits)
          }
        />
      </Form.Field>

      <Row alignItems="center">
        <Button
          isDisabled={!isValid}
          isLoading={loading}
          marginRight="space-32"
          onClick={onPressNext}
          variant="primary"
        >
          {locales.next}
        </Button>

        {loginType === 'sign_in' && passcodeType === 'passcode' ? (
          <Button
            onClick={onForgotPasscode as () => void}
            variant="inline-secondary"
          >
            {locales.passcodeForgotten}
          </Button>
        ) : null}
      </Row>
    </S.Container>
  );
};

export default Passcode;
