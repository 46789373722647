import { useEffect, useState } from 'react';
import { type SdkInitMethod } from 'onfido-sdk-ui';

import { makeCancelable } from 'common/hooks/useCancellablePromise';

const useGetOnfidoInit = (): {
  onfidoInit: SdkInitMethod | null;
} => {
  const [onfidoInit, setOnfidoInit] = useState<SdkInitMethod | null>(null);
  useEffect(() => {
    const importPromise = makeCancelable(
      import('onfido-sdk-ui').then((sdk) => {
        const { init } = sdk;
        // Note that it is necessary to call setOnfidoInit with a
        // callback function. Passing init directly will make it
        // treat init (a function) as a callback and then store its
        // return value rather than the init function itself.
        setOnfidoInit(() => init);
      }),
    );
    return (): void => {
      importPromise.cancel();
    };
  }, []);
  return { onfidoInit };
};

export default useGetOnfidoInit;
