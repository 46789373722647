import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { type FCWithChildren } from 'common/types';
import Button, { type ButtonProps } from 'components/_core/Button';

import locales from './locales';

export interface BackButtonProps extends Omit<ButtonProps, 'children'> {
  to?: string;
  onClick?: () => void;
}

const BackButton: FCWithChildren<BackButtonProps> = ({
  onClick,
  to,
  ...props
}) => {
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  const redirectProps = {
    ...(to ? { as: Link, to } : { onClick: onClick || onBack }),
  };

  return (
    <Button
      alignSelf="start"
      icon="arrow-left"
      iconPosition="left"
      size="sm"
      variant="tertiary"
      {...redirectProps}
      {...props}
    >
      {locales.back}
    </Button>
  );
};

export default BackButton;
