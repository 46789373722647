/* eslint-disable react/display-name */

import { type FC } from 'react';
import {
  type ModelContextFrom,
  type ModelEventsFrom,
} from 'xstate/lib/model.types';

import { type AnyModel } from 'common/bento/types/Abstract';
import {
  type AllStateMachineOptions,
  type MachineFrom,
  type ModuleDataFrom,
  type RunningMachine,
} from 'common/bento/types/Helpers';

import SyncLocation from '../../components/SyncLocation';
import stringifyState from '../../lib/stringifyState';
import useModuleMachine from '../../lib/useModuleMachine';
import { type OnBentoModuleDone } from '../../types/BentoModule';

interface WithStateMachineOptions<TModel extends AnyModel> {
  machineConfig: MachineFrom<TModel>;
  syncLocation?: boolean;
  devTools?: boolean;
}

export type BentoModuleComponent<TModel extends AnyModel> = (props: {
  machine: RunningMachine<TModel>;
  moduleData: ReturnType<TModel['initialContext']['getModuleData']>;
}) => ReturnType<FC>;

const withStateMachine = <TModel extends AnyModel>(
  Module: BentoModuleComponent<TModel>,
  {
    devTools,
    machineConfig,
    syncLocation = true,
  }: WithStateMachineOptions<TModel>,
) => {
  return ({
    machineOptions,
    moduleData,
    onDone,
  }: {
    machineOptions?: AllStateMachineOptions<
      ModelContextFrom<TModel>,
      ModelEventsFrom<TModel>
    >;
    moduleData: ModuleDataFrom<TModel>;
    onDone: OnBentoModuleDone;
  }) => {
    /**
     * Instantiate the machine and bind its `onDone` callback
     */
    const machine = useModuleMachine({
      machineConfig,
      machineOptions: { ...machineOptions, devTools },
      moduleData,
      onDone,
    });

    if (!syncLocation) {
      return <Module machine={machine} moduleData={moduleData} />;
    }

    const [state] = machine;

    return (
      /**
       * Reflect the current state of the machine at the local part of the URL
       */
      <SyncLocation path={stringifyState(state).replace('.', '_')}>
        {/* Provide the running machine to the component, so it can read its state and send events to it */}
        <Module machine={machine} moduleData={moduleData} />
      </SyncLocation>
    );
  };
};

export default withStateMachine;
