import {
  ID_RECTO,
  ID_VERSO,
  PASSPORT,
  RESIDENCE_PERMIT_RECTO,
  RESIDENCE_PERMIT_VERSO,
} from '@shinetools/kyc-validation';
import { toUpper } from 'ramda';

import { type KycDocument } from './types';

// TODO(matt): Consider moving this to the @shinetools/kyc-validation library
const mapOnfidoDocumentToKycDocumentFromProviderInput: (onfidoDocument: {
  id: string;
  side: string;
  type: string;
}) => KycDocument = ({ id, side, type }) => {
  let parsedType;
  const upperCaseType = toUpper(type);
  const upperCaseSide = side?.toUpperCase(); // side will be set to null if document has only one
  if (upperCaseType === 'PASSPORT') {
    parsedType = PASSPORT;
  } else if (upperCaseType === 'NATIONAL_IDENTITY_CARD') {
    parsedType = upperCaseSide === 'FRONT' ? ID_RECTO : ID_VERSO;
  } else if (
    upperCaseType === 'RESIDENCE_PERMIT' ||
    upperCaseType === 'UNKNOWN'
  ) {
    // As the onfido API doesn't support residence permit as a distinct type yet, the returned document type will be unknown.
    parsedType =
      upperCaseSide === 'FRONT'
        ? RESIDENCE_PERMIT_RECTO
        : RESIDENCE_PERMIT_VERSO;
  } else {
    // If it the returned document type is different from what we expect set it to something that will trigger an error further down. We should never end up here.
    parsedType = 'UNKNOWN';
  }

  return {
    provider: 'ONFIDO',
    providerDocumentId: id,
    type: parsedType,
  };
};

export default mapOnfidoDocumentToKycDocumentFromProviderInput;
