/**
 * This is a simple override of the colors of the default Chakra styles.
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts
 */

import { type Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: {
    /**
     * Add a gutter between the toasts and the viewport
     */
    '#chakra-toast-portal > ul': {
      margin: 'space-16',
    },

    '*, *::before, &::after': {
      wordWrap: 'break-word',
    },

    body: {
      background: 'white',
      color: 'text-secondary',
      fontFamily: 'mabry',
      fontFeatureSettings: `'salt' on, 'ss08' on`, // Modify Mabry Pro font characters (middle dot and question mark)
      fontSize: 'font-15',
      fontVariant: 'no-common-ligatures tabular-nums', // Configure Mabry Pro font (no ligature)
      lineHeight: 'base',
      transitionDuration: 'duration-200',
      transitionProperty: 'background-color',
    },

    /**
     * Allows the web-app components to mount in a container spanning the full viewport.
     * Useful to center loaders and to avoid having to use 100vh (whose mobile browsers handling is bad).
     */
    'html, body, #root': {
      height: 'full',
    },

    'img, svg, video, canvas, audio, iframe, embed, object': {
      display: 'block',
      verticalAlign: 'middle',
    },
  },
};

export default styles;
