import { type FC } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Avatar,
  Flex,
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  Text,
} from '@chakra-ui/react';

import useCompanyContext from 'common/hooks/useCompanyContext';
import Button from 'components/_core/Button';
import SunshineIcon from 'components/_core/SunshineIcon';
import MotionBox from 'components/MotionBox';
import { logEvent } from 'features/Analytics/analytics';

import MenuItem from './components/MenuItem';
import locales from './locales';

interface MenuProps {
  firstName: string | null;
  lastName: string | null;
  isMandatee?: boolean;
  isMultiAccount?: boolean;
}

/**
 * The common menu of the team onboarding.
 * Features a logout button directing to `/logout`.
 */
const Menu: FC<MenuProps> = ({
  firstName,
  isMandatee,
  isMultiAccount,
  lastName,
}) => {
  const { clearCompanyContext } = useCompanyContext();

  return (
    <ChakraMenu
      closeOnSelect={false}
      onClose={() => logEvent({ name: 'Close_TeamOnboarding_Menu_Dropdown' })}
      onOpen={() => logEvent({ name: 'Open_TeamOnboarding_Menu_Dropdown' })}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            _hover={{
              background: 'grey.200',
            }}
            as={Button}
            background="white"
            borderRadius="radius-6"
            height="auto"
            marginBottom="space-32"
            marginX="-space-8"
            padding="space-8"
          >
            <Flex align="center" gap="space-8">
              <Avatar
                background="yellow.600"
                color="black"
                icon={<SunshineIcon name="profile" size="icon-20" />}
                name={
                  firstName && lastName ? `${firstName} ${lastName}` : undefined
                }
                size="sm"
              />

              <Text>{firstName || locales.genericLabel}</Text>

              <MotionBox animate={{ rotate: isOpen ? 180 : 0 }}>
                <SunshineIcon color="grey.700" name="chevron-down" />
              </MotionBox>
            </Flex>
          </MenuButton>

          <MenuList minWidth="240px" paddingY="0">
            {isMandatee || isMultiAccount ? (
              <MenuItem
                iconName="company"
                label={locales.switchCompany}
                // Clearing the company context here will redirect the user to the
                // appropriate company selection screen
                onClick={clearCompanyContext}
              />
            ) : null}

            <MenuItem
              as={NavLink}
              iconName="logout"
              label={locales.logout}
              to="/logout"
            />
          </MenuList>
        </>
      )}
    </ChakraMenu>
  );
};

export default Menu;
