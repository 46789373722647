import { type FC, useEffect, useRef } from 'react';
import { equals } from 'ramda';

import { logScreen } from './analytics';

export interface TrackViewProps {
  pageName: string;
  usePrefix?: boolean;
  withTrackingOnPropertiesChange?: boolean;
  eventProperties?: JSONObject;
}

/**
 * TrackView calls `logScreen` (`analytics.track`) on component's mount or `pageName` value change
 * @param pageName
 * @param eventProperties properties passed to `logScreen`
 * @param {boolean} [usePrefix=true] prefixes `pageName` with `Web View` if true
 * @param {boolean} [withTrackingOnPropertiesChange=false] calls `logScreen` again on `eventProperties` change
 */
const TrackView: FC<TrackViewProps> = ({
  eventProperties,
  pageName,
  usePrefix = true,
  withTrackingOnPropertiesChange = false,
}) => {
  const previousPageName = useRef('');
  const previousEventProperties = useRef<JSONObject | undefined>(undefined);

  useEffect((): void => {
    if (
      pageName !== previousPageName.current ||
      (withTrackingOnPropertiesChange &&
        !equals(eventProperties, previousEventProperties.current))
    ) {
      logScreen({
        name: pageName,
        properties: eventProperties,
        usePrefix,
      });
    }

    previousPageName.current = pageName;
    previousEventProperties.current = eventProperties;

    // ignore usePrefix opition changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventProperties, pageName]);

  return null;
};

export default TrackView;
