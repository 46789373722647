import { Box, Grid, Image, VStack } from '@chakra-ui/react';

import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';
import getExtension from 'common/getExtension';
import { type FCWithChildren } from 'common/types';
import Button from 'components/_core/Button';
import PdfViewer from 'components/PdfViewer/PdfViewer';

import OBDocumentViewDrawer from './components/OBDocumentViewDrawer';
import locales from './locales';

/**
 * A full-screen view featuring a document whose `src` is passed as URL, and a sidebar you can inject.
 */
const OBDocumentView: FCWithChildren<{
  src: string;
  onClose: () => void;
}> = ({ children, onClose, src, ...props }) => {
  const isLarge = useLayoutSizeQuery(BentoLayoutSize.lg);

  const isPDF = getExtension(src) === 'pdf';

  return (
    <Grid
      height="100vh"
      {...(isLarge
        ? {
            templateColumns: `1fr 480px`,
          }
        : {
            templateColumns: '1fr',
            templateRows: '1fr 300px',
          })}
      width="100vw"
      {...props}
    >
      <Box background="grey.3" height="100vh">
        {isPDF ? (
          <PdfViewer src={src} />
        ) : (
          <Image
            height="full"
            marginX="auto"
            objectFit="contain"
            src={src}
            width="full"
          />
        )}
      </Box>

      {isLarge ? (
        <VStack
          align="stretch"
          background="white"
          padding="space-40"
          spacing="space-48"
        >
          <Button
            alignSelf="flex-end"
            icon="cross"
            iconPosition="left"
            onClick={onClose}
            size="sm"
            variant="tertiary"
          >
            {locales.exit}
          </Button>

          <Box>{children}</Box>
        </VStack>
      ) : (
        <OBDocumentViewDrawer onClose={onClose}>
          {children}
        </OBDocumentViewDrawer>
      )}
    </Grid>
  );
};

export default OBDocumentView;
