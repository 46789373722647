import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'You will be able to access it once your registration is done and validated by our team',
    disclaimer:
      'This part of the website is restricted to validated accounts only',
    link: 'Go back to the home page',
  },
  fr: {
    description:
      'Vous pourrez accéder à cette section une fois votre inscription terminée et validée par notre équipe',
    disclaimer: 'Cette section du site est réservée aux comptes validés',
    link: 'Retourner à l’accueil',
  },
} as const);

export default locales;
