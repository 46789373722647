import { createContext, type FC, type ReactElement, useContext } from 'react';
import {
  type RadioProps,
  useRadio as useChakraRadio,
  type UseRadioProps,
  type UseRadioReturn,
} from '@chakra-ui/react';

const RadioPropsContext = createContext<UseRadioReturn | null>(null);

interface RadioProviderProps {
  children: ReactElement;
  value: UseRadioProps & RadioProps;
}

export const RadioProvider: FC<RadioProviderProps> = ({ children, value }) => {
  const radio = useChakraRadio(value);

  return (
    <RadioPropsContext.Provider value={radio}>
      {children}
    </RadioPropsContext.Provider>
  );
};

export function useRadio() {
  const context = useContext(RadioPropsContext);

  if (!context) {
    throw new Error('useRadio can only be used inside of a RadioProvider');
  }

  return context;
}
