import { type FC } from 'react';
import { VStack } from '@chakra-ui/react';

import Text from 'components/_core/Text';

import GuidanceBaseHelp from './Base/GuidanceBaseHelp';
import locales from './locales';

/**
 * Help-like guidance to explain
 * how the referral works
 */
const GuidanceReferral: FC = () => {
  const loc = locales.referral;

  return (
    <GuidanceBaseHelp title={loc.title}>
      <VStack spacing="space-16">
        {loc.content.map((str, i) => (
          <Text color="inherit" fontSize="inherit" key={i}>
            {str}
          </Text>
        ))}
      </VStack>
    </GuidanceBaseHelp>
  );
};

export default GuidanceReferral;
