import { type VerificationModuleData } from '../../machine/model';
import getAddressStep from './getAddressStep';
// import getComplianceStep from './getComplianceStep';
import getIdentityStep from './getIdentityStep';
import { VerificationStatus } from './types';

const getVerificationSteps = (moduleData: VerificationModuleData) => {
  const steps = [getAddressStep(moduleData), getIdentityStep(moduleData)];

  return steps.filter(
    ({ metadata, status }) =>
      status !== VerificationStatus.Irrelevant && !metadata.isHidden,
  );
};

export default getVerificationSteps;
