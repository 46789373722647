import en from './locales/en/web-app.json';
import fr from './locales/fr/web-app.json';

export const webAppLocales = {
  en,
  fr,
};

export type WebAppLocales = typeof webAppLocales;
export type WebAppLocale = keyof WebAppLocales;

/**
 * Check `navigator.languages` and return the user's preferred locale between `fr` and `en`.
 */
export const webAppLang: WebAppLocale = (() => {
  const userPreferredLangs = navigator.languages.map(
    (language) => language.split('-')[0] as string,
  );

  for (const lang of userPreferredLangs) {
    if (lang in webAppLocales) {
      return lang as WebAppLocale;
    }
  }

  return 'fr';
})();
