import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    help: 'Help center',
  },
  fr: {
    help: 'Centre d’aide',
  },
} as const);
