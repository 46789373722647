import { menuAnatomy } from '@chakra-ui/anatomy';
import {
  type PartsStyleFunction,
  type SystemStyleFunction,
  type SystemStyleObject,
} from '@chakra-ui/theme-tools';

const parts = menuAnatomy.extend('itemIcon');
type Parts = typeof parts;

const baseStyleButton: SystemStyleObject = {
  cursor: 'pointer',
  display: 'inline-flex',
};

const baseStyleList: SystemStyleObject = {
  '[data-js-focus-visible] &:focus:not([data-focus-visible-added])': {
    boxShadow: 'shadow-small-2',
  },
  backgroundColor: 'white',
  borderRadius: 'radius-8',
  boxShadow: 'shadow-small-2',
  display: 'flex',
  flexDirection: 'column',
  gap: 'space-4',
  minWidth: '15rem', // 240px
  paddingX: 'space-8',
  paddingY: 'space-16',
  zIndex: 'dropdown',
};

const baseStyleItem: SystemStyleFunction = ({ colorScheme }) => ({
  _disabled: {
    _hover: {
      color: `${colorScheme}.700`,
      cursor: 'default',
    },
    opacity: 0.5,
  },
  _focus: {
    backgroundColor: 'grey.200',
    color: `${colorScheme}.800`,
  },
  _hover: {
    color: `${colorScheme}.800`,
  },
  borderRadius: 'radius-6',
  color: `${colorScheme}.700`,
  fontWeight: 'weight-500',
  gap: 'space-8',
  paddingX: 'space-8',
  paddingY: 'space-4',
  transitionDuration: 'duration-50',
  transitionProperty: 'background',
  transitionTimingFunction: 'ease-in',
});

const baseStyleItemIcon: SystemStyleFunction = ({ colorScheme }) => ({
  alignItems: 'center',
  backgroundColor: `${colorScheme}.200`,
  borderRadius: 'full',
  color: `${colorScheme}.800`,
  display: 'flex',
  flexGrow: 0,
  flexShrink: 0,
  height: '1.5rem', // 24px
  justifyContent: 'center',
  width: '1.5rem', // 24px
});

const baseStyleDivider: SystemStyleObject = {
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'grey.300',
  left: '-8px',
  marginY: 'space-16',
  position: 'relative',
  width: 'calc(100% + 16px)',
};

const baseStyle: PartsStyleFunction<Parts> = (props) => ({
  button: baseStyleButton,
  divider: baseStyleDivider,
  item: baseStyleItem(props),
  itemIcon: baseStyleItemIcon(props),
  list: baseStyleList,
});

const MenuTheme = {
  baseStyle,
  parts: parts.keys,
};

export default MenuTheme;
