import { type CSSProperties } from 'react';
import { type StylesConfig } from 'react-select';

import { type LegacyTheme } from 'theme/legacyTheme';

import { type OptionType } from './types';

const getOverriddenStyles = (
  label: string,
  theme: LegacyTheme,
): StylesConfig<OptionType<string>, false> => ({
  container: (provided) => ({
    ...provided,
    flex: 1,
    marginRight: theme.spacings.abs8,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: theme.colors.ctaBlue(4),
    borderRadius: 4,
    borderWidth: '0',
    boxShadow: undefined,
    cursor: 'pointer',
    height: 26,
    minHeight: 26,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    height: 26,
    padding: 3,
  }),
  menu: (provided) => ({
    ...provided,

    border: 0,
    // override border radius to match the box
    borderRadius: '8px',
    boxShadow:
      '0px 4px 8px rgba(69, 89, 128, 0.12), 0px 4px 16px rgba(69, 89, 128, 0.08)',
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    // kill the white space on first and last option
    padding: `${theme.spacings.abs8}px 0`,
  }),
  option: (
    provided,
    { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean },
  ) => {
    const selectedStyle = {
      backgroundColor: theme.colors.ctaBlue(3),
      color: theme.colors.ctaBlue(-1),
    };

    const common = {
      ...provided,

      ':active': selectedStyle,
      // Add to explicitly type so TS doesn't raise
      // an error inferer 'left' is a string breaking returns below
      textAlign: 'left' as CSSProperties['textAlign'],
    };

    if (isSelected) {
      return {
        ...common,
        ...selectedStyle,
      };
    }

    if (isFocused) {
      return {
        ...common,
        backgroundColor: theme.colors.ctaBlue(4),
        color: theme.colors.grey(-2),
        cursor: 'pointer',
      };
    }

    return common;
  },
  singleValue: (provided) => ({
    ...provided,
    maxWidth: '100%',
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontFamily: 'var(--fonts-mabry)',
    fontWeight: 500,
    paddingLeft: theme.spacings.abs8,
  }),
});

export default getOverriddenStyles;
