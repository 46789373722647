import { useQuery } from '@apollo/client';

import { type Feature } from '__generated__/GQL';
import useCompanyContext from 'common/hooks/useCompanyContext';
import { useCurrentPricingPlan } from 'common/hooks/useCurrentPricingPlan';

import {
  PlanChoicePageDocument,
  PlanChoicePageWithFeatureRestrictionDocument,
} from './graphql/planChoicePage.gql';

const usePlanChoice = (featurePreview: Feature | undefined) => {
  const { companyProfileId = '' } = useCompanyContext();
  const { plan } = useCurrentPricingPlan();

  const { data, error, loading } = useQuery(
    featurePreview !== undefined
      ? PlanChoicePageWithFeatureRestrictionDocument
      : PlanChoicePageDocument,
    {
      variables: {
        companyProfileId,
        featureRestriction: featurePreview as Feature,
      },
    },
  );

  if (loading || !plan || !data) {
    return { data: null, error, loading };
  }

  return {
    data: {
      currentPlan: {
        ...plan,
        ...data.viewer.company.currentPlan,
      },
      upgradablePlans: data.viewer.company.currentPlan.upgradablePlans,
    },
    error,
    loading,
  };
};

export default usePlanChoice;
