import { assign } from 'xstate';

import { IdDocumentCase } from '__generated__/GQL';
import assertEventType from 'common/bento/lib/assertEventType';
import assertNever from 'common/bento/lib/assertNever';
import { type MachineActions } from 'common/bento/types/MachineConfig';

import { type Model } from './model';

export enum Action {
  AssignIdentityCheckContext = 'assignIdentityCheckContext',
  AssignDefaultIdDocumentCase = 'assignDefaultIdDocumentCase',
  AssignIdDocumentCase = 'assignIdDocumentCase',
}

const actions: MachineActions<Model, Action> = {
  assignDefaultIdDocumentCase: assign({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    idDocumentCase: (context) => IdDocumentCase.AcceptedPassportOrIdCard,
  }),

  assignIdDocumentCase: assign({
    idDocumentCase: (context, event) => {
      assertEventType(event, [
        'SUBMIT_HAS_RESIDENCY_CARD',
        'SUBMIT_HAS_VISA',
        'SUBMIT_HAS_NO_RESIDENCY_DOCUMENT',
      ]);
      const eventType = event.type;

      switch (eventType) {
        case 'SUBMIT_HAS_RESIDENCY_CARD':
          return event.data.isExpired
            ? IdDocumentCase.ExpiredResidencyCard
            : IdDocumentCase.ValidResidencyCard;
        case 'SUBMIT_HAS_VISA':
          return IdDocumentCase.Visa;
        case 'SUBMIT_HAS_NO_RESIDENCY_DOCUMENT':
          return IdDocumentCase.NoDocument;
        default:
          return assertNever(eventType);
      }
    },
  }),

  assignIdentityCheckContext: assign({
    idCheckContext: (context, event) => {
      assertEventType(event, 'done.invoke.startIdentityVerification');

      return event.data;
    },
  }),
};

export default actions;
