import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    connectionErrorMessage: 'Please check your connection and try again',
    rateLimitError: 'Too many attempts. Try again in an hour',
    unrecognizedErrorMessage: 'Oops! There was an unknown error',
    wrongPasscode: 'Oops! Wrong passcode',
  },
  fr: {
    connectionErrorMessage: 'Pouvez-vous vous reconnecter et réessayer\u00A0?',
    rateLimitError:
      'Vous avez dépassé le nombre d’essais autorisés. Vous pourrez réessayer dans une heure',
    unrecognizedErrorMessage: 'Oups\u00A0! Une erreur inconnue est survenue',
    wrongPasscode: 'Oups\u00A0! Votre code secret semble incorrect',
  },
} as const);

export default locales;
