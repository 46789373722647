import { type FC } from 'react';
import { Box, type BoxProps } from '@chakra-ui/react';

type PageLayoutAreaProps = {
  gridArea: string;
};

/**
 * Most simple area component
 */
const PageLayoutArea: FC<BoxProps & PageLayoutAreaProps> = ({
  children,
  gridArea,
  ...props
}) => {
  return (
    <Box gridArea={gridArea} {...props}>
      {children}
    </Box>
  );
};

export default PageLayoutArea;
