import { type FC } from 'react';
import { Menu, type MenuProps, useMultiStyleConfig } from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/utils';

import Divider from './components/Divider';
import Item, { type ItemProps } from './components/Item';
import List from './components/List';
import Trigger from './components/Trigger';
import { COMPONENT_NAME, StylesProvider } from './context';

interface SunshineDropdownParts {
  Divider: typeof Divider;
  Item: typeof Item;
  List: typeof List;
  Trigger: typeof Trigger;
}

const SunshineDropdown: SunshineDropdownParts & FC<MenuProps> = ({
  children,
  ...props
}) => {
  const styles = useMultiStyleConfig(COMPONENT_NAME, props);

  return (
    <Menu {...props}>
      {(renderProps) => (
        <StylesProvider value={styles}>
          {runIfFn(children, renderProps)}
        </StylesProvider>
      )}
    </Menu>
  );
};

SunshineDropdown.Divider = Divider;
SunshineDropdown.Item = Item;
SunshineDropdown.List = List;
SunshineDropdown.Trigger = Trigger;

export default SunshineDropdown;
export type { ItemProps };
