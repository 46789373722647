import {
  forwardRef,
  Text as ChakraText,
  type TextProps,
} from '@chakra-ui/react';

import { formatText } from './utils';

/**
 * @deprecated Use Typography.Text for simple cases or _core/RichText when locales contains bold formatting.
 *
 * Eg:
 * - `This is a **bold** locale.` => RichText
 * - `This is a simple locale.` => Typography.Text
 * @see https://shinetools.github.io/shine/sunshine-universal-storybook/?path=/docs/typography--docs#text
 */
const Text = forwardRef<TextProps, 'p'>(({ children, ...props }, ref) => (
  <ChakraText {...props} ref={ref}>
    {typeof children === 'string' ? formatText(children) : children}
  </ChakraText>
));

export type { TextProps };
export default Text;
