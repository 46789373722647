import { type FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  getCountryCodeForRegionCode,
  parsePhoneNumber,
} from 'awesome-phonenumber';

import {
  DEFAULT_COUNTRY_CODE,
  getFormattedPhoneNumber,
  getRegionCodeForCountryCode,
} from 'common/phoneNumber';

import Component from './component';
import { type PhoneNumberProps } from './types';

const PhoneNumber: FC<PhoneNumberProps> = ({
  errorMessage,
  initialPhone,
  label,
  onBack,
  onSubmit,
  subtitle,
  title,
  withNoBackButton,
}) => {
  const hasHadFirstRenderRef = useRef(false);
  const [countryCode, setCountryCode] = useState<string>(() =>
    getCountryCodeForRegionCode(DEFAULT_COUNTRY_CODE).toString(),
  );
  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    if (!hasHadFirstRenderRef.current) {
      if (initialPhone) {
        const { formattedCountryCode, formattedPhone } =
          getFormattedPhoneNumber({ countryCode, phoneNumber: initialPhone });
        setCountryCode(formattedCountryCode);
        setPhone(formattedPhone);
      }
      hasHadFirstRenderRef.current = true;
    }
  }, [initialPhone, countryCode]);

  // Updates phone and countryCode based on the last input to phoneFormatterRef.
  const updatePhoneStateWithNumber = useCallback(
    (updatedCountryCode: string, updatedPhone: string) => {
      const { formattedCountryCode, formattedPhone } = getFormattedPhoneNumber({
        countryCode: updatedCountryCode,
        phoneNumber: updatedPhone,
      });
      setCountryCode(formattedCountryCode);
      setPhone(formattedPhone);
    },
    [],
  );

  const onUpdateCountryCode = useCallback(
    (updatedCountryCode: string) => {
      updatePhoneStateWithNumber(updatedCountryCode, phone);
    },
    [phone, updatePhoneStateWithNumber],
  );

  const onUpdatePhone = useCallback(
    (updatedPhone: string) => {
      updatePhoneStateWithNumber(countryCode, updatedPhone);
    },
    [countryCode, updatePhoneStateWithNumber],
  );

  const isSubmitDisabled = phone === '' || countryCode === '';

  const onSubmitHandler = useCallback(() => {
    const parsedPhoneNumber = parsePhoneNumber(phone, {
      regionCode: getRegionCodeForCountryCode(countryCode),
    });
    onSubmit(parsedPhoneNumber.number?.e164 || '');
  }, [phone, countryCode, onSubmit]);
  return (
    <Component
      countryCode={countryCode}
      errorMessage={errorMessage}
      isSubmitDisabled={isSubmitDisabled}
      label={label}
      onBack={onBack}
      onSubmit={onSubmitHandler}
      onUpdateCountryCode={onUpdateCountryCode}
      onUpdatePhone={onUpdatePhone}
      phone={phone}
      subtitle={subtitle}
      title={title}
      withNoBackButton={withNoBackButton}
    />
  );
};

export default PhoneNumber;
