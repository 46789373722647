import { createContext, useCallback, useContext } from 'react';
import {
  type Feature,
  isFeatureAvailable as isFeatureAvailableVendor,
} from '@shinetools/pricing-plan-library';

import { type FCWithChildren } from 'common/types';

import { type CurrentPricingPlan } from './useCurrentPricingPlanQuery';

interface CurrentPricingPlanContext {
  isFeatureAvailable: (feature: Feature) => boolean;
  plan: CurrentPricingPlan | null;
}

const currentPricingPlanContext =
  createContext<CurrentPricingPlanContext | null>(null);

interface CurrentPricingPlanProviderProps {
  plan: CurrentPricingPlan | null;
}

/**
 * This Provider is expected to be used in pair with the query hook
 * `useCurrentPricingPlanQuery` in order to store its result.
 */
export const CurrentPricingPlanProvider: FCWithChildren<
  CurrentPricingPlanProviderProps
> = ({ children, plan }) => {
  const isFeatureAvailable = useCallback(
    (feature: Feature) => isFeatureAvailableVendor(plan, feature),
    [plan],
  );

  return (
    <currentPricingPlanContext.Provider value={{ isFeatureAvailable, plan }}>
      {children}
    </currentPricingPlanContext.Provider>
  );
};

export const useCurrentPricingPlan = () => {
  const contextValues = useContext(currentPricingPlanContext);

  if (!contextValues) {
    throw new Error(
      '"useCurrentPricingPlan" can only be used under CurrentPricingPlanProvider',
    );
  }

  return contextValues;
};
