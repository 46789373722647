import { type FC } from 'react';
import { Box, Image, type ImageProps } from '@chakra-ui/react';

const Illustration: FC<ImageProps> = (props) => (
  <Box marginBottom="space-24">
    <Image boxSize="13.125rem" {...props} />
  </Box>
);

export default Illustration;
