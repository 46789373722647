import { useContext } from 'react';

import { PageLayout } from 'features/Bento/components';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import SasuCreationEligibilityForm from '../../components/SasuCreationEligibility';
import { model } from '../../machine';
import { type Signup } from '../../machine/machine';
import { type SignupPage } from '../../types/SignupPage';

/**
 * A page asking the user if they're eligible to create a SASU
 */
const SasuCreationEligibility: SignupPage<Signup> = ({ send, state }) => {
  const Layout = useContext(OBLayoutContext);

  const hasPrevious = !state.context.target;

  return (
    <Layout
      actions={
        <PageLayout.Actions
          onBack={hasPrevious ? () => send(model.events.PREV()) : undefined}
        />
      }
    >
      <SasuCreationEligibilityForm onSubmit={() => send(model.events.NEXT())} />
    </Layout>
  );
};

export default SasuCreationEligibility;
