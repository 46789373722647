import { companyContextStorage } from 'common/hooks/useCompanyContext/companyContextStorage';
import { webAppOpeningTransaction } from 'common/sentry/webAppOpeningTransaction';
import { identify, logEvent } from 'features/Analytics/analytics';
import { decodeToken } from 'helpers/auth';
import { type Tokens } from 'helpers/auth/types';
import { type User } from 'helpers/auth/user.schema';
import logger from 'helpers/logger';

import { type LoginType } from '../types';
import { type SetCurrentUser } from './types';

/**
 * Attempt to save logged in user
 * @param setCurrentUser user state setter
 * @param phone user's phone
 * @param tokens firebase and access stokens
 * @param deviceToken device's unique id
 * @return if login succeeded
 */
const doLogin = (
  loginType: LoginType,
  setCurrentUser: SetCurrentUser,
  phone: string,
  tokens: Tokens,
): boolean => {
  // In case it was not properly cleaned on logout, e.g: session expiration
  companyContextStorage.clear();

  const decoded = decodeToken(tokens.access_token);
  if (decoded) {
    const uid = decoded.sub;
    const newUser: User = {
      firebaseToken: tokens.firebaseToken,
      phone,
      token: tokens.access_token,
      uid,
      userHash: tokens.user_hash,
    };
    setCurrentUser(newUser);

    try {
      identify(uid);
      logEvent({ name: loginType === 'sign_up' ? 'Sign_Up' : 'Login' });
    } catch (error) {
      logger.warn('An error occurred while sending login analytics events', {
        error,
      });
    }

    if (loginType === 'sign_in') {
      webAppOpeningTransaction.startPostLogin();
    }

    return true;
  }

  return false;
};

export default doLogin;
