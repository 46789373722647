import { createModel } from 'xstate/lib/model';

import baseEvents from 'common/bento/config/baseEvents';
import { type FunctionReturn } from 'features/Bento/types/helpers';

import {
  type AddressSchema,
  getDefaultAddress,
} from '../../../components/AddressForm/addressSchema';
import { type AddressModuleDataQuery } from '../graphql/queries/addressModuleData.gql';
import { type Services } from './services';

export type AddressModuleData = AddressModuleDataQuery;

export type Context = {
  address: AddressSchema;
  getModuleData: () => AddressModuleData;
};

const initialContext: Context = {
  address: getDefaultAddress(),
  getModuleData: () => {
    throw new Error('NOT_IMPLEMENTED');
  },
};

const creators = {
  events: {
    ...baseEvents,
    'done.invoke.loadInitialData': (
      data: FunctionReturn<Services['loadInitialData']>,
    ) => ({
      data,
    }),
    'done.invoke.sendAddress': (
      data: FunctionReturn<Services['sendAddress']>,
    ) => ({ data }),
    EDIT_ADDRESS: () => ({}),
    SUBMIT_ADDRESS: (data: AddressSchema) => ({ data }),
  },
};

const model = createModel(initialContext, creators);

export default model;

export type Model = typeof model;
