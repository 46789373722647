import { forwardRef } from '@chakra-ui/system';

import Text, { type TextProps } from '../../../../Text';
import { useRadio } from '../../hooks/useRadio';

const Title = forwardRef<TextProps, 'p'>((props, ref) => {
  const { state } = useRadio();
  const { isChecked, isDisabled } = state;

  return (
    <Text
      ref={ref}
      {...props}
      {...(isChecked && { fontWeight: 'weight-500' })}
      variant={isDisabled ? 'disabled' : 'primary'}
    />
  );
});

export default Title;
