import {
  type SystemStyleFunction,
  type SystemStyleObject,
} from '@chakra-ui/theme-tools';

import InputTheme from './Input';

export const baseStyleCommon: SystemStyleObject = {
  _disabled: {
    cursor: 'not-allowed',
    opacity: 0.4,
  },
  _readOnly: {
    boxShadow: 'none',
    userSelect: 'all',
  },
  border: '2px solid',
  borderColor: 'transparent',
  paddingX: 'space-0',
  textAlign: 'center',
};

const baseStyle: SystemStyleFunction = (props) => ({
  ...InputTheme.baseStyle(props).field,
  ...baseStyleCommon,
  _hover: {
    backgroundColor: 'grey.300',
  },
  _invalid: {
    backgroundColor: 'red.200',
  },
  backgroundColor: 'grey.200',
  color: 'grey.700',
});

const sizes: Record<string, SystemStyleObject> = {
  md: {
    borderRadius: 'radius-6',
    fontSize: 'font-31',
    height: 'auto',
    paddingY: '5px',
    width: '2.5rem',
  },
};

const defaultProps = {
  size: 'md',
};

const PinInputTheme = {
  baseStyle,
  defaultProps,
  sizes,
};

export default PinInputTheme;
