import { type SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  background: 'gray.100',
  borderBottomWidth: '3px',
  borderRadius: 'radius-6',
  borderWidth: '1px',
  fontSize: '0.8em',
  fontWeight: 'weight-700',
  lineHeight: 'normal',
  paddingX: 'space-6',
  whiteSpace: 'nowrap',
};

const KbdTheme = {
  baseStyle,
};

export default KbdTheme;
