import { countries } from '@shinetools/geo-library';
import { type IconName } from '@shinetools/sunshine-icons';
import { differenceInMilliseconds } from 'date-fns';
import { convert as titleCase } from 'titlecase-french';

import {
  type AuthenticationDevice,
  AuthenticationDevicePlatform,
  AuthenticationDeviceStatus,
} from '__generated__/GQL';
import { getLanguage } from 'common/locale';

type Device = Pick<
  AuthenticationDevice,
  'lastConnectedAt' | 'lastConnectionAttemptedAt' | 'platform' | 'status'
>;

type DeviceWithLocation = Device &
  Pick<AuthenticationDevice, 'lastConnectionCity' | 'lastConnectionCountry'>;

const DEFAULT_DATE = new Date(0);

export const isDeviceBlocked = (device: Device) =>
  device.status === AuthenticationDeviceStatus.Blocked;

export const isDeviceTrusted = (device: Device) =>
  device.status === AuthenticationDeviceStatus.Trusted;

export const getDeviceConnectionDate = (device: Device): Date | undefined => {
  const connectionDate = isDeviceTrusted(device)
    ? device.lastConnectedAt
    : device.lastConnectionAttemptedAt;

  if (!connectionDate) {
    return undefined;
  }

  return new Date(connectionDate);
};

export const compareConnectionDate = (deviceA: Device, deviceB: Device) => {
  // Default to start of unix timestamp to show devices without last connection date
  const deviceAConnectionDate =
    getDeviceConnectionDate(deviceA) || DEFAULT_DATE;
  const deviceBConnectionDate =
    getDeviceConnectionDate(deviceB) || DEFAULT_DATE;
  return differenceInMilliseconds(deviceBConnectionDate, deviceAConnectionDate);
};

export const getIconName = (device: Pick<Device, 'platform'>): IconName => {
  if (
    device.platform === AuthenticationDevicePlatform.Android ||
    device.platform === AuthenticationDevicePlatform.Ios
  ) {
    return 'mobile';
  }

  if (device.platform === AuthenticationDevicePlatform.Web) {
    return 'laptop';
  }

  return 'question';
};

export const getDeviceLocation = (
  device: DeviceWithLocation,
): string | null => {
  const { lastConnectionCity, lastConnectionCountry } = device;

  if (!lastConnectionCity || !lastConnectionCountry) {
    return null;
  }

  const language = getLanguage() === 'fr' ? 'fr' : 'en';
  const country =
    lastConnectionCountry in countries
      ? countries[lastConnectionCountry as keyof typeof countries].name[
          language
        ]
      : null;
  return `${titleCase(lastConnectionCity)}${country ? `, ${country}` : ''}`;
};
