import { type FC } from 'react';
import { Box, type BoxProps } from '@chakra-ui/react';

import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';

/**
 * A box designed for the main content of the OBCard.
 */
const Body: FC<BoxProps> = ({ children, ...props }) => {
  const isLarge = useLayoutSizeQuery(BentoLayoutSize.md);

  return (
    <Box
      paddingX={isLarge ? 'space-24' : 'space-0'}
      paddingY="space-24"
      width="full"
      {...props}
    >
      {children}
    </Box>
  );
};

export default Body;
