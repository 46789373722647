import { type ButtonProps } from 'components/_core/Button';

interface AddOption {
  label: string;
  buttonProps: Omit<ButtonProps, 'children'>;
  value: string;
}

export const addOption = (props: AddOption) => ({
  isAddOption: true,
  ...props,
});
