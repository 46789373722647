import { type FC } from 'react';
import { Radio as ChakraRadio, type RadioProps, Stack } from '@chakra-ui/react';

import Group, { type GroupProps } from './components/Group';
import Label from './components/Label';
import Title from './components/Title';
import { RadioProvider } from './hooks/useRadio';
import { useRadioGroup } from './hooks/useRadioGroup';

export type RadioPositions = 'left' | 'right';

interface RadioParts {
  Group: typeof Group;
  Label: typeof Label;
  Title: typeof Title;
}

type RadioType = RadioParts & FC<RadioProps>;

const Radio: RadioType = ({ children, value, ...props }) => {
  const { getRadioProps, name, radioPosition } = useRadioGroup();
  const radio = getRadioProps({ name, value });

  return (
    <RadioProvider value={{ ...radio, ...props }}>
      <ChakraRadio
        data-testid="radio"
        flexDirection={radioPosition === 'right' ? 'row-reverse' : 'row'}
        {...radio}
        {...props}
      >
        <Stack spacing="space-4">{children}</Stack>
      </ChakraRadio>
    </RadioProvider>
  );
};

Radio.Group = Group;
Radio.Label = Label;
Radio.Title = Title;

export type { GroupProps as RadioGroupProps };
export type { RadioProps };
export default Radio;
