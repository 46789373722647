import { type FC } from 'react';
import { Image } from '@chakra-ui/react';

import sadHeart from 'assets/images/sadHeart.svg';
import Accordion from 'components/_core/Accordion';

import NewDeviceLoginLayout from '../NewDeviceLoginLayout';
import locales from './locales';
import { Description, Title } from './styles';
import { type Props } from './types';

const SUPPORT_EMAIL = 'recuperation@shine.fr';

const ForgotPasscodeUnknownDevice: FC<Props> = ({ cancel }) => {
  return (
    <NewDeviceLoginLayout cancel={cancel}>
      <Image src={sadHeart} width="180px" />
      <Title>{locales.title}</Title>
      <Description>{locales.description}</Description>
      <Accordion>
        <Accordion.Item title={locales.noAccessToTrustedDeviceTitle}>
          <Accordion.Text>
            {locales.noAccessToTrustedDeviceDescription}
          </Accordion.Text>

          <Accordion.Link href={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </Accordion.Link>
        </Accordion.Item>
      </Accordion>
    </NewDeviceLoginLayout>
  );
};

export default ForgotPasscodeUnknownDevice;
