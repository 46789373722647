import { createContext } from 'react';

import { type FCWithChildren } from 'common/types';

interface CanAccessInternationalTransferContextState {
  canAccessInternationalTransfer: boolean;
}

export const canAccessInternationalTransferContext =
  createContext<CanAccessInternationalTransferContextState>({
    canAccessInternationalTransfer: false,
  });

export const CanAccessInternationalTransferContextProvider: FCWithChildren<
  CanAccessInternationalTransferContextState
> = ({ canAccessInternationalTransfer, children }) => {
  return (
    <canAccessInternationalTransferContext.Provider
      value={{
        canAccessInternationalTransfer,
      }}
    >
      {children}
    </canAccessInternationalTransferContext.Provider>
  );
};
