import { useContext, useRef } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { type BentoLayout } from 'common/bento/lib/layout';
import useIsVisible from 'common/hooks/useIsVisible';

import NavigationContext from '../../components/Navigation/NavigationContext';

/**
 * Once collapsed, the navigation content is hardcoded to 40px,
 * with a 12px gridGap, 16px paddingTop, and 1px borderWidth.
 */
const COLLAPSED_NAVIGATION_HEIGHT = 40 + 12 + 16 + 1;

const OnboardingMobile: BentoLayout = ({ children, nav }) => {
  const topOfPageRef = useRef<null | HTMLDivElement>(null);

  const contentRef = useRef<null | HTMLDivElement>(null);
  const contentHeight = contentRef.current?.getBoundingClientRect().height ?? 0;

  /**
   * We'll collapse the navigation title after the user starts to scroll.
   * The window check is to only collapse pages for which it makes sense to do so.
   */
  const shouldCollapseNavigation =
    useIsVisible(topOfPageRef) === false &&
    contentHeight > window.innerHeight - COLLAPSED_NAVIGATION_HEIGHT;

  const navigationContext = useContext(NavigationContext);

  return (
    <NavigationContext.Provider
      value={{
        ...navigationContext,
        shouldCollapseNavigation,
      }}
    >
      <Box position="absolute" ref={topOfPageRef} />

      <VStack align="stretch" height="full" spacing="space-24">
        <Box position="sticky" top={0} zIndex="banner">
          {nav}
        </Box>

        <Box
          alignSelf="center"
          animate={{ opacity: 1, y: 0 }}
          as={motion.div}
          flexGrow={1}
          initial={{ opacity: 0, y: 5 }}
          maxWidth="420px"
          padding="space-16"
          paddingBottom="space-24"
          ref={contentRef}
          width="full"
        >
          {children}
        </Box>
      </VStack>
    </NavigationContext.Provider>
  );
};

export default OnboardingMobile;
