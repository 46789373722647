import { Box } from '@chakra-ui/react';

import { type FCWithChildren } from 'common/types';
import Heading from 'components/_core/Heading';

const Title: FCWithChildren = (props) => (
  <Box marginBottom="space-16">
    <Heading size="lg" {...props} />
  </Box>
);

export default Title;
