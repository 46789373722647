import React, { type ChangeEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Link,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  frenchDepartmentCodes,
  frenchDepartments,
} from '@shinetools/geo-library';
import { isValid, parseISO } from 'date-fns';
import { useCombobox, type UseComboboxGetItemPropsOptions } from 'downshift';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';
import useCountriesList from 'common/countries/useCountriesList';
import * as Form from 'components/_core/form';
import IconButton from 'components/_core/IconButton';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import MemorableDateInput from 'components/MemorableDateInput';
import SelectOption from 'components/SelectOption';
import { FRANCE_COUNTRY_CODE } from 'features/Bento/libs/constants';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';
import StickyButton from 'features/TeamOnboarding/Onboarding/components/StickyButton/StickyButton';
import { formatDate } from 'helpers/date';
import { capitalize } from 'helpers/string';

import Header from '../../../../components/Header';
import Dropdown from '../../components/Dropdown';
import useTrackBirthCityInputUsage from '../../hooks/useTrackBirthCityInputUsage';
import { GENDER_OPTIONS } from '../../libs/constants';
import { type BirthPlace, getResults } from '../../libs/fetchFrenchCommunes';
import identitySchema, { type IdentitySchema } from '../../libs/identitySchema';
import locales from './locales';

interface PersonalInformationProps {
  isSubmitting: boolean;
  onSubmit: (values: IdentitySchema) => void;
  initialValues: IdentitySchema;
}

/**
 * Determines if the user has the right to edit the string of their selection
 * given the selected item. This is because once the user has selected a valid
 * proposition from the list, the input value changes to be a string combo of
 * city + department (e.g. `Agde — Hérault (34)` instead of `Agde`) which won’t
 * give any results from the API since we’re fetching by city name.
 * To change the selection, they can clear the input.
 */
const canEditSelection = (selectedItem: BirthPlace | null | undefined) =>
  selectedItem === null;

/**
 * A page that asks for the user’s identity information.
 */
const PersonalInformation = asBentoPage<PersonalInformationProps>(
  ({ initialValues, isSubmitting, Layout, onPrev, onSubmit }) => {
    const countriesOptions = [
      {
        key: 'FR',
        label: '🇫🇷 France',
        value: 'FR',
      },
      {
        isDisabled: true,
        key: 'divider',
        label: '---',
        value: '',
      },
      ...useCountriesList()
        .filter(({ value }) => value !== 'FR')
        .map(({ emoji, label, value }) => ({
          key: value,
          label: `${emoji} ${label}`,
          value,
        })),
    ];
    const departmentOptions = [
      {
        label: locales.form.selectDepartment,
        value: '',
      },
      ...frenchDepartmentCodes.map((code) => ({
        label: `${code} - ${frenchDepartments[code]}`,
        value: code,
      })),
    ];

    const { control, formState, handleSubmit, register, setValue, watch } =
      useForm<IdentitySchema>({
        defaultValues: initialValues,
        mode: 'onSubmit',
        resolver: zodResolver(identitySchema),
      });

    const [birthCountry, birthCity, birthDepartment, birthdateString] = watch([
      'birthCountry',
      'birthCity',
      'birthDepartment',
      'birthdate',
    ]);
    const birthdate = birthdateString ? parseISO(birthdateString) : null;

    const trackBirthCityInputUsage = useTrackBirthCityInputUsage();
    const [items, setItems] = useState<BirthPlace[]>([]);
    const [isOpen, setIsOpen] = useState(false);

    const {
      closeMenu,
      getInputProps,
      getItemProps,
      getMenuProps,
      highlightedIndex,
      inputValue,
      reset: resetCombobox,
      selectedItem,
    } = useCombobox({
      isOpen,
      items,
      itemToString(item) {
        return item ? item.label : '';
      },
      async onInputValueChange({ inputValue: value }) {
        const results = await getResults(value || '');
        setItems(results);
      },
      onIsOpenChange({ isOpen: newIsOpen, selectedItem: newSelectedItem }) {
        if (
          newIsOpen === false ||
          // Avoid showing the dropdown when input is readonly
          (newIsOpen === true && canEditSelection(newSelectedItem))
        ) {
          setIsOpen(newIsOpen);
        }
      },
      onSelectedItemChange({ selectedItem: newSelectedItem }) {
        setValue('birthCity', newSelectedItem?.birthCity ?? '');
        setValue('birthDepartment', newSelectedItem?.birthDepartment ?? '');

        // Prevent event being triggered when user clears the input
        if (newSelectedItem) {
          trackBirthCityInputUsage('from list');
        }
      },
    });

    const isDesktop = useLayoutSizeQuery(BentoLayoutSize.md);
    const isBirthCityInputFullScreen = isOpen && !isDesktop;
    const display = isBirthCityInputFullScreen ? 'none' : undefined;

    /**
     * This is a hacky way to suppress downshift ref errors when it’s not in use,
     * as we don’t use combobox input when birthCountry is not FR, and we cannot
     * conditionally call its hook.
     */
    if (birthCountry !== 'FR') {
      getMenuProps({}, { suppressRefError: true });
      getInputProps({}, { suppressRefError: true });
    }

    /**
     * Don’t show the birthDepartment input for users not born in France.
     * Show it if the enhanced birthCityInput is not enabled.
     * Don’t show it if there’s a selectedItem (this means the user was able to
     * select from the official list, so we already have the birth department).
     * Don’t show it before the user touched the birthCity field but only when
     * birthDepartment is empty (otherwise it will be hidden when the user goes
     * back to this page with already filled info).
     * Don’t show it when the birthCity input is empty but only when the
     * birthDepartment is empty too.
     */
    const showBirthDepartmentInput = (() => {
      if (birthCountry !== 'FR') {
        return false;
      }

      return !(
        selectedItem ||
        (!formState.touchedFields.birthCity && !birthDepartment) ||
        (!birthCity && !birthDepartment)
      );
    })();

    const reset = () => {
      setValue('birthCity', '');
      setValue('birthDepartment', '');
      resetCombobox();
    };

    return (
      <Layout
        nav={
          <Navigation
            isCollapsed={isBirthCityInputFullScreen}
            onPrev={onPrev}
          />
        }
      >
        <Box overflow="visible">
          <Header display={display} title={locales.page.title} />

          <Flex
            as="form"
            direction="column"
            height="full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <VStack align="stretch" spacing="space-16">
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <Form.Radio.Group
                    alignSelf="flex-start"
                    direction="row"
                    display={display}
                    name="radio-group"
                    onChange={onChange}
                    value={value ?? undefined}
                    variant="outline"
                  >
                    {GENDER_OPTIONS.map((option) => (
                      <Form.Radio key={option.value} value={option.value}>
                        <Form.Radio.Title>{option.label}</Form.Radio.Title>
                      </Form.Radio>
                    ))}
                  </Form.Radio.Group>
                )}
              />

              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <Form.Field
                    display={display}
                    error={formState.errors.firstName?.message}
                    label={locales.form.labelFirstName}
                  >
                    <Form.Input
                      onChange={(e) =>
                        field.onChange(capitalize(e.target.value))
                      }
                      value={field.value}
                    />
                  </Form.Field>
                )}
              />

              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <Form.Field
                    display={display}
                    error={formState.errors.lastName?.message}
                    label={locales.form.labelLastName}
                  >
                    <Form.Input
                      onChange={(e) =>
                        field.onChange(e.target.value.toUpperCase())
                      }
                      value={field.value}
                    />
                  </Form.Field>
                )}
              />

              <Form.Field
                display={display}
                error={formState.errors.birthdate?.message}
                hint={
                  birthdate && isValid(birthdate)
                    ? formatDate(birthdate, 'PPP')
                    : undefined
                }
                label={locales.form.labelBirthdate}
              >
                <Controller
                  control={control}
                  name="birthdate"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MemorableDateInput onChange={onChange} value={value} />
                    );
                  }}
                />
              </Form.Field>

              <Form.Field
                display={display}
                error={formState.errors.birthCountry?.message}
                label={locales.form.labelBirthCountry}
              >
                <Controller
                  control={control}
                  name="birthCountry"
                  render={({ field }) => {
                    const selectedOption = countriesOptions.find(
                      (option) => option.value === field.value,
                    );

                    return (
                      <Form.SunshineSelect
                        components={{ Option: SelectOption }}
                        isSearchable
                        onChange={(option) => {
                          reset();
                          field.onChange(option?.value);
                        }}
                        options={countriesOptions}
                        value={selectedOption}
                      />
                    );
                  }}
                />
              </Form.Field>
              {birthCountry === FRANCE_COUNTRY_CODE ? (
                /**
                 * Shows a combobox where the user should enter their city of birth.
                 * We show them a list of commune + département matching what they typed using
                 * the Découpage administratif > Communes API from geo.api.gouv.fr.
                 * They can still continue with a city that provides no match from the API, but
                 * in this case we show them an additional input to specify the département.
                 */
                <Form.Field
                  error={formState.errors.birthCity?.message}
                  label={locales.form.labelBirthCity}
                  marginTop={isBirthCityInputFullScreen ? 'space-0' : undefined}
                  position="relative"
                >
                  <InputGroup>
                    <Controller
                      control={control}
                      name="birthCity"
                      render={({
                        field: { onBlur, onChange, value, ...fieldRest },
                      }) => (
                        <Form.Input
                          {...getInputProps({
                            autoComplete: 'off',
                            onBlur: (e) => {
                              onBlur();

                              // Don’t track when input is empty or when value
                              // has been selected from list (e.target.value
                              // will be different from value in that case)
                              if (e.target.value && e.target.value === value) {
                                trackBirthCityInputUsage('manual');
                              }
                            },
                            onChange: (e: ChangeEvent<HTMLInputElement>) =>
                              onChange(e.target.value),
                            readOnly: !canEditSelection(selectedItem),
                            type: 'text',
                            value: selectedItem?.label || value,
                            ...fieldRest,
                          })}
                        />
                      )}
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="clear selection"
                        color="grey.600"
                        display={inputValue.length ? 'initial' : 'none'}
                        icon="cross"
                        onClick={reset}
                        tabIndex={-1}
                        variant="inline-secondary"
                      />
                    </InputRightElement>
                  </InputGroup>
                  {isDesktop ? (
                    <Dropdown
                      {...getMenuProps()}
                      // This element need to be present in the DOM at all times for accessibility
                      display={isOpen && inputValue.length ? 'initial' : 'none'}
                    >
                      {items.map((item, index) => (
                        <Dropdown.Option
                          isHighlighted={highlightedIndex === index}
                          key={`${item.label}${index}`}
                          {...getItemProps({ index, item })}
                        >
                          <Text margin="space-0" variant="primary">
                            {item.label}
                          </Text>
                        </Dropdown.Option>
                      ))}
                      {items.length ? null : (
                        <>
                          <Dropdown.Option>
                            <Text margin="space-0" variant="light">
                              {locales.form.birthCityNoResults}
                            </Text>
                          </Dropdown.Option>
                          <Dropdown.Option onClick={closeMenu}>
                            <Link>{locales.form.birthCityOther}</Link>
                          </Dropdown.Option>
                        </>
                      )}
                    </Dropdown>
                  ) : (
                    <SunshineCard.Group
                      as="ul"
                      isBoxed={false}
                      marginTop="space-4"
                      {...getMenuProps()}
                      // This element need to be present in the DOM at all times for accessibility
                      display={isOpen && inputValue.length ? 'block' : 'none'}
                    >
                      {items.map((item, index) => {
                        const [title, subtitle] = item.label.split(' — ');
                        const itemProps = getItemProps({
                          index,
                          item,
                          subtitle,
                          title,
                        } as UseComboboxGetItemPropsOptions<BirthPlace>);

                        return (
                          <SunshineCard
                            as="li"
                            key={`${item.label}${index}`}
                            {...itemProps}
                          >
                            <SunshineCard.Content>
                              <SunshineCard.Text>
                                {itemProps.subtitle}
                              </SunshineCard.Text>
                            </SunshineCard.Content>

                            <SunshineCard.Slot name="action">
                              <SunshineCard.SunshineIcon name="chevron-right" />
                            </SunshineCard.Slot>
                          </SunshineCard>
                        );
                      })}
                      {items.length ? null : (
                        <>
                          <SunshineCard>
                            <SunshineCard.Content>
                              <SunshineCard.Text>
                                {locales.form.birthCityNoResults}
                              </SunshineCard.Text>
                            </SunshineCard.Content>
                          </SunshineCard>

                          <SunshineCard
                            as="li"
                            onClick={closeMenu}
                            title={locales.form.birthCityOther}
                          >
                            <SunshineCard.Slot name="action">
                              <SunshineCard.SunshineIcon name="chevron-right" />
                            </SunshineCard.Slot>
                          </SunshineCard>
                        </>
                      )}
                    </SunshineCard.Group>
                  )}
                </Form.Field>
              ) : (
                <Form.Field
                  error={formState.errors.birthCity?.message}
                  label={locales.form.labelBirthCity}
                >
                  <Form.Input
                    {...register('birthCity', {
                      onBlur: () => {
                        if (birthCountry === 'FR') {
                          trackBirthCityInputUsage('manual');
                        }
                      },
                    })}
                    type="text"
                  />
                </Form.Field>
              )}

              {showBirthDepartmentInput ? (
                <Form.Field
                  display={display}
                  error={formState.errors.birthDepartment?.message}
                  label={locales.form.labelBirthDepartment}
                >
                  <Controller
                    control={control}
                    name="birthDepartment"
                    render={({ field }) => {
                      const selectedOption = departmentOptions.find(
                        (option) => option.value === field.value,
                      );

                      return (
                        <Form.SunshineSelect
                          onChange={(option) => field.onChange(option?.value)}
                          options={departmentOptions}
                          value={selectedOption}
                        />
                      );
                    }}
                  />
                </Form.Field>
              ) : null}

              <Form.Field
                display={display}
                error={formState.errors.nationality?.message}
                label={locales.form.labelNationality}
              >
                <Controller
                  control={control}
                  name="nationality"
                  render={({ field }) => {
                    const selectedOption = countriesOptions.find(
                      (option) => option.value === field.value,
                    );

                    return (
                      <Form.SunshineSelect
                        components={{ Option: SelectOption }}
                        isSearchable
                        onChange={(option) => field.onChange(option?.value)}
                        options={countriesOptions}
                        value={selectedOption}
                      />
                    );
                  }}
                />
              </Form.Field>
            </VStack>

            <StickyButton
              display={display}
              isLoading={isSubmitting}
              type="submit"
            >
              {locales.cta}
            </StickyButton>
          </Flex>
        </Box>
      </Layout>
    );
  },
);

export default PersonalInformation;
