/* eslint-disable jsx-a11y/no-autofocus */

import { useContext } from 'react';
import { Text } from '@chakra-ui/react';

import Heading from 'components/_core/Heading';
import { PinInput } from 'features/Bento/atoms';
import InputErrorMessage from 'features/Bento/atoms/InputErrorMessage/InputErrorMessage';
import { PageLayout } from 'features/Bento/components';
import { type SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import { GuidancePasscode } from '../../../../components/Guidance';
import { model } from '../../machine';
import { type Intro, State } from '../../machine/machine';
import locales from './locales';

/**
 * A page where we ask the user to define their passcode
 */
const Passcode: SignupPage<Intro> = ({ send, state }) => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
      data-testid="passcode"
      guidance={<GuidancePasscode />}
    >
      <Heading marginBottom="space-24" size="lg">
        {locales.title}
      </Heading>

      <Text marginBottom="space-8" size="sm">
        {locales.label}
      </Text>

      <PinInput
        autoFocus
        digits={4}
        isInvalid={state.matches(State.PASSCODE_CONFIRM_INVALID)}
        marginBottom="space-24"
        mask
        onComplete={(value) => send(model.events.SUBMIT_PASSCODE(value))}
      />

      {state.matches(State.PASSCODE_CONFIRM_INVALID) ? (
        <InputErrorMessage marginBottom="0" marginTop="space-8">
          {locales.didNotMatchError}
        </InputErrorMessage>
      ) : null}

      {/* @todo check that we still want to auto submit (and thus keep this button always disabled) */}
    </Layout>
  );
};

export default Passcode;
