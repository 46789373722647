import { createModel } from 'xstate/lib/model';

import baseEvents from 'features/Bento/libs/machines/config/baseEvents';

import { type GetAgreementCatchupContextQuery } from '../graphql/queries/getAgreementCatchupContext.gql';

const context = {
  getModuleData: () => {
    throw new Error('NOT_IMPLEMENTED');
  },
} as { getModuleData: () => GetAgreementCatchupContextQuery };

export type Context = typeof context;

const model = createModel(
  context,

  {
    events: {
      ...baseEvents,
    },
  },
);

export default model;
