import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    cancel: 'Cancel',
    confirmAction: 'Yes, reset my passcode',
    description:
      'You will be able to choose a **new secret code**. We will then ask you to log in using a **temporary 6-digit code you will receive by text.**',
    title: 'Are you sure you want to reset your passcode?',
  },
  fr: {
    back: 'Précédent',
    cancel: 'Annuler',
    confirmAction: 'Réinitialiser mon code secret',
    description:
      'Vous pourrez choisir un **nouveau code secret**. Nous vous demanderons ensuite de vous identifier en renseignant un **code temporaire à six chiffres reçu par SMS.**',
    title: 'Êtes-vous sûr·e de vouloir réinitialiser votre code secret ?',
  },
} as const);

export default locales;
