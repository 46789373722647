import { createContext, useContext } from 'react';
import { type UseCheckboxGroupReturn } from '@chakra-ui/react';

import { type CheckboxPositions } from '..';

interface CheckboxGroupContext extends UseCheckboxGroupReturn {
  checkboxPosition?: CheckboxPositions;
}

const Context = createContext<CheckboxGroupContext | null>(null);

export const CheckboxGroupProvider = Context.Provider;

export function useCheckboxGroup({ checkboxPosition = 'left' } = {}) {
  const context = useContext(Context);

  if (!context) {
    /**
     * if the Checkbox is used outside of a group, return default values
     * to avoid throwing errors
     */
    return {
      checkboxPosition,
      getCheckboxProps: () => {},
    };
  }

  return context;
}
