import { type FC } from 'react';
import { Box, chakra, HStack } from '@chakra-ui/react';

import SunshineDropdown from 'components/_core/SunshineDropdown';
import SunshineIcon from 'components/_core/SunshineIcon';
import Text from 'components/_core/Text';
import { type CompanyItemProps } from 'components/CompanyListItem/types';

const CompanyListItemRebranded: FC<CompanyItemProps> = ({
  avatarUrl,
  disabled = false,
  icon,
  onClick,
  subtitle,
  title,
}) => {
  return (
    <SunshineDropdown.Item as="button" isDisabled={disabled} onClick={onClick}>
      <HStack spacing="space-16">
        <chakra.img
          alt="Company avatar"
          borderRadius="radius-12"
          boxSize={40}
          overflow="hidden"
          src={avatarUrl}
        />
        <Box flex={1}>
          <Text fontWeight="weight-500" variant="primary">
            {title}
          </Text>
          <Text variant="light">{subtitle}</Text>
        </Box>
        {icon ? <SunshineIcon name={icon} /> : null}
      </HStack>
    </SunshineDropdown.Item>
  );
};

export default CompanyListItemRebranded;
