import { type UserCompanyList } from 'common/hooks/useUserCompanyList';

export const getCompanies = (
  companies: UserCompanyList,
  companyProfileId?: string,
) => {
  const selectedCompany = companies.find(
    (company) => company.companyProfileId === companyProfileId,
  );

  const companyInOnboarding = companies.find(
    (company) => company.metadata.accountStatus !== 'VALIDATED',
  );

  const otherValidatedCompanies = companies.filter(
    (company) =>
      company.companyProfileId !== companyInOnboarding?.companyProfileId &&
      company.companyProfileId !== selectedCompany?.companyProfileId,
  );

  return {
    companyInOnboarding,
    otherValidatedCompanies,
    selectedCompany,
  } as const;
};
