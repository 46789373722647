import { PageLayout } from 'features/Bento/components';
import { type OBLayout } from 'features/Bento/types/OBLayout';

/**
 * A full blank page, only children are rendered
 */
const SignupLayoutFull: OBLayout = ({ children }) => {
  return (
    <PageLayout.Grid templateColumns="1fr" templateRows="1fr">
      {children}
    </PageLayout.Grid>
  );
};

export default SignupLayoutFull;
