import { forwardRef } from '@chakra-ui/system';

import Text, { type TextProps } from '../../../../Text';
import { useRadio } from '../../hooks/useRadio';

const Label = forwardRef<TextProps, 'p'>((props, ref) => {
  const { state } = useRadio();
  const { isDisabled } = state;

  return (
    <Text ref={ref} {...props} {...(isDisabled && { variant: 'disabled' })} />
  );
});

export default Label;
