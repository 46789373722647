import { useQuery } from '@apollo/client';

import useCompanyContext from 'common/hooks/useCompanyContext';

import { type Company } from '../..';
import { UserLockedPageDocument } from './userLockedPage.gql';

export const useUserLockedPage = (companies: Company[] = []) => {
  const { companyProfileId = '' } = useCompanyContext();
  const { data, ...hook } = useQuery(UserLockedPageDocument, {
    skip: !companyProfileId || companies.length >= 1,
    variables: { companyProfileId },
  });

  const isLockedFromMultipleCompanies = companies.length >= 2;
  const companyName = isLockedFromMultipleCompanies
    ? null
    : companies[0]?.legalName ?? data?.viewer.company.profile.legalName ?? '';

  return {
    ...hook,
    companyName,
    isLockedFromMultipleCompanies,
  };
};
