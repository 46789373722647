import {
  type BentoModule,
  type BentoModuleInput,
} from 'common/bento/types/BentoModule';

/**
 * The simplest implementation of a Module is a React Component that takes an `onDone` callback.
 *
 * This function is defined to allow future extension of our Modules' features without breaking existing implementations.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const asBentoModule = <TProps extends {}>(
  Component: BentoModuleInput<TProps>,
): BentoModule<TProps> => {
  return Component;
};

export default asBentoModule;
