import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    close: 'Close',
    description:
      'The request has expired because the time limit has passed. Please try again.',
    resend: 'Resend the request',
    title: 'Request expired',
  },
  fr: {
    close: 'Fermer',
    description:
      'La requête a expiré car le temps imparti est écoulé. Veuillez renouveler l’opération.',
    resend: 'Renvoyer la demande',
    title: 'Requête expirée',
  },
} as const);

export default locales;
