import { type KycReviewDocument } from '__generated__/GQL';
import { getKycDocErrorsByCategory } from 'features/Bento/libs/kycDocuments';

interface GetAddressErrorsProps {
  kycDocuments: Pick<KycReviewDocument, 'type' | 'errorLocalized'>[];
}

const getAddressErrors = ({ kycDocuments }: GetAddressErrorsProps) =>
  getKycDocErrorsByCategory({ kycDocuments }, 'ADDRESS');

export default getAddressErrors;
