import { type FC } from 'react';
import { convert as titleCase } from 'titlecase-french';

import pendingUrlRebranded from 'assets/images/accountSelection/pending.svg';
import useCompanyContext from 'common/hooks/useCompanyContext';
import SunshineDropdown from 'components/_core/SunshineDropdown';
import Text from 'components/_core/Text';
import { getCompanies } from 'components/CompanyList/utils';
import CompanyListItem from 'components/CompanyListItem/rebranded';

import locales from './locales';
import { type Props } from './types';

const CompanyList: FC<Props> = ({ companies, onItemClick }) => {
  const logoSize = 40;

  const { companyProfileId, setCompanyContext } = useCompanyContext();
  const { companyInOnboarding, otherValidatedCompanies, selectedCompany } =
    getCompanies(companies, companyProfileId);

  const isPrimaryApplicant = !!companyInOnboarding?.isPrimaryApplicant;
  const description = isPrimaryApplicant ? locales.finish : locales.complete;

  return (
    <SunshineDropdown.List>
      {selectedCompany ? (
        <CompanyListItem
          avatarUrl={selectedCompany.logoSrc}
          disabled
          key={selectedCompany.companyProfileId}
          logoSize={40}
          subtitle={selectedCompany.legalForm}
          title={
            selectedCompany.legalName
              ? titleCase(selectedCompany.legalName)
              : locales.newCompany
          }
        />
      ) : null}

      {otherValidatedCompanies.map((company) => (
        <CompanyListItem
          avatarUrl={company.logoSrc}
          icon="chevron-right"
          key={company.companyProfileId}
          logoSize={56}
          onClick={() => {
            setCompanyContext({
              companyProfileId: company.companyProfileId,
              companyUserId: company.companyUserId,
            });
            onItemClick?.();
          }}
          subtitle={company.legalForm}
          title={titleCase(company.legalName)}
        />
      ))}

      {companyInOnboarding ? (
        <>
          <Text
            marginLeft="space-8"
            marginTop="space-16"
            paddingRight="space-16"
          >
            {description}
          </Text>

          <CompanyListItem
            avatarUrl={pendingUrlRebranded}
            icon="chevron-right"
            key={companyInOnboarding.companyProfileId}
            logoSize={logoSize}
            onClick={() => {
              setCompanyContext({
                companyProfileId: companyInOnboarding.companyProfileId,
                companyUserId: companyInOnboarding.companyUserId,
              });
              onItemClick?.();
            }}
            subtitle={locales.onboardingOngoing}
            title={
              companyInOnboarding.legalName
                ? titleCase(companyInOnboarding.legalName)
                : locales.newCompany
            }
          />
        </>
      ) : null}
    </SunshineDropdown.List>
  );
};

export default CompanyList;
