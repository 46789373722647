import * as Types from '../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetAuthenticationClientQueryVariables = Types.Exact<{
  clientId: Types.Scalars['ID']['input'];
}>;


export type GetAuthenticationClientQuery = { __typename: 'Query', getAuthenticationClient: { __typename: 'AuthenticationClientOutput', displayName: string, logoURL: string } };


export const GetAuthenticationClientDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAuthenticationClient"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"clientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAuthenticationClient"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"clientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"clientId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"logoURL"}}]}}]}}]} as unknown as DocumentNode<GetAuthenticationClientQuery, GetAuthenticationClientQueryVariables>;