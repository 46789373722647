import { ERROR_CODES } from '@shinetools/errors';
import { z } from 'zod';

import { type CompanyInvitationRole } from '__generated__/GQL';
import config from 'config';
import { type AffiliationContext } from 'features/Bento/flows/signup/machine/model';
import { type Gender } from 'features/Onboarding/legacy/types';
import { get, post } from 'helpers/fetch';
import { type FetchError } from 'helpers/fetch/types';

import { type Tokens } from './types';

const { shineApiHost } = config;

// On auth calls, we do not want to report wrong passcode errors to sentry or network errors
const shouldReportToSentry = (err: FetchError) => {
  return (
    err.status !== 429 &&
    err.status !== 400 &&
    err.code !== ERROR_CODES.AUTHENTICATION_PASSCODE_WRONG &&
    err.message !== 'Failed to fetch' &&
    err.message !== 'Load failed' &&
    err.message !== 'undefined'
  );
};

interface CheckEmailResponse {
  available: boolean;
  phoneNumberOwnershipToken: string;
}

interface ForgotPasscodeInput {
  phone: string;
  deviceToken: string;
  model: string;
  name: string;
}

interface ForgotPasscodeResponse {
  shouldTriggerNewDeviceAuthorization: boolean;
}
export const forgotPasscode = ({
  deviceToken,
  model,
  name,
  phone,
}: ForgotPasscodeInput) =>
  post<ForgotPasscodeResponse>(
    `${shineApiHost}/authentication/phone/${phone}/forgot`,
    { deviceToken, model, name },
    {},
    shouldReportToSentry,
  );

export const checkEmail = (email: string) =>
  get<CheckEmailResponse>(
    `${shineApiHost}/authentication/email/${email}/check`,
    {},
    {},
    shouldReportToSentry,
  );

const verificationCodeResponseSchema = z.object({
  data: z.discriminatedUnion('available', [
    z.object({
      available: z.literal(true),
      phoneNumberOwnershipToken: z.string().min(1),
    }),
    z.object({
      available: z.literal(false),
      phoneNumberOwnershipToken: z.literal(null),
    }),
  ]),
});

export type VerificationCodeResponseSchema = z.infer<
  typeof verificationCodeResponseSchema
>;

export const checkVerificationCode = async ({
  phoneNumber,
  verificationCode,
}: {
  phoneNumber: string;
  verificationCode: string;
}) => {
  /**
   * This will throw if the verificationCode was invalid
   * and the endpoint answers a 400
   */
  const response = await post(
    `${shineApiHost}/authentication/phone/${phoneNumber}/verify_2fa_code`,
    { twoFactorCode: verificationCode },
    {},
    shouldReportToSentry,
  );

  return verificationCodeResponseSchema.parse(response);
};

export interface GetInvitationInfoResponse {
  data: {
    companyProfileId: string;
    companyName: string;
    inviteeEmail: string;
    inviterName: string;
    inviteeFirstName: string;
    role: CompanyInvitationRole;
  };
}

export const getInvitationInfo = (slug: string) =>
  get<GetInvitationInfoResponse>(
    `${shineApiHost}/authentication/companies/invitations/${slug}`,
    {},
    {},
    shouldReportToSentry,
  );

interface StartLoginInput {
  phone: string;
  passcode: string;
  deviceToken: string;
  model: string;
  name: string;
}
interface StartLoginResponse {
  authenticationDeviceRequest?: {
    authenticationDeviceId: string;
    authenticationDeviceRequestId: string;
  };
  tokens?: Tokens;
  skip2FA: boolean;
  firebaseToken: Tokens['firebaseToken'];
  shouldTriggerNewDeviceAuthorization: boolean;
}
export const startLogin = ({
  deviceToken,
  model,
  name,
  passcode,
  phone,
}: StartLoginInput): Promise<StartLoginResponse> =>
  post(
    `${shineApiHost}/authentication/phone/${phone}/start`,
    {
      deviceToken,
      model,
      name,
      passcode,
    },
    {},
    shouldReportToSentry,
  );

interface EndLoginInput {
  phone: string;
  passcode: string;
  code: string;
  deviceToken: string;
  model: string;
  name: string;
  slug?: string;
}
export const endLogin = ({
  code,
  deviceToken,
  model,
  name,
  passcode,
  phone,
  slug,
}: EndLoginInput): Promise<Tokens> =>
  post<Tokens>(
    `${shineApiHost}/authentication/phone/${phone}/auth`,
    {
      code,
      deviceToken,
      model,
      name,
      passcode,
      slug,
    },
    {},
    shouldReportToSentry,
  );

interface StartRegisterInput {
  phone: string;
  invitationSlug?: string;
}
export const startRegister = async ({
  phone,
}: StartRegisterInput): Promise<Tokens> => {
  return post<Tokens>(
    `${shineApiHost}/authentication/phone/${phone}/register/start`,
    {},
    {},
    shouldReportToSentry,
  );
};

interface FinishRegisterInput {
  phone: string;
  passcode: string;
  phoneNumberOwnershipToken: string;
  deviceToken: string;
  model: string;
  name: string;
  email: string;
  signupAccountType?: string;
  signupCompanyProfileType?: string;
  gender?: Gender;
  firstName?: string;
  lastName?: string;
  hasAgreedToTermsOfService: boolean;
  invitationSlug?: string;
  affiliationContext?: AffiliationContext;
}

export const finishRegister = ({
  phone,
  ...postProps
}: FinishRegisterInput): Promise<Tokens> =>
  post<{ data: Tokens }>(
    `${shineApiHost}/authentication/phone/${phone}/register/finish`,
    {
      ...postProps,
    },
    {},
    shouldReportToSentry,
  ).then(({ data }) => data);

interface ChangePasscodeInput {
  phone: string;
  passcode: string;
  code: string;
  deviceToken: string;
  model: string;
  name: string;
}

export const changePasscode = ({
  code,
  deviceToken,
  model,
  name,
  passcode,
  phone,
}: ChangePasscodeInput): Promise<Tokens> =>
  post<Tokens>(
    `${shineApiHost}/authentication/phone/${phone}/changePasscode`,
    {
      code,
      deviceToken,
      model,
      name,
      passcode,
      phone,
    },
    {},
    shouldReportToSentry,
  );

export const revoke = ({
  deviceToken,
  phone,
}: {
  phone: string;
  deviceToken: string;
}) =>
  post(
    `${shineApiHost}/authentication/phone/${phone}/revoke`,
    {
      deviceToken,
    },
    {},
    shouldReportToSentry,
  );

interface GetAllowedCountryCodesResponse {
  data: string[];
}
export const getAllowedCountryCodes = async () => {
  const { data } = await get<GetAllowedCountryCodesResponse>(
    `${shineApiHost}/authentication/phone/allowed_countries`,
    {},
    {},
    shouldReportToSentry,
  );
  return data;
};
