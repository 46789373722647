import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    hello: 'Hello {name},',
    selectYourAccount: 'Select the account you wish to access',
  },
  fr: {
    hello: 'Bonjour {name},',
    selectYourAccount: 'Sélectionnez le compte auquel vous souhaitez accéder',
  },
} as const);
