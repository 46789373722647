import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    subtitle: 'See you there!',
    title: 'To continue, please follow the email again from your computer',
  },

  fr: {
    subtitle:
      'Vous pourrez reprendre votre inscription au même endroit, depuis votre ordinateur.',
    title:
      'Pour continuer, cliquez à nouveau sur le lien de l’e-mail depuis un ordinateur',
  },
} as const);

export default locales;
