import { type SignupModuleWrapper } from '../flows/signup/types/SignupModule';
import { type SignupPage } from '../flows/signup/types/SignupPage';
import { type AbstractState } from '../types/Abstract';
import { type AnyPage } from '../types/Global';

type Components = {
  [stateValue: string]: AnyPage | Components;
};

/**
 * Given a Record where stringified states are mapped to Pages or to other similar Records,
 * this function will return the first Page that matches the provided State object.
 */
const getPage = (
  state: AbstractState,
  components: Components,
  parentState?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AnyPage | SignupPage<any> | SignupModuleWrapper<any> | null => {
  for (const [stateValue, stateComponent] of Object.entries(components)) {
    const fullStateValue = parentState
      ? `${parentState}.${stateValue}`
      : stateValue;

    if (state.matches(fullStateValue)) {
      return typeof stateComponent === 'function'
        ? stateComponent
        : getPage(state, stateComponent, fullStateValue);
    }
  }

  return null;
};

export default getPage;
