import { useQuery, type WatchQueryFetchPolicy } from '@apollo/client';
import {
  type RootCompanyListFragment,
  RootDocument,
} from 'Main/SecuredRoutes/graphql/root.gql';

import { CompanyUserStatus, type Role } from '__generated__/GQL';
import brownLogoRebranded from 'assets/images/accountSelection/brown.svg';
import greenLogoRebranded from 'assets/images/accountSelection/green.svg';
import greyLogoRebranded from 'assets/images/accountSelection/grey.svg';
import purpleLogoRebranded from 'assets/images/accountSelection/purple.svg';
import yellowLogoRebranded from 'assets/images/accountSelection/yellow.svg';

import { type UserCompanyListQuery } from './userCompanyList.gql';

type CompanyFromQuery = UserCompanyListQuery['viewer']['companies'][number];

export interface UserCompany extends CompanyFromQuery {
  companyUserId: string;
  isCompanyUserLocked?: boolean;
  roles: Role[];
  isPrimaryApplicant: boolean | null;
  legalForm: string;
  legalName: string;
  logoSrc: string;
}

const logosRebranded = [
  brownLogoRebranded,
  greenLogoRebranded,
  greyLogoRebranded,
  purpleLogoRebranded,
  yellowLogoRebranded,
];

export const computeCompanyList = (
  data: RootCompanyListFragment | undefined,
  {
    excludeLockedAccess,
  }: {
    excludeLockedAccess: boolean;
  },
) => {
  const companies = (data?.viewer.companies ?? []).reduce(
    (userCompanies: UserCompany[], company) => {
      const { companyProfileId } = company;
      const companyUser = data?.viewer.companyUsers.find(
        (user) =>
          user.uid === data.viewer.uid &&
          user.companyProfileId === companyProfileId,
      );
      if (!companyUser) {
        throw new Error(
          `A CompanyUser should exist for uid: '${data?.viewer.uid}'' & companyProfileId: '${companyProfileId}'`,
        );
      }
      const { companyUserId, isPrimaryApplicant, roles, status } = companyUser;
      const isCompanyUserLocked = status === CompanyUserStatus.Locked;

      if (excludeLockedAccess && isCompanyUserLocked) {
        return userCompanies;
      }

      return [
        ...userCompanies,
        {
          ...company,
          companyUserId,
          isPrimaryApplicant,
          legalForm: company.profile.legalForm,
          legalName: company.profile.legalName,
          logoSrc: logosRebranded[
            userCompanies.length % logosRebranded.length
          ] as string,
          roles,
          ...(!excludeLockedAccess && { isCompanyUserLocked }),
        },
      ];
    },
    [],
  );

  return {
    companies,
    isLockedFromEveryCompany:
      companies.length > 0 &&
      companies.every((company) => company.isCompanyUserLocked),
  };
};

export const useUserCompanyList = (
  {
    excludeLockedAccess,
  }: { excludeLockedAccess: boolean; fetchPolicy?: WatchQueryFetchPolicy } = {
    excludeLockedAccess: true,
  },
) => {
  // Fetching the RootDocument here means this query will always come from cache
  const { data, ...hook } = useQuery(RootDocument, {});

  return { ...computeCompanyList(data, { excludeLockedAccess }), ...hook };
};

export type UserCompanyList = ReturnType<
  typeof useUserCompanyList
>['companies'];
