import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    help: 'Help',
  },

  fr: {
    help: 'Aide',
  },
} as const);
