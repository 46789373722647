import { assign } from 'xstate';

import assertEventType from 'common/bento/lib/assertEventType';
import { type MachineActions } from 'common/bento/types/MachineConfig';

import type model from './model';

export enum Action {
  AssignAddress = 'assignAddress',
  AssignInitialData = 'assignInitialData',
}

const actions: MachineActions<typeof model, Action> = {
  assignAddress: assign({
    address: (_, event) => {
      assertEventType(event, ['SUBMIT_ADDRESS', 'done.invoke.sendAddress']);

      return event.data;
    },
  }),

  assignInitialData: assign({
    address: (_, event) => {
      assertEventType(event, 'done.invoke.loadInitialData');

      return event.data.address;
    },
  }),
};

export default actions;
