import { type TrackSegmentEventInput } from '__generated__/GQL';
import { apolloClient } from 'common/graphql/clients';
import { TrackSegmentEventDocument } from 'features/Analytics/graphql/mutations/trackSegmentEvent.gql';
import logger from 'helpers/logger';

import { type SharedAction } from '.';

const serverTrack: SharedAction<TrackSegmentEventInput> = (data) => ({
  exec: () => {
    apolloClient.mutate({
      mutation: TrackSegmentEventDocument,
      variables: {
        input: data,
      },
    });

    logger.info('ℹ️ TRACKED SERVER-SIDE EVENT: ', data);
  },
  type: 'serverTrack',
});

export default serverTrack;
