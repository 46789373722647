import { ApolloError } from '@apollo/client';
import { type ERROR_CODES } from '@shinetools/errors';
import { isNil } from 'ramda';

import { type ErrorWithCode, type ErrorWithStatusCode } from './types';

type ErrorCodesValueType = (typeof ERROR_CODES)[keyof typeof ERROR_CODES];
type GetServerErrorCodeReturn = ErrorCodesValueType | undefined;
type GetServerErrorBodyReturn<T> = T | undefined;

export const getServerErrorCode = (
  error: unknown,
): GetServerErrorCodeReturn => {
  if (!(error instanceof ApolloError)) {
    return undefined;
  }

  const { graphQLErrors } = error;

  if (isNil(graphQLErrors)) {
    return undefined;
  }

  if (graphQLErrors.length === 0) {
    return undefined;
  }

  // Casting to the known possible types of the exception error code
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const possibleCode = ((graphQLErrors[0]?.extensions?.exception as any)
    ?.code || graphQLErrors[0]?.extensions?.code) as GetServerErrorCodeReturn;

  return possibleCode;
};

export const getServerErrorBody = <T>(
  error: unknown,
): GetServerErrorBodyReturn<T> => {
  if (!(error instanceof ApolloError)) {
    return undefined;
  }

  const { graphQLErrors } = error;

  if (isNil(graphQLErrors)) {
    return undefined;
  }

  if (graphQLErrors.length === 0) {
    return undefined;
  }

  // Casting to the known possible types of the exception error code
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const possibleBody = ((graphQLErrors[0]?.extensions?.exception as any)
    ?.body || graphQLErrors[0]?.extensions?.body) as GetServerErrorCodeReturn;

  return possibleBody as T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isErrorWithCode = (error: any): error is ErrorWithCode => {
  return typeof error.code === 'string';
};

export const isErrorWithStatusCode = (
  error: unknown,
): error is ErrorWithStatusCode => {
  return (
    error !== null &&
    typeof error === 'object' &&
    'status' in error &&
    typeof error.status === 'number'
  );
};
