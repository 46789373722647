import { type FC } from 'react';
import { Image } from '@chakra-ui/react';

import flowerImg from 'assets/brand/flower@2x.png';
import TrackView from 'features/Analytics/TrackView';

import locales from './locales';
import { Container, Subtitle, Title } from './styles';

const HandleMobileInvitation: FC = () => (
  <Container>
    <TrackView pageName="SmallResScreenInvitation" />
    <Image alt="" height="180px" role="presentation" src={flowerImg} />
    <Title>{locales.title}</Title>
    <Subtitle>{locales.subtitle}</Subtitle>
  </Container>
);

export default HandleMobileInvitation;
