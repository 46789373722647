import { type FC } from 'react';
import { Flex } from '@chakra-ui/react';

import Text from 'components/_core/Text';
import { Rating } from 'features/Bento/components';

import locales from '../locales';

const RATING = 4.8;

/**
 * Display the mobile app ratings
 */
const MobileAppRatings: FC = () => (
  <>
    <Flex alignItems="center" color="grey.-1" justifyContent="center">
      <Rating fontSize="font-22" value={RATING} />

      <Text fontWeight="weight-500" marginX="space-8" marginY="space-4">
        {RATING}
      </Text>
    </Flex>

    <Text align="center" marginBottom="space-40">
      {locales.storesLabel}
    </Text>
  </>
);

export default MobileAppRatings;
