/* eslint-disable @typescript-eslint/no-explicit-any */

import { type FC, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import errorHandlerLocales from 'common/hooks/useErrorHandledMutation/locales';
import Loader from 'components/Loader';
import useToast from 'components/ToastProvider/useToast';
import { useUser } from 'helpers/auth';
import { useDevice } from 'helpers/device';
import logger from 'helpers/logger';

import { RejectAuthenticationActionRequestDocument } from '../graphql/mutations/rejectAuthenticationActionRequest.gql';
import { ActionRequestDocument } from '../graphql/queries/actionRequest.gql';
import locales from '../locales';
import { type ActionRequestType } from '../types';
import ActionRequest from './component';

interface ActionRequestContainerProps {
  actionRequest: ActionRequestType;
  setStep: (step: string) => void;
  close: () => void;
}

const ActionRequestContainer: FC<ActionRequestContainerProps> = ({
  actionRequest: { authenticationActionRequestId },
  close,
  setStep,
}) => {
  const user = useUser();
  const device = useDevice();
  const { hideToast, showToast } = useToast();
  const [rejecting, setRejecting] = useState(false);
  const { data, error, loading } = useQuery(ActionRequestDocument, {
    variables: {
      authenticationActionRequestId,
    },
  });
  const goToPasscode = useCallback(() => {
    setStep('passcode');
  }, [setStep]);
  const [rejectActionMutation] = useMutation(
    RejectAuthenticationActionRequestDocument,
  );
  const reject = useCallback(async () => {
    try {
      hideToast();
      setRejecting(true);
      await rejectActionMutation({
        variables: {
          input: {
            authenticationActionRequestId,
            deviceToken: device.token,
            phone: user.phone || '',
          },
        },
      });
      close();
    } catch (err: any) {
      setRejecting(false);
      const errorLocale = locales.errors[err.code];
      if (errorLocale) {
        showToast({ message: errorLocale, type: 'error' });
      } else {
        logger.error(err);
        showToast({
          message: errorHandlerLocales.unrecognizedErrorMessage,
          type: 'error',
        });
      }
    }
  }, [
    device,
    setRejecting,
    authenticationActionRequestId,
    close,
    user,
    showToast,
    hideToast,
    rejectActionMutation,
  ]);
  // Close modal on error
  useEffect(() => {
    if (error) {
      logger.error(error);
      close();
    }
  }, [error, close]);

  if (error) {
    return null;
  }

  if (loading || data === undefined) {
    return <Loader />;
  }

  const { actionRequest } = data;

  return (
    <ActionRequest
      actionRequest={actionRequest}
      close={close}
      goToPasscode={goToPasscode}
      reject={reject}
      rejecting={rejecting}
    />
  );
};

export default ActionRequestContainer;
