import { type FC } from 'react';
import { useQuery } from '@apollo/client';
import { Flex } from '@chakra-ui/react';

import { AuthenticationDeviceStatus } from '__generated__/GQL';
import easyImg from 'assets/brand/easy@2x.png';
import Button from 'components/_core/Button';
import SunshineModal from 'components/_core/SunshineModal';
import Text from 'components/_core/Text';
import Loader from 'components/Loader';

import DeviceList from './components/DeviceList';
import { TrustedDevicesDocument } from './hooks/queries/trustedDevices.gql';
import locales from './locales';
import { type Props } from './types';

const AcceptedStep: FC<Props> = ({ onClose, request }) => {
  const { data, loading } = useQuery(TrustedDevicesDocument, {
    variables: { status: AuthenticationDeviceStatus.Trusted },
  });

  const devices = data?.viewer.authenticationDevices || [];

  return (
    <>
      <SunshineModal.Body>
        <SunshineModal.Body.Image src={easyImg} />
        <SunshineModal.Title>{locales.title}</SunshineModal.Title>
        <Text>{locales.description}</Text>

        <Flex flexDirection="column" paddingTop="space-40">
          {loading ? (
            <Loader />
          ) : (
            <DeviceList devices={devices} request={request} />
          )}
        </Flex>
      </SunshineModal.Body>

      <SunshineModal.Footer>
        <Button icon="arrow-right" onClick={onClose} variant="primary">
          {locales.allGood}
        </Button>
      </SunshineModal.Footer>
    </>
  );
};

export default AcceptedStep;
