import { type FC } from 'react';
import { Box, HStack, Image } from '@chakra-ui/react';

import Text from 'components/_core/Text';

import notificationsImg from './assets/employee-notifications.png';
import orderCardImg from './assets/employee-orderCard.png';
import transactionsImg from './assets/employee-transactions.png';
import locales from './locales';

interface Feature {
  id: string;
  imgSrc: string;
  text: string;
}

const features: Feature[] = [
  {
    id: 'notifications',
    imgSrc: notificationsImg,
    text: locales.features.notifications,
  },
  {
    id: 'orderCard',
    imgSrc: orderCardImg,
    text: locales.features.orderCard,
  },
  {
    id: 'transactions',
    imgSrc: transactionsImg,
    text: locales.features.transactions,
  },
];

const EmployeeFeatures: FC = () => (
  <HStack alignItems="flex-start" spacing="5%">
    {features.map(({ id, imgSrc, text }) => (
      <Box key={id}>
        <Image
          alt=""
          marginBottom="space-16"
          role="presentation"
          src={imgSrc}
        />

        <Text>{text}</Text>
      </Box>
    ))}
  </HStack>
);

export default EmployeeFeatures;
