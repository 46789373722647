import { type ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';

import Modal from 'components/_core/Modal';

interface Props {
  children?: ReactNode;
}
const ModalBody = ({ children }: Props) => {
  return (
    <Modal.Body>
      <Flex flexDirection="column" justifyContent="start" minHeight="570">
        {children}
      </Flex>
    </Modal.Body>
  );
};

export default ModalBody;
