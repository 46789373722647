import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    closeButton: 'Close',
    description:
      'The payment below was not accepted in time. No amount has been debited from your account.',
    title: 'Confirmation time expired',
  },
  fr: {
    closeButton: 'Fermer',
    description:
      'Le paiement ci-dessous n’a pas été acceptée dans les temps. Aucun montant n’a été débité de votre compte.',
    title: 'Délai de confirmation expiré',
  },
} as const);

export default locales;
