import { avatarAnatomy } from '@chakra-ui/anatomy';
import {
  isDark,
  type PartsStyleFunction,
  type PartsStyleObject,
  randomColor,
  type SystemStyleFunction,
  type SystemStyleObject,
} from '@chakra-ui/theme-tools';

type Parts = typeof avatarAnatomy;

const baseStyleBadge: SystemStyleObject = {
  border: '0.2em solid',
  borderColor: 'white',
  borderRadius: 'radius-full',
  transform: 'translate(25%, 25%)',
};

const baseStyleExcessLabel: SystemStyleObject = {
  background: 'gray.200',
};

const baseStyleContainer: SystemStyleFunction = ({ name, theme }) => {
  const background = name ? randomColor({ string: name }) : 'gray.400';
  const isBgDark = isDark(background)(theme);

  return {
    background,
    borderColor: 'white',
    color: isBgDark ? 'white' : 'gray.800',
    verticalAlign: 'top',
  };
};

const baseStyle: PartsStyleFunction<Parts> = (props) => ({
  badge: baseStyleBadge,
  container: baseStyleContainer(props),
  excessLabel: baseStyleExcessLabel,
});

const getSize = (size: string): PartsStyleObject<Parts> => ({
  container: {
    fontSize: `calc(${size} / 2.5)`,
    height: size,
    width: size,
  },
  excessLabel: {
    height: size,
    width: size,
  },
  label: {
    fontSize: `calc(${size} / 2.5)`,
    lineHeight: size !== '100%' ? size : undefined,
  },
});

const sizes = {
  md: getSize('2.5rem'),
  sm: getSize('2rem'),
};

const defaultProps = {
  size: 'sm',
};

const AvatarTheme = {
  baseStyle,
  defaultProps,
  parts: avatarAnatomy.keys,
  sizes,
};

export default AvatarTheme;
