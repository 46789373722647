import { useContext } from 'react';

import { PageLayout } from 'features/Bento/components';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';
import InviteeFollowEmailLinkCard from 'features/Onboarding/legacy/components/InviteeFollowEmailLinkCard';

import { model } from '../../machine';
import { type Signup } from '../../machine/machine';
import { type SignupPage } from '../../types/SignupPage';

/**
 * A page explaining to the user how to redeem their company invitation
 */
const BeneficiaryInviteReceived: SignupPage<Signup> = ({ send }) => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
    >
      <InviteeFollowEmailLinkCard />
    </Layout>
  );
};

export default BeneficiaryInviteReceived;
