import { type FC } from 'react';
import {
  Box,
  Flex,
  HStack,
  Spacer,
  Text,
  type ThemeTypings,
  VStack,
} from '@chakra-ui/react';

import { CompanyUserStatus } from '__generated__/GQL';
import HotlineInfo from 'components/HotlineInfo';
import Logo from 'components/Logo';
import Help from 'features/Help/components/HelpButton';
import {
  type Navigation,
  NavigationStepStatus,
} from 'features/Onboarding/components/OBNavigation/NavigationContext';

import OBBackButton from '../OBBackButton';
import OBCloseButton from '../OBCloseButton';
import OBMenu from './components/OBMenu';
import useObNavigation from './hooks/useObNavigation';
import locales from './locales';
import { type OBNavigationProps } from './OBNavigation';

interface OBNavigationDesktopProps extends OBNavigationProps {
  navigation: Navigation;
}

const OBNavigationDesktop: FC<OBNavigationDesktopProps> = ({
  navigation,
  onClose,
  onPrev,
  ...props
}) => {
  const { data } = useObNavigation();

  const getStepNumberVariant = (stepStatus: NavigationStepStatus) => {
    const map: Record<
      NavigationStepStatus,
      ThemeTypings['components']['Text']['variants']
    > = {
      DONE: 'disabled',
      ONGOING: 'primary',
      TODO: 'light',
    };

    return map[stepStatus];
  };

  const getStepTitleVariant = (stepStatus: NavigationStepStatus) => {
    const map: Record<
      NavigationStepStatus,
      ThemeTypings['components']['Text']['variants']
    > = {
      DONE: 'disabled',
      ONGOING: 'secondary',
      TODO: 'disabled',
    };

    return map[stepStatus];
  };

  const { onboardingHotline } = data ?? {};

  return (
    <HStack align="flex-start" height="full" justify="space-between">
      <Flex align="start" direction="column" height="full" {...props}>
        {data ? (
          <OBMenu
            firstName={data.viewer.profile.firstName ?? null}
            isMandatee={data.viewer.metadata.isMandatee}
            isMultiAccount={
              data.viewer.companyUsers.filter(
                (cu) => cu.status !== CompanyUserStatus.Locked,
              ).length > 1
            }
            lastName={data.viewer.profile.lastName ?? null}
          />
        ) : null}

        <VStack align="stretch" spacing="space-32">
          {navigation.steps.map((step, index) => (
            <Box key={step.title}>
              <Text
                fontWeight="weight-500"
                marginBottom="space-8"
                variant={getStepNumberVariant(step.status)}
              >
                {locales.formatString(locales.step, { stepNumber: index + 1 })}
              </Text>

              <Text
                variant={getStepTitleVariant(step.status)}
                {...(step.status === NavigationStepStatus.Done && {
                  textDecoration: 'line-through',
                })}
              >
                {step.title}
              </Text>

              {step.slot}
            </Box>
          ))}
        </VStack>

        <Spacer />

        {onboardingHotline ? <HotlineInfo hotline={onboardingHotline} /> : null}

        <HStack
          alignSelf="stretch"
          justifyContent="space-between"
          spacing="space-32"
        >
          <Logo />

          <Help alignment="left" />
        </HStack>
      </Flex>

      {onClose ? (
        <Box paddingTop="0">
          <OBCloseButton onClick={onClose} />
        </Box>
      ) : null}

      {onPrev && !onClose ? (
        <Box paddingTop="0">
          <OBBackButton onClick={onPrev} />
        </Box>
      ) : null}
    </HStack>
  );
};

export default OBNavigationDesktop;
