import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    confirm: 'Confirm',
    description:
      'Your account’s security is our priority. Actions such as confirming a payment require your secret code.',
    goBack: 'Go back',
    title: 'Confirm with your secret code',
  },
  fr: {
    confirm: 'Confirmer',
    description:
      'La sécurité de votre compte est notre priorité. Les démarches importantes comme la validation d’un paiement nécessitent votre code secret.',
    goBack: 'Retour',
    title: 'Confirmer avec votre code secret',
  },
} as const);

export default locales;
