import { type FC } from 'react';
import {
  IconButton as ChakraIconButton,
  type IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';

import Icon, { type ShineIcon } from '../Icon/Icon';

const SIZES = {
  md: 'space-32',
};

type IconButtonProps = Partial<Omit<ChakraIconButtonProps, 'size'>> & {
  name: ShineIcon;
  size?: keyof typeof SIZES;
};

/**
 * @deprecated use _core/IconButton instead
 *
 * Extends Chakra UI's IconButton with useful defaults and with our ShineIcons only.
 *
 * To style the background, simply pass a `background` prop and to style the icon, pass a `color` prop.
 */
const IconButton: FC<IconButtonProps> = ({ name, size = 'md', ...props }) => (
  <ChakraIconButton
    aria-label={props['aria-label'] ?? name}
    borderRadius="radius-full"
    boxSize={SIZES[size]}
    icon={<Icon boxSize="50%" name={name} />}
    {...props}
  />
);

export default IconButton;
