/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8558%3A35124&t=Xm8z32P7kJjS0RXs-1
 */

import {
  chakra,
  forwardRef,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  type InputProps as ChakraInputProps,
  InputRightElement,
  useFormControlContext,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { type IconName } from '@shinetools/sunshine-icons';

import useForwardRef from 'common/hooks/useForwardRef';
import SunshineIcon from 'components/_core/SunshineIcon';

import { COMPONENT_NAME, StylesProvider } from './context';

interface InputProps extends ChakraInputProps {
  leftIcon?: IconName;
  rightIcon?: IconName;
}

const Input = forwardRef<InputProps, 'input'>(
  ({ leftIcon, rightIcon, ...props }, ref) => {
    const styles = useMultiStyleConfig(COMPONENT_NAME, {
      hasValue: String(props.value ?? '').length > 0,
      leftIcon: Boolean(leftIcon),
      rightIcon: Boolean(rightIcon),
    });

    const formControlContext = useFormControlContext();

    const isInvalid = props.isInvalid ?? formControlContext?.isInvalid;

    const InputWrapper = leftIcon ?? rightIcon ? InputGroup : chakra.div;

    const inputRef = useForwardRef<HTMLInputElement>(ref);

    // Disable increase/decrease on scroll for number inputs
    const onWheel =
      props.type === 'number'
        ? (e: React.WheelEvent<HTMLInputElement>) => {
            if (e.target === inputRef?.current) {
              inputRef?.current?.blur();
              e.stopPropagation();
              setTimeout(() => {
                inputRef?.current?.focus();
              }, 0);
            }
          }
        : undefined;

    return (
      <InputWrapper __css={styles.group} aria-disabled={props.isDisabled}>
        <StylesProvider value={styles}>
          {leftIcon ? (
            <InputLeftElement
              /**
               * to-do: improve pointer events
               * https://linear.app/shine/issue/SUN-25/rethink-pointerevents-for-inputelements
               */
              pointerEvents="none"
            >
              <SunshineIcon name={leftIcon} size="icon-24" />
            </InputLeftElement>
          ) : null}

          <ChakraInput
            ref={inputRef}
            {...props}
            isInvalid={isInvalid}
            onWheel={onWheel}
          />

          {rightIcon ? (
            <InputRightElement
              /**
               * to-do: improve pointer events
               * https://linear.app/shine/issue/SUN-25/rethink-pointerevents-for-inputelements
               */
              pointerEvents="none"
            >
              <SunshineIcon name={rightIcon} size="icon-24" />
            </InputRightElement>
          ) : null}
        </StylesProvider>
      </InputWrapper>
    );
  },
);

export type { InputProps };
export default Input;
