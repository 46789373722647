import { type FC } from 'react';
import { Text, type TextProps } from '@chakra-ui/react';

import parseString from './lib/parseString';

/**
 * @deprecated use _core/RichText instead
 *
 * Extends Text, but support only string children,
 * and will parse supported markdown tags inside them.
 */
const RichText: FC<TextProps & { children: string }> = ({
  children,
  ...props
}) => <Text {...props}>{parseString(children)}</Text>;

export default RichText;
