import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    label: 'Enter your 4-digit passcode again.',
    title: 'Confirm your passcode',
  },

  fr: {
    label: 'Saisissez de nouveau votre code personnel à 4 chiffres.',
    title: 'Confirmez votre code personnel',
  },
} as const);

export default locales;
