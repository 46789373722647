import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    help: 'Help',
    terms: 'Terms of use',
    website: 'About Shine',
  },
  fr: {
    help: 'Aide',
    terms: 'Conditions d’utilisation',
    website: 'Découvrir Shine',
  },
} as const);

export default locales;
