import { type FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Box } from '@chakra-ui/react';

import useResize from 'common/hooks/useResize';
import Button from 'components/_core/Button';
import { INTERCOM_LAUNCHER_PROPS } from 'features/Intercom';

import locales from './locales';
import { type ButtonProps } from './types';

const BOX_MARGIN_BOTTOM = 20;

interface HelpButtonProps {
  alignment: 'right' | 'left';
  withIntercomDetached?: boolean;
}

const HelpButton: FC<HelpButtonProps> = ({
  alignment,
  withIntercomDetached,
}) => {
  const [vertical, setVertical] = useState(0);
  const launcherRef = useRef<HTMLDivElement | null>(null);
  const intercom = useIntercom();

  useResize((): void => {
    const { current: el } = launcherRef;
    let verticalPadding = 0;

    if (!withIntercomDetached && el) {
      const { top } = el.getBoundingClientRect();
      verticalPadding = window.innerHeight - top + BOX_MARGIN_BOTTOM;
    }

    setVertical(verticalPadding);
  });

  useEffect(() => {
    intercom.update({
      alignment,
      verticalPadding: vertical,
    });
  }, [intercom, vertical, alignment]);

  const buttonProps: ButtonProps = withIntercomDetached
    ? { as: Link, to: '/help' }
    : { ...INTERCOM_LAUNCHER_PROPS };

  return (
    <Box ref={launcherRef}>
      <Button
        icon="information"
        iconPosition="left"
        size="sm"
        variant="discreet"
        {...buttonProps}
      >
        {locales.help}
      </Button>
    </Box>
  );
};

export default HelpButton;
