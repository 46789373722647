import { type FC } from 'react';

import successImg from 'assets/success.svg';
import Button from 'components/_core/Button';
import Text from 'components/_core/Text';

import locales from './locales';
import { Container, Image, Root } from './styles';
import { type ActionRequestConfirmationProps } from './types';

const getActionLocale = (action: string) => {
  if (action === 'bank:transfers:create') {
    return locales['bank:transfers:create'];
  }

  return undefined;
};

const ActionRequestConfirmation: FC<ActionRequestConfirmationProps> = ({
  actionRequest,
  close,
}) => {
  const actionLocale = getActionLocale(actionRequest.action);
  const title = actionLocale ? actionLocale.title : locales.title;

  return (
    <Root>
      <Container>
        <Image alt="Success" src={successImg} />
        <Text
          fontWeight="weight-500"
          marginBottom="space-16"
          size="lg"
          textAlign="center"
        >
          {title}
        </Text>
        <Text textAlign="center">
          {locales.formatString(locales.description, {
            clientName: actionRequest.client.displayName,
          })}
        </Text>
      </Container>
      <Button onClick={close}>{locales.dismissButton}</Button>
    </Root>
  );
};

export default ActionRequestConfirmation;
