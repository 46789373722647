import getIdentityErrors from 'features/TeamOnboarding/Onboarding/modules/AdminIdentity/libs/getIdentityErrors';
import getIdentityStatus from 'features/TeamOnboarding/Onboarding/modules/AdminIdentity/libs/getIdentityStatus';

import locales from '../../locales';
import { State } from '../../machine';
import model from '../../machine/model';
import { type GetVerificationStep, VerificationStatus } from './types';

const getIdentityStep: GetVerificationStep = ({ verificationData }) => {
  const status = getIdentityStatus({
    companyUser: verificationData.viewer.companyUser,
  });

  return {
    errors: getIdentityErrors({
      kycDocuments: verificationData.viewer.companyUser.kycDocuments,
      kycReviewError:
        verificationData.viewer.companyUser.lastKycReview?.error ?? null,
      providerReviewError:
        verificationData.viewer.companyUser.kycIdProviderError,
      stepStatus: status,
    }),
    id: State.IDENTITY,
    metadata: {
      event: model.events.GO_IDENTITY(),
      iconName: 'profile',
      isDisabled: status === VerificationStatus.Completed,
      label: locales.id,
    },
    status,
  };
};

export default getIdentityStep;
