import { type CountryCode } from '@shinetools/geo-library';

/**
 * The list of countries we don't require a residency card / VISA for.
 * @see https://www.notion.so/shinetools/KYC-Conna-tre-nos-users-Pi-ces-d-identit-JDD-Shine-start-ce18cdc2eb8e4ad8beb199e2c852dc76#0a2d12bb29e246c0ad2e5b732e1c4727
 */
export const ACCEPTED_PASSPORT_AND_ID_CARD_COUNTRIES = new Set<CountryCode>([
  // EU countries
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czechia (Czech Republic)
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  // Other accepted countries
  'CH', // Switzerland
  'NO', // Norway
  'IS', // Iceland
  'LI', // Liechtenstein
]);
