import { type FC } from 'react';

import InternalFieldRegistrationContext from './_internal/InternalFieldRegistrationContext';
import { type ComponentProps } from './_internal/types';

const FormComponent: FC<ComponentProps> = ({
  children,
  formFieldRegistrationContext,
  onSubmitHandler,
  ...otherProps
}) => {
  return (
    <form noValidate onSubmit={onSubmitHandler} {...otherProps}>
      <InternalFieldRegistrationContext.Provider
        value={formFieldRegistrationContext}
      >
        {children}
      </InternalFieldRegistrationContext.Provider>
    </form>
  );
};

export default FormComponent;
