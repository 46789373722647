import { forwardRef } from '@chakra-ui/system';

import Text, { type TextProps } from '../../../../Text';
import { useCheckbox } from '../../hooks/useCheckbox';

const Title = forwardRef<TextProps, 'p'>((props, ref) => {
  const { state } = useCheckbox();
  const { isChecked, isDisabled } = state;

  return (
    <Text
      ref={ref}
      {...props}
      {...(isDisabled && { variant: 'disabled' })}
      {...(isChecked && { fontWeight: 'weight-500' })}
    />
  );
});

export default Title;
