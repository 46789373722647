import { type ReactNode } from 'react';

import { type FCWithChildren } from 'common/types';

export type BentoLayoutProps = {
  header?: ReactNode;
  aside?: ReactNode;
  footer?: ReactNode;
  nav?: ReactNode;
  variant?: BentoLayoutVariant;
};

export type BentoLayout = FCWithChildren<BentoLayoutProps>;

export type BentoLayoutVariant = 'default' | 'larger';

export enum BentoLayoutSize {
  base = 'base',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

/**
 * Mandatory `base` layout.
 * Other layouts are optional.
 */
export type BentoLayoutVariants = {
  [BentoLayoutSize.base]: BentoLayout;
} & {
  [key in BentoLayoutSize]?: BentoLayout;
};
