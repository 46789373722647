import { chakra, forwardRef } from '@chakra-ui/system';

import Text, { type TextProps } from '../../../Text';
import { useStyles } from '../../context';

const Content = forwardRef<TextProps, 'p'>((props, ref) => {
  const styles = useStyles();

  return (
    <chakra.p __css={styles.content} as={Text} ref={ref} size="sm" {...props} />
  );
});

export default Content;
