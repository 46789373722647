import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { type SystemStyleFunction } from '@chakra-ui/theme-tools';

const helpers = createMultiStyleConfigHelpers([
  'root',
  'header',
  'headerIcon',
  'body',
  'closeButton',
  'footer',
]);

const baseStyleBody = defineStyle({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingX: 'space-24',
});

const baseStyleHeader = defineStyle({
  display: 'flex',
  flexDirection: 'column',
  padding: 'space-16',
  position: 'relative',
});

const baseStyleHeaderIcon = defineStyle({
  alignSelf: 'center',
  backgroundColor: 'white',
  borderRadius: 'radius-24',
  bottom: '-space-36',
  boxShadow:
    '0px 2px 4px rgba(69, 89, 128, 0.12), 0px 2px 8px rgba(69, 89, 128, 0.08)',
  boxSize: '72px',
  position: 'absolute',
});

const baseStyleCloseButton: SystemStyleFunction = ({ colorScheme }) => ({
  color: `${colorScheme}.800`,
  flexGrow: 0,
  flexShrink: 0,
});

const baseStyleFooter = defineStyle({
  background: 'white',
  borderTop: '1px solid',
  borderTopColor: 'border',
  display: 'flex',
  flexDirection: 'column',
  gap: 'space-24',
  paddingX: 'space-32',
  paddingY: 'space-24',
});

const baseStyleRoot = defineStyle({
  borderLeft: '1px solid',
  borderLeftColor: 'border',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
});

const baseStyle = helpers.definePartsStyle((props) => ({
  body: baseStyleBody,
  closeButton: baseStyleCloseButton(props),
  footer: baseStyleFooter,
  header: baseStyleHeader,
  headerIcon: baseStyleHeaderIcon,
  root: baseStyleRoot,
}));

const variantGhost = helpers.definePartsStyle({
  body: {
    marginTop: 'space-0',
  },
  closeButton: {
    _hover: {
      backgroundColor: 'grey.300',
    },
    backgroundColor: 'grey.200',
  },
  header: {
    backgroundColor: 'transparent',
  },
});

const variantSolid = helpers.definePartsStyle(({ colorScheme }) => ({
  body: {
    marginTop: 'space-32',
  },
  closeButton: {
    _hover: {
      backgroundColor: `${colorScheme}.500`,
    },
    backgroundColor: `${colorScheme}.400`,
  },
  header: {
    backgroundColor: `${colorScheme}.300`,
    borderBottomRadius: '50% 10%',
    marginBottom: 'space-32',
    paddingBottom: 'space-56',
  },
}));

const variants = {
  ghost: variantGhost,
  solid: variantSolid,
};

const DetailSidebar = helpers.defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'grey',
    variant: 'solid',
  },
  variants,
});

export default DetailSidebar;
