import { Fragment, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useBreakpointValue } from '@chakra-ui/react';

import { type FCWithChildren } from 'common/types';
import Button from 'components/_core/Button';
import IconButton from 'components/_core/IconButton';
import Loader from 'features/Onboarding/legacy/components/Loader';

import locales from './locales';
import {
  LoadingWrapper,
  OnboardingBackButtonWrapper,
  OnboardingPageScroll,
  OnboardingPageSection,
  OnboardingPageWidthConstraint,
  OnboardingPageWrapper,
} from './styles';
import { type PageComponentProps } from './types';

const Page: FCWithChildren<PageComponentProps> = ({
  backButtonIcon = 'arrow-left',
  backButtonText = locales.back,
  children,
  loading,
  loadingMessage,
  withScroll = true,
  withWidthConstraint = true,
  ...otherProps
}) => {
  const isMediumScreen = useBreakpointValue({ base: false, md: true }, 'md');
  const isLargeScreen = useBreakpointValue({ base: false, lg: true }, 'lg');

  const history = useHistory();
  const onBackPress = useCallback(() => {
    if (
      'backButtonAction' in otherProps &&
      otherProps.backButtonAction !== undefined
    ) {
      otherProps.backButtonAction();
    } else if ('backButtonNavigatesBack' in otherProps) {
      history.goBack();
    }
  }, [otherProps, history]);

  /**
   * On mobiles, we don't want an internal scroll as we have only one main container anyway.
   */
  const ScrollIfEnabled =
    withScroll && isMediumScreen ? OnboardingPageScroll : Fragment;

  const WidthConstraintIfEnabled = withWidthConstraint
    ? OnboardingPageWidthConstraint
    : Fragment;
  const content = loading ? (
    <LoadingWrapper data-testid="onboarding-page-loading">
      <Loader message={loadingMessage} />
    </LoadingWrapper>
  ) : (
    <WidthConstraintIfEnabled>{children}</WidthConstraintIfEnabled>
  );

  return (
    <Fragment>
      {isMediumScreen ? (
        <OnboardingBackButtonWrapper
          buttonDisabled={'withNoBackButton' in otherProps}
        >
          {isLargeScreen ? (
            <Button
              icon={backButtonIcon}
              iconPosition="left"
              onClick={onBackPress}
              size="sm"
              variant="tertiary"
            >
              {backButtonText}
            </Button>
          ) : (
            <IconButton
              aria-label={backButtonText}
              icon={backButtonIcon}
              onClick={onBackPress}
              variant="inline-secondary"
            />
          )}
        </OnboardingBackButtonWrapper>
      ) : null}
      <OnboardingPageSection data-testid={otherProps['data-testid']}>
        <OnboardingPageWrapper>
          <ScrollIfEnabled>{content}</ScrollIfEnabled>
        </OnboardingPageWrapper>
        {/* The <Plants/> component breaks the new Bento layout as it displays the plants twice */}
        {/* The whole <Page/> is going to be removed totally soon */}
        {/* <Plants /> */}
      </OnboardingPageSection>
    </Fragment>
  );
};

export default Page;
