/* eslint-disable react/display-name */

import useOnHistoryPop from 'common/bento/lib/useOnHistoryPop';
import {
  type BentoPage,
  type BentoPageInput,
  type BentoPageProps,
} from 'common/bento/types/BentoPage';
import useScrollToTopOnMount from 'common/hooks/useScrollToTopOnMount';
import { useTrackLoadingState } from 'common/sentry/webAppOpeningTransaction';

import { useLayoutContext } from '../../lib/layout';

const asBentoPage = <TProps,>(
  Component: BentoPageInput<TProps>,
  options: {
    isLoadingState?: boolean;
  } = {},
): BentoPage<TProps> => {
  return (props: BentoPageProps<TProps>) => {
    /**
     * If we're rendering a page that's NOT one of the loader pages,
     * we can assume that the onboarding finished loading.
     *
     * Note: it's an imperfect assumption for multiple reasons, but the best we found at time of writing.
     */
    const isOnboardingLoaded =
      !options.isLoadingState &&
      window.location.pathname.startsWith('/onboarding/');

    useTrackLoadingState({
      destination: 'onboarding',
      isLoaded: isOnboardingLoaded,
    });

    useOnHistoryPop(props.onPrev);
    useScrollToTopOnMount();

    /**
     * Get the Layout provided by the BentoContainer.
     */
    const { Layout } = useLayoutContext();

    return <Component Layout={Layout} {...props} />;
  };
};

export default asBentoPage;
