import { type FC } from 'react';

import bookImg from 'assets/brand/book@2x.png';

import Container from '../../../../layouts/FeaturesLayout/components/Container';
import Description from '../../../../layouts/FeaturesLayout/components/Description';
import Illustration from '../../../../layouts/FeaturesLayout/components/Illustration';
import Title from '../../../../layouts/FeaturesLayout/components/Title';
import locales from './locales';

interface BankStatementsProps {
  companyName: string;
}

const BankStatements: FC<BankStatementsProps> = ({ companyName }) => (
  <Container>
    <Illustration src={bookImg} />
    <Title>{locales.formatString(locales.title, { companyName })}</Title>
    <Description>{locales.description}</Description>
  </Container>
);

export default BankStatements;
