import { type FC, useEffect, useRef } from 'react';
import { type SdkHandle, type SdkResponse } from 'onfido-sdk-ui';

import useCurrentLanguage from 'common/hooks/useCurrentLanguage';
import { logEvent } from 'features/Analytics/analytics';

import mapOnfidoDocumentToKycDocumentFromProviderInput from './mapOnfidoDocumentToKycDocumentFromProviderInput';
import {
  type OnfidoDocumentVerifierProps,
  type OnfidoUserAnalyticsEvent,
} from './types';

const OnfidoDocumentVerifier: FC<OnfidoDocumentVerifierProps> = ({
  containerId,
  customUI,
  onComplete,
  onfidoInit,
  steps,
  token,
  userSmsNumber,
}) => {
  const currentLanguage = useCurrentLanguage();
  const onfidoLibRef = useRef<SdkHandle>();
  // Initialising the Onfido library is delayed until the required token is available
  // but the library still needs tearing down on unmount
  useEffect(
    () => (): void => {
      if (onfidoLibRef.current !== undefined) {
        onfidoLibRef.current.tearDown();
        onfidoLibRef.current = undefined;
      }
    },
    [],
  );

  // Otherwise, run an effect whenever the other values change to (re)initialise
  // the Onfido library.
  useEffect((): void => {
    if (token !== undefined && onfidoLibRef.current === undefined) {
      const onCompleteOnfido = (data: SdkResponse): void => {
        const documents = [];
        if (data.document_front !== undefined) {
          documents.push(
            mapOnfidoDocumentToKycDocumentFromProviderInput(
              data.document_front,
            ),
          );
        }
        if (data.document_back !== undefined) {
          documents.push(
            mapOnfidoDocumentToKycDocumentFromProviderInput(data.document_back),
          );
        }
        onComplete(documents);
      };

      onfidoLibRef.current = onfidoInit({
        containerId,
        customUI,
        language: currentLanguage === 'en' ? 'en_US' : 'fr_FR',
        onComplete: onCompleteOnfido,
        steps,
        token,
        /** @info remove when Onfido fixes the issue, see https://github.com/onfido/onfido-sdk-ui#sdk-navigation-issues */
        useMemoryHistory: true,

        userDetails: {
          smsNumber: userSmsNumber,
        },
      });
    }
    // TODO(matt): Consider calling setOptions to update certain properties
    // if/when they change. This shouldn't happen in normal operation though.
    // Similarly, currentLanguage should not change under normal operation.
  }, [
    userSmsNumber,
    token,
    onComplete,
    onfidoInit,
    currentLanguage,
    containerId,
    steps,
    customUI,
  ]);

  useEffect(() => {
    const onOnfidoEvent = (event: OnfidoUserAnalyticsEvent) => {
      const onfidoEventName = event.detail?.eventName;

      if (onfidoEventName) {
        // Turns Onfido event name to Pascal Case
        // ie: "VIDEO_FACIAL_INTRO" -> "Video_Facial_Intro"
        const onfidoEventNameToPascalCase = onfidoEventName.replace(
          /([a-zA-Z])([a-zA-Z]*)/g,
          (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase(),
        );

        logEvent({
          name: `Onfido_Sdk_${onfidoEventNameToPascalCase}`,
          properties: { isOnMobileDevice: event.detail.isCrossDevice },
        });
      }
    };

    window.addEventListener(
      'userAnalyticsEvent',
      onOnfidoEvent as EventListener,
    );

    return () => {
      window.removeEventListener(
        'userAnalyticsEvent',
        onOnfidoEvent as EventListener,
      );
    };
  }, []);

  return <div id={containerId} />;
};

export default OnfidoDocumentVerifier;
