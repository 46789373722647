import { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { type OutstandingAgreement } from '__generated__/GQL';
import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import OBDocumentView from 'features/Bento/components/OBDocumentView';

import locales from './locales';

interface AgreementPageProps {
  agreement: Pick<OutstandingAgreement, 'pdfDocumentUrl' | 'type'>;
  isLoading: boolean;
  onNext: () => void;
}

/**
 * A page where the user can see the outstanding agreement and accept it.
 */
const AgreementPage = asBentoPage<AgreementPageProps>(
  ({ agreement, isLoading, onNext, onPrev }) => {
    const document = agreement.pdfDocumentUrl;

    if (!document) {
      throw new Error('agreement missing');
    }

    const [hasAgreed, setHasAgreed] = useState(false);

    return (
      <OBDocumentView
        data-testid="user-agreement"
        onClose={onPrev}
        src={document}
      >
        <Flex align="stretch" direction="column">
          <Heading marginBottom="space-16">
            {locales.title[agreement.type]}
          </Heading>

          <Text marginBottom="space-16">
            {locales.description[agreement.type]}
          </Text>

          <SunshineCard
            data-testid="check-card"
            isChecked={hasAgreed}
            onChange={() => setHasAgreed(!hasAgreed)}
            title={locales.checkbox[agreement.type]}
            variant="checkbox"
          />

          <Button
            alignSelf="flex-end"
            isDisabled={!hasAgreed}
            isLoading={isLoading}
            marginTop="space-64"
            onClick={onNext}
          >
            {locales.cta}
          </Button>
        </Flex>
      </OBDocumentView>
    );
  },
);

export default AgreementPage;
