import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useState,
} from 'react';

import webView from 'common/webView';

import {
  type CompanyContextState,
  companyContextStorage,
} from './companyContextStorage';
import { type CompanyContextType } from './types';

export const CompanyContext = createContext<CompanyContextType>({
  clearCompanyContext: () => undefined,
  companyProfileId: undefined,
  companyUserId: undefined,
  setCompanyContext: () => undefined,
});

export const CompanyContextProvider: FC<PropsWithChildren> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [companyContext, _setCompanyContext] = useState(
    companyContextStorage.retrieve(),
  );

  const setCompanyContext = (newCompanyContext: CompanyContextState) => {
    _setCompanyContext(newCompanyContext);
    companyContextStorage.persist(newCompanyContext);
  };

  const clearCompanyContext = () => {
    setCompanyContext({
      companyProfileId: undefined,
      companyUserId: undefined,
    });

    /**
     * If in the onboarding webView,
     * we inform the app that the `companyContext` has to be cleared (triggering the native account switcher)
     */
    webView.postMessage({ action: 'ACCOUNT_SWITCH_REQUESTED' });
  };

  return (
    <CompanyContext.Provider
      value={{ clearCompanyContext, setCompanyContext, ...companyContext }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

const useCompanyContext = () => useContext(CompanyContext);

export default useCompanyContext;
