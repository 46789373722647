import { anatomy, type PartsStyleObject } from '@chakra-ui/theme-tools';

const parts = anatomy('Timeline').parts('step', 'connector', 'content');

export type Parts = typeof parts;

const CIRCLE_SIZE = 16;

const baseStyle: PartsStyleObject = {
  connector: {
    _after: {
      backgroundColor: 'grey.400',
      bottom: 0,
      content: `""`,
      left: CIRCLE_SIZE / 2 - 1,
      position: 'absolute',
      top: CIRCLE_SIZE,
      width: '2px',
    },
    border: '2px solid',
    borderColor: 'grey.400',
    boxSize: CIRCLE_SIZE,
    flexGrow: 0,
    flexShrink: 0,
  },
  content: {
    marginTop: '-2px', // visually align with the circle
  },
  step: {
    alignItems: 'flex-start',
    display: 'flex',
    gap: 'space-8',
    position: 'relative',
  },
};

const TimelineTheme = {
  baseStyle,
  defaultProps: {
    colorScheme: 'blue',
  },
  parts: parts.keys,
};

export default TimelineTheme;
