import { createContext, useContext, useState } from 'react';

import { type FCWithChildren } from 'common/types';

import FEATURES from './features';
import { type FeatureName, type HookReturnType } from './types';

const getKey = (featureName: FeatureName) =>
  `@hasSeenNewFeature:${featureName}`;

const HasSeenNewFeatureContext = createContext<
  Map<FeatureName, HookReturnType> | undefined
>(undefined);

const useHasSeenNewFeatureConstructor: (
  featureName: FeatureName,
) => [FeatureName, HookReturnType] = (featureName) => {
  const key = getKey(featureName);
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    return item === null ? false : item === 'true';
  });

  const setValue = (value: boolean) => {
    setStoredValue(value);
    window.localStorage.setItem(key, JSON.stringify(value));
  };
  return [featureName, [storedValue, setValue]];
};

const HasSeenNewFeatureContextProvider: FCWithChildren = ({ children }) => {
  const value = new Map(FEATURES.map(useHasSeenNewFeatureConstructor));

  return (
    <HasSeenNewFeatureContext.Provider value={value}>
      {children}
    </HasSeenNewFeatureContext.Provider>
  );
};

/**
 * A hook allowing to set and check if a given feature has been seen.
 *
 * The value is persisted in localStorage.
 * The hook must be used within a HasSeenNewFeatureContextProvider.
 * @param featureName The name of the feature, from the list defined in src/common/useHasSeenNewFeature/features.ts
 * @returns A tuple containing the value and a function to set it.
 */
const useHasSeenNewFeature = (featureName: FeatureName) => {
  const context = useContext(HasSeenNewFeatureContext);

  if (!context) {
    throw new Error(
      `useHasSeenNewFeature must be used within a HasSeenNewFeatureContextProvider`,
    );
  }

  const hook = context.get(featureName);
  if (!hook) {
    throw new Error(
      `The corresponding hook for feature ${featureName} is not defined. Please add it to the FEATURES array.`,
    );
  }

  return hook;
};

export { HasSeenNewFeatureContextProvider, useHasSeenNewFeature };
