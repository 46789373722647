import { Children, type FC, useState } from 'react';
import { type BoxProps } from '@chakra-ui/react';

import Carousel from 'components/Carousel';
import OnboardingContainer from 'features/TeamOnboarding/components/OnboardingContainer';

import locales from './locales';

interface CarouselFeaturesLayoutProps extends BoxProps {
  carouselHeight?: string;
  getCtaText?: (params: {
    carouselIndex: number;
    isLast: boolean;
  }) => string | null;
  onDone: () => void;
}

const CarouselFeaturesLayout: FC<CarouselFeaturesLayoutProps> = ({
  carouselHeight = 'auto',
  children,
  getCtaText,
  onDone,
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const isLast = carouselIndex === Children.count(children) - 1;

  const onNext = () => setCarouselIndex((index) => index + 1);

  return (
    <OnboardingContainer
      ctaText={getCtaText?.({ carouselIndex, isLast }) || locales.next}
      onClick={isLast ? onDone : onNext}
    >
      <Carousel
        height={carouselHeight}
        onChange={(index) => setCarouselIndex(index)}
        value={carouselIndex}
      >
        {children}
      </Carousel>
    </OnboardingContainer>
  );
};

export default CarouselFeaturesLayout;
