import { VStack } from '@chakra-ui/react';
import { frenchDepartments } from '@shinetools/geo-library';
import { format, parse } from 'date-fns';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import useCountriesList from 'common/countries/useCountriesList';
import Button from 'components/_core/Button';
import Text from 'components/_core/Text';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import Header from '../../../../components/Header';
import { GENDER_OPTIONS } from '../../libs/constants';
import { type IdentitySchema } from '../../libs/identitySchema';
import FieldRecap from './components/FieldRecap';
import SectionHeader from './components/SectionHeader';
import locales from './locales';

interface RecapProps {
  identityValues: IdentitySchema;
  onNext: () => void;
}

/**
 * A page that shows a recap of the user’s identity information
 */
const Recap = asBentoPage<RecapProps>(
  ({ identityValues, Layout, onNext, onPrev }) => {
    const {
      birthCity,
      birthCountry,
      birthdate,
      birthDepartment,
      firstName,
      gender,
      lastName,
      nationality,
    } = identityValues;
    const countriesList = useCountriesList();

    const getFullNameWithGender = (): string => {
      if (!firstName && !lastName) {
        return '';
      }

      const genderLabel = GENDER_OPTIONS.find(
        (option) => option.value === gender,
      )?.label;

      return genderLabel
        ? `${genderLabel} ${firstName} ${lastName}`
        : `${firstName} ${lastName}`;
    };

    const getBirthdateString = (): string => {
      if (!birthdate) {
        return '';
      }

      const date = parse(birthdate, 'yyyy-MM-dd', new Date());

      return format(date, 'dd/MM/yyyy');
    };

    const getBirthPlace = (): string => {
      const birthCountryLabel = countriesList.find(
        (country) => country.value === birthCountry,
      )?.label;

      if (!birthCountryLabel) {
        return '';
      }

      const frenchDepartmentLabel = frenchDepartments[
        birthDepartment as keyof typeof frenchDepartments
      ] as string | undefined;

      return frenchDepartmentLabel
        ? `${birthCountryLabel} - ${frenchDepartmentLabel} (${birthDepartment})`
        : `${birthCountryLabel}`;
    };

    const fullNameWithGender = getFullNameWithGender();
    const birthdateString = getBirthdateString();
    const birthPlace = getBirthPlace();
    const nationalityString = countriesList.find(
      (country) => country.value === nationality,
    )?.label;

    return (
      <Layout data-testid="compliance" nav={<Navigation onPrev={onPrev} />}>
        <Header description={locales.description} title={locales.title} />

        <VStack alignItems="start" marginBottom="space-32" spacing="space-8">
          <SectionHeader title={locales.sections.personnalInformation} />

          <Text>{fullNameWithGender}</Text>
        </VStack>

        <VStack alignItems="start" marginBottom="space-24" spacing="space-8">
          <SectionHeader title={locales.sections.additionalInformation} />

          {birthdateString ? (
            <FieldRecap
              label={locales.labels.birthdate}
              value={birthdateString}
            />
          ) : null}
          {birthCity ? (
            <FieldRecap label={locales.labels.birthCity} value={birthCity} />
          ) : null}
          {birthPlace ? (
            <FieldRecap
              label={locales.labels.birthCountry}
              value={birthPlace}
            />
          ) : null}
          {nationalityString ? (
            <FieldRecap
              label={locales.labels.nationality}
              value={nationalityString}
            />
          ) : null}
        </VStack>

        <Button isExpanded onClick={onNext}>
          {locales.cta}
        </Button>
      </Layout>
    );
  },
);

export default Recap;
