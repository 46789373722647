import { type FC } from 'react';

import Button from 'components/_core/Button';
import Text from 'components/_core/Text';
import Column from 'components/Column';
import Row from 'components/Row';

import locales from './locales';
import { type ResetPasscodeProps } from './types';

const ResetPasscode: FC<ResetPasscodeProps> = ({ onBack, onConfirm }) => (
  <Column alignItems="flex-start">
    <Text
      fontWeight="weight-500"
      marginBottom="space-16"
      size="lg"
      variant="primary"
    >
      {locales.title}
    </Text>

    <Text marginBottom="space-32">{locales.description}</Text>

    <Row alignItems="center" gap="abs32">
      <Button onClick={onConfirm}>{locales.confirmAction}</Button>
      <Button onClick={onBack} variant="inline-primary">
        {locales.cancel}
      </Button>
    </Row>
  </Column>
);

export default ResetPasscode;
