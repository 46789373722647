import { type FC } from 'react';

import SunshineCard from 'components/_core/SunshineCard';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import NewBadge from 'components/NewBadge';
import { getDeviceConnectionDate, getIconName } from 'helpers/auth/devices';
import { formatDate, P } from 'helpers/date';

import { type Props } from './types';

const Device: FC<Props> = ({ device, isNew }) => {
  const { model, name } = device;

  const connectionDate = getDeviceConnectionDate(device);
  const formattedDate = connectionDate
    ? formatDate(connectionDate, P)
    : undefined;

  const iconName = getIconName(device);

  return (
    <SunshineCard>
      <SunshineCard.Slot name="aside">
        <SquircleIcon name={iconName} />
      </SunshineCard.Slot>

      <SunshineCard.Content>
        <SunshineCard.Title>{name}</SunshineCard.Title>
        <SunshineCard.Text>
          {model}
          {formattedDate ? ` • ${formattedDate}` : null}
        </SunshineCard.Text>
      </SunshineCard.Content>

      {isNew ? (
        <SunshineCard.Slot name="action">
          <NewBadge />
        </SunshineCard.Slot>
      ) : null}
    </SunshineCard>
  );
};

export default Device;
