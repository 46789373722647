import { useSubscription } from '@apollo/client';

import {
  CompanyUserDocument,
  type CompanyUserSubscription,
  type CompanyUserSubscriptionVariables,
} from './companyUserSubscription.gql';

const useCompanyUserSubscription = (
  variables: CompanyUserSubscriptionVariables,
) =>
  useSubscription<CompanyUserSubscription, CompanyUserSubscriptionVariables>(
    CompanyUserDocument,
    { variables },
  );

export default useCompanyUserSubscription;
