/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8558%3A35124&t=GNHsXVlDt2Zpruph-1
 */

import { type SystemStyleObject } from '@chakra-ui/theme-tools';

const invalidStyles = {
  color: 'error',
};

const baseStyle: SystemStyleObject = {
  _groupFocusWithin: {
    _invalid: { ...invalidStyles },
    color: 'text-primary',
  },
  _invalid: {
    ...invalidStyles,
  },
  color: 'text-label',
  fontSize: 'font-13',
  strong: { fontWeight: 'weight-500' },
};

const FormLabelTheme = {
  baseStyle,
};

export default FormLabelTheme;
