import { type ActionObject } from 'xstate';

import {
  type AnyContext,
  type AnyMachineEvent,
} from 'common/bento/types/Abstract';

import alias from './alias';
import error from './error';
import identify from './identify';
import serverTrack from './serverTrack';
import toast from './toast';
import track from './track';
import trackView from './trackView';
import unhandled from './unhandled';

/**
 * This is the type of a function that accepts an argument (or none, if you don't specify one in the generic),
 * and returns an action object to be executed by XState.
 */
export type SharedAction<ActionArg = undefined> = (
  ...args: ActionArg extends undefined ? never[] : [ActionArg]
) => ActionObject<AnyContext, AnyMachineEvent>;

const actions = {
  alias,
  error,
  identify,
  serverTrack,
  toast,
  track,
  trackView,
  unhandled,
};

export default actions;
