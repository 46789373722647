import { type FC } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import Button from 'components/_core/Button';

import locales from './locales';

interface CloseButtonProps {
  to?: string;
}

const CloseButton: FC<CloseButtonProps> = ({ to }) => {
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  const redirectProps = {
    ...(to ? { to } : { onClick: onBack }),
  };

  return (
    <Button
      as={to ? Link : 'button'}
      icon="cross"
      iconPosition="left"
      variant="secondary"
      {...redirectProps}
    >
      {locales.back}
    </Button>
  );
};

export default CloseButton;
