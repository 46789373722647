import { type FC } from 'react';
import { Text, type TextProps } from '@chakra-ui/react';

type Props = TextProps;

/**
 * An InputErrorMessage component accepting all Chakra TextProps and a children prop
 */
const InputErrorMessage: FC<Props> = ({ children, ...props }) => (
  <Text
    color="red.-1"
    marginBottom="space-8"
    marginTop="space-4"
    minHeight="19px"
    size="xs"
    {...props}
  >
    {children}
  </Text>
);

export default InputErrorMessage;
