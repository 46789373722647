import { type FC } from 'react';
import { isAfter } from 'date-fns';

import logo from 'assets/shine-icon.svg';
import { formatAmount } from 'common/formatAmount';
import Button from 'components/_core/Button';
import Icon from 'components/_core/Icon';
import Text from 'components/_core/Text';
import { formatRelative } from 'helpers/date';

import { type ActionRequestStatus } from '../types';
import locales from './locales';
import {
  Bottom,
  CloseButton,
  Container,
  Expiry,
  ImageContainer,
  ImageGroup,
  RightImageContainer,
  RoundImage,
  Top,
} from './styles';
import { type ActionRequestProps } from './types';

const getIconName = (status: ActionRequestStatus) => {
  if (status === 'CONFIRMED') {
    return 'check';
  }
  if (status === 'REJECTED') {
    return 'close';
  }
  return 'timer';
};

const getInformation = ({
  expiresAt,
  hasExpired,
  status,
}: {
  expiresAt: string;
  hasExpired: boolean;
  status: ActionRequestStatus;
}) => {
  if (status === 'CONFIRMED') {
    return locales.confirmed;
  }
  if (status === 'REJECTED') {
    return locales.rejected;
  }
  if (hasExpired) {
    return locales.expired;
  }
  return locales.formatString(locales.expiry, {
    expiry: formatRelative(new Date(expiresAt), new Date()),
  });
};

const getActionLocale = (action: string) => {
  if (action === 'bank:transfers:create') {
    return locales['bank:transfers:create'];
  }

  return undefined;
};

const ActionRequest: FC<ActionRequestProps> = ({
  actionRequest: {
    action,
    client: { displayName, logoURL },
    expiresAt,
    payload,
    recipient,
    status,
  },
  close,
  goToPasscode,
  reject,
  rejecting,
}) => {
  const recipientName = recipient?.name || '';
  const actionLocale = getActionLocale(action);
  const title = actionLocale ? actionLocale.title : locales.updateTitle;
  const description = actionLocale
    ? locales.description
    : locales.updateDescription;
  const { amount = 0 } = JSON.parse(payload);
  const hasExpired = isAfter(new Date(), new Date(expiresAt));
  return (
    <Container>
      {hasExpired || status !== 'PENDING' ? (
        <CloseButton onClick={close}>{locales.close}</CloseButton>
      ) : null}
      <Top>
        <ImageGroup>
          <ImageContainer>
            <RoundImage src={logo} />
          </ImageContainer>
          <RightImageContainer>
            <RoundImage src={logoURL} />
          </RightImageContainer>
        </ImageGroup>
        <Text fontWeight="weight-500" size="lg" textAlign="center">
          {locales.formatString(title, {
            amount: formatAmount({ forcePlusSign: false, value: amount }),
            displayName,
            recipientName,
          })}
        </Text>
        <Text textAlign="center">{description}</Text>
      </Top>
      {actionLocale ? (
        <Bottom>
          <Expiry>
            <Icon
              marginRight="space-4"
              marginTop="space-2"
              name={getIconName(status)}
            />
            <Text textAlign="center">
              {getInformation({ expiresAt, hasExpired, status })}
            </Text>
          </Expiry>
          <Button
            isDisabled={status !== 'PENDING' || hasExpired}
            marginBottom="space-16"
            onClick={goToPasscode}
          >
            {locales.confirm}
          </Button>
          <Button
            isDisabled={status !== 'PENDING' || hasExpired}
            isLoading={rejecting}
            onClick={reject}
            variant="secondary"
          >
            {locales.reject}
          </Button>
        </Bottom>
      ) : null}
    </Container>
  );
};

export default ActionRequest;
