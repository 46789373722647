import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { Center, Flex, Text } from '@chakra-ui/react';

import fireworksImg from 'assets/brand/fireworks@2x.png';
import hornImg from 'assets/brand/horn@2x.png';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SuspenseQuery from 'components/SuspenseQuery';
import { OBCard } from 'features/Bento/components';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import Loader from '../../../../../../components/Loader';
import SignupSidebar from '../../components/Sidebar/Sidebar';
import { SignupGetUserNameDocument } from '../../graphql/queries/get-user-name.gql';
import { ApplicationStartedDocument } from '../../graphql/subscriptions/application-started.gql';
import { model, State } from '../../machine';
import { type Signup } from '../../machine/machine';
import { CompanyCreationLegalForm } from '../../machine/model';
import { type SignupPage } from '../../types/SignupPage';
import locales from './locales';

/**
 * A page welcoming the user and inviting him to continue the onboarding
 */
const Welcome: SignupPage<Signup> = ({ send, state }) => {
  const Layout = useContext(OBLayoutContext);
  const query = useQuery(SignupGetUserNameDocument);
  const { loading: isWaitingForEvent } = useSubscription(
    ApplicationStartedDocument,
  );

  /**
   * We’re waiting for `application.started` event to continue.
   * So we’re displaying a loader until we receive it.
   */
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [loading]);

  const { body, illus } = useMemo(() => {
    if (state.matches(State.CAP_DEP)) {
      return {
        body: locales.body.capitalDeposit,
        illus: hornImg,
      };
    }

    if (state.matches(State.COMPANY_CREATION)) {
      if (state.context.companyLegalForm === CompanyCreationLegalForm.SASU) {
        return {
          body: locales.body.inHouseSasuCreation,
          illus: fireworksImg,
        };
      }

      return {
        body: locales.body.shineStart,
        illus: hornImg,
      };
    }

    return {
      body: locales.body.standard,
      illus: hornImg,
    };
  }, [state]);

  if (loading && isWaitingForEvent) {
    return (
      <Center height="100%">
        <Loader />
      </Center>
    );
  }

  return (
    <Layout
      data-testid="welcome"
      sidebar={
        <Flex
          align="start"
          background="white"
          flexDirection="column"
          height="100vh"
          justify="space-between"
          padding="space-40"
        >
          <SignupSidebar hideSteps state={state} />
        </Flex>
      }
    >
      <SuspenseQuery query={query}>
        {({ viewer }) => {
          const { firstName, lastName } = viewer.profile;

          if (!firstName || !lastName) {
            throw Error('firstName and lastName should be defined');
          }

          return (
            <OBCard>
              <OBCard.Illustration src={illus} />

              <OBCard.Body textAlign="center">
                <Heading marginBottom="space-16" size="lg">
                  {locales.formatString(locales.title, {
                    firstName,
                    lastName,
                  })}
                </Heading>

                <Text marginBottom="space-32">{body}</Text>

                <Button isExpanded onClick={() => send(model.events.NEXT())}>
                  {locales.cta}
                </Button>
              </OBCard.Body>
            </OBCard>
          );
        }}
      </SuspenseQuery>
    </Layout>
  );
};

export default Welcome;
