import { type ComponentWithAs, Stack, type StackProps } from '@chakra-ui/react';

const Content: ComponentWithAs<'div', StackProps> = (props) => (
  <Stack
    alignItems="flex-start"
    paddingTop="space-2"
    spacing="space-2"
    {...props}
  />
);

export default Content;
