import { type FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';

import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import { PageLayout } from 'features/Bento/components';
import { type SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import GuidanceHome from '../../components/Guidance/GuidanceHome';
import { model } from '../../machine';
import { type Signup } from '../../machine/machine';
import locales from './locales';

/**
 * Action bar for home
 */
const HomeActions: FC = () => (
  <PageLayout.Actions>
    <Box marginX="auto">
      <Text as="span" variant="primary">
        {locales.login.label}{' '}
      </Text>

      <Button as={Link} to="/sign-in" variant="inline-primary">
        {locales.login.link}
      </Button>
    </Box>
  </PageLayout.Actions>
);

/**
 * The hub of the signup flow.
 */
const Home: SignupPage<Signup> = ({ send }) => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout
      actions={<HomeActions />}
      data-testid="home"
      guidance={<GuidanceHome />}
    >
      <Heading marginBottom="space-32" size="lg">
        {locales.page.title}
      </Heading>

      <SunshineCard.Group isBoxed={false} marginBottom="space-40">
        <SunshineCard
          onClick={() => send(model.events.GO_STANDARD())}
          title={locales.standard.label}
        >
          <SunshineCard.Slot name="aside">
            <SquircleIcon name="company" />
          </SunshineCard.Slot>

          <SunshineCard.Content>
            <SunshineCard.Text>
              {locales.standard.description}
            </SunshineCard.Text>
          </SunshineCard.Content>

          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>

        <SunshineCard
          onClick={() => send(model.events.GO_CAP_DEP())}
          title={locales.capDep.label}
        >
          <SunshineCard.Slot name="aside">
            <SquircleIcon name="file" />
          </SunshineCard.Slot>

          <SunshineCard.Content>
            <SunshineCard.Text>{locales.capDep.description}</SunshineCard.Text>
          </SunshineCard.Content>

          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>

        <SunshineCard
          onClick={() => send(model.events.GO_COMPANY_CREATION())}
          title={locales.companyCreation.label}
        >
          <SunshineCard.Slot name="aside">
            <SquircleIcon name="flash" />
          </SunshineCard.Slot>

          <SunshineCard.Content>
            <SunshineCard.Text>
              {locales.companyCreation.description}
            </SunshineCard.Text>
          </SunshineCard.Content>

          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>
      </SunshineCard.Group>

      <Text marginBottom="space-8" size="sm">
        {locales.inviteeQuestion}
      </Text>

      <SunshineCard
        isBoxed={false}
        onClick={() => send(model.events.GO_BENEFICIARY())}
        paddingY="space-16"
        title={locales.beneficiary.label}
      >
        <SunshineCard.Slot name="aside">
          <SquircleIcon name="mail" />
        </SunshineCard.Slot>

        <SunshineCard.Content>
          <SunshineCard.Text>
            {locales.beneficiary.description}
          </SunshineCard.Text>
        </SunshineCard.Content>

        <SunshineCard.Slot name="action">
          <SunshineCard.SunshineIcon name="chevron-right" />
        </SunshineCard.Slot>
      </SunshineCard>
    </Layout>
  );
};

export default Home;
