import {
  chakra,
  type ComponentWithAs,
  type ThemeTypings,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import { type IconName } from '@shinetools/sunshine-icons';

import IconButton from '../IconButton';
import SquircleIcon from '../SunshineSquircleIcon';
import Text from '../Text';

type Variant = ThemeTypings['components']['SunshineToast']['variants'];

const iconNameMap: Record<Variant, IconName> = {
  error: 'cross',
  success: 'tick',
};

export interface SunshineToastProps {
  onClose: () => void;
  variant?: Variant;
}

/**
 * https://www.figma.com/file/GXtg4B5Uy32yVEBVUJahQF/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?type=design&node-id=8469%3A37163&t=4DaFNMo1i3yjNwRb-1
 */
const SunshineToast: ComponentWithAs<'div', SunshineToastProps> = ({
  children,
  onClose,
  variant = 'success',
  ...props
}) => {
  const styles = useMultiStyleConfig('SunshineToast', { variant });

  return (
    <chakra.div __css={styles.container} {...props}>
      <SquircleIcon
        colorScheme={variant === 'success' ? 'green' : 'red'}
        name={iconNameMap[variant]}
      />

      <chakra.p __css={styles.text} as={Text} size="sm" variant="primary">
        {children}
      </chakra.p>

      <chakra.div
        __css={styles.closeButton}
        aria-label=""
        as={IconButton}
        icon="cross"
        iconSize="icon-14"
        onClick={onClose}
        variant="inline-secondary"
      />
    </chakra.div>
  );
};

export default SunshineToast;
