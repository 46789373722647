import { useState } from 'react';

import {
  type Application,
  type CompanyMetadata,
  type OnboardingCompanyProfile,
} from '__generated__/GQL';

import { ApplicationType } from '../types';

const LOCAL_STORAGE_KEY = 'onboarding.applicationType';

interface GetApplicationTypeFromQueryProps {
  company: Pick<
    Application,
    'isCapitalDeposit' | 'isShineStart' | 'isCompanyCreationSASU'
  > & {
    profile: Pick<OnboardingCompanyProfile, 'legalRegistrationNumber'>;
    metadata: Pick<CompanyMetadata, 'status'>;
  };
}

export const getApplicationTypeFromQuery = ({
  company,
}: GetApplicationTypeFromQueryProps) => {
  if (company.metadata.status === 'CLOSED') {
    return null;
  }

  if (company.isCompanyCreationSASU) {
    return ApplicationType.SasuCreation;
  }

  if (company.isCapitalDeposit) {
    return ApplicationType.CapitalDeposit;
  }

  if (company.isShineStart) {
    return ApplicationType.ShineStart;
  }

  /**
   * We have to discriminate the cases where a user started Shine Start or Capital Deposit,
   * therefore he has a company, but no capital deposit or shinestart entity.
   */
  if (company.profile.legalRegistrationNumber) {
    return ApplicationType.Standard;
  }

  /**
   * Here, the user may be at the start of a flow and we can't know which one.
   * So we'll let `localStorage` decide.
   */
  return null;
};

const applicationTypeLocalStorage = {
  clear: () => localStorage.removeItem(LOCAL_STORAGE_KEY),

  get: () => localStorage.getItem(LOCAL_STORAGE_KEY) as ApplicationType | null,

  set: (applicationType: ApplicationType) =>
    localStorage.setItem(LOCAL_STORAGE_KEY, applicationType),
};

const useApplicationTypeStore = () => {
  const [localApplicationType, setLocalApplicationType] =
    useState<ApplicationType | null>(applicationTypeLocalStorage.get());

  return {
    dispatch: (applicationType: ApplicationType | null) => {
      setLocalApplicationType(applicationType);

      return applicationType
        ? applicationTypeLocalStorage.set(applicationType)
        : applicationTypeLocalStorage.clear();
    },

    value: localApplicationType,
  };
};

export default useApplicationTypeStore;
