import { type FC } from 'react';
import { ModalBody, type ModalBodyProps } from '@chakra-ui/react';

import Image from './components/Image';

interface BodyParts {
  Image: typeof Image;
}

const Body: FC<ModalBodyProps> & BodyParts = (props) => (
  <ModalBody {...props} />
);

Body.Image = Image;

export default Body;
