import { type ApplicationType } from '../useCurrentApplicationType/types';

export const APPLICATION_TYPE_STORAGE_KEY = 'onboarding.applicationType';

export const getApplicationType = (): ApplicationType | null => {
  const applicationType = window.localStorage.getItem(
    APPLICATION_TYPE_STORAGE_KEY,
  );

  if (!applicationType) {
    return null;
  }

  return applicationType as ApplicationType;
};

export const saveApplicationType = (applicationType: ApplicationType): void => {
  if (applicationType === undefined) {
    return window.localStorage.removeItem(APPLICATION_TYPE_STORAGE_KEY);
  }

  return window.localStorage.setItem(
    APPLICATION_TYPE_STORAGE_KEY,
    applicationType,
  );
};
