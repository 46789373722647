import { type FC } from 'react';
import { useIntercom } from 'react-use-intercom';
import { HStack } from '@chakra-ui/react';

import Heading from 'components/_core/Heading';
import IconButton from 'components/_core/IconButton';

interface SectionHeaderProps {
  title: string;
}
const SectionHeader: FC<SectionHeaderProps> = ({ title }) => {
  const intercom = useIntercom();

  return (
    <HStack spacing="space-8">
      <Heading size="sm">{title}</Heading>
      <IconButton
        aria-label="edit"
        height="auto"
        icon="edit"
        onClick={() => intercom.showNewMessages('')}
        variant="inline-primary"
      />
    </HStack>
  );
};

export default SectionHeader;
