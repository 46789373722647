import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    authorize: 'Authorize',
    cancel: 'Cancel',
    errorDescription:
      'This view couldn’t load correctly. It happens sometimes, and in this case you have to wait a few minutes before trying again. If the problem persists after 10 minutes, you can contact our support team.',
    errorDescriptionPermission:
      'You do not have sufficient permissions to authorize Shine Connect access.',
    errorTitle: 'An error occurred',
    logout: 'Use a different account',
    title: '{0} wants to:',
  },
  fr: {
    authorize: 'Autoriser',
    cancel: 'Annuler',
    errorDescription:
      'Cette vue n’a pas pu charger correctement. Cela arrive parfois, et il faut dans ce cas-là patienter quelques minutes avant de réessayer. Si le problème persiste après 10 minutes, vous pouvez prendre contact avec nos équipes.',
    errorDescriptionPermission:
      'Vous n’avez pas les permissions suffisantes pour autoriser l’accès Shine Connect.',
    errorTitle: 'Une erreur est survenue',
    logout: 'Changer d’utilisateur',
    title: '{0} souhaite : ',
  },
} as const);

export default locales;
