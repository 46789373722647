import { type FC, useCallback, useEffect } from 'react';
import { Divider, Flex, Image } from '@chakra-ui/react';

import clock from 'assets/brand/clock@2x.png';
import Button from 'components/_core/Button';
import useToast from 'components/ToastProvider/useToast';

import NewDeviceLoginLayout from '../NewDeviceLoginLayout';
import locales from './locales';
import { Description, Title } from './styles';
import { type Props } from './types';

const RequestExpired: FC<Props> = ({
  cancel,
  passcode,
  submitPasscode,
  submitPasscodeErrorMessage,
  submitPasscodeLoading,
}) => {
  const { showToast } = useToast();

  const resend = useCallback(async () => {
    await submitPasscode(passcode);
  }, [submitPasscode, passcode]);

  useEffect(() => {
    if (submitPasscodeErrorMessage) {
      showToast({ message: submitPasscodeErrorMessage, type: 'error' });
    }
  }, [submitPasscodeErrorMessage, showToast]);

  return (
    <NewDeviceLoginLayout>
      <Image src={clock} width="180px" />
      <Title>{locales.title}</Title>
      <Description>{locales.description}</Description>
      <Divider
        left={0}
        marginTop="space-32"
        position="relative"
        width="calc(100% + 32px)"
      />
      <Flex justifyContent="space-between" width="100%">
        <Button
          marginTop="space-24"
          onClick={() => cancel()}
          variant="inline-primary"
        >
          {locales.close}
        </Button>
        <Button
          isLoading={submitPasscodeLoading}
          marginTop="space-24"
          onClick={() => resend()}
        >
          {locales.resend}
        </Button>
      </Flex>
    </NewDeviceLoginLayout>
  );
};

export default RequestExpired;
