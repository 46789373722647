import { type FC } from 'react';

import articlesImg from 'assets/brand/articles@2x.png';

import Container from '../../../../layouts/FeaturesLayout/components/Container';
import Description from '../../../../layouts/FeaturesLayout/components/Description';
import Illustration from '../../../../layouts/FeaturesLayout/components/Illustration';
import Title from '../../../../layouts/FeaturesLayout/components/Title';
import locales from './locales';

interface TransactionsProps {
  companyName: string;
}

const Transactions: FC<TransactionsProps> = ({ companyName }) => (
  <Container>
    <Illustration src={articlesImg} />
    <Title>{locales.formatString(locales.title, { companyName })}</Title>
    <Description>{locales.description}</Description>
  </Container>
);

export default Transactions;
