import { listAnatomy as parts } from '@chakra-ui/anatomy';
import {
  type PartsStyleObject,
  type SystemStyleObject,
} from '@chakra-ui/theme-tools';

type Parts = typeof parts;

const baseStyleIcon: SystemStyleObject = {
  display: 'inline',
  marginEnd: 'space-8',
  verticalAlign: 'text-bottom',
};

const baseStyle: PartsStyleObject<Parts> = {
  container: {},
  icon: baseStyleIcon,
  item: {},
};

const ListTheme = {
  baseStyle,
  parts: parts.keys,
};

export default ListTheme;
