/* eslint-disable react/no-children-prop */

import { createElement, type ReactNode } from 'react';

const strong = /\*\*(.*?)\*\*/gim;

/**
 * Given a string, this function will parse a defined list of markdown tags
 * and return the corresponding renderable list of React nodes.
 *
 * @supports **strong** tags
 */
const parseString = (input: string) => {
  /**
   * Our fallback behavior for unsupported browsers is to return the string as-is.
   */
  if (input.matchAll === undefined) {
    return input;
  }

  const nodes: ReactNode[] = [];
  const matches = [...input.matchAll(strong)];

  /**
   * Keep the start of the input until the first match (if no match, keep the input as-is)
   */
  nodes.push(input.slice(0, matches[0]?.index ?? undefined));

  matches.forEach((match, matchIndex) => {
    if (match.index === undefined) {
      throw Error(`No index for match ${match} in input ${input}`);
    }

    const [matched, text] = match;

    /**
     * Add to the resulting nodes a HTML node containing the matched text
     */
    nodes.push(
      createElement('strong', {
        children: text,
        // An arbitrary key, starting from the start of the input to be fully unique.
        key: input.slice(0, match.index + matched.length),
      }),
    );

    /**
     * Complete the text with text spanning...
     */
    const followingText = input.slice(
      // ...from the end of this match...
      match.index + matched.length,
      // ...to the start of the next match, or just the end of the input.
      matches[matchIndex + 1]
        ? // @ts-expect-error Object is possibly 'undefined'.
          matches[matchIndex + 1].index
        : input.length + 1,
    );

    if (followingText) {
      nodes.push(followingText);
    }
  });

  return nodes;
};

export default parseString;
