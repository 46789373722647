import { type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import SunshineCard from 'components/_core/SunshineCard';
import Header from 'features/Onboarding/components/Header';
import OBStickyButton from 'features/Onboarding/components/OBStickyButton/OBStickyButton';

import locales from './locales';

const eligibilitySchema = z.object({
  hasEUCitizenshipOrVisa: z.literal<boolean>(true),
  isAdult: z.literal<boolean>(true),
});

interface SasuCreationEligibilityFormProps {
  onSubmit: () => void;
}

/**
 * A form asking the user if they're eligible to create a SASU
 */
const SasuCreationEligibilityForm: FC<SasuCreationEligibilityFormProps> = ({
  onSubmit,
}) => {
  const form = useForm({
    defaultValues: {
      hasEUCitizenshipOrVisa: undefined,
      isAdult: undefined,
    },
    mode: 'onChange',
    resolver: zodResolver(eligibilitySchema),
  });

  return (
    <Flex direction="column" height={{ base: 'full', md: 'auto' }}>
      <Header title={locales.title} />

      <Flex
        as="form"
        direction="column"
        height="full"
        justify="space-between"
        onSubmit={form.handleSubmit(() => {
          onSubmit();
        })}
      >
        <SunshineCard.Group>
          <Controller
            control={form.control}
            name="isAdult"
            render={({ field }) => (
              <SunshineCard
                isChecked={field.value}
                onChange={field.onChange}
                title={locales.labels.isAdult}
                variant="checkbox"
              />
            )}
          />

          <Controller
            control={form.control}
            name="hasEUCitizenshipOrVisa"
            render={({ field }) => (
              <SunshineCard
                isChecked={field.value}
                onChange={field.onChange}
                title={locales.labels.hasEUCitizenshipOrVisa}
                variant="checkbox"
              />
            )}
          />
        </SunshineCard.Group>

        <OBStickyButton isDisabled={!form.formState.isValid} type="submit">
          {locales.cta}
        </OBStickyButton>
      </Flex>
    </Flex>
  );
};

export default SasuCreationEligibilityForm;
