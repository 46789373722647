import { useContext } from 'react';

import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import { PageLayout } from 'features/Bento/components';
import { GuidanceShineStartAdvertising } from 'features/Bento/flows/signup/components/Guidance';
import { model } from 'features/Bento/flows/signup/machine';
import { type Signup } from 'features/Bento/flows/signup/machine/machine';
import { type SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import locales from './locales';

const CompanyCreationSelectTypeV2: SignupPage<Signup> = ({ send }) => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
      guidance={<GuidanceShineStartAdvertising />}
    >
      <PageLayout.Header marginBottom="space-24">
        <Heading size="lg">{locales.title}</Heading>
      </PageLayout.Header>

      <SunshineCard.Group isBoxed={false} marginBottom="space-24">
        <SunshineCard
          onClick={() => {
            send(model.events.SUBMIT_COMPANY_CREATION_TYPE('society'));
          }}
          title={locales.societyTitle}
          variant="default"
        >
          <SunshineCard.Content>
            <SunshineCard.Text>{locales.societyDescription}</SunshineCard.Text>
          </SunshineCard.Content>
          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>

        <SunshineCard
          onClick={() => {
            send(model.events.GO_CAP_DEP());
          }}
          title={locales.capdepTitle}
          variant="default"
        >
          <SunshineCard.Content>
            <SunshineCard.Text>{locales.capdepDescription}</SunshineCard.Text>
          </SunshineCard.Content>
          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>

        <SunshineCard
          onClick={() => {
            send(model.events.SUBMIT_COMPANY_CREATION_TYPE('independent'));
          }}
          title={locales.independentTitle}
        >
          <SunshineCard.Content>
            <SunshineCard.Text>
              {locales.independentDescription}
            </SunshineCard.Text>
          </SunshineCard.Content>
          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>

        <SunshineCard
          onClick={() => {
            send(model.events.SUBMIT_COMPANY_CREATION_TYPE('unknown'));
          }}
          title={locales.unknownTitle}
        >
          <SunshineCard.Content>
            <SunshineCard.Text>{locales.unknownDescription}</SunshineCard.Text>
          </SunshineCard.Content>
          <SunshineCard.Slot name="action">
            <SunshineCard.SunshineIcon name="chevron-right" />
          </SunshineCard.Slot>
        </SunshineCard>
      </SunshineCard.Group>
    </Layout>
  );
};

export default CompanyCreationSelectTypeV2;
