/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8558%3A35124&t=GNHsXVlDt2Zpruph-1
 */

import { formAnatomy } from '@chakra-ui/anatomy';
import {
  type PartsStyleObject,
  type SystemStyleObject,
} from '@chakra-ui/theme-tools';

export type Parts = typeof formAnatomy;

const baseStyleHelperText: SystemStyleObject = {
  color: 'text-discreet',
  fontSize: 'font-12',
};

const baseStyle: PartsStyleObject<Parts> = {
  helperText: baseStyleHelperText,
};

const FormTheme = {
  baseStyle,
  parts: formAnatomy.keys,
};

export default FormTheme;
