import { type FC } from 'react';

import { CompanyInvitationRole } from '__generated__/GQL';
import Loader from 'components/Loader';

import AccountantFeatures from './components/AccountantFeatures';
import EmployeeFeatures from './components/EmployeeFeatures';
import useCompanyUserInvitation from './hooks/useCompanyUserInvitation';
import useToggleCardDone from './hooks/useToggleCardDone';

interface TeamOnboardingTutorialsProps {
  cardId: string;
}
const TeamOnboardingTutorials: FC<TeamOnboardingTutorialsProps> = ({
  cardId,
}) => {
  const { featuresData, loading, role } = useCompanyUserInvitation();

  const [toggleCardDone] = useToggleCardDone(cardId);

  const onTutorialsDone = async () => {
    await toggleCardDone();
  };

  if (loading) {
    return <Loader />;
  }

  switch (role) {
    case CompanyInvitationRole.Accountant:
      return (
        <AccountantFeatures
          onTutorialsDone={onTutorialsDone}
          {...featuresData}
        />
      );
    case CompanyInvitationRole.Admin:
    case CompanyInvitationRole.Employee:
      return (
        <EmployeeFeatures onTutorialsDone={onTutorialsDone} {...featuresData} />
      );
    default:
      // we should not have a Card without an Accountant or Employee invitation
      throw new Error(
        'Missing or wrong invitation for team onboarding tutorial card',
      );
  }
};

export default TeamOnboardingTutorials;
