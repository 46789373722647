import {
  CompanyInvitationRole,
  CompanyUserReviewStatus,
  KycStatus,
} from '__generated__/GQL';
import { type MachineGuards } from 'common/bento/types/MachineConfig';

import { type Context, type Model } from './model';

export enum Guard {
  IsProfileCompleted = 'isProfileCompleted',
  IsAccountant = 'isAccountant',
  IsEmployee = 'isEmployee',
  IsWaitingForReview = 'isWaitingForReview',
  IsReviewInvalid = 'isReviewInvalid',
  IsAwaitingReviewSubmission = 'isAwaitingReviewSubmission',
}

const guards: MachineGuards<Model, Guard> = {
  /**
   * check if the user is an accountant
   */
  isAccountant: ({ getModuleData }: Context) => {
    const { invitation } = getModuleData();
    return invitation.role === CompanyInvitationRole.Accountant;
  },

  /*
   * check if the review should be submitted which may happen
   * when the user stopped the id check at the wrong moment
   */
  isAwaitingReviewSubmission: ({ getModuleData }: Context) =>
    getModuleData().kycIdStatus === KycStatus.Pending,

  /**
   * check if the user is an employee
   */
  isEmployee: ({ getModuleData }: Context) => {
    const { invitation } = getModuleData();
    return invitation.role === CompanyInvitationRole.Employee;
  },

  /**
   * check if the user profile is completed
   */
  isProfileCompleted: ({ getModuleData }) => {
    const { profile } = getModuleData();
    return Boolean(
      profile.firstName &&
        profile.lastName &&
        profile.gender &&
        profile.birthdate &&
        profile.nationality,
    );
  },

  /**
   * check if the user review is invalid
   */
  isReviewInvalid: ({ getModuleData }: Context) => {
    const { reviewStatus } = getModuleData();
    return reviewStatus === CompanyUserReviewStatus.Invalid;
  },

  /**
   * check if the user review is waiting for a review
   */
  isWaitingForReview: ({ getModuleData }: Context) => {
    const { reviewStatus } = getModuleData();
    return reviewStatus === CompanyUserReviewStatus.SentForPrecheck;
  },
};

export default guards;
