import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    seePlansDetails: 'See the plans details',
    title: 'Choose my plan',
  },
  fr: {
    seePlansDetails: 'Voir le détail des plans',
    title: 'Choisir mon offre',
  },
} as const);

export default locales;
