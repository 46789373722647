import { type FC } from 'react';
import { HStack, Link } from '@chakra-ui/react';

import locales from './locales';

const actions = [
  {
    id: 'website',
    label: locales.website,
    url: 'https://shine.fr',
  },
  {
    id: 'help',
    label: locales.help,
    url: 'https://help.shine.fr/',
  },
  {
    id: 'terms',
    label: locales.terms,
    url: 'https://shine.fr/terms',
  },
];

interface HelpAndTermsLinksProps {
  center?: boolean;
}

const HelpAndTermsLinks: FC<HelpAndTermsLinksProps> = ({ center = false }) => {
  return (
    <HStack
      {...(center && { justifyContent: 'center' })}
      marginTop="space-16"
      spacing="space-24"
    >
      {actions.map(({ id, label, url }) => (
        <Link
          _hover={{ color: 'text-discreet' }}
          color="text-discreet"
          href={url}
          isExternal
          key={id}
          rel="noopener noreferrer"
          size="sm"
          textDecoration="none"
        >
          {label}
        </Link>
      ))}
    </HStack>
  );
};

export default HelpAndTermsLinks;
