import { type FC } from 'react';

import SunshineTag, {
  type SunshineTagProps,
} from 'components/_core/SunshineTag';

import locales from './locales';

const NewBadge: FC<Omit<SunshineTagProps, 'colorScheme' | 'size'>> = () => {
  return (
    <SunshineTag colorScheme="green" size="sm">
      {locales.new}
    </SunshineTag>
  );
};

export default NewBadge;
