import { AFFILAE_PARAM } from '../params';

/**
 * @returns false is the url is not a valid url
 * @returns false if the url does not have the affilae param
 * @returns true if the url has the affilae param
 */
export const isUrlFromAffilae = (url: string) => {
  try {
    return new URL(url).searchParams.has(AFFILAE_PARAM);
  } catch (e) {
    return false;
  }
};
