import { type OBErrorCode } from './OBErrorCode';

/**
 * An augmented Error for our error handling in the onboarding.
 */
export class OBError extends Error {
  metadata: unknown[] = [];
  localizedMessage: string | null;
  shouldReport: boolean;

  constructor(
    input: Error | OBErrorCode | string,
    {
      localizedMessage,
      metadata,
      shouldReport,
    }: {
      metadata?: unknown;
      localizedMessage?: string;
      shouldReport?: boolean;
    } = {},
  ) {
    super(input instanceof Error ? input.message : input);

    this.localizedMessage = localizedMessage ?? null;

    this.shouldReport = shouldReport ?? true;

    if (metadata) {
      this.metadata.push(metadata);
    }

    if (input instanceof Error) {
      this.stack = input.stack;
      this.name = input.name;
    }
  }

  enrich(data: unknown) {
    this.metadata = [...this.metadata, data];
  }
}
