/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';
import { ERROR_CODES } from '@shinetools/errors';

import { finishRegister, forgotPasscode } from 'helpers/auth/service';
import { useDevice } from 'helpers/device';
import logger from 'helpers/logger';

import { type LoginType } from '../types';
import doLogin from './doLogin';
import locales from './locales';
import sendSignupZapierHook from './sendSignupZapierHook';
import {
  type LoginStep,
  type SetCurrentUser,
  type SubmitPasscodeHookResult,
} from './types';
import useAutoResettingErrorMessage from './useAutoResettingErrorMessage';

const useCreatePasscodeSubmit = ({
  currentStep,
  email,
  hasAgreedToTermsOfService,
  loginType,
  phone,
  phoneNumberOwnershipToken,
  setCurrentStep,
  setCurrentUser,
}: {
  loginType: LoginType;
  currentStep: LoginStep;
  setCurrentStep: (currentStep: LoginStep) => void;
  setCurrentUser: SetCurrentUser;
  phone: string;
  email: string;
  hasAgreedToTermsOfService: boolean;
  phoneNumberOwnershipToken: string;
}): SubmitPasscodeHookResult => {
  const { model, name, token: deviceToken } = useDevice();
  const [errorMessage, setErrorMessage] =
    useAutoResettingErrorMessage(currentStep);
  const [loading, setLoading] = useState(false);
  const [passcode, setPasscode] = useState('');
  const onSubmit = useCallback(
    async (
      newPasscode: string,
    ): ReturnType<SubmitPasscodeHookResult['onSubmit']> => {
      if (phone === '') {
        throw new Error('Phone number should be available');
      }
      try {
        setErrorMessage('');
        setLoading(true);
        if (loginType === 'sign_up') {
          const tokens = await finishRegister({
            deviceToken,
            email,
            hasAgreedToTermsOfService,
            model,
            name,
            passcode: newPasscode,
            phone,
            phoneNumberOwnershipToken,
          });

          const loginSuccessful = doLogin(
            loginType,
            setCurrentUser,
            phone,
            tokens,
          );

          if (loginSuccessful) {
            try {
              await sendSignupZapierHook({ phone });
            } catch (e) {
              // Do not block the sign up if there was an error while sending the Zapier hook.
              // However, if we have persistent problems with Zapier, they need investigating
              // and so it's important this is reported/logged
              logger.error(e);
            }
          }

          return loginSuccessful;
        } else if (loginType === 'passcode_reset') {
          const { shouldTriggerNewDeviceAuthorization } = await forgotPasscode({
            deviceToken,
            model,
            name,
            phone,
          });

          if (shouldTriggerNewDeviceAuthorization) {
            setCurrentStep('forgotPasscodeUnknownDevice');
            return true;
          }
        }
        setPasscode(newPasscode);
        setCurrentStep('createVerificationCode');
        setLoading(false);
        return true;
      } catch (error: any) {
        switch (error.code) {
          case ERROR_CODES.AUTHENTICATION_PASSCODE_WRONG:
            setErrorMessage(locales.invalidPasscode);
            break;

          case ERROR_CODES.AUTHENTICATION_PHONE_PREFIX_NOT_ALLOWED_REGISTER:
            setErrorMessage(locales.phoneNumberNotAllowed);
            break;

          default:
            setErrorMessage(locales.connectionErrorMessage);
            logger.error(error, {
              context: {
                currentStep,
                loginType,
                phone,
              },
            });

            break;
        }
        setLoading(false);

        return false;
      }
    },
    [
      phone,
      setErrorMessage,
      loginType,
      setCurrentStep,
      deviceToken,
      email,
      hasAgreedToTermsOfService,
      model,
      name,
      phoneNumberOwnershipToken,
      setCurrentUser,
      currentStep,
    ],
  );
  return {
    authenticationDeviceId: '',
    authenticationDeviceRequestId: '',
    errorMessage,
    loading,
    loginType,
    onSubmit,
    passcode,
  };
};

export default useCreatePasscodeSubmit;
