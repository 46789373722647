import * as Types from '../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetAgreementCatchupContextQueryVariables = Types.Exact<{
  companyProfileId: Types.Scalars['UUIDv4']['input'];
}>;


export type GetAgreementCatchupContextQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', uid: string, company: { __typename: 'Company', companyProfileId: string }, outstandingAgreements: Array<{ __typename: 'OutstandingAgreement', agreementId: string, status: Types.OutstandingAgreementStatus, type: Types.OutstandingAgreementType }> } };


export const GetAgreementCatchupContextDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAgreementCatchupContext"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUIDv4"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyProfileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"outstandingAgreements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyProfileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"agreementId"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]} as unknown as DocumentNode<GetAgreementCatchupContextQuery, GetAgreementCatchupContextQueryVariables>;