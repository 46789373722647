import { countries } from '@shinetools/geo-library';
import { convert as titleCase } from 'titlecase-french';

import { type AuthenticationDeviceRequest } from '__generated__/GQL';
import { getLanguage } from 'common/locale';

const formatLocationFromRequest = (
  request?: Pick<AuthenticationDeviceRequest, 'country' | 'city'>,
): string | null => {
  const language = getLanguage() === 'fr' ? 'fr' : 'en';

  const city = titleCase(request?.city);
  let country = request?.country || null;

  if (country) {
    country =
      country in countries
        ? countries[country as keyof typeof countries].name[language]
        : null;
  }

  if (country && city) {
    return `${city}, ${country}`;
  }

  if (city) {
    return city;
  }

  if (country) {
    return country;
  }
  return null;
};

export default formatLocationFromRequest;
