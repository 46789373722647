import { createMachine } from 'xstate';

import getBootstrapGuards from 'common/bento/lib/bootstrap/getBootstrapGuards';
import shared from 'common/bento/shared';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';
import { type AnyMachineEvent } from 'features/Bento/types/Abstract';

import locales from '../../../locales';
import actions, { Action } from './actions';
import guards, { Guard } from './guards';
import model, { type Context } from './model';
import services from './services';

export enum State {
  INIT = 'init',
  HOME = 'home',
  ADDRESS = 'address',
  IDENTITY = 'identity',
  DONE = 'done',
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    context: model.initialContext,

    id: 'verification',

    initial: State.INIT,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.INIT]: {
        always: [
          // Allows to jump into Identity if the URL includes a param `bootstrap:verification=identity`
          ...getBootstrapGuards([State.IDENTITY]),
          {
            actions: Action.TrackVerificationHomeShowed,
            target: State.HOME,
          },
        ],

        entry: Action.TrackAvoidAppLayoutExperiment,
      },

      [State.HOME]: {
        entry: [
          /**
           * WebView users should never see the Verification Home.
           * The native KYC timeline holds responsability for being the hub at this stage.
           */
          shared.actions.trackView(),
          Action.TrackVerificationHomeErrors,
        ],

        on: {
          GO_ADDRESS: State.ADDRESS,
          GO_IDENTITY: State.IDENTITY,
          NEXT: State.DONE,

          PREV: [
            {
              actions: shared.actions.toast({
                status: 'error',
                title: locales.toast.definitiveStep,
              }),
            },
          ],
        },
      },

      [State.IDENTITY]: {
        on: {
          NEXT: [
            {
              cond: Guard.ShouldSkipHomeToSubmit,
              target: State.DONE,
            },
            State.HOME,
          ],
          PREV: State.HOME,
        },
      },

      [State.ADDRESS]: {
        on: {
          NEXT: State.HOME,
          PREV: State.HOME,
        },
      },

      [State.DONE]: {
        data: {
          status: BentoModuleDoneStatus.DONE,
        },
        type: 'final',
      },
    },
  },

  {
    actions,
    guards,
    services,
  },
);

export default machine;
