import { type FC } from 'react';
import { Link } from 'react-router-dom';

type ButtonProps = Omit<React.ComponentProps<'button'>, 'type'>;
type LinkProps = React.ComponentProps<typeof Link>;

type ButtonBadgeWrapperProps = (ButtonProps | LinkProps) & {
  to?: string;
};

const ButtonBadgeWrapper: FC<ButtonBadgeWrapperProps> = ({ to, ...props }) =>
  to ? (
    <Link {...(props as LinkProps)} to={to} />
  ) : (
    <button type="button" {...(props as ButtonProps)} />
  );

export default ButtonBadgeWrapper;
