import LocalizedStrings from 'localized-strings';

/**
 * @todo locales
 */
const locales = new LocalizedStrings({
  en: {
    cardDescription: `
    Nous ne cessons de faire évoluer nos services afin de vous donner toujours plus de satisfaction.

    C’est pourquoi nous avons dû modifier nos conditions générales d’utilisation afin de refléter ces changements. Pour pouvoir continuer à utiliser votre compte Shine, vous devez les lire et les accepter.
    `,
    cardTitle: 'Les conditions générales d’utilisation de Shine',
    cta: 'Accepter les CGU',
  },
  fr: {
    cardDescription: `
      Nous ne cessons de faire évoluer nos services afin de vous donner toujours plus de satisfaction.

      C’est pourquoi nous avons dû modifier nos conditions générales d’utilisation afin de refléter ces changements. Pour pouvoir continuer à utiliser votre compte Shine, vous devez les lire et les accepter.
      `,
    cardTitle: 'Les conditions générales d’utilisation de Shine',
    cta: 'Accepter les CGU',
  },
} as const);

export default locales;
