import { type FC } from 'react';

import Passcode from '../Passcode';
import { type PasscodeProps } from '../Passcode/types';

const VerificationCode: FC<PasscodeProps> = (props) => (
  <Passcode {...props} passcodeType="verificationCode" />
);

export default VerificationCode;
