import { chakra, forwardRef } from '@chakra-ui/system';

import Text, { type TextProps } from '../../../Text';
import { useStyles } from '../../context';

const Title = forwardRef<TextProps, 'p'>((props, ref) => {
  const styles = useStyles();

  return <chakra.p __css={styles.title} as={Text} ref={ref} {...props} />;
});

export default Title;
