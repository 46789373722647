import styled, { type CSSProperties } from 'styled-components';

import { type LegacySpacing } from '../../theme/legacyTheme';

type RowProps = Pick<
  CSSProperties,
  'alignItems' | 'justifyContent' | 'textAlign'
> & { gap?: LegacySpacing };

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  ${({ justifyContent }): string =>
    justifyContent ? `justify-content: ${justifyContent};` : ''}
  ${({ alignItems }): string =>
    alignItems ? `align-items: ${alignItems};` : ''}
  ${({ textAlign }): string => (textAlign ? `text-align: ${textAlign};` : '')}
  ${({ gap, theme }): string => (gap ? `gap: ${theme.spacings[gap]}px;` : '')}
`;

export default Row;
