import { useContext } from 'react';

import Heading from 'components/_core/Heading';
import { PageLayout } from 'features/Bento/components';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import { GuidanceShineStartAdvertising } from '../../components/Guidance';
import { model } from '../../machine';
import { type Signup } from '../../machine/machine';
import { type SignupPage } from '../../types/SignupPage';
import SignupLandbot from './components/SignupLandbot';
import getSignupSituationFromState from './libs/getSignupSituationFromState';
import locales from './locales';

/**
 * A page presenting the Landbot to the user
 */
const CompanyCreationLandbot: SignupPage<Signup> = ({ send, state }) => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
      guidance={<GuidanceShineStartAdvertising />}
    >
      <PageLayout.Header marginBottom="space-24">
        <Heading size="lg">{locales.title}</Heading>
      </PageLayout.Header>

      <SignupLandbot
        callback={(event) => {
          if (event === 'goShineStartMicro') {
            send(model.events.GO_SHINE_START_MICRO());
          } else if (event === 'goCapdep') {
            send(model.events.GO_CAP_DEP());
          }
        }}
        customData={{
          affiliate: state.context.affiliate || undefined,
          legalform: state.context.companyLegalForm || undefined,
          signupSituation: getSignupSituationFromState(state),
        }}
        marginBottom="space-16"
      />
    </Layout>
  );
};

export default CompanyCreationLandbot;
