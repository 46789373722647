import LocalizedStrings from 'localized-strings';

const errorMessages = new LocalizedStrings({
  en: {
    actionRequestAlreadyConfirmed: 'This operation has already been confirmed.',
    actionRequestAlreadyRejected: 'This operation has already been rejected.',
    actionRequestExpired: 'This operation has expired.',
    apeCodeDoesNotExist: 'This APE code does not exist.',
    bankTransferAlreadyCanceled: 'This transfer is already canceled.',
    bankTransferAlreadyValidated: 'This transfer is already validated.',
    bicNotValid:
      'We could not validate this BIC. Maybe you made a typo? We only accept' +
      ' BICs from SEPA countries.',
    checkDepositMonthlyAmountLimit: `You've reached the monthly cumulative limit of €10000.`,
    companyRefused: 'Your company is ineligible.',
    creditCardNotOwned: 'You cannot edit a card that is not yours.',
    deleteImgError:
      'Error while deleting this file. Could you please try again?',
    documentsValidated: 'Your documents are already validated.',
    emailAlreadyExists: 'This email is already used.',
    flow3DSFailure:
      'An error occured during 3D Secure flow. Could you please try again?',
    ibanNotValid:
      'We could not validate this IBAN. Maybe you made a typo? We only accept IBANs from SEPA countries.',
    insuficientFunds: 'Your have insufficient funds to complete this transfer.',
    invalidCheckNumber: 'The check number is invalid.',
    invalidCoupon: 'This coupon is invalid.',
    invalidEmail: 'This email address is not valid.',
    invalidRlmc:
      'The key is not valid. Verify both the check number and the key.',
    invalidZipCode: 'This zip code is invalid.',
    invoiceFiscalIdNotUnique: 'Invoice number already used.',
    kycAlreadyValidated: 'Your account has already been validated.',
    kycNotReady: 'An action is required before submitting your application.',
    legalRegistrationNumberAlreadyExists:
      'An account for this company already exists.',
    localizedNetworkError:
      'You seem to be offline. Please try again when you’re back online.',
    localizedUnknownError:
      'Something went wrong. Please try again in a few minutes and contact our customer service if the problem remains.',
    missingKycDocument: 'Psst! Don’t forget to upload your documents.',
    paymentDeclined: 'Your card was declined.',
    paymentExpired: 'Your card has expired.',
    paymentInsufficientFunds: 'Your card has insufficient funds.',
    paymentInvalidCVC: 'Your card’s security code is incorrect.',
    paymentInvalidNumber: 'Your card number is incorrect.',
    paymentInvalidZipCode:
      'The zip code you have provided is invalid. Please check your address.',
    paymentNotSupported: 'Your card does not support this type of purchase.',
    quoteFiscalIdNotUnique: 'Quote number already used.',
    rateLimitingError: 'Too many attempts. Try again in a hour.',
    resize: 'This image is not supported.',
    reviewInProgress: 'Your application is still in review.',
    scheduledBankTransferEndsAtBeforeOrSameAsFirstOccurrence:
      'Last due date must be after first due date.',
    scheduledBankTransferInvalidEndsAt:
      'The last due date can’t be today or in the past.',
    scheduledBankTransferInvalidFirstOccurrence:
      'The next due date can’t be today or in the past.',
    scheduledBankTransferUpdateForbidden:
      'This scheduled transfer cannot be updated because it is not active anymore.',
    tokensDoNotMatch: 'The token provided does not match the one on your card.',
    trzBankAccountIbanAlreadyExists:
      'This IBAN is already used by one of your recipients.',
    uploadImgError: 'We couldn’t upload the image. Could you please try again?',
    userIsUnder18Error: 'You have to be over 18 to use Shine.',
  },
  fr: {
    actionRequestAlreadyConfirmed: 'Cette opération a déjà été confirmée.',
    actionRequestAlreadyRejected: 'Cette opération a déjà été refusée.',
    actionRequestExpired: 'Cette opération a expiré.',
    apeCodeDoesNotExist: 'Ce code APE n’existe pas.',
    bankTransferAlreadyCanceled: 'Ce virement est déjà annulé.',
    bankTransferAlreadyValidated: 'Ce virement est déjà validé.',
    bicNotValid:
      "Nous n'avons pas pu valider ce BIC. Peut-être une faute de frappe ?" +
      ' Nous acceptons uniquement les BICs provenant de la zone SEPA (Union Européenne).',
    checkDepositMonthlyAmountLimit: `Vous avez atteint la limite mensuelle d'encaissement de chèques de 10 000€.`,
    companyRefused: 'Votre société n’est pas éligible.',
    creditCardNotOwned:
      'Vous ne pouvez pas modifier une carte qui ne vous appartient pas.',
    deleteImgError:
      'Il y a eu un problème lors de la suppression du document. Pouvez-vous réessayer ?',
    documentsValidated: 'Vos documents sont déjà validés.',
    emailAlreadyExists: 'Cette adresse e-mail est déjà utilisée.',
    flow3DSFailure:
      'Il y a eu un problème lors de la vérification 3D Secure. Pouvez-vous réessayer ?',
    ibanNotValid:
      "Nous n'avons pas pu valider cet IBAN. Peut être une faute de frappe ?" +
      ' Nous acceptons uniquement les IBANs provenant de la zone SEPA (Union Européenne).',
    insuficientFunds:
      'Votre compte n’est pas assez approvisionné pour effectuer ce transfert.',
    invalidCheckNumber: 'Le numéro de chèque est invalide.',
    invalidCoupon: 'Ce code de réduction est invalide.',
    invalidEmail: 'Votre adresse e-mail n’est pas valide.',
    invalidRlmc:
      'La clé n’est pas valide. Vérifiez le numéro de chèque et la clé saisie.',
    invalidZipCode: 'Le code postal est invalide.',
    invoiceFiscalIdNotUnique: 'Numéro de facture déjà utilisé.',
    kycAlreadyValidated: 'Votre compte a déjà été validé.',
    kycNotReady: 'Une action est nécessaire avant de soumettre votre dossier.',
    legalRegistrationNumberAlreadyExists:
      'Un compte pour cette entreprise existe déjà.',
    localizedNetworkError:
      'Il semble que vous soyez hors ligne. Essayez à nouveau lorsque vous aurez une connexion à Internet.',
    localizedUnknownError:
      'Oups, il y a eu un problème ! Essayez à nouveau dans quelques minutes. Si le problème persiste après quelques minutes, contactez-nous.',
    missingKycDocument: 'Psst ! N’oubliez pas de télécharger vos documents.',
    paymentDeclined: 'Votre carte a été refusée.',
    paymentExpired: 'Votre carte a expiré.',
    paymentInsufficientFunds: 'Votre carte n’a pas les fonds suffisants.',
    paymentInvalidCVC: 'Le code de vérification de votre carte est incorrect.',
    paymentInvalidNumber: 'Le numéro de votre carte est incorrect.',
    paymentInvalidZipCode:
      'Le code postal que vous avez renseigné est invalide. Pouvez-vous vérifier votre adresse ?',
    paymentNotSupported: 'Votre carte ne supporte pas ce type d’achat.',
    quoteFiscalIdNotUnique: 'Numéro de devis déjà utilisé.',
    rateLimitingError:
      'Vous avez dépassé le nombre d’essais autorisés. Vous pourrez réessayer dans une heure.',
    resize: 'Ce type d’image n’est pas supporté.',
    reviewInProgress: 'Votre dossier est toujours en cours de vérification.',
    scheduledBankTransferEndsAtBeforeOrSameAsFirstOccurrence:
      'La date de dernière échéance doit être après la première échéance.',
    scheduledBankTransferInvalidEndsAt:
      'La date de dernière échéance ne peut pas être aujourd’hui ou être passée.',
    scheduledBankTransferInvalidFirstOccurrence:
      'La date de première échéance ne peut pas être aujourd’hui ou être passée.',
    scheduledBankTransferUpdateForbidden:
      'Ce virement programmé ne peut être modifié car il n’est plus actif.',
    tokensDoNotMatch:
      'Le token saisi ne correspond pas à celui sur votre carte.',
    trzBankAccountIbanAlreadyExists:
      'Cet IBAN existe déjà pour l’un de vos bénéficiaires.',
    uploadImgError:
      'Il y a eu un problème lors du téléchargement de l’image. Pouvez-vous réessayer ?',
    userIsUnder18Error: 'Vous devez avoir 18 ans ou plus pour utiliser Shine.',
  },
} as const);

export default errorMessages;
