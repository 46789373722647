import { type FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Divider, Flex, Stack } from '@chakra-ui/react';
import { ERROR_CODES } from '@shinetools/errors';
import { getRemoteConfig } from 'firebase/remote-config';

import flowerWaveImg from 'assets/brand/flower-wave@2x.png';
import { getServerErrorCode } from 'common/errors/utils';
import Button from 'components/_core/Button';
import Callout from 'components/_core/Callout';
import SunshineCard from 'components/_core/SunshineCard';
import SunshineModal from 'components/_core/SunshineModal';
import Text from 'components/_core/Text';
import PlaceholderLoader from 'components/PlaceholderLoader';
import { formatDate, PPp } from 'helpers/date';
import formatLocationFromRequest from 'helpers/location/formatLocation';

import {
  fetchAndActivateRemoteConfig,
  getConfigGettersOnceLoaded,
} from '../../../../../../../common/remoteConfig';
import { AcceptAuthenticationDeviceRequestDocument } from './hooks/mutations/acceptAuthenticationDeviceRequest.gql';
import { RefuseAuthenticationDeviceRequestDocument } from './hooks/mutations/refuseAuthenticationDeviceRequest.gql';
import { GetAuthenticationDeviceRequestDocument } from './hooks/queries/getAuthenticationDeviceRequest.gql';
import locales from './locales';
import { ModalStep, type Props } from './types';

const shouldDisplayFakePhoneScamCalloutFirebaseKey =
  'shouldDisplayFakePhoneScamCallout';
const ONE_MINUTE = 60 * 1000;
const CACHE_INVALIDATION_FETCH_INTERVAL = 1;

const PendingStep: FC<Props> = ({
  onRequestAlreadyHandled,
  onRequestExpired,
  request,
  setStep,
}) => {
  const [
    shouldDisplayFakePhoneScamCallout,
    setShouldDisplayFakePhoneScamCallout,
  ] = useState(true);
  const [
    hasUnderstoodOtherDeviceWhitelisted,
    setHasUnderstoodOtherDeviceWhitelisted,
  ] = useState(false);

  const [hasUnderstoodNeverGiveAccess, setHasUnderstoodNeverGiveAccess] =
    useState(false);

  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    (async () => {
      const { getBoolean } = await getConfigGettersOnceLoaded();

      const config = getRemoteConfig();

      config.settings = {
        fetchTimeoutMillis: ONE_MINUTE,
        minimumFetchIntervalMillis: CACHE_INVALIDATION_FETCH_INTERVAL,
      };

      // Invalidate remote config cache
      await fetchAndActivateRemoteConfig(config);

      const result = getBoolean(shouldDisplayFakePhoneScamCalloutFirebaseKey);
      setShouldDisplayFakePhoneScamCallout(result);
    })();
  }, []);
  const { authenticationDeviceId, authenticationDeviceRequestId, phone } =
    request;

  const [acceptAuthenticationDeviceRequest, { loading: acceptLoading }] =
    useMutation(AcceptAuthenticationDeviceRequestDocument, {
      variables: {
        input: {
          authenticationDeviceId,
          authenticationDeviceRequestId,
          phone,
        },
      },
    });

  const [refuseAuthenticationDeviceRequest, { loading: refuseLoading }] =
    useMutation(RefuseAuthenticationDeviceRequestDocument, {
      variables: {
        input: {
          authenticationDeviceId,
          authenticationDeviceRequestId,
          phone,
        },
      },
    });

  const { data, loading: loadingDevice } = useQuery(
    GetAuthenticationDeviceRequestDocument,
    {
      variables: {
        authenticationDeviceId,
        authenticationDeviceRequestId,
        phone,
      },
    },
  );
  const device = data?.viewer.authenticationDevice;

  const loading = acceptLoading || refuseLoading;

  const toggleHasUnderstoodOtherDeviceWhitelisted = () =>
    setHasUnderstoodOtherDeviceWhitelisted((value) => !value);

  const toggleHasUnderstoodNeverGiveAccess = () =>
    setHasUnderstoodNeverGiveAccess((value) => !value);

  const handleError = (err: unknown) => {
    const errorCode = getServerErrorCode(err);

    if (
      errorCode ===
      ERROR_CODES.AUTHENTICATION_DEVICE_REQUEST_ALREADY_IN_FINAL_STATUS
    ) {
      onRequestAlreadyHandled();
      return;
    }

    if (errorCode === ERROR_CODES.AUTHENTICATION_DEVICE_REQUEST_EXPIRED) {
      onRequestExpired();
      return;
    }

    setError(err);
  };

  const onAccept = async () => {
    try {
      setError(null);
      await acceptAuthenticationDeviceRequest();
      setStep(ModalStep.Accepted);
    } catch (err) {
      handleError(err);
    }
  };

  const onRefuse = async () => {
    try {
      setError(null);
      await refuseAuthenticationDeviceRequest();
      setStep(ModalStep.Refused);
    } catch (err) {
      handleError(err);
    }
  };

  const canConfirm =
    hasUnderstoodOtherDeviceWhitelisted && hasUnderstoodNeverGiveAccess;

  const location = formatLocationFromRequest(device?.request);

  return (
    <>
      <SunshineModal.Body>
        <SunshineModal.Body.Image src={flowerWaveImg} />

        <SunshineModal.Title>{locales.title}</SunshineModal.Title>

        <Text>{locales.description}</Text>

        <Box marginTop="space-40">
          <Flex justifyContent="space-between">
            <Text>{locales.device}</Text>
            <PlaceholderLoader isLoaded={!loadingDevice}>
              <Text variant="primary">
                {device?.model || locales.unknownDeviceType}
              </Text>
            </PlaceholderLoader>
          </Flex>

          <Divider marginY="space-16" />

          <Flex justifyContent="space-between">
            <Text>{locales.deviceName}</Text>
            <PlaceholderLoader isLoaded={!loadingDevice}>
              <Text variant="primary">
                {device?.name || locales.unknownDevice}
              </Text>
            </PlaceholderLoader>
          </Flex>

          <Divider marginY="space-16" />

          <Flex justifyContent="space-between">
            <Text>{locales.location}</Text>
            <PlaceholderLoader isLoaded={!loadingDevice}>
              <Text variant="primary">
                {location ? location : locales.unknownLocation}
              </Text>
            </PlaceholderLoader>
          </Flex>

          <Divider marginY="space-16" />

          <Flex justifyContent="space-between">
            <Text>{locales.loginAttempt}</Text>
            <PlaceholderLoader isLoaded={!loadingDevice}>
              <Text textAlign="right" variant="primary">
                {device?.request.createdAt
                  ? formatDate(new Date(device?.request.createdAt), PPp)
                  : ''}
                <br />
                {locales.parisTimezone}
              </Text>
            </PlaceholderLoader>
          </Flex>
        </Box>

        <Stack paddingTop="space-40">
          <Text fontWeight="weight-500" variant="primary">
            {locales.checkDescription}
          </Text>

          <SunshineCard.Group>
            <SunshineCard
              id="device-whitelisted"
              isChecked={hasUnderstoodOtherDeviceWhitelisted}
              onChange={toggleHasUnderstoodOtherDeviceWhitelisted}
              title={locales.deviceWhitelisted}
              variant="checkbox"
            />
            <SunshineCard
              id="never-give-access"
              isChecked={hasUnderstoodNeverGiveAccess}
              onChange={toggleHasUnderstoodNeverGiveAccess}
              title={locales.neverGiveAccess}
              variant="checkbox"
            />
          </SunshineCard.Group>

          {error ? (
            <Callout marginTop="space-32" variant="error">
              <Callout.Title>{locales.error}</Callout.Title>
            </Callout>
          ) : null}
        </Stack>

        {shouldDisplayFakePhoneScamCallout ? (
          <Callout marginTop="space-32" variant="error">
            <Callout.Title>{locales.fakePhoneScamCalloutTitle}</Callout.Title>
            <Callout.Content>
              {locales.fakePhoneScamCalloutContent}
            </Callout.Content>
          </Callout>
        ) : null}
      </SunshineModal.Body>

      <SunshineModal.Footer>
        <Button
          isDisabled={loading}
          isLoading={refuseLoading}
          onClick={onRefuse}
          variant="inline-primary"
        >
          {locales.no}
        </Button>

        <Button
          isDisabled={!canConfirm || loading}
          isLoading={acceptLoading}
          onClick={onAccept}
        >
          {locales.yes}
        </Button>
      </SunshineModal.Footer>
    </>
  );
};

export default PendingStep;
