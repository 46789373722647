import { useContext } from 'react';
import { Image, Text, VStack } from '@chakra-ui/react';

import Heading from 'components/_core/Heading';
import { PageLayout } from 'features/Bento/components';
import { type SignupPage } from 'features/Bento/flows/signup/types/SignupPage';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

import { GuidanceReferral } from '../../../../components/Guidance';
import { model } from '../../machine';
import { type Intro } from '../../machine/machine';
import Mail from './assets/Mail@2x.png';
import locales from './locales';

/**
 * A page where we ask the user for basic personal informations.
 */
const ReferralGuide: SignupPage<Intro> = ({ send }) => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout
      actions={<PageLayout.Actions onBack={() => send(model.events.PREV())} />}
      guidance={<GuidanceReferral />}
    >
      <Image
        boxSize="180px"
        marginBottom="space-24"
        marginX="auto"
        src={Mail}
      />

      <Heading marginBottom="space-24" size="lg">
        {locales.title}
      </Heading>

      <VStack marginBottom="space-16" spacing="space-16">
        {locales.body.map((str) => (
          <Text key={str}>{str}</Text>
        ))}
      </VStack>
    </Layout>
  );
};

export default ReferralGuide;
