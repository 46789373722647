import { type FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Center, Image } from '@chakra-ui/react';

import coneImg from 'assets/brand/cone.png';
import { companyContextStorage } from 'common/hooks/useCompanyContext/companyContextStorage';
import Card from 'components/_core/Card';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';
import Loader from 'components/Loader';
import Login from 'components/Login';
import RedeemInvitation from 'components/Login/RedeemInvitation';
import { getInvitationInfo } from 'helpers/auth/service';
import { type InvitationInfo } from 'types/invitation';

import locales from './locales';

interface HandleInvitationProps {
  match: {
    params: {
      slug: string;
    };
  };
}

const ALREADY_REDEEMED = 'Invitation already redeemed';

const HandleInvitation: FC<HandleInvitationProps> = ({
  match: {
    params: { slug },
  },
}) => {
  const [loading, setLoading] = useState(true);
  const [isInvitationAlreadyRedeemed, setIsInvitationAlreadyRedeemed] =
    useState(false);
  const [error, setError] = useState<{ title: string; message: string } | null>(
    null,
  );
  const [invitationInfo, setInvitationInfo] = useState<InvitationInfo | null>(
    null,
  );
  const history = useHistory();

  useEffect(() => {
    // In the case of a connected user who goes to a REDEEMED invitation link,
    // clearing the companyProfileId will redirect him/her to the account selection if
    // multiple accounts is linked to this user.
    if (isInvitationAlreadyRedeemed) {
      companyContextStorage.clear();
      history.replace('/');
    }

    getInvitationInfo(slug)
      .then(({ data }) => {
        setInvitationInfo(data);
      })
      .catch((err) => {
        if (err) {
          if (err.status === 400 && err.message === ALREADY_REDEEMED) {
            setIsInvitationAlreadyRedeemed(true);
          }
          if (err.status === 429) {
            setError({
              message: locales.tooManyRequests.description,
              title: locales.tooManyRequests.title,
            });
          } else if (err.status === 404) {
            setError({
              message: locales.restrictedAccess.description,
              title: locales.restrictedAccess.title,
            });
          } else {
            setError({
              message: locales.genericError.description,
              title: locales.genericError.title,
            });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [history, isInvitationAlreadyRedeemed, slug]);

  if (loading) {
    return (
      <Center height="100%">
        <Loader />
      </Center>
    );
  }

  if (!invitationInfo && error) {
    return (
      <Center height="100%">
        <Card alignItems="center" maxWidth="25rem" size="lg" textAlign="center">
          <Image
            alt=""
            boxSize="11rem"
            marginBottom="space-24"
            role="presentation"
            src={coneImg}
          />

          <Heading marginBottom="space-16" size="lg">
            {error.title}
          </Heading>

          <Text>{error.message}</Text>
        </Card>
      </Center>
    );
  }

  return (
    <Login invitationInfo={invitationInfo} invitationSlug={slug}>
      <RedeemInvitation invitationSlug={slug} />
    </Login>
  );
};

export default HandleInvitation;
