import * as Types from '../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetAgreementDataQueryVariables = Types.Exact<{
  companyProfileId: Types.Scalars['UUIDv4']['input'];
  agreementType: Types.Scalars['String']['input'];
}>;


export type GetAgreementDataQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', uid: string, company: { __typename: 'Company', companyProfileId: string }, outstandingAgreements: Array<{ __typename: 'OutstandingAgreement', agreementId: string, pdfDocumentUrl: string | null, status: Types.OutstandingAgreementStatus, type: Types.OutstandingAgreementType, updatedAt: string }> } };


export const GetAgreementDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAgreementData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUIDv4"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"agreementType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyProfileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"outstandingAgreements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyProfileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"agreementType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"agreementId"}},{"kind":"Field","name":{"kind":"Name","value":"pdfDocumentUrl"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode<GetAgreementDataQuery, GetAgreementDataQueryVariables>;