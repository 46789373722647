import { type FC } from 'react';
import { MenuList, type MenuListProps } from '@chakra-ui/react';

import { useStyles } from '../../context';

const List: FC<MenuListProps> = (props) => {
  const styles = useStyles();

  return <MenuList {...props} __css={styles.list} />;
};

export default List;
