import { OutstandingAgreementType } from '__generated__/GQL';
import getIsAgreementCompleted from 'common/bento/modules/Agreement/libs/getIsAgreementCompleted';

import { type Context } from './model';

const guards = {
  hasSignedAgreement: ({ getModuleData }: Context) => {
    const { outstandingAgreements } = getModuleData().viewer;

    return getIsAgreementCompleted({
      agreementType: OutstandingAgreementType.Cgu,
      outstandingAgreements,
    });
  },
};

export default guards;
