import { cssVar, type SystemStyleObject } from '@chakra-ui/theme-tools';

const $bg = cssVar('tooltip-bg');
const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle: SystemStyleObject = {
  [$bg.variable]: 'colors.gray.700',
  background: [$bg.reference],
  borderRadius: 'radius-2',
  boxShadow: 'shadow-small-1',
  [$arrowBg.variable]: [$bg.reference],
  color: 'whiteAlpha.900',
  fontSize: 'font-14',
  fontWeight: 'weight-500',
  maxWidth: '320px',
  paddingX: 'space-8',
  paddingY: 'space-4',
  zIndex: 'tooltip',
};

const TooltipTheme = {
  baseStyle,
};

export default TooltipTheme;
