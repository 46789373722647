import { type PropsWithChildren } from 'react';
import {
  chakra,
  forwardRef,
  type HTMLChakraProps,
  useMenuButton,
} from '@chakra-ui/react';

import { useStyles } from '../../context';

type TriggerProps = PropsWithChildren<HTMLChakraProps<'div'>>;

const Trigger = forwardRef<TriggerProps, 'div'>((props, ref) => {
  const buttonProps = useMenuButton(props, ref);
  const styles = useStyles();

  return (
    <chakra.div
      __css={styles.button}
      {...buttonProps}
      onClick={(e) => {
        buttonProps.onClick(e);
        e.preventDefault();
      }}
    />
  );
});

export default Trigger;
