import { KYC_DOCUMENTS_TYPES } from '@shinetools/kyc-validation';

import { KycProvider, type KycReviewDocumentType } from '__generated__/GQL';
import { type MachineServices } from 'common/bento/types/MachineConfig';
import { apolloClient } from 'common/graphql/clients';
import { UserContextDocument } from 'features/Bento/graphql/user-context.gql';

import { FinishTeamIdentityVerificationDocument } from '../pages/IdCheck/graphql/mutations/finishIdentityVerification.gql';
import { type Model } from './model';

export enum Service {
  CreateKycDocumentsFromProvider = 'createKycDocumentsFromProvider',
}

const services: MachineServices<Model, Service> = {
  createKycDocumentsFromProvider: ({ getModuleData }, event) => {
    if (event.type !== 'SUCCESS') {
      throw new Error(`event.type is "${event.type}", but should be "SUCCESS"`);
    }

    const { companyProfileId, companyUserId } = getModuleData();
    const documents = event.data;

    return apolloClient.mutate({
      awaitRefetchQueries: true,
      mutation: FinishTeamIdentityVerificationDocument,
      refetchQueries: [
        {
          query: UserContextDocument,
          variables: {
            companyProfileId,
            documents,
            kycDocumentTypes: KYC_DOCUMENTS_TYPES,
          },
        },
      ],
      variables: {
        input: {
          companyUserId,
          provider: KycProvider.Onfido,
          providerDocuments: documents.map((doc) => ({
            providerDocumentId: doc.providerDocumentId,
            type: doc.type as KycReviewDocumentType,
          })),
        },
      },
    });
  },
};

export default services;
export type Services = typeof services;
