import { publicApolloClient } from 'common/graphql/clients';

import { VerifyReferralCodeDocument } from '../graphql/mutations/verify-referral-code.gql';

/**
 * A function querying the Shine API endpoint to verify if a referral is valid and retrieve its data.
 */
export const verifyReferral = async (code: string) => {
  const { data } = await publicApolloClient.mutate({
    mutation: VerifyReferralCodeDocument,
    variables: {
      input: {
        referralCode: code,
      },
    },
  });

  return data;
};
