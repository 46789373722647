import { forwardRef, MenuItem, type MenuItemProps } from '@chakra-ui/react';

import { CircleIcon } from 'features/Bento/atoms';
import { type ShineIcon } from 'features/Bento/atoms/Icon/Icon';

interface OBMenuItemProps extends MenuItemProps {
  iconName: ShineIcon;
  label: string;
}

const OBMenuItem = forwardRef<OBMenuItemProps, 'button'>(
  ({ iconName, label, ...rest }, ref) => (
    <MenuItem
      _focus={{
        background: 'grey.200',
      }}
      _hover={{
        background: 'grey.200',
      }}
      icon={
        <CircleIcon background="grey.200" color="grey.700" name={iconName} />
      }
      key={label}
      paddingY="space-12"
      ref={ref}
      {...rest}
    >
      {label}
    </MenuItem>
  ),
);

export default OBMenuItem;
