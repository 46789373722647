import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    next: 'Next',
  },
  fr: {
    next: 'Suivant',
  },
} as const);

export default locales;
