import { type Theme } from 'theme';

export const getOnfidoTheme = (theme: Theme) => ({
  borderRadiusButton: theme.radii['radius-8'],
  colorBackgroundButtonPrimary: theme.colors.grey['800'],
  colorBackgroundButtonPrimaryActive: theme.colors.grey['700'],
  colorBackgroundButtonPrimaryHover: theme.colors.grey['700'],
  colorBackgroundLinkHover: theme.colors.transparent,
  colorBackgroundSurfaceModal: theme.colors.white,
  colorBorderButtonPrimary: theme.colors.transparent,
  colorBorderButtonPrimaryActive: theme.colors.grey['700'],
  colorBorderButtonPrimaryHover: theme.colors.grey['700'],
  colorBorderDocTypeButtonActive: theme.colors.grey['800'],
  colorBorderDocTypeButtonHover: theme.colors.grey['800'],
  colorBorderLinkUnderline: theme.colors.grey['800'],
  colorBorderSurfaceModal: theme.colors.grey['300'],
  colorContentButtonPrimaryText: theme.colors.white,
  colorContentButtonSecondaryText: theme.colors.grey['800'],
  colorContentLinkTextHover: theme.colors.grey['800'],
  colorIcon: theme.colors.grey['800'],
  colorInputOutline: theme.colors.grey['800'],
  fontFamilyBody: theme.fonts.body,
  fontFamilyTitle: theme.fonts.heading,
  fontWeightSubtitle: 500,
  fontWeightTitle: 500,
});
