import {
  forwardRef,
  type ForwardRefRenderFunction,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, Link, SimpleGrid, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { type UserProfile } from '__generated__/GQL';
import { BentoLayoutSize } from 'common/bento/lib/layout';
import useLayoutSizeQuery from 'common/bento/lib/layout/useLayoutSizeQuery';
import * as Form from 'components/_core/form';
import IconButton from 'components/_core/IconButton';
import SunshineIcon from 'components/_core/SunshineIcon';

import AddressSearchInput from '../AddressSearchInput';
import Header from '../Header';
import SelectOnlyFrance from '../SelectOnlyFrance';
import StickyButton from '../StickyButton/StickyButton';
import addressSchema, { type AddressSchema } from './addressSchema';
import locales from './locales';

export interface AddressFormElement {
  readonly isEditing: boolean;
  reset: () => void;
  submit: () => void;
}

interface AddressFormProps {
  addressOverlayClose: () => void;
  initialValues:
    | AddressSchema
    | Pick<
        UserProfile,
        'city' | 'streetAddition' | 'street' | 'zip' | 'country'
      >;

  isOverlayOpen: boolean;
  isSending: boolean;
  locales: {
    title: {
      default: string;
      fill: string;
      verify: string;
    };
    continueCta?: string;
    description?: string;
    streetLabel?: string;
  };
  noSubmitButton?: boolean;
  onContinue: (payload: AddressSchema) => void;
  onOverlayOpen: () => void;
  onOverlayClose: () => void;
}

const AddressForm: ForwardRefRenderFunction<
  AddressFormElement,
  AddressFormProps
> = (
  {
    addressOverlayClose,
    initialValues,
    isOverlayOpen,
    isSending,
    locales: propsLocales,
    noSubmitButton,
    onContinue,
    onOverlayClose,
    onOverlayOpen,
  },
  ref,
) => {
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setFocus,
    setValue,
  } = useForm({
    defaultValues: {
      city: initialValues.city ?? '',
      street: initialValues.street ?? '',
      streetAddition: initialValues.streetAddition ?? '',
      zip: initialValues.zip ?? '',
    },
    mode: initialValues.street ? 'onChange' : 'onSubmit',
    resolver: zodResolver(addressSchema),
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const isDesktop = useLayoutSizeQuery(BentoLayoutSize.md);

  const [hasSelectedAddress, setHasSelectedAddress] = useState(false);
  const [hasAddressComplement, setHasAddressComplement] = useState(
    Boolean(initialValues.streetAddition),
  );
  const [isManualMode, setIsManualMode] = useState(false);

  const isVerifying =
    hasSelectedAddress || initialValues.street || isManualMode;

  const onSubmit = handleSubmit(onContinue);

  const getTitle = () => {
    if (isManualMode) {
      return propsLocales.title.fill;
    }
    if (isVerifying) {
      return propsLocales.title.verify;
    }
    return propsLocales.title.default;
  };

  useImperativeHandle(ref, () => ({
    get isEditing() {
      return isManualMode || hasSelectedAddress;
    },
    reset: () => {
      setValue('street', '');
      setValue('streetAddition', '');
      setValue('city', '');
      setValue('zip', '');
      setHasAddressComplement(false);
      setHasSelectedAddress(false);
      setIsManualMode(false);
      reset();
    },
    submit: onSubmit,
  }));

  useLayoutEffect(() => {
    // Focus the streetAddition field when you just clicked on the add button
    // We can't do the focus in the callback of the button because the input is not displayed yet
    if (hasAddressComplement && !getValues('streetAddition')) {
      setFocus('streetAddition');
    }
  }, [hasAddressComplement, setFocus, getValues]);

  return (
    <Box ref={containerRef}>
      <Flex
        align="stretch"
        as="form"
        data-testid="personal-address-form"
        direction="column"
        display={isOverlayOpen ? 'none' : undefined}
        noValidate
        onSubmit={isVerifying ? onSubmit : (e) => e.preventDefault()}
      >
        <Header description={propsLocales.description} title={getTitle()} />

        <VStack align="stretch" spacing="space-24">
          <Box>
            <Form.Field
              error={errors.street?.message}
              label={propsLocales.streetLabel || locales.inputs.street.label}
            >
              {isManualMode ? (
                <Form.Input
                  {...register('street')}
                  data-testid="street-input"
                  required
                />
              ) : (
                <AddressSearchInput
                  {...register('street')}
                  allowManualMode
                  data-testid="street-input"
                  isDesktop={isDesktop}
                  isOverlayOpen={isOverlayOpen}
                  isRequired
                  label={
                    propsLocales.streetLabel || locales.inputs.street.label
                  }
                  onOverlayClose={onOverlayClose}
                  onOverlayOpen={onOverlayOpen}
                  onSelectAddress={({ city, street, streetAddition, zip }) => {
                    reset();
                    setValue('street', street);
                    setValue('city', city);
                    setValue('zip', zip);
                    setValue('streetAddition', streetAddition ?? '');
                    setHasAddressComplement(Boolean(streetAddition));
                    setHasSelectedAddress(true);
                  }}
                  onSelectedManualMode={() => {
                    reset();
                    setIsManualMode(true);
                    setValue('street', '');
                    setValue('streetAddition', '');
                    setValue('city', '');
                    setValue('zip', '');
                    addressOverlayClose();
                  }}
                  overlayContainerRef={containerRef}
                  placeholder={
                    isVerifying ? undefined : locales.inputs.street.placeholder
                  }
                  searchPlaceholder={locales.inputs.street.placeholder}
                />
              )}
            </Form.Field>
            {isVerifying ? (
              <>
                <Form.Field
                  display={hasAddressComplement ? undefined : 'none'}
                  error={errors.streetAddition?.message}
                  label={locales.inputs.streetAddition.label}
                  marginTop="space-24"
                >
                  <Flex alignItems="center" gap="space-8">
                    <Form.Input
                      {...register('streetAddition')}
                      data-testid="streetAddition-input"
                      placeholder={locales.inputs.streetAddition.placeholder}
                    />
                    <IconButton
                      aria-label={locales.clear}
                      icon="trash"
                      onClick={() => {
                        setValue('streetAddition', '');
                        setHasAddressComplement(false);
                      }}
                      variant="inline-secondary"
                    />
                  </Flex>
                </Form.Field>
                <Link
                  alignItems="center"
                  display={hasAddressComplement ? 'none' : undefined}
                  flex="row"
                  marginTop="space-16"
                  onClick={() => setHasAddressComplement(true)}
                >
                  <SunshineIcon
                    marginRight="space-12"
                    name="plus"
                    size="icon-13"
                  />
                  {locales.addStreetAddition}
                </Link>
              </>
            ) : null}
          </Box>

          {isVerifying ? (
            <>
              <SimpleGrid columns={2} spacing="space-16">
                <Form.Field
                  error={errors.zip?.message}
                  label={locales.inputs.zip.label}
                >
                  <Form.Input
                    {...register('zip')}
                    data-testid="zip-input"
                    inputMode="numeric"
                    maxLength={5}
                    required
                  />
                </Form.Field>
                <Form.Field
                  error={errors.city?.message}
                  label={locales.cityLabel}
                >
                  <Form.Input
                    {...register('city')}
                    data-testid="city-input"
                    required
                  />
                </Form.Field>
              </SimpleGrid>

              <SelectOnlyFrance />
            </>
          ) : null}
        </VStack>
        {noSubmitButton ? null : (
          <StickyButton
            isLoading={isSending}
            justifyContent="left"
            type="submit"
          >
            {propsLocales?.continueCta ?? locales.continueCta}
          </StickyButton>
        )}
      </Flex>
    </Box>
  );
};

export default forwardRef(AddressForm);
