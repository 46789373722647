import { type MouseEventHandler } from 'react';
import { Image } from '@chakra-ui/react';
import { type IconName } from '@shinetools/sunshine-icons';

import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';

export interface CompanyItemProps {
  avatarUrl: string;
  disabled?: boolean;
  title: string;
  subtitle: string;
  logoSize?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  icon?: IconName;
}

const CompanyListItem = ({
  avatarUrl,
  disabled = false,
  icon,
  logoSize = 40,
  subtitle,
  title,
  ...props
}: CompanyItemProps) => (
  <SunshineCard isDisabled={disabled} padding="space-16" {...props}>
    <SunshineCard.Slot name="aside">
      <Image
        alt="Company avatar"
        height={logoSize}
        src={avatarUrl}
        width={logoSize}
      />
    </SunshineCard.Slot>

    <SunshineCard.Content>
      <Text fontWeight="weight-500" variant="primary">
        {title}
      </Text>
      <Text variant="light">{subtitle}</Text>
    </SunshineCard.Content>

    {icon ? (
      <SunshineCard.Slot name="action">
        <SunshineCard.SunshineIcon name={icon} />
      </SunshineCard.Slot>
    ) : null}
  </SunshineCard>
);

export default CompanyListItem;
