import { type FC, useCallback } from 'react';
import { Avatar, HStack, type MenuProps } from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/utils';

import SunshineDropdown from 'components/_core/SunshineDropdown';
import SunshineIcon from 'components/_core/SunshineIcon';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import Text from 'components/_core/Text';
import locales from 'components/UserDropdownMenu/locales';
import { logEvent } from 'features/Analytics/analytics';

export interface UserDropdownMenuProps extends Pick<MenuProps, 'children'> {
  firstName: string | null;
  avatarSrc: string | undefined;
  trackingName: string;
}

const UserDropdownMenu: FC<UserDropdownMenuProps> & {
  Item: typeof SunshineDropdown.Item;
} = ({ avatarSrc, children, firstName, trackingName }) => {
  const trackEvent = useCallback(
    (menuState: boolean) => {
      logEvent({ name: `${menuState ? 'Open' : 'Close'}_${trackingName}` });
    },
    [trackingName],
  );

  return (
    <SunshineDropdown
      offset={[0, -16]}
      onClose={() => trackEvent(false)}
      onOpen={() => trackEvent(true)}
    >
      {(renderProps) => (
        <>
          <SunshineDropdown.Trigger>
            <HStack
              as="button"
              gap={0}
              sx={{
                '& .chakra-avatar__initials': {
                  position: 'relative',
                  // Initials have a slight offset with the Mabry Pro font,
                  // so the offset makes it perfectly aligned
                  top: 1,
                },
              }}
            >
              {firstName ? (
                <Avatar
                  backgroundColor="grey.200"
                  borderRadius="radius-10"
                  boxSize="space-32"
                  color="grey.800"
                  fontWeight="weight-500"
                  src={avatarSrc}
                />
              ) : (
                <SquircleIcon boxSize="2rem" name="profile" />
              )}
              <Text
                fontWeight="weight-500"
                marginLeft="space-8"
                marginRight="space-6"
                variant="secondary"
              >
                {firstName || locales.genericLabel}
              </Text>
              <SunshineIcon
                color="grey.800"
                name={renderProps.isOpen ? 'chevron-up' : 'chevron-down'}
              />
            </HStack>
          </SunshineDropdown.Trigger>

          <SunshineDropdown.List marginTop="space-32">
            {runIfFn(children, renderProps)}
          </SunshineDropdown.List>
        </>
      )}
    </SunshineDropdown>
  );
};

UserDropdownMenu.Item = SunshineDropdown.Item;

export default UserDropdownMenu;
