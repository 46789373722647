import { type FC } from 'react';
import { type BoxProps } from '@chakra-ui/react';

import MotionBox from 'components/MotionBox';

const layoutVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { delayChildren: 0.12 },
  },
};

/**
 * An area container for the main content
 */
const PageLayoutBody: FC<BoxProps> = ({ children, ...props }) => (
  <MotionBox
    animate="show"
    initial="hidden"
    overflow="auto"
    sx={{
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      'scrollbar-width': 'none' /* Firefox */,
    }}
    variants={layoutVariants}
    {...props}
  >
    {children}
  </MotionBox>
);

export default PageLayoutBody;
