import ReactIcomoon from 'react-icomoon';
import {
  chakra,
  type ComponentWithAs,
  type IconProps as ChakraIconProps,
} from '@chakra-ui/react';
import { iconSet } from '@shinetools/suntheme';

import { type ArrayElement } from 'features/Bento/types/helpers';

import type iconsList from './utils/iconsList';

export type ShineIcon = ArrayElement<typeof iconsList>;

/**
 * Thanks to the `chakra` factory function,
 * this component now accepts Chakra style props.
 */
const ChakraIcomoon = chakra(ReactIcomoon);

export type IconProps = ChakraIconProps & { name: ShineIcon };

/**
 * @deprecated use _core/SunshineIcon instead
 *
 * An Icon component accepting all Chakra IconProps and a `name` prop
 */
const Icon: ComponentWithAs<'svg', IconProps> = ({ name, ...props }) => (
  <ChakraIcomoon boxSize="1em" icon={name} iconSet={iconSet} {...props} />
);

export default Icon;
