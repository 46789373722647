import { type FC } from 'react';

import receiptsImg from 'assets/brand/receipt@2x.png';

import Container from '../../../../layouts/FeaturesLayout/components/Container';
import Description from '../../../../layouts/FeaturesLayout/components/Description';
import Illustration from '../../../../layouts/FeaturesLayout/components/Illustration';
import Title from '../../../../layouts/FeaturesLayout/components/Title';
import locales from './locales';

const Receipts: FC = () => (
  <Container>
    <Illustration src={receiptsImg} />
    <Title>{locales.title}</Title>
    <Description>{locales.description}</Description>
  </Container>
);

export default Receipts;
