import { OutstandingAgreementType } from '__generated__/GQL';
import getIsAgreementCompleted from 'common/bento/modules/Agreement/libs/getIsAgreementCompleted';
import { type MachineGuards } from 'common/bento/types/MachineConfig';
import getIdentityStatus from 'features/TeamOnboarding/Onboarding/modules/AdminIdentity/libs/getIdentityStatus';
import { VerificationStatus } from 'features/TeamOnboarding/Onboarding/modules/Verification/lib/steps/types';

import getIsCompanyUserKycSent from '../../../lib/getIsCompanyUserKycSent';
import { type Model } from './model';

export enum Guard {
  HasSignedCguAgreement = 'hasSignedAgreement',
  HasStartedApplication = 'hasStartedApplication',
  IsApplicationSent = 'isApplicationSent',
}

const guards: MachineGuards<Model, Guard> = {
  hasSignedAgreement: ({ getModuleData }) => {
    const { outstandingAgreements } = getModuleData().data.viewer;

    return getIsAgreementCompleted({
      agreementType: OutstandingAgreementType.Cgu,
      outstandingAgreements,
    });
  },

  hasStartedApplication: (context) => {
    const { companyUser } = context.getModuleData().data.viewer;

    return getIdentityStatus({ companyUser }) !== VerificationStatus.ToDo;
  },

  isApplicationSent: (context) =>
    getIsCompanyUserKycSent(context.getModuleData().data.viewer),
};

export default guards;
