import { type FC, type ReactNode } from 'react';
import { type CenterProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

import ErrorBoundary from 'components/ErrorBoundary';
import { ErrorPageType } from 'components/ErrorPage/types';
import UserMenu from 'features/Sidebar/UserMenu';
import {
  ALL_USER_MENU_ITEMS,
  UserMenuItem,
} from 'features/Sidebar/UserMenu/types';
import ErrorLayout from 'layouts/ErrorLayout';

interface ErrorPageProps extends CenterProps {
  children: ReactNode;
  type: ErrorPageType;
}

const EmptyView: FC = () => null;

const disableBrowsingFor: Record<ErrorPageType, Set<UserMenuItem>> = {
  [ErrorPageType.Generic]: new Set([]),
  [ErrorPageType.UserRevoked]: new Set([
    UserMenuItem.Profile,
    UserMenuItem.BankInfo,
    UserMenuItem.Company,
    UserMenuItem.RecentActivities,
    UserMenuItem.Security,
  ]),
  [ErrorPageType.UserKycRefused]: ALL_USER_MENU_ITEMS,
};

export const ErrorPage: FC<ErrorPageProps> = ({ children, type, ...props }) => {
  return (
    <ErrorLayout {...props}>
      <ErrorBoundary errorView={EmptyView}>
        <Box left="space-24" position="fixed" top="space-32" zIndex="modal">
          <UserMenu
            disableBrowsingFor={disableBrowsingFor[type]}
            forceNavigationReload
          />
        </Box>
      </ErrorBoundary>
      {children}
    </ErrorLayout>
  );
};
