import {
  Circle,
  forwardRef,
  type SquareProps,
  type ThemeTypings,
} from '@chakra-ui/react';
import { type IconName } from '@shinetools/sunshine-icons';

import SunshineIcon from '../SunshineIcon';

export interface IconCircleProps extends SquareProps {
  color: ThemeTypings['colors'];
  backgroundColor: ThemeTypings['colors'];
  name: IconName;
  size: NonNullable<SquareProps['size']>;
}

const IconCircle = forwardRef<IconCircleProps, 'div'>(
  ({ name, ...props }, ref) => (
    <Circle {...props} ref={ref}>
      <SunshineIcon name={name} size="icon-18" />
    </Circle>
  ),
);

export default IconCircle;
