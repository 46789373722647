import LocalizedStrings from 'localized-strings';

const errorMessages = new LocalizedStrings({
  en: {
    toast: {
      definitiveStep: `You can’t go back at this step - contact our support if you think you’ve made a mistake.`,
      unknownError: 'Oops! There was an unknown error',
    },
  },

  fr: {
    toast: {
      definitiveStep: `Il n’est plus possible de revenir sur vos déclarations précédentes.\nContactez notre support si vous pensez avoir fait une erreur.`,
      unknownError: 'Oups! Une erreur inconnue est survenue',
    },
  },
} as const);

export default errorMessages;
