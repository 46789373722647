import { type FC } from 'react';
import { Flex, type FlexProps } from '@chakra-ui/react';

import Button from 'components/_core/Button';
import { IconButton } from 'features/Bento/atoms';

import locales from './locales';

type Actions = {
  onBack?: () => void;
};

/**
 * An area container for the (top)bar/nav/actions content
 */
const PageLayoutActions: FC<Actions & FlexProps> = ({
  children,
  onBack,
  ...props
}) => {
  /**
   * Display an icon button when breakpoint is sm
   * and display a button when breakpoint is md or more
   * using the css display: none value
   */
  const renderBackButton = () => (
    <>
      <Button
        display={{ base: 'none', md: 'inline-flex' }}
        icon="arrow-left"
        iconPosition="left"
        onClick={onBack}
        size="sm"
        variant="tertiary"
      >
        {locales.back}
      </Button>

      <IconButton display={{ md: 'none' }} name="arrow-left" onClick={onBack} />
    </>
  );

  return (
    <Flex justifyContent="space-between" {...props}>
      {onBack ? renderBackButton() : null}

      {children}
    </Flex>
  );
};

export default PageLayoutActions;
