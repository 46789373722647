import { Box } from '@chakra-ui/react';
import { type PublicStepConfig, type PublicStepTypes } from 'onfido-sdk-ui';

import useGetOnfidoInit from 'common/hooks/onfido/useGetOnfidoInit';
import useGetOnfidoToken from 'common/hooks/onfido/useGetOnfidoToken';
import { useUserPhone } from 'common/hooks/useUserPhone';
import Loader from 'components/Loader';
import OnfidoDocumentVerifier from 'components/OnfidoDocumentVerifier';
import { type KycDocument } from 'components/OnfidoDocumentVerifier/types';
import { throwIfErrorPresent } from 'features/TeamOnboarding/Onboarding/utils';
import { getOnfidoTheme } from 'helpers/onfido';
import useTheme from 'theme/hooks/useTheme';

import { PROVIDER_CONTAINER_ID } from '../..';

interface IdCheckProps {
  fitContainer?: boolean;
  steps: Array<PublicStepTypes | PublicStepConfig>;
  onSuccess: (documents: KycDocument[]) => void;
}

const IdCheck = ({ fitContainer = false, onSuccess, steps }: IdCheckProps) => {
  const { onfidoInit } = useGetOnfidoInit();
  const { phone } = useUserPhone();
  const theme = useTheme();
  const { error: tokenError, onfidoToken } = useGetOnfidoToken();

  throwIfErrorPresent(tokenError);

  if (!onfidoToken) {
    return <Loader />;
  }

  return (
    <Box>
      <Box
        sx={{
          '.onfido-sdk-ui-crossDevice-CrossDeviceLink-qrCodeContainer svg': {
            marginX: 'auto',
          },

          '.onfido-sdk-ui-Modal-inner': fitContainer
            ? {
                /** Onfido adds height: 37.5em which is not enough with FR locales */
                height: '42.5em',

                /** Onfido adds width: 94w which breaks the layout on desktop */
                width: 'auto',
              }
            : {},

          /**
           * Onfido relies on the description being shown even with the `hidden` attribute.
           * But Chakra UI's CSSReset rightfully try to hide it.
           */
          'ul[hidden]': {
            display: 'block !important',
          },
        }}
      >
        {onfidoInit !== null ? (
          <OnfidoDocumentVerifier
            containerId={PROVIDER_CONTAINER_ID}
            customUI={getOnfidoTheme(theme)}
            onComplete={onSuccess}
            onfidoInit={onfidoInit}
            steps={steps}
            token={onfidoToken}
            userSmsNumber={phone || undefined}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default IdCheck;
