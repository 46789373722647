import { type MutableRefObject, useCallback, useEffect } from 'react';

type ClickOutsideEventHandler = (event: MouseEvent | TouchEvent) => void;

// Src: https://usehooks.com/useOnClickOutside/
function useOnClickOutside<Element extends HTMLElement>(
  ref: MutableRefObject<Element | null>,
  handler: ClickOutsideEventHandler,
) {
  const listener: ClickOutsideEventHandler = useCallback(
    (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !ref.current ||
        (event.target instanceof HTMLElement &&
          ref.current.contains(event.target))
      ) {
        return;
      }
      handler(event);
    },
    [ref, handler],
  );
  useEffect(() => {
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [listener]);
}

export default useOnClickOutside;
