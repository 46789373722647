import * as Types from '../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ConfirmAuthenticationActionRequestMutationVariables = Types.Exact<{
  input: Types.ConfirmAuthenticationActionRequestInput;
}>;


export type ConfirmAuthenticationActionRequestMutation = { __typename: 'Mutation', confirmAuthenticationActionRequest: { __typename: 'ConfirmAuthenticationActionRequestOutput', accessToken: string | null, authenticationActionRequestId: string, deviceToken: string | null, passcode: string | null, phone: string | null } };


export const ConfirmAuthenticationActionRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ConfirmAuthenticationActionRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ConfirmAuthenticationActionRequestInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"confirmAuthenticationActionRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationActionRequestId"}},{"kind":"Field","name":{"kind":"Name","value":"deviceToken"}},{"kind":"Field","name":{"kind":"Name","value":"passcode"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}}]}}]}}]} as unknown as DocumentNode<ConfirmAuthenticationActionRequestMutation, ConfirmAuthenticationActionRequestMutationVariables>;