import { Image } from '@chakra-ui/react';

import { CardProductId } from '__generated__/GQL';
import locales from 'common/paymentCards/locales';
import { getCardAssetSrc } from 'common/paymentCards/utils';
import {
  BASIC_CARD_NAME,
  PREMIUM_CARD_NAME,
} from 'helpers/paymentCards/constants';

import { type Request } from '../types';
import RequestDetailsRow from './RequestDetailsRow';

const formatCreditCardPan = (pan: string) => `•••• ${pan.slice(-4)}`;

interface Props {
  creditCard: Request['creditCard'];
}

const getCardLabel = (creditCard: Props['creditCard']) => {
  if (!creditCard.isPhysical) {
    if (creditCard.cardProductId === CardProductId.Budget) {
      return locales.virtualBudgetCard;
    }

    return locales.virtualCard;
  }

  return `Mastercard ${
    creditCard.isPremium ? PREMIUM_CARD_NAME : BASIC_CARD_NAME
  }`;
};

const CardInfo = ({ creditCard }: Props) => {
  return (
    <RequestDetailsRow
      description={
        creditCard.__typename === 'CreditCardPendingOrder'
          ? undefined
          : formatCreditCardPan(creditCard.maskedPan)
      }
      label={getCardLabel(creditCard)}
      leftComponent={
        <Image
          paddingX="space-4"
          src={getCardAssetSrc(creditCard)}
          width="space-40"
        />
      }
    />
  );
};

export default CardInfo;
