import * as Types from '../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GrantAuthenticationClientMutationVariables = Types.Exact<{
  input: Types.GrantAuthenticationClientInput;
}>;


export type GrantAuthenticationClientMutation = { __typename: 'Mutation', grantAuthenticationClient: { __typename: 'GrantAuthenticationClientOutput', redirectTo: string } };


export const GrantAuthenticationClientDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GrantAuthenticationClient"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GrantAuthenticationClientInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"grantAuthenticationClient"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"redirectTo"}}]}}]}}]} as unknown as DocumentNode<GrantAuthenticationClientMutation, GrantAuthenticationClientMutationVariables>;