import { type Context } from '../../../machine/model';

/**
 * This function returns a XState action that, when executed with a given path,
 * use the history provided in context to navigate to the path.
 */
const navigate = (path: string) => ({
  exec: (context: Context) => {
    context.helpers.getHistory().push(path);
  },
  type: 'navigate',
});

export default navigate;
