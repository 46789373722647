import {
  Flex,
  forwardRef,
  ModalHeader,
  type ModalHeaderProps,
} from '@chakra-ui/react';

import IconButton from 'components/_core/IconButton';

import { useStyles } from '../../context';
import { useModalContext } from '../../hooks/useModalContext';
import locales from './locales';

const Header = forwardRef<ModalHeaderProps, 'div'>(
  ({ children, ...props }, ref) => {
    const { isClosable, onClose } = useModalContext();
    const styles = useStyles();

    return (
      <ModalHeader
        display="flex"
        justifyContent="flex-end"
        ref={ref}
        {...props}
      >
        <Flex align="center" justify="space-between">
          {children}

          {isClosable ? (
            /**
             * we have to use a custom `IconButton` component
             * because `ModalCloseButton` does not accept a custom icon
             */
            <IconButton
              __css={styles.closeButton}
              aria-label={locales.label}
              icon="cross"
              onClick={onClose}
            />
          ) : null}
        </Flex>
      </ModalHeader>
    );
  },
);

export default Header;
