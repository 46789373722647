import { type FC } from 'react';
import { chakra } from '@chakra-ui/react';

import Button, { type ButtonProps } from '../../../Button';
import { useCard } from '../../hooks/useCard';

const CardButton: FC<ButtonProps> = (props) => {
  const { isDisabled } = useCard();

  return (
    <chakra.div paddingTop="space-4">
      <Button variant="inline-primary" {...props} isDisabled={isDisabled} />
    </chakra.div>
  );
};

export default CardButton;
