import { type ActionObject } from 'xstate';

import {
  type AnyContext,
  type AnyMachineEvent,
} from 'common/bento/types/Abstract';
import { logEvent } from 'features/Analytics/analytics';
import { getUser } from 'helpers/auth';
import logger from 'helpers/logger';

import stringifyState from '../../lib/stringifyState';
import {
  buildOnboardingPageViewedProperties,
  stateToPascalCase,
} from './utils';

interface TrackViewOptions {
  properties?: JSONObject;
}

/**
 * Given a TrackedEvent,
 * this function returns an XState action that tracks the current state.
 */
const trackView: (
  options?: TrackViewOptions,
) => ActionObject<AnyContext, AnyMachineEvent> = (options = {}) => ({
  exec: (_context, _event, meta) => {
    const moduleName = meta.state.configuration.find(
      ({ order }) => order === 0,
    )?.id;

    const viewName = stateToPascalCase(
      `${moduleName}-${stringifyState(meta.state)}`,
    );

    const environment = import.meta.env.VITE_API_ENV;
    const user = getUser();
    const name = `Onboarding Page ${viewName} Viewed`;
    const properties = buildOnboardingPageViewedProperties(environment, user);

    logEvent({
      name,
      properties: { ...options.properties, ...properties },
      usePrefix: false,
    });

    if (environment !== 'production') {
      logger.info(`ℹ️ TRACKED VIEW: ${name}`);
    }
  },
  type: 'trackView',
});

export default trackView;
