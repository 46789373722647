import { createToast } from 'features/Bento/atoms';
import getErrorMessage from 'features/Bento/libs/errors/getErrorMessage';
import { OBError } from 'features/Bento/libs/errors/OBError';
import logger from 'helpers/logger';

import { type SharedAction } from '.';

/**
 * This function returns a XState action that, when executed with an `onError` event,
 * - call busgnag with the error,
 * - displays a relevant error message to the user.
 */
const error: SharedAction = () => ({
  exec: (context, event) => {
    const err = event.data;

    logger.error(err, {
      tags: { feature: 'bento' },
      ...(err instanceof OBError &&
        err.localizedMessage && {
          context: {
            message: err.localizedMessage,
          },
        }),
    });

    if (err instanceof Error) {
      createToast({
        status: 'error',
        title: getErrorMessage(err),
      });
    }
  },
  type: 'error',
});

export default error;
