import { type FC } from 'react';
import { useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Spacer,
  Text,
  type ThemeTypings,
  VStack,
} from '@chakra-ui/react';

import { CompanyUserStatus } from '__generated__/GQL';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import Logo from 'components/Logo';
import Help from 'features/Help/components/HelpButton';

import BackButton from '../BackButton';
import CloseButton from '../CloseButton';
import Menu from './components/Menu';
import { TeamOnboardingNavigationDocument } from './graphql/queries/navigation.gql';
import locales from './locales';
import { type NavigationProps } from './Navigation';
import { type Navigation, NavigationStepStatus } from './NavigationContext';

interface NavigationDesktopProps extends NavigationProps {
  navigation: Navigation;
}

const NavigationDesktop: FC<NavigationDesktopProps> = ({
  navigation,
  onClose,
  onPrev,
  ...props
}) => {
  const { data } = useQuery(TeamOnboardingNavigationDocument);

  const getStepNumberVariant = (stepStatus: NavigationStepStatus) => {
    const map: Record<
      NavigationStepStatus,
      ThemeTypings['components']['Text']['variants']
    > = {
      DONE: 'disabled',
      ONGOING: 'primary',
      TODO: 'light',
    };

    return map[stepStatus];
  };

  const getStepTitleVariant = (stepStatus: NavigationStepStatus) => {
    const map: Record<
      NavigationStepStatus,
      ThemeTypings['components']['Text']['variants']
    > = {
      DONE: 'disabled',
      ONGOING: 'secondary',
      TODO: 'disabled',
    };

    return map[stepStatus];
  };

  const { onboardingHotline } = data ?? {};

  return (
    <HStack align="flex-start" height="full" justify="space-between">
      <Flex align="start" direction="column" height="full" {...props}>
        {data ? (
          <Menu
            firstName={data.viewer.profile.firstName ?? null}
            isMandatee={data.viewer.metadata.isMandatee}
            isMultiAccount={
              data.viewer.companyUsers.filter(
                (cu) => cu.status !== CompanyUserStatus.Locked,
              ).length > 1
            }
            lastName={data.viewer.profile.lastName ?? null}
          />
        ) : null}

        <VStack align="stretch" spacing="space-32">
          {navigation.steps.map((step, index) => (
            <Box key={step.title}>
              <Text
                fontWeight="weight-500"
                marginBottom="space-8"
                variant={getStepNumberVariant(step.status)}
              >
                {locales.formatString(locales.step, { stepNumber: index + 1 })}
              </Text>

              <Text
                variant={getStepTitleVariant(step.status)}
                {...(step.status === NavigationStepStatus.Done && {
                  textDecoration: 'line-through',
                })}
              >
                {step.title}
              </Text>

              {step.slot}
            </Box>
          ))}
        </VStack>

        <Spacer />

        {onboardingHotline ? (
          <>
            <HStack alignItems="flex-start" spacing="space-16">
              {onboardingHotline.isAvailable ? (
                <SquircleIcon colorScheme="green" name="phone" />
              ) : (
                <SquircleIcon colorScheme="gray" name="phone-offline" />
              )}

              <Box>
                <Text variant="primary">{onboardingHotline.phoneNumber}</Text>
                <Text fontSize="font-13" variant="secondary">
                  {onboardingHotline.description}
                </Text>
                <Text fontSize="font-13" variant="light">
                  {onboardingHotline.availability}
                </Text>
              </Box>
            </HStack>

            <Divider marginY="space-24" />
          </>
        ) : null}

        <HStack
          alignSelf="stretch"
          justifyContent="space-between"
          spacing="space-32"
        >
          <Logo />

          <Help alignment="left" />
        </HStack>
      </Flex>

      {onClose ? (
        <Box paddingTop="0">
          <CloseButton onClick={onClose} />
        </Box>
      ) : null}

      {onPrev && !onClose ? (
        <Box paddingTop="0">
          <BackButton onClick={onPrev} />
        </Box>
      ) : null}
    </HStack>
  );
};

export default NavigationDesktop;
