import { createElement, type FC } from 'react';

export const formatText = (text: string, options?: { renderStrong?: FC }) =>
  text.split('**').map((subText, idx) => {
    // If the string contains '**' tags, the split should make all
    // the strong ones end up on odd positions.
    // Ex: '0 **1** 0 **1** 0' => [0, 1, 0, 1, 0]
    if (idx % 2 === 0) {
      return subText;
    }

    return createElement(
      options?.renderStrong || 'strong',
      {
        key: `${idx}-${subText}`,
      },
      subText,
    );
  });
