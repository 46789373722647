import { createModel } from 'xstate/lib/model';

import baseEvents from 'common/bento/config/baseEvents';
import { type ModuleDataWithBootstrap } from 'common/bento/lib/bootstrap/types';
import { type CompanyContextType } from 'common/hooks/useCompanyContext/types';

import { type TeamOnboardingVerificationModuleDataQuery } from '../graphql/queries/teamOnboardingVerificationModuleData.gql';

export interface VerificationModuleData extends ModuleDataWithBootstrap {
  companyContext: CompanyContextType;
  verificationData: TeamOnboardingVerificationModuleDataQuery;
}

const initialContext = {
  getModuleData: () => {
    throw new Error('NOT_IMPLEMENTED');
  },
} as { getModuleData: () => VerificationModuleData };

export type Context = typeof initialContext;

const model = createModel(
  initialContext,

  {
    events: {
      ...baseEvents,
      GO_ADDRESS: () => ({}),
      GO_IDENTITY: () => ({}),
    },
  },
);

export default model;

export type Model = typeof model;
