import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    'bank:transfers:create': {
      title:
        '{displayName} wants to make a transfer of {amount} to {recipientName}',
    },
    close: 'Close',
    confirm: 'Confirm the operation',
    confirmed: 'The request has been confirmed',
    description:
      'If you initiated this operation, you can confirm it with your passcode',
    expired: 'The request has expired',
    expiry: 'The request will expire {expiry}',
    reject: 'Reject this operation',
    rejected: 'The request has been rejected',
    updateDescription:
      'To confirm this operation, you need to download the latest version of the application first.',
    updateTitle: 'App update required',
  },
  fr: {
    'bank:transfers:create': {
      title:
        '{displayName} souhaite initier un virement de {amount} à {recipientName}',
    },
    close: 'Fermer',
    confirm: 'Confirmer l’opération',
    confirmed: 'La demande a été confirmée',
    description:
      'Si vous êtes bien à l’origine de cette opération, vous pouvez la confirmer tout simplement avec votre code secret.',
    expired: 'La demande a expiré',
    expiry: 'La demande expira {expiry}',
    reject: 'Refuser l’opération',
    rejected: 'La demande a été refusée',
    updateDescription:
      'Pour confirmer cette opération, vous devez d’abord mettre à jour votre application.',
    updateTitle: 'Mise à jour de l’application requise',
  },
} as const);

export default locales;
