import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { Box, Select, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { differenceInYears, isValid as isValidDate, parseISO } from 'date-fns';
import { z } from 'zod';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import useCountriesList from 'common/countries/useCountriesList';
import { Gender } from 'common/types';
import zodLocales from 'common/zod/locales';
import Button from 'components/_core/Button';
import * as Form from 'components/_core/form';
import HelpAndTermsLinks from 'components/HelpAndTermsLinks';
import DatePicker from 'components/LegacyInput/DatePicker';
import { Footer } from 'components/Login/LoginForm/styles';
import Logo from 'components/Logo';
import { FRANCE_COUNTRY_CODE } from 'features/Bento/libs/constants';
import Content from 'features/TeamOnboarding/Onboarding/components/Content';
import AuthentificationLayout from 'layouts/AuthentificationLayout';

import { type ModuleData } from '../../machine/model';
import CustomDateInput from './components/CustomDateInput';
import locales from './locales';

const LEGAL_AGE = 18;

const formSchema = z.object({
  birthdate: z
    .string()
    .min(1, zodLocales.errors.required)
    .refine((value) => isValidDate(parseISO(value)), locales.errors.invalidDate)
    .refine(
      (value) => differenceInYears(new Date(), new Date(value)) >= LEGAL_AGE,
      locales.errors.isNotAdult,
    ),
  firstName: z.string().min(1, zodLocales.errors.required),
  gender: z.nativeEnum(Gender),
  lastName: z.string().min(1, zodLocales.errors.required),
  nationality: z.string().min(1, zodLocales.errors.required).length(2),
});

type FormSchema = z.infer<typeof formSchema>;

interface PersonalInformationProps {
  moduleData: ModuleData;
  isLoading: boolean;
  onNext: SubmitHandler<FormSchema & { companyProfileId: string }>;
}

const PersonalInformation = asBentoPage<PersonalInformationProps>(
  ({ isLoading, moduleData, onNext }) => {
    const { companyProfileId, invitation, profile } = moduleData;

    const {
      control,
      formState: { errors, isSubmitting, isValid },
      handleSubmit,
      register,
    } = useForm<FormSchema>({
      defaultValues: {
        firstName: invitation?.inviteeFirstName || profile.firstName,
        gender: profile.gender as Gender,
        lastName: invitation?.inviteeLastName || profile.lastName,
        nationality: profile.nationality || FRANCE_COUNTRY_CODE,
      },
      mode: 'onChange',
      resolver: zodResolver(formSchema),
    });

    const onSubmit = handleSubmit((values) => {
      onNext({
        companyProfileId,
        ...values,
      });
    });

    const countriesOptions = useCountriesList().map(
      ({ emoji, label, value }) => (
        <option key={value} value={value}>
          {`${label} ${emoji}`}
        </option>
      ),
    );

    return (
      <AuthentificationLayout invitationInfo={{ role: invitation.role }}>
        <Box flex="0 0 20%">
          <a href="https://shine.fr" rel="noopener noreferrer" target="_blank">
            <Logo />
          </a>
        </Box>

        <Stack as="form" onSubmit={onSubmit} spacing="space-24">
          <Content textAlign="left" title={locales.title} />

          <Form.Field
            error={errors.gender?.message}
            label={locales.labels.legalTitle}
          >
            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, value } }) => (
                <Form.Radio.Group
                  direction="row"
                  onChange={onChange}
                  radioPosition="right"
                  value={value ?? undefined}
                  variant="outline"
                >
                  <Form.Radio value={Gender.FEMALE}>
                    <Form.Radio.Title>
                      {locales.labels.gender[Gender.FEMALE]}
                    </Form.Radio.Title>
                  </Form.Radio>

                  <Form.Radio value={Gender.MALE}>
                    <Form.Radio.Title>
                      {locales.labels.gender[Gender.MALE]}
                    </Form.Radio.Title>
                  </Form.Radio>
                </Form.Radio.Group>
              )}
            />
          </Form.Field>

          <Form.Field
            error={errors.firstName?.message}
            label={locales.labels.firstName}
          >
            <Form.Input {...register('firstName')} />
          </Form.Field>

          <Form.Field
            error={errors.lastName?.message}
            label={locales.labels.lastName}
          >
            <Form.Input textTransform="uppercase" {...register('lastName')} />
          </Form.Field>

          <Form.Field
            error={errors.birthdate?.message}
            label={locales.labels.birthdate}
          >
            <Controller
              control={control}
              name="birthdate"
              render={({ field: { name, onChange, value } }) => {
                // TODO(COP-1167): to replace with new calendar once implemented
                return (
                  <DatePicker
                    customInput={<CustomDateInput />}
                    id={name}
                    onValueChange={onChange}
                    placeholderText={locales.placeholders.birthdate}
                    value={value || ''}
                  />
                );
              }}
            />
          </Form.Field>

          <Form.Field label={locales.labels.nationality}>
            <Select {...register('nationality')}>{countriesOptions}</Select>
          </Form.Field>

          <Button
            alignSelf="flex-start"
            isDisabled={!isValid}
            isLoading={isSubmitting || isLoading}
            type="submit"
          >
            {locales.continue}
          </Button>
        </Stack>

        <Box flexGrow={1} />

        <Footer>
          <HelpAndTermsLinks />
        </Footer>
      </AuthentificationLayout>
    );
  },
);

export default PersonalInformation;
