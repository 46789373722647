import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    rateLimitError: 'Too many attempts. Try again in a hour',
    unknownError: 'Oops! There was an unknown error',
    wrongPasscode: 'Oops! Wrong passcode',
  },
  fr: {
    rateLimitError:
      'Vous avez dépassé le nombre d’essais autorisés. Vous pourrez réessayer dans une heure',
    unknownError: 'Oups\u00A0! Une erreur inconnue est survenue',
    wrongPasscode: 'Oups\u00A0! Votre code secret semble incorrect',
  },
} as const);
