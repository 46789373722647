import { type CompanyUser } from '__generated__/GQL';

/**
 * Returns true if a company user can edit their personnal information
 * For now, it will return false only if the company user is a mandatee
 * and they can start a new onboarding, meaning one of their application has been validated once
 */
const canEditPersonalInformation = ({
  companyUser,
  isNewOnboardingAllowed,
}: {
  isNewOnboardingAllowed: boolean;
  companyUser: Pick<CompanyUser, 'isMandatee'> | null;
}): boolean => {
  // If the company user is null, it means they're in a non mandate flow
  if (companyUser === null) {
    return true;
  }

  return companyUser.isMandatee === false || isNewOnboardingAllowed === false;
};

export default canEditPersonalInformation;
