import { type ArrayElement } from 'features/Bento/types/helpers';
import logger from 'helpers/logger';

/**
 * Use this function to narrow a XState event from an enum of possible events to:
 * - a single event (if you provided a single string representing the expected event type).
 * - a narrower enum of events (if you provided an array of expected event types).
 *
 * @note arrow functions are not allowed for TS assertion functions.
 *
 * @example // Assert that `event.type` is 'SUBMIT_INFO', and narrow the event.data payload accordingly.
 * assertEventType(event, 'SUBMIT_INFO')
 *
 * @example // Assert that `event.type` is either 'SUBMIT_INFO' or 'SUBMIT_MAIL', and narrow the event.data payload accordingly.
 * assertEventType(event, ['SUBMIT_INFO', 'SUBMIT_MAIL'])
 *
 * @warning this function does not throw in production if the event doesn't match.
 * In the unlikely case where a human error would ship to production,
 * throwing in an action is most often the worst & the most blocking for the user, even if continuing may lead to unexpected behaviour.
 */
function assertEventType<
  TEvent extends { type: string },
  TExpectedType extends TEvent['type'] | Array<TEvent['type']>,
>(
  event: TEvent,
  type: TExpectedType,
): asserts event is TEvent & {
  type: TExpectedType extends string
    ? TExpectedType
    : ArrayElement<TExpectedType>;
} {
  /**
   * If you provided an array of event types, ensure the actual event type is one of them.
   */
  if (Array.isArray(type)) {
    if (!type.includes(event.type)) {
      const error = `Expected an event in '${type.join(',')}' and received '${
        event.type
      }'`;

      logger.error(error);

      if (import.meta.env.MODE === 'development') {
        throw new Error(error);
      }
    }

    return;
  }

  /**
   * If you provided a single expected type, ensure the actual type is matching it.
   */
  if (event.type !== type) {
    const error = `Expected event '${type}' and received '${event.type}'`;

    logger.error(error);

    if (import.meta.env.MODE === 'development') {
      throw new Error(error);
    }
  }
}

export default assertEventType;
