import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    cancel: 'Cancel',
  },
  fr: {
    cancel: 'Annuler',
  },
} as const);

export default locales;
