import { type FC, useCallback, useEffect } from 'react';
import { Divider, Image } from '@chakra-ui/react';

import { AuthenticationDevicePlatform } from '__generated__/GQL';
import easyImg from 'assets/brand/easy@2x.png';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import Text from 'components/_core/Text';
import NewBadge from 'components/NewBadge';
import useToast from 'components/ToastProvider/useToast';
import { getIconName } from 'helpers/auth/devices';
import { useDevice } from 'helpers/device';

import NewDeviceLoginLayout from '../NewDeviceLoginLayout';
import locales from './locales';
import { type Props } from './types';

const ConnectionAccepted: FC<Props> = ({
  passcode,
  submitPasscode,
  submitPasscodeErrorMessage,
  submitPasscodeLoading,
}) => {
  const { showToast } = useToast();
  const continueLogin = useCallback(async () => {
    await submitPasscode(passcode);
  }, [submitPasscode, passcode]);

  useEffect(() => {
    if (submitPasscodeErrorMessage) {
      showToast({ message: submitPasscodeErrorMessage, type: 'error' });
    }
  }, [showToast, submitPasscodeErrorMessage]);

  const device = useDevice();
  // We do not have access to the authentication device here, build one with local data
  const localDeviceWithPlatform = {
    ...device,
    platform: AuthenticationDevicePlatform.Web,
  };
  const iconName = getIconName(localDeviceWithPlatform);

  return (
    <NewDeviceLoginLayout>
      <Image marginBottom="space-24" src={easyImg} width="144px" />

      <Heading marginBottom="space-16" size="lg">
        {locales.title}
      </Heading>

      <Text marginBottom="space-32">{locales.description}</Text>

      <SunshineCard width="100%">
        <SunshineCard.Slot name="aside">
          <SquircleIcon name={iconName} />
        </SunshineCard.Slot>

        <SunshineCard.Content>
          <SunshineCard.Title>
            {device.name || locales.unknownDevice}
          </SunshineCard.Title>
          <SunshineCard.Text>
            {device.model || locales.unknownDeviceType}
          </SunshineCard.Text>
        </SunshineCard.Content>

        <SunshineCard.Slot name="action">
          <NewBadge />
        </SunshineCard.Slot>
      </SunshineCard>

      <Divider marginTop="space-40" />

      <Button
        isExpanded
        isLoading={submitPasscodeLoading}
        marginTop="space-24"
        onClick={() => continueLogin()}
      >
        {locales.accessMyAccount}
      </Button>
    </NewDeviceLoginLayout>
  );
};

export default ConnectionAccepted;
