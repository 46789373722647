import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description: 'You can authorize this device using the link sent by email.',
    receiveAnEmail: 'Send me a verification email',
    successDescription:
      'Click on the link received by email to approve this new device.',
    successTitle: 'A verification email has been sent!',
    title: 'You don’t have access to an already connected device?',
  },
  fr: {
    description:
      'Vous pouvez autoriser cet appareil à se connecter à votre compte grâce à un lien envoyé par e-mail.',
    receiveAnEmail: 'Recevoir un e-mail de vérification',
    successDescription:
      'Cliquez sur le lien reçu par e-mail pour autoriser ce nouvel appareil.',
    successTitle: 'Un e-mail de vérification a bien été envoyé\u00A0!',
    title: 'Vous n’avez pas accès à un appareil déjà connecté\u00A0?',
  },
} as const);

export default locales;
