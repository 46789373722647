import { type ReactNode } from 'react';
import {
  AccordionItem,
  AccordionPanel,
  type ComponentWithAs,
} from '@chakra-ui/react';

import Button from './components/Button';

export interface ItemProps {
  title: ReactNode;
}

const Item: ComponentWithAs<'div', ItemProps> = ({
  children,
  title,
  ...props
}) => (
  <AccordionItem {...props}>
    {({ isExpanded }) => (
      <>
        <Button isExpanded={isExpanded}>{title}</Button>
        <AccordionPanel>{children}</AccordionPanel>
      </>
    )}
  </AccordionItem>
);

export default Item;
