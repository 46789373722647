/* eslint-disable react/display-name */

import {
  type OBLayout,
  type OBLayoutProps,
} from 'features/Bento/types/OBLayout';

import LayoutFull from './LayoutFull';

/**
 * Main layout with sub layout check depending on the viewport
 */
const withLayoutFull =
  (Layout: OBLayout) =>
  ({ fullpage, ...props }: OBLayoutProps) => {
    const Component = fullpage ? LayoutFull : Layout;

    return <Component {...props} />;
  };

export default withLayoutFull;
