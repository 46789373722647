import { forwardRef, useModalContext } from '@chakra-ui/react';

import HeaderIconButton, { type HeaderIconButtonProps } from '../IconButton';

const CloseButton = forwardRef<Omit<HeaderIconButtonProps, 'icon'>, 'button'>(
  ({ onClick, ...props }, ref) => {
    const { onClose } = useModalContext();

    return (
      <HeaderIconButton
        aria-label=""
        icon="cross"
        onClick={(event) => {
          event.stopPropagation();
          onClose();
          onClick?.(event);
        }}
        ref={ref}
        variant="secondary"
        {...props}
      />
    );
  },
);

export default CloseButton;
