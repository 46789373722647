import { type CompanyUser, CompanyUserReviewStatus } from '__generated__/GQL';

/**
 * Given the companyUser, return whether the KYC of this user is sent or not.
 * Note that this doesn’t include KYB, so it should be used only to check KYC.
 * This is useful for flows that rely only on KYC e.g. invited beneficiary or
 * invited account holder.
 */
const getIsCompanyUserKycSent = ({
  companyUser,
}: {
  companyUser: Pick<CompanyUser, 'reviewStatus'>;
}) => {
  return [
    CompanyUserReviewStatus.Prechecked,
    CompanyUserReviewStatus.SentForPrecheck,
    CompanyUserReviewStatus.SentToTreezor,
    CompanyUserReviewStatus.SentForPostcheck,
    CompanyUserReviewStatus.Validated,
  ].includes(companyUser.reviewStatus);
};

export default getIsCompanyUserKycSent;
