import { type FC } from 'react';
import { Center } from '@chakra-ui/react';

import Button, { type ButtonProps } from 'components/_core/Button';

/**
 * A preset for the primary CTAs at the bottom of most onboarding screens.
 */
const OBStickyButton: FC<ButtonProps> = ({
  justifyContent = 'center',
  ...buttonProps
}) => (
  <Center
    alignSelf="center"
    background={{
      base: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,.6) 80%, rgba(255,255,255,0) 100%)',
      md: 'none',
    }}
    bottom="0"
    justifyContent={justifyContent}
    marginTop="space-32"
    paddingY="space-24"
    position={{ base: 'sticky', md: 'initial' }}
    width="full"
  >
    <Button isExpanded {...buttonProps} />
  </Center>
);

export default OBStickyButton;
