import { type FC } from 'react';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useBreakpointValue } from '@chakra-ui/react';

import { useCurrentCompany } from 'common/hooks/useCurrentCompany';
import UserDropdownMenu from 'components/UserDropdownMenu';

import { type User } from '../../types';
import locales from './locales';

interface OnboardingMenuProps {
  user: Pick<User, 'firstName' | 'lastName'>;
}

const OnboardingMenu: FC<OnboardingMenuProps> = ({ user }) => {
  const { firstName } = user;
  const company = useCurrentCompany();
  const intercom = useIntercom();

  const isLarge = useBreakpointValue({ base: false, md: true });

  return (
    <UserDropdownMenu
      avatarSrc={company?.logoSrc}
      firstName={firstName}
      trackingName="Onboarding_Menu_Dropdown"
    >
      {!isLarge ? (
        <UserDropdownMenu.Item icon="help" onClick={intercom.show}>
          {locales.help}
        </UserDropdownMenu.Item>
      ) : null}
      <UserDropdownMenu.Item as={Link} icon="logout-left" to="/logout">
        {locales.logout}
      </UserDropdownMenu.Item>
    </UserDropdownMenu>
  );
};

export default OnboardingMenu;
