import { type FC, useState } from 'react';
import { Divider, Image } from '@chakra-ui/react';

import { AuthenticationDeviceRequestStatus } from '__generated__/GQL';
import shieldImg from 'assets/brand/shield@2x.png';
import Button from 'components/_core/Button';
import { useDevice } from 'helpers/device';

import ConnectionAcceptedComponent from '../ConnectionAccepted';
import ConnectionRefusedComponent from '../ConnectionRefused';
import NewDeviceLoginLayout from '../NewDeviceLoginLayout';
import NoAccessToDevice from '../NoAccessToDevice';
import RequestExpiredComponent from '../RequestExpired';
import useUpdatedAuthenticationDeviceRequest from './hooks/useUpdatedAuthenticationDeviceRequest';
import locales from './locales';
import { Description, Title } from './styles';
import { type Props } from './types';

const NewDeviceAuthorization: FC<Props> = ({
  authenticationDeviceId,
  authenticationDeviceRequestId,
  cancel,
  passcode,
  phone,
  submitPasscode,
  submitPasscodeErrorMessage,
  submitPasscodeLoading,
}) => {
  const [hasNoAccessToOtherDevices, setHasNoAccessToOtherDevices] =
    useState(false);
  const { token: deviceToken } = useDevice();

  const { data: subscriptionData } = useUpdatedAuthenticationDeviceRequest({
    deviceToken,
    phone,
  });
  const authenticationDeviceRequestStatus =
    subscriptionData?.updatedAuthenticationDeviceRequest.status;

  if (
    authenticationDeviceRequestStatus ===
    AuthenticationDeviceRequestStatus.Accepted
  ) {
    return (
      <ConnectionAcceptedComponent
        passcode={passcode}
        submitPasscode={submitPasscode}
        submitPasscodeErrorMessage={submitPasscodeErrorMessage}
        submitPasscodeLoading={submitPasscodeLoading}
      />
    );
  }

  if (
    authenticationDeviceRequestStatus ===
    AuthenticationDeviceRequestStatus.Refused
  ) {
    return <ConnectionRefusedComponent cancel={cancel} />;
  }

  if (
    authenticationDeviceRequestStatus ===
    AuthenticationDeviceRequestStatus.Expired
  ) {
    return (
      <RequestExpiredComponent
        cancel={cancel}
        passcode={passcode}
        submitPasscode={submitPasscode}
        submitPasscodeErrorMessage={submitPasscodeErrorMessage}
        submitPasscodeLoading={submitPasscodeLoading}
      />
    );
  }

  if (hasNoAccessToOtherDevices) {
    return (
      <NoAccessToDevice
        authenticationDeviceId={authenticationDeviceId}
        authenticationDeviceRequestId={authenticationDeviceRequestId}
        cancel={cancel}
        passcode={passcode}
        phone={phone}
      />
    );
  }

  return (
    <NewDeviceLoginLayout cancel={cancel}>
      <Image marginTop="space-8" src={shieldImg} width="140px" />
      <Title>{locales.connectionFromANewDevice}</Title>
      <Description>{locales.firstConnection}</Description>
      <Divider marginTop="space-32" />
      <Button
        marginTop="space-24"
        onClick={() => setHasNoAccessToOtherDevices(true)}
        variant="discreet"
      >
        {locales.noAccess}
      </Button>
    </NewDeviceLoginLayout>
  );
};

export default NewDeviceAuthorization;
