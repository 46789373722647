/* eslint-disable jsx-a11y/no-autofocus */

import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { range, update } from 'ramda';

import logger from 'helpers/logger';

import { PasscodeDigitInput, PasscodeInputContainer } from './styles';
import {
  type ClearAllDigitsFn,
  type DigitInputRefs,
  type NewDigitCallback,
  type PasscodeInputProps,
} from './types';

const NON_NUMERIC_REGEX = /\D/g;

// Compute the disabled state of a digit input
const getDisabled = (
  digit: string,
  position: number,
  digitValues: string[],
): boolean => {
  const lastIndex = digitValues.length - 1;
  switch (position) {
    case 0:
      // Disable the first digit input if it's filled
      return digit !== '';
    case lastIndex:
      // Disable the last digit if the previous one has not been filled
      return digitValues[position - 1] === '';
    default:
      // Disable digit inputs "in the "middle" if they have been filled
      // or if the previous digits have not.
      return digit !== '' || digitValues[position - 1] === '';
  }
};

const useFocusDigitAfterRender: (digitRefs: DigitInputRefs) => {
  focusDigitAfterRender: (position: number) => void;
} = (digitRefs) => {
  // In many instances below, it is necessary to only focus after next render
  // as at the point the callback is run, the next input element will still be
  // disabled until the new state values have been reconciled.
  const [digitToBeFocused, setDigitToBeFocused] = useState<number | null>(null);
  useEffect(() => {
    if (digitToBeFocused !== null) {
      const digitElementToFocus = digitRefs.current[digitToBeFocused];
      if (digitElementToFocus) {
        digitElementToFocus.focus();
      }
      setDigitToBeFocused(null);
    }
  }, [digitRefs, digitToBeFocused]);
  const focusDigitAfterRender = useCallback(
    (position: number) => {
      if (position > digitRefs.current.length - 1) {
        throw new Error(`Digit position ${position} invalid`);
      }
      setDigitToBeFocused(position);
    },
    [digitRefs],
  );
  return { focusDigitAfterRender };
};

const produceNewDigitValueCallbacks = (
  digits: Readonly<number[]>,
  digitValues: string[],
  setDigitValues: (value: string[]) => void,
  digitRefs: DigitInputRefs,
  enhancedOnSubmit: (passcode: string) => Promise<void>,
  focusDigitAfterRender: (position: number) => void,
): NewDigitCallback[] =>
  digits.map(
    (position): NewDigitCallback =>
      (newDigit): void => {
        const newDigitValues = update(position, newDigit, digitValues);
        setDigitValues(newDigitValues);
        if (newDigit !== '') {
          // If a digit has been added,
          if (position !== digits.length - 1) {
            // focus the next input field
            focusDigitAfterRender(position + 1);
          } else {
            // or blur if it's the last one.
            const currentDigit = digitRefs.current[position];
            if (currentDigit) {
              currentDigit.blur();
              enhancedOnSubmit(newDigitValues.join(''));
            }
          }
        } else {
          // If a digit has just been erased programatically, focus the input
          focusDigitAfterRender(position);
        }
      },
  );

const PasscodeInput: FC<PasscodeInputProps> = ({
  disabled = false,
  hidden = false,
  length = 4,
  onSubmit,
}) => {
  const digits: Readonly<number[]> = range(0, length);

  const [digitValues, setDigitValues] = useState<string[]>(
    digits.map(() => ''),
  );

  const digitRefs: DigitInputRefs = useRef(digits.map(() => null));

  const { focusDigitAfterRender } = useFocusDigitAfterRender(digitRefs);

  const clearAllDigits: ClearAllDigitsFn = useCallback(
    ({ andFocusFirstInput }): void => {
      // TODO(Achille/Matthew): This check should be removed
      // and the caller should instead make sure this method is not called
      // after the component has been unmounted
      if (digitRefs.current[0] !== null) {
        setDigitValues(digits.map(() => ''));
        if (andFocusFirstInput) {
          focusDigitAfterRender(0);
        }
      } else {
        logger.warn(
          'clearAllDigits was called after the component was unmounted. This is probably a bug',
        );
      }
    },
    [digits, focusDigitAfterRender],
  );

  const enhancedOnSubmit = useCallback(
    async (passcode: string): Promise<void> => {
      await onSubmit(passcode, clearAllDigits); // onSubmit is not necesarily a Promise
    },
    [onSubmit, clearAllDigits],
  );

  const newDigitValueCallbacks = useMemo(
    () =>
      produceNewDigitValueCallbacks(
        digits,
        digitValues,
        setDigitValues,
        digitRefs,
        enhancedOnSubmit,
        focusDigitAfterRender,
      ),
    [
      digits,
      digitValues,
      setDigitValues,
      digitRefs,
      enhancedOnSubmit,
      focusDigitAfterRender,
    ],
  );

  return (
    <PasscodeInputContainer data-testid="pin-input">
      {digits.map((position): React.ReactNode => {
        const key = `passcode-input-digit-${position}`;
        const digitValue = digitValues[position] as string;
        return (
          <PasscodeDigitInput
            autoFocus={position === 0}
            disabled={
              disabled || getDisabled(digitValue, position, digitValues)
            }
            hide={hidden}
            inputMode="decimal"
            key={key}
            length={length}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const cleanValue = event.target.value.replace(
                NON_NUMERIC_REGEX,
                '',
              );

              // Allow pasting the code when first input is focused
              if (position === 0 && cleanValue.length === digits.length) {
                setDigitValues(cleanValue.split(''));
                event.target.blur();
                enhancedOnSubmit(cleanValue);
              } else {
                const newDigit = cleanValue.charAt(0);
                // @ts-expect-error Cannot invoke an object which is possibly 'undefined'.
                newDigitValueCallbacks[position](newDigit);
              }
            }}
            onKeyDown={(event): void => {
              if (
                position !== 0 &&
                event.key === 'Backspace' &&
                digitValue === ''
              ) {
                // avoid the browser from catching the event which it would interpret as a back navigation
                event.preventDefault();
                event.stopPropagation();
                // If the user pressed the backspace in an empty digit input (not the first one),
                // erase the previous digit.
                // @ts-expect-error Cannot invoke an object which is possibly 'undefined'.
                newDigitValueCallbacks[position - 1]('');
              }
            }}
            ref={(element): void => {
              digitRefs.current[position] = element;
            }}
            value={digitValues[position]}
          />
        );
      })}
    </PasscodeInputContainer>
  );
};

export default PasscodeInput;
