import { type FC, useCallback, useEffect, useState } from 'react';
import { type SubscriptionHookOptions, useQuery } from '@apollo/client';
import { isBefore } from 'date-fns';
import { path } from 'ramda';

import useSubscription from 'common/hooks/useSubscription';
import PopIn from 'components/PopIn';
import logger from 'helpers/logger';

import ActionRequest from './ActionRequest';
import ActionRequestConfirmation from './ActionRequestConfirmation';
import ActionRequestPasscode from './ActionRequestPasscode';
import { ActionRequestDocument } from './graphql/queries/actionRequest.gql';
import { ActionRequestsDocument } from './graphql/queries/actionRequests.gql';
import {
  OnUpdatedActionRequestDocument,
  type OnUpdatedActionRequestSubscription,
} from './graphql/subscriptions/onUpdatedActionRequest.gql';
import { Container, HighContainer, Overlay } from './styles';
import { type ActionRequestType } from './types';

const updateActionRequestsCache: SubscriptionHookOptions<OnUpdatedActionRequestSubscription>['onData'] =
  ({ client, data: { data } }) => {
    if (!data) {
      return;
    }
    const { updatedActionRequest } = data;
    // Refetch pending list query
    client
      .query({
        fetchPolicy: 'network-only',
        query: ActionRequestsDocument,
      })
      .catch((err) => logger.error(err));
    // Refetch query by authenticationActionRequestId
    client
      .query({
        fetchPolicy: 'network-only',
        query: ActionRequestDocument,
        variables: {
          authenticationActionRequestId:
            updatedActionRequest.authenticationActionRequestId,
        },
      })
      .catch((err) => logger.error(err));
  };

const getComponent = (step?: string) => {
  switch (step) {
    case 'passcode':
      return ActionRequestPasscode;
    case 'confirmation':
      return ActionRequestConfirmation;
    default:
      return ActionRequest;
  }
};

const ActionRequests: FC = () => {
  useSubscription(OnUpdatedActionRequestDocument, updateActionRequestsCache);
  const { data } = useQuery(ActionRequestsDocument);
  const [step, setStep] = useState('');
  const [actionRequest, setActionRequest] = useState<ActionRequestType | null>(
    null,
  );
  const lastActionRequest = path<ActionRequestType>(
    ['viewer', 'actionRequests', 0],
    data,
  );
  useEffect(
    () => {
      if (
        lastActionRequest &&
        lastActionRequest.status === 'PENDING' &&
        isBefore(new Date(), new Date(lastActionRequest.expiresAt)) &&
        (!actionRequest ||
          actionRequest.authenticationActionRequestId !==
            lastActionRequest.authenticationActionRequestId)
      ) {
        setActionRequest(lastActionRequest);
        setStep('');
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [setStep, lastActionRequest, setActionRequest],
  );
  const close = useCallback(() => {
    setActionRequest(null);
  }, [setActionRequest]);
  if (!actionRequest) {
    return null;
  }
  const Component = getComponent(step);
  const ContainerComponent = step ? Container : HighContainer;
  return (
    <PopIn>
      <Overlay>
        <ContainerComponent>
          <Component
            actionRequest={actionRequest}
            close={close}
            setStep={setStep}
          />
        </ContainerComponent>
      </Overlay>
    </PopIn>
  );
};

export default ActionRequests;
