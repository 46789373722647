import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { addSeconds, differenceInSeconds, subSeconds } from 'date-fns';

import {
  GetPendingCardPaymentStrongAuthenticationRequestsDocument,
  type GetPendingCardPaymentStrongAuthenticationRequestsQuery,
} from '../graphql/queries/getPendingCardPaymentStrongAuthenticationRequests.gql';
import { NewOrUpdatedCardPaymentStrongAuthenticationRequestDocument } from '../graphql/subscriptions/newOrUpdatedCardPaymentStrongAuthenticationRequest.gql';

// We need to adjust both the authentication request date and the expiration
// date of the request to avoid issues with devices that don't have their time
// correctly synchronized.
const adjustRequestDates = (
  request: GetPendingCardPaymentStrongAuthenticationRequestsQuery['cardPaymentStrongAuthenticationRequests'][number],
) => {
  const adjustedExpiresAt = addSeconds(
    new Date(),
    request.secondsLeftBeforeExpiration,
  );

  const requestDurationInSeconds = differenceInSeconds(
    new Date(request.expiresAt),
    new Date(request.authenticationRequestedAt),
  );
  const adjustedAuthenticationRequestedAt = subSeconds(
    new Date(),
    requestDurationInSeconds - request.secondsLeftBeforeExpiration,
  );

  return {
    ...request,
    authenticationRequestedAt: adjustedAuthenticationRequestedAt.toISOString(),
    expiresAt: adjustedExpiresAt.toISOString(),
  };
};

const useCardPaymentStrongAuthenticationRequest = () => {
  const {
    data: queryData,
    loading,
    refetch,
    subscribeToMore,
  } = useQuery(GetPendingCardPaymentStrongAuthenticationRequestsDocument, {
    notifyOnNetworkStatusChange: true,
  });

  const current = queryData?.cardPaymentStrongAuthenticationRequests[0];

  useEffect(() => {
    return subscribeToMore({
      document: NewOrUpdatedCardPaymentStrongAuthenticationRequestDocument,
      updateQuery: (previous, { subscriptionData }) => {
        const [currentRequest] =
          previous.cardPaymentStrongAuthenticationRequests ?? [];

        const { cardPaymentStrongAuthenticationRequest: updatedRequest } =
          subscriptionData.data;

        // If the updated entity is the current one we merge the two
        if (
          updatedRequest.cardPaymentStrongAuthenticationRequestId ===
          currentRequest?.cardPaymentStrongAuthenticationRequestId
        ) {
          return {
            ...previous,
            cardPaymentStrongAuthenticationRequests: [
              {
                ...currentRequest,
                ...updatedRequest,
              },
            ],
          };
        }

        // if we aren't already handling a request we refetch
        if (!currentRequest) {
          refetch();
        }

        return previous;
      },
    });
  }, [refetch, subscribeToMore]);

  return {
    loading,
    refetch: () => refetch(),
    request: current && adjustRequestDates(current),
  };
};

export default useCardPaymentStrongAuthenticationRequest;
