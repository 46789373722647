import { type FC } from 'react';

import ErrorCard from 'components/ErrorCard';
import { ErrorPage } from 'components/ErrorPage';
import { ErrorPageType } from 'components/ErrorPage/types';
import TrackView from 'features/Analytics/TrackView';

const ErrorView: FC = () => (
  <ErrorPage type={ErrorPageType.Generic}>
    <TrackView pageName="ErrorView" />
    <ErrorCard retry={() => window.location.reload()} />
  </ErrorPage>
);

export default ErrorView;
