import {
  Accordion as ChakraAccordion,
  type AccordionProps,
  type ComponentWithAs,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import Item from './components/Item';
import Link from './components/Link';
import Text from './components/Text';
import { COMPONENT_NAME, StylesProvider } from './context';

export interface AccordionParts {
  Item: typeof Item;
  Text: typeof Text;
  Link: typeof Link;
}

type AccordionType = ComponentWithAs<'div', AccordionProps> & AccordionParts;

const Accordion: AccordionType = ({ children, ...props }) => {
  const styles = useMultiStyleConfig(COMPONENT_NAME);

  return (
    <ChakraAccordion allowToggle {...props}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </ChakraAccordion>
  );
};

Accordion.Item = Item;
Accordion.Text = Text;
Accordion.Link = Link;

export default Accordion;
