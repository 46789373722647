import { type SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  marginX: 'auto',
  maxWidth: '60rem',
  width: '100%', // 906px
};

const ContainerTheme = {
  baseStyle,
};

export default ContainerTheme;
