import { type FC } from 'react';
import { Box, Divider } from '@chakra-ui/react';

import Button from 'components/_core/Button';
import Card from 'components/_core/Card';

interface OnboardingContainerProps {
  children: React.ReactNode;
  ctaText: string;
  onClick: () => void;
  ctaIsLoading?: boolean;
}

const OnboardingContainer: FC<OnboardingContainerProps> = ({
  children,
  ctaIsLoading,
  ctaText,
  onClick,
  ...props
}) => {
  return (
    <Box marginTop="space-56" marginX="auto" maxWidth="37.5rem" {...props}>
      <Card size="lg">
        {children}

        <Box marginBottom="space-24" width="100%">
          <Box marginBottom="space-24" width="100%">
            <Box marginX="-space-24">
              <Divider />
            </Box>
          </Box>
        </Box>

        <Button
          alignSelf="center"
          isExpanded
          isLoading={ctaIsLoading}
          onClick={onClick}
        >
          {ctaText}
        </Button>
      </Card>
    </Box>
  );
};

export default OnboardingContainer;
