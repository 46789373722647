import { type SystemStyleFunction } from '@chakra-ui/theme-tools';

import InputTheme from './Input';

const baseStyle: SystemStyleFunction = (props) => ({
  ...InputTheme.baseStyle(props).field,
  borderRadius: 'radius-6',
  lineHeight: 'short',
  minHeight: '3.5rem',
  paddingX: 'space-8',
  paddingY: 'space-12',
  verticalAlign: 'top',
});

const defaultProps = {
  size: 'md',
  variant: 'outline',
};

const TextareaTheme = {
  baseStyle,
  defaultProps,
};

export default TextareaTheme;
