import { type FC } from 'react';
import { Box, Stack, type StackProps } from '@chakra-ui/react';

import { Icon } from '../../atoms';
import { getStarDecomposition } from './utils';

/**
 * Display `max` stars and fill some of them with a color.
 * Used to display a rating, typically the stars of an app store.
 */
const Ratings: FC<StackProps & { value: number; max?: number }> = ({
  max = 5,
  value,
  ...props
}) => {
  const { fullStarsCount, lastStarPolygon } = getStarDecomposition(value, max);

  return (
    <Stack direction="row" spacing="space-4" {...props}>
      {Array(max)
        .fill('')
        .map((_, i) => (
          <Box boxSize="1em" key={i} position="relative">
            {i >= fullStarsCount ? (
              <Icon
                color="grey.500"
                left={0}
                name="star"
                position="absolute"
                top={0}
              />
            ) : null}

            {i <= fullStarsCount ? (
              <Icon
                color="grey.800"
                css={
                  i === fullStarsCount && {
                    clipPath: lastStarPolygon,
                  }
                }
                left={0}
                name="star"
                position="absolute"
                top={0}
              />
            ) : null}
          </Box>
        ))}
    </Stack>
  );
};

export default Ratings;
