import { gql, useSubscription } from '@apollo/client';

import {
  type UpdatedAuthenticationDeviceRequestPayload,
  type UpdatedAuthenticationDeviceRequestVariables,
} from './types';

export const UPDATED_AUTHENTICATION_DEVICE_REQUEST_SUBSCRIPTION_NAME =
  'updatedAuthenticationDeviceRequest';

/**
 * This subscription is meant to be used in the "public schema" **ONLY**.
 * We cannot use codegen since it is on the public schema != the authenticated one
 */
const UPDATED_AUTHENTICATION_DEVICE_REQUEST_SUBSCRIPTION = gql`
 subscription ${UPDATED_AUTHENTICATION_DEVICE_REQUEST_SUBSCRIPTION_NAME} (
   $deviceToken: ID!
   $phone: ID!
 ) {
   updatedAuthenticationDeviceRequest(phone: $phone, deviceToken: $deviceToken) {
     phone
     authenticationDeviceId
     authenticationDeviceRequestId
     status
     updatedAt
   }
 }
`;

const useUpdatedAuthenticationDeviceRequest = (
  variables: UpdatedAuthenticationDeviceRequestVariables,
) => {
  return useSubscription<
    UpdatedAuthenticationDeviceRequestPayload,
    UpdatedAuthenticationDeviceRequestVariables
  >(UPDATED_AUTHENTICATION_DEVICE_REQUEST_SUBSCRIPTION, {
    variables,
  });
};

export default useUpdatedAuthenticationDeviceRequest;
