import { type ApolloError, useQuery } from '@apollo/client';

import { UserPhoneQueryDocument } from './userPhone.gql';

interface UserPhoneResult {
  phone?: string | null;
  loading: boolean;
  error?: ApolloError;
}

export const useUserPhone = (): UserPhoneResult => {
  const { data, error, loading } = useQuery(UserPhoneQueryDocument);
  const phone = data?.viewer?.profile?.phone;
  return { error, loading, phone };
};
