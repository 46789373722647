import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    errors: {
      customRequiredFields: {
        birthCity: 'The birth city is required',
        birthCountry: 'The birth country is required',
        birthdate: 'The birthdate is required',
        birthDepartment: 'The birth department is required',
        email: 'The email address is required',
        firstName: 'The first name is required',
        gender: 'Please choose your legal title',
        lastName: 'The last name is required',
        nationality: 'The nationality is required',
      },
      dateIsInvalid: 'This date seems invalid',
      default: 'This field is invalid',
      email: 'This email seems invalid',
      invalidYear:
        'Invalid date of birth. Age cannot exceed 120 years. Please enter a valid year.',
      required: 'This field is required',
      shouldAgreeWithTermsOfService:
        'You should agree with the terms of service',
      shouldBeAdult: 'You must be 18 or older to open a Shine account',
    },
  },
  fr: {
    errors: {
      customRequiredFields: {
        birthCity: 'La ville de naissance est requise',
        birthCountry: 'Le pays de naissance est requis',
        birthdate: 'La date de naissance est requise',
        birthDepartment: 'Le département de naissance est requis',
        email: 'L’adresse e-mail est requise',
        firstName: 'Le prénom est requis',
        gender: 'Veuillez choisir votre civilité',
        lastName: 'Le nom de famille est requis',
        nationality: 'La nationalité est requise',
      },
      dateIsInvalid: 'Cette date semble invalide',
      default: 'Ce champ est invalide',
      email: 'Cette adresse e-mail semble invalide',
      invalidYear: `Date de naissance non valide. L'âge ne peut pas dépasser 120 ans. Veuillez saisir une année valide.`,
      required: 'Ce champ est requis',
      shouldAgreeWithTermsOfService:
        'Vous devez accepter les conditions générales d’utilisation',
      shouldBeAdult: 'Vous devez être majeur·e pour ouvrir un compte Shine',
    },
  },
} as const);
