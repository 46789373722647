import { forwardRef, useStyleConfig } from '@chakra-ui/system';
import { mergeWith } from '@chakra-ui/utils';

import Button, { type ButtonProps } from '../../../Button';
import { useStyles } from '../../context';

const CalloutButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const styles = useStyles();

  const buttonStyles = useStyleConfig('Button', {
    variant: 'inline-secondary',
  });

  return (
    <Button
      __css={mergeWith(buttonStyles, styles.button)}
      ref={ref}
      {...props}
    />
  );
});

export default CalloutButton;
