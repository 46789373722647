/* eslint-disable react/display-name */

import { type ComponentType } from 'react';
import { omit } from 'ramda';

/**
 * A HOC which strips unwanted props which might be passed accidentally
 * by the parent component. Particulary useful with styled-components
 * @param {arrray} omittedProps The props to omit
 * @param {React.ComponentType} Component React component which will be wrapped by the HOC
 * @return {React.ComponentType} A new React component which does not receive
 * the omitted props
 * @example styled(componentWithoutProps(['active'], Text))
 * // Text won't receive the active prop if it passed to the parent Styled component
 */
const componentWithoutProps =
  <OmittedProps extends string, ComponentProps>(
    omittedProps: OmittedProps[],
    Component: ComponentType<ComponentProps>,
  ) =>
  (props: { [key in OmittedProps]: unknown } & ComponentProps) => {
    const componentProps = omit(
      omittedProps,
      props,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as unknown as any;
    return <Component {...componentProps} />;
  };

export default componentWithoutProps;
