/* eslint-disable react/display-name */
import { Box, Flex } from '@chakra-ui/react';
import { css } from '@emotion/react';

import { PageLayout } from 'features/Bento/components';
import { type OBLayout } from 'features/Bento/types/OBLayout';

import withLayoutFull from './withLayoutFull';

/**
 * A grid layout for the onboarding screens.
 */
const SignupLayoutSmall: OBLayout = ({
  actions,
  children,
  'data-testid': testId,
  footer,
  guidance,
}) => (
  <Flex
    // fill-available is to correctly display footer on webkit browsers
    // cf https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/
    // And emtion css function is to be able to apply 2 values for the same css property
    css={css`
      min-height: 100vh;
      min-height: -webkit-fill-available;
    `}
    data-testid={testId}
    flexDirection="column"
    left="0"
    margin="0 auto"
    maxWidth="462px"
    top="0"
  >
    {/* Buttons and CTAs */}

    {actions ? (
      <Box paddingX="space-16" paddingY="space-12">
        {actions}
      </Box>
    ) : null}

    {/* Main content */}

    <PageLayout.Body
      display="flex"
      flexDirection="column"
      flexGrow={1}
      padding="space-16"
    >
      {children}

      {/* Help message */}

      {guidance || null}
    </PageLayout.Body>

    {/* Bottom sticky element */}

    {footer ? (
      <Flex
        alignItems="center"
        background="white"
        paddingBottom="space-24"
        paddingTop="space-16"
        paddingX="space-32"
        width="100%"
      >
        {footer}
      </Flex>
    ) : null}

    {/*
    gridArea="footer"
     */}
  </Flex>
);

export default withLayoutFull(SignupLayoutSmall);
