import 'react-datepicker/dist/react-datepicker.css';

import { type FC, memo, useCallback, useMemo, useRef } from 'react';
import BaseDatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { range } from 'ramda';

import dateToString from 'common/dateToString';
import SunshineIcon from 'components/_core/SunshineIcon';

import locales from './locales';
import Select from './Select';
import { type OptionType } from './Select/types';
import {
  DatePickerButton,
  DatePickerCustomHeader,
  DatePickerDropdowns,
  DatePickerWrapper,
} from './styles';
import { type DatePickerProps } from './types';

// Regsiter french locale on react-datepicker (see: https://github.com/Hacker0x01/react-datepicker/#localization)
registerLocale('fr', fr);

const toOption = (value: string): OptionType<string> => ({
  label: value,
  value,
});
const toString = (x: number): string => `${x}`;
const getMonth = (date: Date): number => date.getMonth();
const getYear = (date: Date): number => date.getFullYear();

/**
 * Date picker value change callback
 *
 * @callback onValueChangeCallback
 * @param {string} date - set date as a YYYY-MM-DD string or empty string
 */

/**
 * Custom date picker
 *
 * @param {onValueChangeCallback} onValueChange
 */
const DatePicker: FC<DatePickerProps> = ({
  disabled,
  max,
  // Empty functions used below as an easier to handle default value
  min,
  onBlur = () => {},
  onFocus = () => {},
  onValueChange = () => {},
  value,
  ...props
}) => {
  const pickerRef = useRef<BaseDatePicker>(null);
  const { months } = locales;
  const minDate = useMemo(() => (min ? new Date(min) : undefined), [min]);
  const maxDate = useMemo(() => (max ? new Date(max) : undefined), [max]);
  const valueDate = useMemo(
    () => (value ? new Date(value) : undefined),
    [value],
  );

  const years = useMemo(() => {
    let yearsRange;

    if (minDate && maxDate) {
      yearsRange = range(getYear(minDate), getYear(maxDate));
    } else if (maxDate) {
      const maxYear = getYear(maxDate);
      yearsRange = range(maxYear - 100, maxYear + 1);
    } else if (minDate) {
      const minYear = getYear(minDate);
      yearsRange = range(minYear, minYear + 101);
    } else {
      const nextYear = getYear(new Date()) + 1;
      yearsRange = range(nextYear - 100, nextYear + 1);
    }

    return yearsRange.reverse().map(toString);
  }, [minDate, maxDate]);

  const monthsOptions = useMemo(() => months.map(toOption), [months]);
  const yearsOptions = useMemo(() => years.map(toOption), [years]);

  const renderCustomHeader = useCallback(
    ({
      changeMonth,
      changeYear,
      date,
      decreaseMonth,
      increaseMonth,
      nextMonthButtonDisabled,
      prevMonthButtonDisabled,
    }: {
      date: Date;
      changeYear(year: number): void;
      changeMonth(month: number): void;
      decreaseMonth(): void;
      increaseMonth(): void;
      prevMonthButtonDisabled: boolean;
      nextMonthButtonDisabled: boolean;
      decreaseYear(): void;
      increaseYear(): void;
      prevYearButtonDisabled: boolean;
      nextYearButtonDisabled: boolean;
    }) => (
      <DatePickerCustomHeader>
        <DatePickerDropdowns>
          <Select
            label="month"
            onChange={(monthValue: string | null) => {
              if (monthValue) {
                // @ts-expect-error incorrect locales types
                changeMonth(months.indexOf(monthValue));
              }
            }}
            options={monthsOptions}
            // @ts-expect-error months[getMonth(date)] is possibly 'undefined'.
            value={months[getMonth(date)]}
          />
          <Select
            label="year"
            onChange={(yearValue: string | null) => {
              if (yearValue) {
                changeYear(parseInt(yearValue, 10));
              }
            }}
            options={yearsOptions}
            value={toString(getYear(date))}
          />
        </DatePickerDropdowns>
        <DatePickerButton
          disabled={prevMonthButtonDisabled}
          onClick={decreaseMonth}
        >
          <SunshineIcon name="chevron-left" size="icon-16" />
        </DatePickerButton>
        <DatePickerButton
          disabled={nextMonthButtonDisabled}
          onClick={increaseMonth}
        >
          <SunshineIcon name="chevron-right" size="icon-16" />
        </DatePickerButton>
      </DatePickerCustomHeader>
    ),
    [months, monthsOptions, yearsOptions],
  );

  return (
    <DatePickerWrapper>
      <BaseDatePicker
        dateFormat="P"
        disabled={disabled}
        locale={locales.getLanguage()}
        maxDate={maxDate}
        minDate={minDate}
        onBlur={(event) => {
          onBlur(event);
          // TODO(alex): the datepicker has an option to close on select which doesn't work.
          // Probably for the same reason that made me use setTimeout. Check how to fix that.
          setTimeout(() => {
            if (pickerRef.current) {
              pickerRef.current.setOpen(false);
            }
          });
        }}
        onChange={(date: Date) => {
          onValueChange(date !== null ? dateToString(date) : '');
        }}
        onFocus={onFocus}
        ref={pickerRef}
        renderCustomHeader={renderCustomHeader}
        selected={valueDate}
        showYearDropdown
        {...props}
      />
    </DatePickerWrapper>
  );
};

export default memo(DatePicker);
