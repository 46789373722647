import okHandImg from 'assets/brand/ok-hand@2x.png';
import asBentoPage from 'common/bento/hoc/asBentoPage';
import OnboardingContainer from 'features/TeamOnboarding/components/OnboardingContainer';
import OnboardingContainerContent from 'features/TeamOnboarding/components/OnboardingContainer/components/OnboardingContainerContent';

import { type ModuleData } from '../../machine/model';
import locales from './locales';

interface ValidatedProps {
  moduleData: ModuleData;
  onNext: () => void;
}

const Validated = asBentoPage<ValidatedProps>(({ moduleData, onNext }) => {
  return (
    <OnboardingContainer ctaText="Continuer" onClick={onNext}>
      <OnboardingContainerContent
        content={
          locales.formatString<string>(locales.content, {
            companyName: moduleData.companyName,
          }) as string
        }
        illustration={okHandImg}
        title={locales.title}
      />
    </OnboardingContainer>
  );
});

export default Validated;
