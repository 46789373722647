import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    onfidoNext: 'Next',
    onfidoWelcomeDescription:
      'For this next stage, we will need you to send us a photo of your identity document.',
    onfidoWelcomeDescriptionTwo:
      'Please follow the instructions on the next page.',
    onfidoWelcomeTitle: 'Your identity document',
  },
  fr: {
    onfidoNext: 'Suivant',
    onfidoWelcomeDescription:
      'Pour cette prochaine étape, vous devrez nous envoyer une photo de votre pièce d’identité.',
    onfidoWelcomeDescriptionTwo:
      'Veuillez suivre les instructions à la page suivante.',
    onfidoWelcomeTitle: 'Votre pièce d’identité',
  },
} as const);

export default locales;
