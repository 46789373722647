import { type FC, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useMutation } from '@apollo/client';
import { ERROR_CODES } from '@shinetools/errors';
import { type SecuredRoutesState } from 'Main/SecuredRoutes';

import { UserCompanyListDocument } from 'common/hooks/useUserCompanyList/userCompanyList.gql';
import Loader from 'components/Loader';

import ParallelOnboardingRestriction from './components/ParallelOnboardingRestriction';
import { RedeemCompanyInvitationDocument } from './graphql/mutations/redeemCompanyInvitation.gql';
import { Container } from './styles';
import { type Props } from './types';

const RedeemInvitation: FC<Props> = ({ invitationSlug }) => {
  const [redeemCompanyInvitation, { called, error, loading }] = useMutation(
    RedeemCompanyInvitationDocument,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const code = (error?.graphQLErrors[0]?.extensions?.exception as any)?.code as
    | string
    | undefined;

  useEffect(() => {
    redeemCompanyInvitation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: UserCompanyListDocument,
        },
      ],
      variables: {
        input: {
          slug: invitationSlug,
        },
      },
    }).catch(() => {});
  }, [redeemCompanyInvitation, invitationSlug]);

  if (!called || loading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (
    !error ||
    code === ERROR_CODES.INVITATION_CANNOT_BE_SELF_REDEEMED ||
    code ===
      ERROR_CODES.INVITATION_CANNOT_BE_REDEEMED_BY_AN_ALREADY_EXISTING_COMPANY_USER
  ) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: {
            invitationRedeemedByExistingTeamMember: !!error,
          } as SecuredRoutesState,
        }}
      />
    );
  }

  if (code === ERROR_CODES.PARALLEL_ONBOARDING_NOT_SUPPORTED) {
    return <ParallelOnboardingRestriction />;
  }

  throw error;
};

export default RedeemInvitation;
