import { type FC } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { Image, Text, VStack } from '@chakra-ui/react';

import folderImg from 'assets/brand/folder.png';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import Loader from 'components/Loader';

import useCompanyContext from '../../../../common/hooks/useCompanyContext';
import { APPLICATION_TYPE_STORAGE_KEY } from '../../../Onboarding/common/localStorage/applicationType';
import locales from '../../locales';
import { UserProfileDocument } from '../../queries/userProfile.gql';

const CreateNewAccountCard: FC = () => {
  const { setCompanyContext } = useCompanyContext();
  const history = useHistory();
  const { data: userProfileData, loading: userProfileLoading } =
    useQuery(UserProfileDocument);

  const createNewAccount = () => {
    localStorage.removeItem(APPLICATION_TYPE_STORAGE_KEY);

    setCompanyContext({
      companyProfileId: undefined,
      companyUserId: undefined,
    });

    history.push('/onboarding?addNewAccount=true');
  };

  if (userProfileLoading) {
    return <Loader />;
  }

  const email = userProfileData?.viewer.profile.email;
  const phone = userProfileData?.viewer.profile.phone;

  return (
    <SunshineCard.Group>
      <SunshineCard padding="space-32" textAlign="center">
        <VStack marginY="space-24" spacing="space-16">
          <Image height="144px" src={folderImg} />
          <Heading size="lg">{locales.title}</Heading>
          <Text>{locales.subtitle}</Text>

          <VStack fontWeight="weight-500">
            <Text>{email}</Text>
            <Text>{phone}</Text>
          </VStack>
        </VStack>
      </SunshineCard>

      <SunshineCard padding="space-24" textAlign="center">
        <Button isExpanded marginY="space-8" onClick={createNewAccount}>
          {locales.buttonLabel}
        </Button>
      </SunshineCard>
    </SunshineCard.Group>
  );
};

export default CreateNewAccountCard;
