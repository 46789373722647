import getAddressErrors from 'features/TeamOnboarding/Onboarding/modules/Address/libs/getAddressErrors';
import getAddressStatus from 'features/TeamOnboarding/Onboarding/modules/Address/libs/getAddressStatus';

import locales from '../../locales';
import { State } from '../../machine';
import model from '../../machine/model';
import { type GetVerificationStep } from './types';

const getAddressStep: GetVerificationStep = ({ verificationData }) => ({
  errors: getAddressErrors({
    kycDocuments: verificationData.viewer.companyUser.kycDocuments,
  }),
  id: State.ADDRESS,
  metadata: {
    event: model.events.GO_ADDRESS(),
    iconName: 'home',
    isHidden: false,
    label: locales.address,
  },
  status: getAddressStatus({
    userProfile: verificationData.viewer.profile,
  }),
});

export default getAddressStep;
