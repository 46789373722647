import {
  chakra,
  type ComponentWithAs,
  type HTMLChakraProps,
  type ThemeTypings,
  useMultiStyleConfig,
} from '@chakra-ui/system';

import SunshineIcon, {
  type IconName as SunshineIconName,
} from '../SunshineIcon';
import Button from './components/Button';
import Content from './components/Content';
import Title from './components/Title';
import { COMPONENT_NAME, StylesProvider } from './context';

type Variant = ThemeTypings['components']['Callout']['variants'];

const iconsRebrandedMap: Record<Variant, SunshineIconName> = {
  error: 'warning',
  info: 'information',
  warning: 'warning',
};

interface CalloutParts {
  Button: typeof Button;
  Content: typeof Content;
  Title: typeof Title;
}

export interface CalloutProps extends HTMLChakraProps<'div'> {
  hideIcon?: boolean;
  variant?: Variant;
}

type CalloutType = ComponentWithAs<'div', CalloutProps> & CalloutParts;

/**
 * @deprecated Use Callout from `@shinetools/sunshine-universal` instead.
 * @see https://shinetools.github.io/shine/sunshine-universal-storybook/?path=/docs/callout--docs
 */
const Callout: CalloutType = ({
  children,
  hideIcon = false,
  variant = 'info',
  ...props
}) => {
  const styles = useMultiStyleConfig(COMPONENT_NAME, { variant });

  return (
    <chakra.div __css={styles.container} {...props}>
      {hideIcon ? null : (
        <chakra.div __css={styles.iconContainer}>
          <chakra.div
            __css={styles.icon}
            as={SunshineIcon}
            name={iconsRebrandedMap[variant]}
            size="icon-15"
          />
        </chakra.div>
      )}

      <StylesProvider value={styles}>{children}</StylesProvider>
    </chakra.div>
  );
};

Callout.Button = Button;
Callout.Content = Content;
Callout.Title = Title;

export default Callout;
