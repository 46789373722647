import { createMachine } from 'xstate';

import shared from 'common/bento/shared';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';
import { type AnyMachineEvent } from 'features/Bento/types/Abstract';

import actions, { Action } from './actions';
import model, { type Context } from './model';
import services from './services';

export enum State {
  ADDRESS_LOAD = 'address-load',
  ADDRESS_FORM = 'address-form',
  ADDRESS_SEND = 'address-send',
  DONE = 'done',
  ABORT = 'abort',
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    context: model.initialContext,

    id: 'address',

    initial: State.ADDRESS_LOAD,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.ADDRESS_LOAD]: {
        invoke: {
          id: 'loadInitialData',
          onDone: [
            {
              actions: Action.AssignInitialData,
              target: State.ADDRESS_FORM,
            },
          ],
          onError: {
            actions: shared.actions.error(),
            target: State.ADDRESS_FORM,
          },
          src: 'loadInitialData',
        },
      },

      [State.ADDRESS_FORM]: {
        entry: shared.actions.trackView(),

        on: {
          PREV: [
            {
              target: State.ABORT,
            },
          ],
          SUBMIT_ADDRESS: {
            actions: Action.AssignAddress,
            target: State.ADDRESS_SEND,
          },
        },
      },

      [State.ADDRESS_SEND]: {
        invoke: {
          onDone: {
            actions: Action.AssignAddress,
            target: State.DONE,
          },
          onError: {
            actions: shared.actions.error(),
            target: State.ADDRESS_FORM,
          },
          src: 'sendAddress',
        },
      },

      [State.DONE]: {
        data: {
          status: BentoModuleDoneStatus.DONE,
        },
        type: 'final',
      },

      [State.ABORT]: {
        data: {
          status: BentoModuleDoneStatus.ABORT,
        },
        type: 'final',
      },
    },
  },

  {
    actions,
    guards: {},
    services,
  },
);

export default machine;
