import { type LoginType } from '../types';
import { type ConfirmPasscodeHookResult, type LoginStep } from './types';

const useConfirmResetPasscode = (
  setLoginType: (logintType: LoginType) => void,
  setCurrentStep: (currentStep: LoginStep) => void,
): ConfirmPasscodeHookResult => {
  const onConfirm = () => {
    setLoginType('passcode_reset');
    setCurrentStep('createPasscode');
  };
  return { onConfirm };
};

export default useConfirmResetPasscode;
