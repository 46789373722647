import { type ReactElement } from 'react';

/**
 * Completion status of a module
 */
export enum BentoModuleStatus {
  NONE = 'none', // No information about the verification status is available
  WAITING = 'waiting', // An action is required from the user
  PENDING = 'pending', // An action is required from Shine (human or system)
  SUCCESS = 'success', // The user or Shine has done everything right (ex: doc send, doc validated)
  ERROR = 'error', // Something went wrong (ex: Invalid/refused document)
}

/**
 * Reason of the exit of a module
 */
export enum BentoModuleDoneStatus {
  DONE = 'DONE',
  ABORT = 'ABORT',
}
export interface BentoModuleDoneData {
  status: BentoModuleDoneStatus;
  finalState?: string;
}

/**
 * Called when a module want to inform
 * its parent that it can be left
 */
export type OnBentoModuleDone = (props: BentoModuleDoneData) => void;

export type BentoModule<TProps = Record<string, never>> = (
  props: TProps & {
    onDone: OnBentoModuleDone;
  },
) => ReactElement | null;

/**
 * Currently the HOC is a No-op
 */
export type BentoModuleInput<TProps> = BentoModule<TProps>;
