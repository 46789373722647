import * as Types from '../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UserLockedPageQueryVariables = Types.Exact<{
  companyProfileId: Types.Scalars['UUIDv4']['input'];
}>;


export type UserLockedPageQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', uid: string, company: { __typename: 'Company', companyProfileId: string, profile: { __typename: 'CompanyProfile', companyProfileId: string, legalName: string } } } };


export const UserLockedPageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userLockedPage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUIDv4"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyProfileId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyProfileId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"legalName"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UserLockedPageQuery, UserLockedPageQueryVariables>;