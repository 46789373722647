import { Box, Stack, type StackProps } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';

import { useStyles } from '../../context';
import Description from '../Description';
import Title from '../Title';

export interface CardContentProps extends Omit<StackProps, 'onChange'> {
  title?: string;
  subtitle?: string;
}

const CardContent = forwardRef<CardContentProps, 'div'>(
  ({ children, subtitle, title, ...props }, ref) => {
    const styles = useStyles();

    return (
      <Box __css={styles.content} ref={ref}>
        <Stack
          alignItems="flex-start"
          flexGrow={1}
          justifyContent="center"
          spacing="space-0"
          {...props}
        >
          {title ? <Title>{title}</Title> : null}
          {subtitle ? <Description>{subtitle}</Description> : null}
          {children ? <>{children}</> : null}
        </Stack>
      </Box>
    );
  },
);

export default CardContent;
