import {
  anatomy,
  getColor,
  type PartsStyleFunction,
  type PartsStyleObject,
} from '@chakra-ui/theme-tools';

const parts = anatomy('Card').parts('action', 'container', 'content');

type Parts = typeof parts;

const baseStyle: PartsStyleFunction<Parts> = ({ isActive, theme }) => ({
  action: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    '[data-js-focus-visible] &': {
      _focusVisible: {
        boxShadow: `0px 0px 0px 2px ${getColor(theme, 'blue.1')}`,
        outline: 'none',
        zIndex: 5,
      },
      ...(isActive && {
        borderColor: 'blue.-1',
        zIndex: 10,
      }),
    },
    background: 'white',
    border: '1px solid',
    borderColor: 'grey.2',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    textAlign: 'left',
    transitionDuration: 'duration-200',
    transitionProperty: 'common',
    transitionTimingFunction: 'ease-out',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
});

type Size = 'md' | 'lg';

const sizes: Record<Size, PartsStyleObject<Parts>> = {
  lg: {
    container: {
      borderRadius: 'radius-16',
      padding: 'space-24',
    },
  },
  md: {
    container: {
      borderRadius: 'radius-8',
      padding: 'space-16',
    },
  },
};

type Variant = 'normal' | 'action';

const variants: Record<Variant, PartsStyleObject<Parts>> = {
  action: {
    container: {
      _hover: { backgroundColor: 'grey.5' },
      cursor: 'pointer',
      flexDirection: 'row',
    },
  },
  normal: {},
};

type DefaultProps = {
  variant: Variant;
  size: Size;
};

const defaultProps: DefaultProps = {
  size: 'md',
  variant: 'normal',
};

const CardTheme = {
  baseStyle,
  defaultProps,
  parts: parts.keys,
  sizes,
  variants,
};

export default CardTheme;
