import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { ERROR_CODES } from '@shinetools/errors';

import {
  changePasscode,
  checkVerificationCode,
  endLogin,
  startRegister,
} from 'helpers/auth/service';
import { useDevice } from 'helpers/device';

import { type LoginType } from '../types';
import doLogin from './doLogin';
import locales from './locales';
import {
  type LoginStep,
  type SetCurrentUser,
  type SubmitVerificationCodeHookResult,
} from './types';
import useAutoResettingErrorMessage from './useAutoResettingErrorMessage';

const useVerificationCodeSubmit = ({
  currentStep,
  loginType,
  passcode,
  phone,
  setCurrentStep,
  setCurrentUser,
  setPhoneNumberOwnershipToken,
}: {
  currentStep: LoginStep;
  loginType: LoginType;
  phone: string;
  passcode?: string;
  setCurrentUser: SetCurrentUser;
  setCurrentStep: (currentStep: LoginStep) => void;
  setPhoneNumberOwnershipToken: (phoneNumberOwnershipToken: string) => void;
}): SubmitVerificationCodeHookResult => {
  const { token: deviceToken, ...device } = useDevice();
  const [errorMessage, setErrorMessage] =
    useAutoResettingErrorMessage(currentStep);
  const [loading, setLoading] = useState(false);
  const [resendSmsLoading, setResendSmsLoading] = useState(false);

  const onSubmit = async (
    code: string,
  ): ReturnType<SubmitVerificationCodeHookResult['onSubmit']> => {
    try {
      setErrorMessage('');
      setLoading(true);

      Sentry.addBreadcrumb({
        data: {
          phone,
        },
        level: 'info',
        message: 'Calling sendCodesFn',
      });

      if (loginType === 'sign_up') {
        /**
         * If the verification code is wrong, this throws and is handled below.
         */
        const { data } = await checkVerificationCode({
          phoneNumber: phone,
          verificationCode: code,
        });

        setLoading(false);

        if (data.available === false) {
          setCurrentStep('accountAlreadyExists');
          return false;
        }

        setPhoneNumberOwnershipToken(data.phoneNumberOwnershipToken);
        setCurrentStep('createPasscode');
        return true;
      }

      let tokens;

      switch (loginType) {
        case 'sign_in':
          tokens = await endLogin({
            code,
            deviceToken,
            passcode: passcode as string,
            phone,
            ...device,
          });
          break;

        case 'passcode_reset':
          tokens = await changePasscode({
            code,
            deviceToken,
            passcode: passcode as string,
            phone,
            ...device,
          });
      }

      Sentry.addBreadcrumb({
        data: {
          phone,
        },
        level: 'info',
        message: 'sendCodesFn called successfully',
      });

      setLoading(false);

      Sentry.addBreadcrumb({
        data: {
          phone,
        },
        level: 'info',
        message: 'Calling doLogin',
      });

      const loginSuccessful = doLogin(loginType, setCurrentUser, phone, tokens);

      Sentry.addBreadcrumb({
        data: {
          phone,
        },
        level: 'info',
        message: 'doLogin called successfully',
      });

      return loginSuccessful;
    } catch (error) {
      if ((error as { status: number }).status === 400) {
        setErrorMessage(locales.invalidVerificationCode);
      } else {
        switch ((error as { code: string }).code) {
          case ERROR_CODES.AUTHENTICATION_PASSWORDLESS_CODE_NOT_MATCH:
            setErrorMessage(locales.invalidVerificationCode);
            break;
          case ERROR_CODES.AUTHENTICATION_UID_IS_EMPTY:
            setErrorMessage(locales.registerNotFinished);
            break;
          default:
            setErrorMessage(locales.blockedByServer);
        }
      }

      setLoading(false);

      return false;
    }
  };
  const resendSms = async () => {
    try {
      setErrorMessage('');
      setResendSmsLoading(true);
      await startRegister({ phone });
      setResendSmsLoading(false);
    } catch (e) {
      setErrorMessage(locales.connectionErrorMessage);
      setResendSmsLoading(false);
    }
  };
  return {
    errorMessage,
    loading,
    loginType,
    onSubmit,
    phone,
    resendSms,
    resendSmsLoading,
  };
};

export default useVerificationCodeSubmit;
