/* eslint-disable react/no-unused-prop-types, @typescript-eslint/no-explicit-any */

import { type FC, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { ERROR_CODES } from '@shinetools/errors';

import errorHandlerLocales from 'common/hooks/useErrorHandledMutation/locales';
import { type OnPasscodeSubmit } from 'components/PasscodeInput/types';
import useToast from 'components/ToastProvider/useToast';
import { useUser } from 'helpers/auth';
import { useDevice } from 'helpers/device';
import logger from 'helpers/logger';

import { ConfirmAuthenticationActionRequestDocument } from '../graphql/mutations/confirmAuthenticationActionRequest.gql';
import locales from '../locales';
import { type ActionRequestType } from '../types';
import ActionRequestPasscode from './component';

interface ActionRequestPasscodeContainerProps {
  actionRequest: ActionRequestType;
  setStep: (step: string) => void;
  close: () => void;
}

const ActionRequestPasscodeContainer: FC<
  ActionRequestPasscodeContainerProps
> = ({ actionRequest, setStep }) => {
  const user = useUser();
  const device = useDevice();
  const { hideToast, showToast } = useToast();
  const [confirmActionMutation] = useMutation(
    ConfirmAuthenticationActionRequestDocument,
  );

  const onPasscodeSubmit = useCallback<OnPasscodeSubmit>(
    async (passcode, clearAllDigits) => {
      try {
        hideToast();
        await confirmActionMutation({
          variables: {
            input: {
              authenticationActionRequestId:
                actionRequest.authenticationActionRequestId,
              deviceToken: device.token,
              passcode,
              phone: user.phone || '',
            },
          },
        });
        setStep('confirmation');
      } catch (error: any) {
        const errorLocale = locales.errors[error.code];
        if (errorLocale) {
          showToast({ message: errorLocale, type: 'error' });
        } else if (error.status === 429) {
          showToast({
            message: errorHandlerLocales.rateLimitError,
            type: 'error',
          });
        } else if (error.code === ERROR_CODES.AUTHENTICATION_PASSCODE_WRONG) {
          showToast({
            message: errorHandlerLocales.wrongPasscode,
            type: 'error',
          });
        } else {
          logger.error(error);
          showToast({
            message: errorHandlerLocales.unrecognizedErrorMessage,
            type: 'error',
          });
        }

        clearAllDigits({ andFocusFirstInput: true });
      }
    },
    [
      actionRequest,
      device,
      hideToast,
      showToast,
      user,
      setStep,
      confirmActionMutation,
    ],
  );
  const goBack = useCallback(() => {
    setStep('');
  }, [setStep]);
  return (
    <ActionRequestPasscode
      actionRequest={actionRequest}
      goBack={goBack}
      onPasscodeSubmit={onPasscodeSubmit}
    />
  );
};

export default ActionRequestPasscodeContainer;
