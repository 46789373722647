import { type FC } from 'react';
import { Image } from '@chakra-ui/react';

import hornImg from 'assets/brand/horn@2x.png';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

import NewDeviceLoginLayout from '../NewDeviceLoginLayout';
import locales from './locales';
import { type Props } from './types';

const ConnectionRefused: FC<Props> = ({ cancel }) => (
  <NewDeviceLoginLayout cancel={cancel}>
    <Image alt="" role="presentation" src={hornImg} width="144px" />

    <Heading marginTop="space-24" size="lg">
      {locales.title}
    </Heading>

    <Text marginTop="space-16" paddingBottom="space-24">
      {locales.description}
    </Text>
  </NewDeviceLoginLayout>
);

export default ConnectionRefused;
