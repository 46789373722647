import { useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  IntercomProvider as IntercomBaseProvider,
  useIntercom,
} from 'react-use-intercom';

import { type FCWithChildren } from 'common/types';

import './style.css';
import config from '../../config';

export { default as IntercomAPI } from './api';
export * from './constants';

const Intercom: FCWithChildren = ({ children }) => {
  const intercom = useIntercom();
  const { pathname } = useLocation();

  useEffect((): void => {
    // Hide Intercom on route change
    intercom.hide();

    // Intercom update have to be triggered on route change to check for new message
    // See https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app#tell-intercom-when-your-data-or-url-changes
    intercom.update();

    // pathname is not used inside the effect,
    // but we need to retrigger the effect when this changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <>{children}</>;
};

const IntercomProvider: FCWithChildren = ({ children }) => {
  return (
    <IntercomBaseProvider appId={config.intercomAppId}>
      <Intercom>{children}</Intercom>
    </IntercomBaseProvider>
  );
};

export default IntercomProvider;
