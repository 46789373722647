import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'To continue, in the next step, please click on I already have an account.',
    login: 'Ok, I understand',
    title: 'This phone number is already associated with a Shine account',
  },

  fr: {
    description: `Afin de continuer, à la prochaine étape, veuillez cliquer sur
      j'ai déjà un compte Shine.`,
    login: `Ok, c'est compris`,
    title: 'Ce numéro de téléphone est déjà associé à un compte Shine',
  },
} as const);

export default locales;
