import { type FC, type ReactNode } from 'react';
import { Image, useBreakpointValue } from '@chakra-ui/react';

import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

import ChillDude from '../assets/ChillDude@2x.png';
import GuidanceLarge from './GuidanceLarge';
import GuidanceSmall from './GuidanceSmall';

interface GuidanceBaseHelpProps {
  title: string;
  children: ReactNode;
}

/**
 * The base guidance with predefined design and behavior
 * - title is mandatory
 * - children is the text content
 */
const GuidanceBaseHelp: FC<GuidanceBaseHelpProps> = ({ children, title }) => {
  const isSmall = useBreakpointValue({
    base: true,
    lg: false,
  });

  return isSmall ? (
    <GuidanceSmall title={title}>{children}</GuidanceSmall>
  ) : (
    <GuidanceLarge variant="help">
      <Image
        height="128px"
        marginBottom="space-24"
        marginTop="space-40"
        src={ChillDude}
      />

      <Heading marginBottom="space-16" size="sm">
        {title}
      </Heading>

      <Text>{children}</Text>
    </GuidanceLarge>
  );
};

export default GuidanceBaseHelp;
