/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';
import { ERROR_CODES } from '@shinetools/errors';

import { startLogin } from 'helpers/auth/service';
import { useDevice } from 'helpers/device';
import logger from 'helpers/logger';

import { type LoginType } from '../types';
import doLogin from './doLogin';
import locales from './locales';
import {
  type LoginStep,
  type SetCurrentUser,
  type SubmitPasscodeHookResult,
} from './types';
import useAutoResettingErrorMessage from './useAutoResettingErrorMessage';

const usePasscodeSubmit = (
  currentStep: LoginStep,
  phone: string,
  setCurrentStep: (currentStep: LoginStep) => void,
  setCurrentUser: SetCurrentUser,
  loginType: LoginType,
): SubmitPasscodeHookResult => {
  const { model, name, token: deviceToken } = useDevice();
  const [errorMessage, setErrorMessage] =
    useAutoResettingErrorMessage(currentStep);
  const [loading, setLoading] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [authenticationDeviceId, setAuthenticationDeviceId] = useState('');
  const [authenticationDeviceRequestId, setAuthenticationDeviceRequestId] =
    useState('');

  const onSubmit = useCallback(
    async (
      newPasscode: string,
    ): ReturnType<SubmitPasscodeHookResult['onSubmit']> => {
      if (phone === '') {
        throw new Error('Phone number should be available');
      }
      try {
        setPasscode(newPasscode);
        setErrorMessage('');
        setLoading(true);

        Sentry.addBreadcrumb({
          data: {
            phone,
          },
          level: 'info',
          message: 'Calling startLogin',
        });

        const {
          authenticationDeviceRequest,
          firebaseToken,
          shouldTriggerNewDeviceAuthorization,
          skip2FA,
          tokens,
        } = await startLogin({
          deviceToken,
          model,
          name,
          passcode: newPasscode,
          phone,
        });

        if (authenticationDeviceRequest?.authenticationDeviceId) {
          setAuthenticationDeviceId(
            authenticationDeviceRequest.authenticationDeviceId,
          );
        }

        if (authenticationDeviceRequest?.authenticationDeviceRequestId) {
          setAuthenticationDeviceRequestId(
            authenticationDeviceRequest.authenticationDeviceRequestId,
          );
        }

        Sentry.addBreadcrumb({
          data: {
            phone,
          },
          level: 'info',
          message: 'startLogin called successfully',
        });

        setLoading(false);
        if (shouldTriggerNewDeviceAuthorization) {
          setCurrentStep('newDeviceAuthorization');
          return false;
        }

        if (skip2FA && tokens) {
          Sentry.addBreadcrumb({
            data: {
              phone,
            },
            level: 'info',
            message: 'Calling doLogin',
          });

          const doLoginResult = doLogin(loginType, setCurrentUser, phone, {
            ...tokens,
            firebaseToken,
          });

          Sentry.addBreadcrumb({
            data: {
              phone,
            },
            level: 'info',
            message: 'doLogin called successfully',
          });

          return doLoginResult;
        }
        setCurrentStep('verificationCode');
      } catch (error: any) {
        setLoading(false);

        // rate limiting error
        if (error.status === 429) {
          setErrorMessage(locales.rateLimitError);
        } else {
          switch (error.code) {
            case ERROR_CODES.AUTHENTICATION_PASSCODE_WRONG:
            case ERROR_CODES.AUTHENTICATION_PASSWORDLESS_CODE_NOT_MATCH:
              setErrorMessage(locales.invalidPasscode);
              break;
            case ERROR_CODES.AUTHENTICATION_PHONE_PREFIX_NOT_ALLOWED_LOGIN:
              setErrorMessage(locales.phoneNumberNotAllowed);
              break;
            default:
              logger.error(error, {
                context: { currentStep, loginType, phone },
              });
              setErrorMessage(locales.unknownError);
              break;
          }
        }

        return false;
      }

      return false;
    },
    [
      phone,
      setErrorMessage,
      deviceToken,
      model,
      name,
      setCurrentStep,
      loginType,
      setCurrentUser,
      currentStep,
    ],
  );

  const onForgotPasscode = () => {
    setCurrentStep('confirmResetPasscode');
  };

  return {
    authenticationDeviceId,
    authenticationDeviceRequestId,
    errorMessage,
    loading,
    loginType,
    onForgotPasscode,
    onSubmit,
    passcode,
  };
};

export default usePasscodeSubmit;
