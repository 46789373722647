import { createMachine } from 'xstate';

import shared from 'common/bento/shared';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';
import { type AnyMachineEvent } from 'features/Bento/types/Abstract';

import actions from './actions';
import guards from './guards';
import model, { type Context } from './model';
import services from './services';

export enum State {
  INTRODUCTION = 'introduction',
  AGREEMENT = 'agreement',
  SUCCESS = 'success',
  DONE = 'done',
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    context: model.initialContext,

    id: 'agreement-catchup',

    initial: State.INTRODUCTION,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.INTRODUCTION]: {
        /**
         * Eject users that would already have signed their agreement.
         */
        always: {
          cond: 'hasSignedAgreement',
          target: State.DONE,
        },

        on: {
          NEXT: State.AGREEMENT,
        },
      },

      [State.AGREEMENT]: {
        on: {
          NEXT: [
            {
              cond: 'hasSignedAgreement',
              target: State.SUCCESS,
            },
            {
              target: State.INTRODUCTION,
            },
          ],
          PREV: State.INTRODUCTION,
        },
      },

      [State.SUCCESS]: {
        on: {
          NEXT: State.DONE,
          /**
           * At this point, there is no point trying to go back.
           *
           * If a user clicks their browser's back button, we send them to the end of the flow,
           * which means the most relevant screen for their situation (onboarding or account).
           */
          PREV: State.DONE,
        },
      },

      [State.DONE]: {
        data: {
          status: BentoModuleDoneStatus.DONE,
        },
        type: 'final',
      },
    },
  },

  {
    actions,
    guards,
    services,
  },
);

export default machine;
