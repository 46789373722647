import { type FC } from 'react';
import { useQuery } from '@apollo/client';

import OBMenu from 'features/Onboarding/components/OBNavigation/components/OBMenu';

import { SignupGetUserNameDocument } from '../../../graphql/queries/get-user-name.gql';

/**
 * The sidebar menu for the signup
 */
const SidebarMenu: FC = () => {
  const { data } = useQuery(SignupGetUserNameDocument);

  if (!data) {
    return null;
  }

  const { firstName, lastName } = data.viewer.profile;

  return <OBMenu firstName={firstName || null} lastName={lastName || null} />;
};

export default SidebarMenu;
