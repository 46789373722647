import {
  forwardRef,
  Heading as ChakraHeading,
  type HeadingProps as ChakraHeadingProps,
} from '@chakra-ui/react';

type HeadingProps = ChakraHeadingProps &
  (
    | {
        variant?: 'primary';
        size?: 'xl' | 'lg' | 'md' | 'sm';
      }
    | {
        variant: 'number';
        size: '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm';
      }
  );

/**
 * @deprecated Use Typography.Header from `@shinetools/sunshine-universal` instead.
 *
 * @see https://shinetools.github.io/shine/sunshine-universal-storybook/?path=/docs/typography--docs#header
 */
const Heading = forwardRef<HeadingProps, 'h1'>((props, ref) => (
  <ChakraHeading as="h1" ref={ref} {...props} />
));

export type { HeadingProps };
export default Heading;
