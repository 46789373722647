import { useEffect } from 'react';

import { CompanyUserKycStatus } from '__generated__/GQL';
import iddpImg from 'assets/brand/iddp@2x.png';
import asBentoPage from 'common/bento/hoc/asBentoPage';
import OnboardingContainer from 'features/TeamOnboarding/components/OnboardingContainer';
import OnboardingContainerContent from 'features/TeamOnboarding/components/OnboardingContainer/components/OnboardingContainerContent';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import { type ModuleData } from '../../machine/model';
import useCompanyUserSubscription from './hooks/useCompanyUserSubscription';
import locales from './locales';

interface AutoReviewApplicationProps {
  moduleData: ModuleData;
  onNext: () => void;
  onTimeout: () => void;
  onError: () => void;
}

const TIMEOUT = 60000; // 60s

const AutoReviewApplication = asBentoPage<AutoReviewApplicationProps>(
  ({ Layout, moduleData, onError, onNext, onTimeout }) => {
    useCompanyUserSubscription({
      companyUserId: moduleData.companyUserId,
    });

    // `moduleData` will be updated when the apollo cache updates thanks to the subscription
    if (moduleData.kycStatus === CompanyUserKycStatus.Validated) {
      onNext();
    }

    if (
      moduleData.kycStatus === CompanyUserKycStatus.Refused ||
      moduleData.kycStatus === CompanyUserKycStatus.Invalid
    ) {
      onError();
    }

    // if we don't receive any kycStatus update, transition onto another page
    useEffect(() => {
      const timeoutId = setTimeout(onTimeout, TIMEOUT);
      return () => clearInterval(timeoutId);
    }, [onTimeout]);

    return (
      <Layout nav={<Navigation />}>
        <OnboardingContainer ctaIsLoading={true} ctaText="" onClick={() => {}}>
          <OnboardingContainerContent
            illustration={iddpImg}
            title={locales.title}
          />
        </OnboardingContainer>
      </Layout>
    );
  },
);

export default AutoReviewApplication;
