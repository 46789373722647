import { type FC } from 'react';
import { Center, Image, type ImageProps } from '@chakra-ui/react';

const ModalImage: FC<ImageProps> = ({ alt = '', ...props }) => (
  <Center boxSize="144px" marginBottom="space-24" marginX="auto">
    <Image
      alt={alt}
      {...(alt === '' && {
        role: 'presentation',
      })}
      maxHeight="100%"
      {...props}
    />
  </Center>
);

export default ModalImage;
