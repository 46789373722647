import { Route, useRouteMatch } from 'react-router-dom';

import { type FCWithChildren } from 'common/types';

/**
 * This component wraps its children in a Route with a given param.
 * It allows children to navigate locally, on the basis of this Route.
 */
const RouteParameter: FCWithChildren<{ param: string }> = ({
  children,
  param,
}) => {
  const { url } = useRouteMatch();

  return <Route path={`${url}/:${param}?`}>{children}</Route>;
};

export default RouteParameter;
