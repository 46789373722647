import { type KycReviewDocument } from '__generated__/GQL';
import { getKycDocErrorsByCategory } from 'features/Bento/libs/kycDocuments';

import { VerificationStatus } from '../../Verification/lib/steps/types';
import locales from '../locales';

interface GetIdentityErrorsProps {
  kycDocuments: Pick<KycReviewDocument, 'type' | 'errorLocalized'>[];
  kycReviewError: string | null;
  providerReviewError: string | null;
  stepStatus: VerificationStatus;
}

const getIdentityErrors = ({
  kycDocuments,
  kycReviewError,
  providerReviewError,
  stepStatus,
}: GetIdentityErrorsProps) => {
  const kycDocErrors = getKycDocErrorsByCategory({ kycDocuments }, 'ID');

  if (kycDocErrors.length) {
    return kycDocErrors;
  }

  // If is no error, but the status if Refused, we want to display a generic error message
  if (!kycReviewError && stepStatus === VerificationStatus.Invalid) {
    return [providerReviewError || locales.identity.genericError];
  }

  return [];
};

export default getIdentityErrors;
