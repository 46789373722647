import {
  forwardRef,
  Image as ChakraImage,
  type ImageProps,
} from '@chakra-ui/react';

const Image = forwardRef<ImageProps, 'div'>((props, ref) => (
  <ChakraImage marginBottom="space-24" marginX="auto" ref={ref} {...props} />
));

export default Image;
