import LocalizedStrings from 'localized-strings';

interface AccessItem {
  label: string;
  tooltip: string;
}

interface AccessItemLocales {
  [key: string]: AccessItem;
}

const locales: { accessItems: AccessItemLocales } = new LocalizedStrings({
  en: {
    accessItems: {
      bank: {
        label:
          'Have access to your bank account and execute actions on your behalf',
        tooltip: '',
      },
      bankAccountsRead: {
        label: 'Have access to your bank account',
        tooltip: '',
      },
      bankBalancesRead: { label: 'Have access to your balance', tooltip: '' },
      bankTransactionsRead: {
        label: 'Have access to your transactions',
        tooltip: '',
      },
      bankTransfersRead: {
        label: 'Have access to your bank transfers',
        tooltip: '',
      },
      bankTransfersRecipientsRead: {
        label: 'Have access to your recipients',
        tooltip: '',
      },
      bankTransfersRecipientsWrite: {
        label: 'Be able to create new recipients',
        tooltip: '',
      },
      bankTransfersWrite: {
        label: 'Be able to make a bank transfer',
        tooltip: '',
      },
      companyProfileRead: {
        label: 'Have access to your company information',
        tooltip: '',
      },
      email: { label: 'Have access to your email address', tooltip: '' },
      invoices: {
        label: 'Have access to your invoices and create new ones',
        tooltip: '',
      },
      invoicesRead: {
        label: 'Have access to your invoices',
        tooltip: '',
      },
      invoicesWrite: {
        label: 'Create new invoices',
        tooltip: '',
      },
      phone: { label: 'Have access to your phone number', tooltip: '' },
      profile: {
        label: 'Have access to your first name & last name',
        tooltip: '',
      },
      receipts: {
        label: 'Have access to your receipts and create new ones',
        tooltip: '',
      },
      receiptsRead: {
        label: 'Have access to your receipts',
        tooltip: '',
      },
      receiptsWrite: {
        label: 'Create new receipts',
        tooltip: '',
      },
      user: {
        label: 'Have access to your profile and company informations',
        tooltip: '',
      },
      userCompaniesRead: {
        label: 'Have access to your company informations',
        tooltip: '',
      },
      userProfileRead: { label: 'Have access to your profile', tooltip: '' },
    },
  },
  fr: {
    accessItems: {
      bank: {
        label:
          'Accéder à votre compte bancaire et exécuter des actions en votre nom',
        tooltip: '',
      },
      bankAccountsRead: {
        label: 'Accéder à votre compte en banque',
        tooltip: '',
      },
      bankBalancesRead: { label: 'Accéder à votre balance', tooltip: '' },
      bankTransactionsRead: {
        label: 'Accéder à vos transactions',
        tooltip: '',
      },
      bankTransfersRead: { label: 'Accéder à vos virements', tooltip: '' },
      bankTransfersRecipientsRead: {
        label: 'Accéder à vos bénéficiaires',
        tooltip: '',
      },
      bankTransfersRecipientsWrite: {
        label: 'Pouvoir créer de nouveaux bénéficiaires',
        tooltip: '',
      },
      bankTransfersWrite: {
        label: 'Pouvoir émettre des virements',
        tooltip: '',
      },
      companyProfileRead: {
        label: 'Accéder aux informations de votre entreprise',
        tooltip: '',
      },
      email: { label: 'Accéder à votre email', tooltip: '' },
      invoices: {
        label: 'Accéder à vos factures et en créer',
        tooltip: '',
      },
      invoicesRead: {
        label: 'Accéder à vos factures',
        tooltip: '',
      },
      invoicesWrite: {
        label: 'Créer des factures',
        tooltip: '',
      },
      phone: { label: 'Accéder à votre numéro de téléphone', tooltip: '' },
      profile: { label: 'Accéder à votre nom et prénom', tooltip: '' },
      receipts: {
        label: 'Accéder à vos reçus et en créer',
        tooltip: '',
      },
      receiptsRead: {
        label: 'Accéder à vos reçus',
        tooltip: '',
      },
      receiptsWrite: {
        label: 'Créer des reçus',
        tooltip: '',
      },
      user: {
        label: 'Accéder à votre profil et aux informations de votre entreprise',
        tooltip: '',
      },
      userCompaniesRead: {
        label: 'Accéder aux informations de votre entreprise',
        tooltip: '',
      },
      userProfileRead: { label: 'Accéder à votre profil', tooltip: '' },
    },
  },
} as const);

export default locales;
