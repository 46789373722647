import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    description:
      'Thank you for having taken the time to provide your information.',
    openNewAccount: 'Open another account for my own company',
    title: 'Your application has been validated',
  },
  fr: {
    description:
      'Merci d’avoir pris le temps de remplir vos informations\u00A0!',
    openNewAccount: 'Ouvrir un autre compte pour mon entreprise',
    title: 'Votre profil est validé\u00A0!',
  },
} as const);
