import {
  forwardRef,
  MenuItem as ChakraMenuItem,
  type MenuItemProps as ChakraMenuItemProps,
} from '@chakra-ui/react';

import { CircleIcon } from 'features/Bento/atoms';
import { type ShineIcon } from 'features/Bento/atoms/Icon/Icon';

interface MenuItemProps extends ChakraMenuItemProps {
  iconName: ShineIcon;
  label: string;
}

const MenuItem = forwardRef<MenuItemProps, 'button'>(
  ({ iconName, label, ...rest }, ref) => (
    <ChakraMenuItem
      _focus={{
        background: 'grey.200',
      }}
      _hover={{
        background: 'grey.200',
      }}
      icon={
        <CircleIcon background="grey.200" color="grey.700" name={iconName} />
      }
      key={label}
      paddingY="space-12"
      ref={ref}
      {...rest}
    >
      {label}
    </ChakraMenuItem>
  ),
);

export default MenuItem;
