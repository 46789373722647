import { createModel } from 'xstate/lib/model';

import { type Gender } from 'common/types';
import { type OBErrorCode } from 'features/Bento/libs/errors/OBErrorCode';
import { baseContext, baseEvents } from 'features/Bento/libs/machines';
import { type VerificationCodeResponseSchema } from 'helpers/auth/service';
import { type Device } from 'helpers/device';

import { type AffiliationContext } from '../../../machine/model';
import { type SignupReferral } from '../../../types/SignupReferral';
import { defaultValues } from '../libs/formSchema';

export type AccountType =
  | 'standard'
  | 'shine-start'
  | 'capital-deposit'
  | 'sasu-creation';
export type CompanyProfileType =
  | 'solo'
  | 'fewer-than-ten-employees'
  | 'more-than-ten-employees';

/**
 * Placeholder function to get device that should be replaced at machine instantiation.
 */
const getDevice: () => Device = () => {
  throw Error('Not implemented');
};

const context = {
  ...baseContext,

  accountType: null as AccountType | null,

  affiliate: null as string | null,
  affiliationContext: undefined as AffiliationContext | undefined,

  companyProfileType: null as CompanyProfileType | null,
  form: defaultValues,
  helpers: {
    getDevice,
  },
  passcode: null as string | null,
  phoneNumber: null as string | null,
  /**
   * Used to assess that this user just went through an OTP with this phone number.
   * This token should be passed into the /end API call and the back-end will verify its authenticity.
   */
  phoneNumberOwnershipToken: null as string | null,
  referral: null as SignupReferral,
  standardType: null as string | null,
  verificationCodeError: null as OBErrorCode | null,
  verificationCodeTimestamp: null as string | null,
};

export type Context = typeof context;

const creators = {
  events: {
    ...baseEvents,

    ASK_NEW_VERIFICATION_CODE: () => ({}),

    'done.invoke.checkVerificationCode': (
      data: VerificationCodeResponseSchema['data'],
    ) => ({
      data,
    }),
    'done.invoke.startRegister': (data: null) => ({
      data,
    }),
    'error.platform.createAccountAndLogin': (data: Error) => ({
      data,
    }),
    GO_REFERRAL_GUIDE: () => ({}),
    SUBMIT_FORM_DATA: (data: {
      gender: Gender;
      firstName: string;
      lastName: string;
      email: string;
      hasAgreedToTermsOfService: boolean;
    }) => ({ data }),
    SUBMIT_PASSCODE: (data: string) => ({ data }),

    SUBMIT_PHONE: (data: string) => ({ data }),
    SUBMIT_VERIFICATION_CODE: (data: string) => ({
      data,
    }),
  },
};

const model = createModel(context, creators);

export default model;

export type Model = typeof model;
