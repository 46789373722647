/**
 * The key of the param designating the referralCode of this user.
 */
export const REFERRAL_PARAM = 'referral';

/**
 * The key of the param corresponding to the affiliateLandingHref the user is coming from.
 */
export const AFFILIATE_PARAM = 'affiliate';

/**
 * The key of the param corresponding to the timestamp of the user's visit.
 */
export const VISITED_AT_PARAM = 'visitedAt';

/**
 * Query param mandatory for an Affilae url
 */
export const AFFILAE_PARAM = 'ae';
