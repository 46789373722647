import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    description:
      'Make sure your address is correct, we will then ask you for a proof of address.',
    title: {
      default: 'What is your personal address?',
      fill: 'Type your personnal address',
      verify: 'Verifiy your personnal address',
    },
  },
  fr: {
    description:
      'Nous vous demanderons par la suite un justificatif de domicile afin de vérifier votre adresse.',
    title: {
      default: 'Quelle est votre adresse personnelle\u00a0?',
      fill: 'Saisissez votre adresse personnelle',
      verify: 'Vérifiez votre adresse personnelle',
    },
  },
} as const);
