import { type FC, useEffect, useState } from 'react';
import {
  countries as defaultCountries,
  type CountryCode,
} from '@shinetools/geo-library';
import { uniqBy } from 'ramda';

import { type ListedCountry } from 'common/countries/types';
import * as Form from 'components/_core/form';
import { getAllowedCountryCodes } from 'helpers/auth/service';
import logger from 'helpers/logger';

import { type CountryCallingCodeOption } from './types';

const mapCountryToCallingCode = ({
  callingCode,
  flag,
}: Pick<ListedCountry, 'callingCode' | 'flag'>) => ({
  flag,
  label: `+${callingCode}`,
  value: callingCode,
});

const sortByLabel = (
  a: Pick<ListedCountry, 'callingCode'>,
  b: Pick<ListedCountry, 'callingCode'>,
) => parseInt(a.callingCode) - parseInt(b.callingCode);

const CountryCallingCodePicker: FC<{
  value: string;
  onChange: (countryCallingCode: string) => void;
}> = ({ onChange, value }) => {
  const [countries, setCountries] = useState<CountryCallingCodeOption[]>([
    mapCountryToCallingCode(defaultCountries.FR),
  ]);

  const getCountryList = async () => {
    try {
      const allowedCountryCodes = await getAllowedCountryCodes();
      const allowedCountries = allowedCountryCodes
        .map((countryCode) => {
          const country = defaultCountries[countryCode as CountryCode];
          if (country === undefined) {
            logger.error(`Unhandled country ISO code ${countryCode}`);
          }
          return country;
        })
        .filter((country) => country !== undefined);

      // Some "countries" share the same phone code, e.g: +262 for some french overseas territories
      const uniqueCountries = uniqBy<ListedCountry, string>(
        (country) => country.callingCode,
      )(allowedCountries);

      const formattedCountries = uniqueCountries
        .sort(sortByLabel)
        .map(mapCountryToCallingCode);
      setCountries(formattedCountries);
    } catch (error) {
      logger.warn('Failed to fetch country list');
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  return (
    <Form.SunshineSelect
      components={{
        Option: Form.SunshineSelect.components.LanguageOption,
        SingleValue: Form.SunshineSelect.components.LanguageSingleValue,
      }}
      onChange={(option) => onChange(option?.value ?? '')}
      options={countries}
      value={countries.find((country) => country.value === value)}
    />
  );
};

export default CountryCallingCodePicker;
