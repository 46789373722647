import { type CompanyProfileInputState } from './machine/model';

export const COMPANY_INPUT_STORAGE_KEY = 'onboarding.companyProfileInputState';

const set = (companyProfileInput: CompanyProfileInputState) =>
  localStorage.setItem(
    COMPANY_INPUT_STORAGE_KEY,
    JSON.stringify(companyProfileInput),
  );

const get = () => {
  const rawData = localStorage.getItem(COMPANY_INPUT_STORAGE_KEY);

  if (!rawData) {
    return null;
  }

  try {
    return JSON.parse(rawData) as CompanyProfileInputState;
  } catch {
    return null;
  }
};

const clear = () => localStorage.removeItem(COMPANY_INPUT_STORAGE_KEY);

const companyProfileStorage = {
  clear,
  get,
  set,
};

export default companyProfileStorage;
