import { createContext, useContext, useState } from 'react';

import { IS_PRODUCTION } from 'common/environment';
import { type FCWithChildren } from 'common/types';
// eslint-disable-next-line no-restricted-imports
import baseFeatureFlags, {
  type FeatureFlags,
  type FeatureFlagsKey,
} from 'featureFlags';

const getStorageValue = (key: string) => sessionStorage.getItem(`flag-${key}`);

const FeatureFlagsSwitcherContext = createContext<
  | {
      toggleFeatureFlag: (key: FeatureFlagsKey) => void;
      featureFlags: FeatureFlags;
      reset: () => void;
      isBetaTester: boolean;
      setIsBetaTester: (newValue: boolean) => void;
    }
  | undefined
>(undefined);

const featureKeys = Object.keys(
  baseFeatureFlags,
) as (keyof typeof baseFeatureFlags)[];

export const FeatureFlagsSwitcherProvider: FCWithChildren = ({ children }) => {
  const [isBetaTester, setIsBetaTester] = useState(false);

  const getFeatureKeyValue = (key: FeatureFlagsKey): boolean => {
    // If we are in production, we always return the value from the base feature flags
    if (IS_PRODUCTION && !isBetaTester) {
      return baseFeatureFlags[key];
    }

    const storageValue = getStorageValue(key);

    // If the flag is not set in sessionStorage, we return the value from the base feature flags
    if (!storageValue) {
      return baseFeatureFlags[key];
    }

    return storageValue === 'true';
  };

  const initialFeatureFlags: FeatureFlags = featureKeys.reduce(
    (acc, featureKey) => ({
      ...acc,
      [featureKey]: getFeatureKeyValue(featureKey),
    }),
    baseFeatureFlags,
  );

  const [featureFlags, setFeatureFlagsState] = useState(initialFeatureFlags);

  const toggleFeatureFlag = (key: FeatureFlagsKey) => {
    if (IS_PRODUCTION && !isBetaTester) {
      return;
    }

    sessionStorage.setItem(`flag-${key}`, featureFlags[key] ? 'false' : 'true');
    setFeatureFlagsState({ ...featureFlags, [key]: !featureFlags[key] });
  };

  const reset = () => {
    featureKeys.forEach((key) => {
      sessionStorage.removeItem(`flag-${key}`);
    });
    setFeatureFlagsState(baseFeatureFlags);
  };

  return (
    <FeatureFlagsSwitcherContext.Provider
      value={{
        featureFlags,
        isBetaTester,
        reset,
        setIsBetaTester,
        toggleFeatureFlag,
      }}
    >
      {children}
    </FeatureFlagsSwitcherContext.Provider>
  );
};

const useFeatureFlagsSwitcher = () => {
  const context = useContext(FeatureFlagsSwitcherContext);

  if (!context) {
    throw new Error(
      '"useFeatureFlagsSwitcher" can only be used under FeatureFlagsSwitcherContext',
    );
  }
  return context;
};

export default useFeatureFlagsSwitcher;
export type { FeatureFlags, FeatureFlagsKey };
