import { type SystemStyleObject } from '@chakra-ui/theme-tools';

import Badge from './Badge';

const { defaultProps } = Badge;

const baseStyle: SystemStyleObject = {
  borderRadius: 'radius-2',
  fontFamily: 'mono',
  fontSize: 'font-14',
  paddingX: '0.2em',
};

const CodeTheme = {
  baseStyle,
  defaultProps,
};

export default CodeTheme;
