import { type FC, type FormEvent } from 'react';
import { Box } from '@chakra-ui/react';

import Button from 'components/_core/Button';
import * as Form from 'components/_core/form';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

import LoginBackButton from '../components/BackButton';
import locales from './locales';
import PhoneInput from './PhoneInput';
import * as S from './styles';
import { type PhoneNumberComponentProps } from './types';

const PhoneNumber: FC<PhoneNumberComponentProps> = ({
  countryCode,
  errorMessage,
  isSubmitDisabled,
  label,
  onBack,
  onSubmit,
  onUpdateCountryCode,
  onUpdatePhone,
  phone,
  subtitle,
  title,
  withNoBackButton,
}) => {
  const phoneInput = (
    <PhoneInput
      countryCode={countryCode}
      onUpdateCountryCode={onUpdateCountryCode}
      onUpdatePhone={onUpdatePhone}
      phone={phone}
    />
  );

  return (
    <S.Container
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {withNoBackButton ? (
        /**
         * visually align the texts between the steps
         * when there is no back button
         */
        <Box height="space-80" />
      ) : (
        <LoginBackButton onClick={onBack} />
      )}

      <Box marginBottom="space-24">
        {title ? <Heading>{title}</Heading> : null}
        {subtitle ? <Text marginTop="space-8">{subtitle}</Text> : null}
      </Box>

      <S.Form>
        <Form.Field
          error={errorMessage}
          label={label ?? locales.phoneNumber}
          width="100%"
        >
          {phoneInput}
        </Form.Field>
      </S.Form>

      <Button isDisabled={isSubmitDisabled} type="submit">
        {locales.next}
      </Button>
    </S.Container>
  );
};

export default PhoneNumber;
