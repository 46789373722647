/* eslint-disable react/display-name */

import { type ComponentPropsWithRef, type FC } from 'react';

import RouteParameter from '../components/RouteParameter';

/**
 * Wraps a Bento flow inside a Route to allow programmatic navigation.
 */
const withFlowWrapper =
  (Component: FC) => (props: ComponentPropsWithRef<FC>) => (
    <RouteParameter param="page">
      <Component {...props} />
    </RouteParameter>
  );

export default withFlowWrapper;
