import { type SystemStyleFunction } from '@chakra-ui/theme-tools';
import { getTokenValue } from '@shinetools/sunshine';

const selectedBoxShadow = `0px 0px 0px 1px ${getTokenValue(
  'card-borderColor-selected',
)}`;

const baseStyle: SystemStyleFunction = ({
  isActionnable,
  isActive,
  isBoxed,
}) => ({
  alignItems: 'flex-start',
  backgroundColor: 'card-backgroundColor',
  borderRadius: 'radius-8',
  display: 'flex',
  gap: 'space-16',
  width: 'full',
  ...(isBoxed && {
    ...(isActive && {
      _focus: { boxShadow: selectedBoxShadow },
      _focusVisible: { boxShadow: 'outline-rebranded' },
      boxShadow: selectedBoxShadow,
      zIndex: 5,
    }),
    border: '1px solid',
    borderColor: isActive ? 'card-borderColor-selected' : 'card-borderColor',
    padding: 'space-16',
  }),
  ...(isActionnable && {
    _focusVisible: {
      borderColor: 'transparent',
      boxShadow: 'outline-rebranded',
      outline: 'none',
      zIndex: 6,
    },
    _hover: {
      backgroundColor: 'card-backgroundColor-hover',
      borderColor: isActive
        ? 'card-borderColor-selected'
        : 'card-borderColor-hover',
    },
    cursor: 'pointer',
    transitionDuration: 'duration-200',
    transitionProperty: 'common',
    transitionTimingFunction: 'ease-out',
  }),
  _disabled: {
    backgroundColor: 'card-backgroundColor-disabled',
    borderColor: 'card-borderColor-disabled',
    cursor: 'not-allowed',
  },
});

const SunshineCardTheme = {
  baseStyle,
};

export default SunshineCardTheme;
