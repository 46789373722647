import { type FC } from 'react';
import {
  Divider,
  forwardRef,
  Stack,
  type StackProps,
  useStyleConfig,
} from '@chakra-ui/react';

import { CardGroupContextProvider } from '../../hooks/useCardGroup';
import { CLASSNAME } from '../../utils';

interface GroupProps extends StackProps {
  isActive?: boolean;
  isBoxed?: boolean;
}

// TODO: improve support of focus + active state
const Group: FC<GroupProps> = forwardRef(
  ({ children, isActive, isBoxed = true, ...props }, ref) => {
    const { gap, padding, ...styles } = useStyleConfig('SunshineCard', {
      isActive,
      isBoxed,
    });

    return (
      <CardGroupContextProvider value={{ isBoxed }}>
        <Stack
          __css={styles}
          alignItems="stretch"
          divider={<Divider borderColor="border" />}
          spacing="space-0"
          {...props}
          ref={ref}
          sx={{
            [`> .${CLASSNAME}`]: {
              // when grouped, the border between two Cards will be replaced by the Stack divider
              borderWidth: '0',
              // force the vertical padding
              paddingY: 'space-16',
            },
          }}
        >
          {children}
        </Stack>
      </CardGroupContextProvider>
    );
  },
);

export default Group;
