import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    cta: 'Continue',
    form: {
      birthCityNoResults: 'No results with this name...',
      birthCityOther: 'It is indeed my birth city',
      errors: {
        missingBirthDepartment: 'Please select your department of birth',
      },
      labelBirthCity: 'Birth city',
      labelBirthCountry: 'Country of birth',
      labelBirthdate: 'Date of birth',
      labelBirthDepartment: 'Birth department',
      labelFirstName: 'First name',
      labelLastName: 'Last name',
      labelLegalTitle: 'Legal title',
      labelNationality: 'Nationality',
      selectDepartment: 'Select a department',
    },

    page: {
      title: 'Fill in your personal information',
    },
  },
  fr: {
    cta: 'Continuer',
    form: {
      birthCityNoResults: 'Aucun résultat avec ce nom...',
      birthCityOther: 'C’est bien ma ville de naissance',
      errors: {
        missingBirthDepartment:
          'Veuillez renseigner le département de naissance',
      },
      labelBirthCity: 'Ville de naissance',
      labelBirthCountry: 'Pays de naissance',
      labelBirthdate: 'Date de naissance',
      labelBirthDepartment: 'Département de naissance',
      labelFirstName: 'Prénom',
      labelLastName: 'Nom',
      labelLegalTitle: 'Civilité',
      labelNationality: 'Nationalité',
      selectDepartment: 'Sélectionner un département',
    },

    page: {
      title: 'Compléter vos informations personnelles',
    },
  },
} as const);

export default locales;
