import {
  Children,
  type ElementType,
  isValidElement,
  type ReactNode,
} from 'react';

export type Slots = 'aside' | 'action';

interface UseSlotHook {
  children: ReactNode | ReactNode[];
  name: Slots;
}

const useSlot = ({ children, name }: UseSlotHook): ElementType | null => {
  const hit = Children.toArray(children).find(
    (child) => isValidElement(child) && child.props.name === name,
  );

  if (isValidElement(hit)) {
    return hit.props.children;
  }

  return null;
};

export default useSlot;
