import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    title: 'Switch account',
  },
  fr: {
    title: 'Changer de compte',
  },
} as const);

export default locales;
