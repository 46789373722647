import useReplaceModuleLocation from 'features/Bento/hooks/useReplaceModuleLocation';
import stringifyState from 'features/Bento/libs/machines/stringifyState';

import { type SignupModule } from '../../types/SignupModule';
import { type model, type State } from './machine';
import { getPageFromState } from './pages';

/**
 * This module redirects the user if he•she's on mobile and multi-kyc
 */
const AccountCreation: SignupModule<typeof model> = ({
  machine: [state, send],
  ...props
}) => {
  useReplaceModuleLocation(stringifyState(state));

  const Component = getPageFromState(stringifyState(state) as State);

  return <Component {...props} send={send} state={state} />;
};

export default AccountCreation;
