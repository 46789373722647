import { type ComponentType } from 'react';
import { components, type OptionProps } from 'react-select';

import Text from 'components/_core/Text';

const SelectOption: ComponentType<
  OptionProps<{ label: string; value: string }, false>
> = ({ children, data, isDisabled, ...props }) => (
  <components.Option data={data} isDisabled={isDisabled} {...props}>
    <Text variant={isDisabled ? 'disabled' : 'primary'}>{data.label}</Text>
  </components.Option>
);

export default SelectOption;
