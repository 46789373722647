import { type ReactNode } from 'react';
import {
  Box,
  Button as ChakraButton,
  type ButtonProps as ChakraButtonProps,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react';

import ButtonTheme, {
  isExpandableVariant,
  type Variant,
} from 'theme/components/Button';

import SunshineIcon, { type IconName } from '../SunshineIcon';

export interface ButtonProps
  extends Omit<ChakraButtonProps, 'leftIcon' | 'rightIcon'> {
  children: ReactNode; // for a Button with an icon but no text content, use IconButton instead
  icon?: IconName;
  iconPosition?: 'left' | 'right';
  /**
   *  use isDisabled instead
   */
  disabled?: undefined;
  /**
   * button with a defined minWidth
   */
  isExpanded?: boolean;
}

/**
 * @deprecated Use Button from `@shinetools/sunshine-universal` instead.
 *
 * @see https://shinetools.github.io/shine/sunshine-universal-storybook/?path=/docs/button--docs
 */
const Button = forwardRef<ButtonProps, 'button'>(
  (
    {
      children,
      icon,
      iconPosition = 'right',
      isExpanded,
      // variant can be undefined because the default value from the Chakra theme is applied afterwards
      // but we need this default value here so that isExpandableVariant returns the correct result
      variant = ButtonTheme.defaultProps.variant,
      ...props
    },
    ref,
  ) => {
    const isExpandable = isExpandableVariant(variant as Variant);

    if (isExpanded && !isExpandable) {
      // eslint-disable-next-line no-console
      console.warn(
        `☼ Button ☼ Cannot set "isExpanded" for the non-expandable button variant, "${variant}.`,
      );
    }

    const styles = useStyleConfig('Button', {
      iconPosition,
      isExpanded: isExpandable && isExpanded,
      variant,
      ...props,
    });

    return (
      <ChakraButton
        __css={styles}
        ref={ref}
        {...(icon &&
          iconPosition === 'left' && {
            leftIcon: <SunshineIcon name={icon} />,
          })}
        {...(icon &&
          iconPosition === 'right' && {
            rightIcon: <SunshineIcon name={icon} />,
          })}
        {...props}
      >
        <Box flexGrow={1}>{children}</Box>
      </ChakraButton>
    );
  },
);

export default Button;
