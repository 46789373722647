import { type FC, type ReactNode } from 'react';

import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

import * as S from './styles';

interface NoAccessScreenProps {
  testId: string;
  locales: {
    disclaimer: string;
    description: string;
  };
  button: ReactNode;
}

const NoAccessScreen: FC<NoAccessScreenProps> = ({
  button,
  locales,
  testId,
}) => (
  <S.FullscreenContainer data-testid={testId}>
    <S.InnerWrapper>
      <S.Logo />
      <Heading>{locales.disclaimer}</Heading>
      <Text marginBottom="space-24" marginTop="space-16">
        {locales.description}
      </Text>
      {button}
    </S.InnerWrapper>
  </S.FullscreenContainer>
);

export default NoAccessScreen;
