/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8558%3A35124&t=GNHsXVlDt2Zpruph-1
 */

import { formErrorAnatomy } from '@chakra-ui/anatomy';
import {
  type PartsStyleObject,
  type SystemStyleObject,
} from '@chakra-ui/theme-tools';

export type Parts = typeof formErrorAnatomy;

const baseStyleText: SystemStyleObject = {
  color: 'error',
  fontSize: 'font-12',
};

const baseStyle: PartsStyleObject<Parts> = {
  text: baseStyleText,
};

const FormErrorTheme = {
  baseStyle,
  parts: formErrorAnatomy.keys,
};

export default FormErrorTheme;
