import {
  chakra,
  type ComponentWithAs,
  MenuItem,
  type MenuItemProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { type ColorName } from '@shinetools/sunshine';
import { type IconName } from '@shinetools/sunshine-icons';

import SunshineIcon from 'components/_core/SunshineIcon';

import Text from '../../../Text';
import { StylesProvider } from '../../context';

export interface ItemProps extends Omit<MenuItemProps, 'command' | 'icon'> {
  colorScheme?: ColorName;
  icon?: IconName;
}

const Item: ComponentWithAs<typeof MenuItem, ItemProps> = ({
  children,
  colorScheme = 'grey',
  icon,
  ...props
}) => {
  /**
   * Re-generate the theme here to be able to use `colorScheme`
   * on the Item level rather than at the root
   */
  const styles = useMultiStyleConfig('SunshineDropdown', {
    colorScheme,
  });

  return (
    <StylesProvider value={styles}>
      <MenuItem {...props} sx={styles.item}>
        {icon ? (
          <chakra.div __css={styles.itemIcon}>
            <SunshineIcon name={icon} size="icon-16" />
          </chakra.div>
        ) : null}

        <Text color="inherit" width="full">
          {children}
        </Text>
      </MenuItem>
    </StylesProvider>
  );
};

export default Item;
