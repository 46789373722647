import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    download: 'Download the document',
  },
  fr: {
    download: 'Télécharger le document',
  },
} as const);

export default locales;
