import ReactIcomoon from 'react-icomoon';
import {
  chakra,
  type ChakraProps,
  forwardRef,
  type ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { type IconName, iconSet } from '@shinetools/suntheme';

const ChakraIcomoon = chakra(ReactIcomoon);

// visual offset between our font and the icon
const OFFSET = '-0.15em';

export interface IconProps extends ThemingProps<'Icon'>, ChakraProps {
  name: IconName;
}

/**
 * @deprecated use _core/SunshineIcon instead
 */
const Icon = forwardRef<IconProps, 'svg'>(
  (
    {
      css,
      name,
      size = '1em', // use parent's font-size
      ...props
    },
    ref,
  ) => {
    const {
      // use theme sizes or fallback to the size prop
      height = size,
      width = size,
      ...styles
    } = useStyleConfig('Icon', { size });

    return (
      <ChakraIcomoon
        __css={{ height, width, ...styles }}
        icon={name}
        iconSet={iconSet}
        ref={ref}
        // enforce sizing
        size={width}
        verticalAlign={OFFSET}
        {...props}
      />
    );
  },
);

export type { IconName };
export default Icon;
