import styled, { css } from 'styled-components';

import { type LegacyTheme } from 'theme/legacyTheme';

const DAY_MARGIN = 5;

const inputMixin = css`
  background-color: transparent;
  color: ${({ theme }): string => theme.colors.grey(-2)};
  font-size: ${({ theme }) => theme.texts.typeScale(0)}px;
  padding: ${({ theme }) => theme.spacings.abs4}px 0
    ${({ theme }) => theme.spacings.abs8}px 0;
  outline: none;
  border: 0;
  font-family: var(--fonts-mabry);
  font-weight: 500;

  :not(:focus)::placeholder {
    color: transparent;
  }

  ::placeholder {
    color: ${({ theme }): string => theme.colors.grey(0)};
    font-weight: 400;
  }
`;

export const DatePickerWrapper = styled.div`
  .react-datepicker {
    padding: ${({ theme }: { theme: LegacyTheme }) => theme.spacings.abs16}px;
    border: 0;
    border-radius: 8px;

    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);

    &-wrapper {
      width: 100%;

      input {
        width: 100%;
        ${inputMixin}
      }
    }

    &-popper[data-placement^='bottom'] {
      margin-top: ${({ theme }) => theme.spacings.abs8}px;
    }

    &__triangle {
      display: none;
    }

    &__month-container {
      border: 0;
    }

    &__header {
      background: transparent;
      border: 0;
      padding: 0;
    }

    &__day-names {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &__day-name {
      margin: 0;
      text-transform: uppercase;
      font-size: ${({ theme }: { theme: LegacyTheme }) =>
        theme.texts.typeScale(-2)}px;
      color: ${({ theme }: { theme: LegacyTheme }) => theme.colors.grey(1)};
      line-height: 1;
      padding: 14px 0;
    }

    &__day {
      margin: ${DAY_MARGIN}px;
      /* sizes are in pixels to match initial styling from react-datepicker.css */
      line-height: 1.8rem;
      font-family: var(--fonts-mabry);
      font-size: ${({ theme }: { theme: LegacyTheme }) =>
        theme.texts.typeScale(-1)}px;
      color: ${({ theme }: { theme: LegacyTheme }) => theme.colors.grey(-2)};

      &:hover,
      &--keyboard-selected,
      &--selected {
        color: ${({ theme }: { theme: LegacyTheme }) => theme.colors.white(0)};
        background-color: ${({ theme }: { theme: LegacyTheme }) =>
          theme.colors.ctaBlue(-1)};
        border-radius: 50%;
      }

      &--outside-month,
      &--disabled {
        color: ${({ theme }: { theme: LegacyTheme }) => theme.colors.grey(1)};
        cursor: default;
        pointer-events: none;

        &:hover {
          color: ${({ theme }: { theme: LegacyTheme }) => theme.colors.grey(1)};
          background: none;
        }
      }

      &--today {
        font-weight: inherit;
      }
    }

    &__month {
      margin: ${-DAY_MARGIN}px;
    }

    &__week {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const DatePickerCustomHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DatePickerDropdowns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > div:first-of-type {
    /*
      The dropdown for the month should be wider than the one for the year.
      This is to ensure that the dropdown for the month can display the full month name 
      without being cut off.
     */
    min-width: 130px;
  }
`;

export const DatePickerButton = styled.button.attrs({
  type: 'button',
})`
  width: 26px;
  height: 26px;
  border: 0;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borders.radius.base}px;

  &:hover {
    background: ${({ theme }: { theme: LegacyTheme }) =>
      theme.colors.ctaBlue(4)};
  }

  &:disabled {
    opacity: 0.5;
    background: none;
  }

  /* add a gap between the two arrows */
  & + & {
    margin-left: 2px;
  }
`;
