import { type FC } from 'react';
import { Box, Text } from '@chakra-ui/react';

interface FieldRecapProps {
  label: string;
  value: string;
}
const FieldRecap: FC<FieldRecapProps> = ({ label, value }) => (
  <Box>
    <Text>{label}</Text>
    <Text fontWeight="weight-500" variant="primary">
      {value}
    </Text>
  </Box>
);

export default FieldRecap;
