import { type FC, type ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';

import { apolloClient } from 'common/graphql/clients';

interface Props {
  children: ReactNode;
}

const ClientProvider: FC<Props> = ({ children }) => (
  <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
);

export default ClientProvider;
