import { type ComponentWithAs } from '@chakra-ui/system';

import Text, { type TextProps } from '../../../Text';
import { useCard } from '../../hooks/useCard';

const CardTitle: ComponentWithAs<'p', TextProps> = (props) => {
  const { isDisabled } = useCard();

  return (
    <Text
      fontWeight="weight-500"
      variant="primary"
      {...props}
      {...(isDisabled && { variant: 'disabled' })}
    />
  );
};

export default CardTitle;
